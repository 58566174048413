import { RequiredAccountConfigurationBankConciliations } from '../../models/special-document/ElementArgs'
import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'


export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface RegistersSamplingDifference {
  id?: number
  account?: string
  difference_value?: number
  is_total_difference?: boolean
  is_error?: boolean
} 

export class SamplingDifferenceBankConciliationsRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  async getRegistersSamplingDifference(engagement_id: number|null | undefined, documentId: number, reference: string | undefined): Promise<RegistersSamplingDifference> {
    const apiUrl = getUrl('substantive_test_api/get_substantive_test_sampling_difference/' + engagement_id + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: RegistersSamplingDifference = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}
