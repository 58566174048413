import React, { useState } from 'react';
import { T } from "../../utils/i18n-config"
import EditModeBase from "./EditModeBase";
import EditModeProps from './EditModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import ModalWithButtons from '../modals/AcceptModalBase';



/**
* Component to display the memorandum of other risks in edit mode
* @param iElement - Element to display
* @param handleDeleteElement - Function to delete the element
* @param handleUpElement - Function to move the element up
* @param handleDownElement - Function to move the element down
*/
const MemorandumOtherRisksEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isEditable , setIsEditable] = useState<boolean>(false);

  /**
   * Function to handle the configuration of the element
   */
  const handleConfiguration = () => {
    setShowConfigurationsModal(true);
  };

  /**
   * Function to handle the accept of the configurations
   * and close the modal
   */
  const handleConfigurationsModalAccept = () => {
    setShowConfigurationsModal(false);    
  };

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={isEditable}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{T("Element to display the memorandum of other risks")}</h6>
            </div>
          </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Element to display the memorandum of other risks")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}>
        <div style={{display: "flex", justifyContent: "flex-end"}}></div>
        <div className="card-body p-3">
          <h6>{T("Element to display the memorandum of other risks")}</h6>
        </div>
      </ModalWithButtons>
    </div>
  )
}
export default MemorandumOtherRisksEditMode;
