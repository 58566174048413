import React, { useEffect, useRef, useState } from 'react'
import { T } from "../../utils/i18n-config"
import { InternalControlComponentsQuestionsElement } from "../../models/special-document/InternalControlComponentsQuestionsElement"
import { Permissions } from '../../models/special-document/ElementBase'
import ViewModeProps from './ViewModeProps';
import ViewModeBase from "./ViewModeBase";


const InternalControlComponentsConfigurationViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<InternalControlComponentsQuestionsElement>(iElement as InternalControlComponentsQuestionsElement)

  return (
    <div>
      <ViewModeBase
        isEditable={false}
        permissions={element.permissions as Permissions}>
        <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{T("Element for displaying the internal control components configuration")}</h6>
            </div>
          </div>
      </ViewModeBase>
    </div>
  );
};

export default InternalControlComponentsConfigurationViewMode;
