import { MenuService } from './services/business-logic/MenuService'
import { BoardController } from './controller/BoardController'
import { AccountFlowchartService } from './services/business-logic/AccountFlowchartService'
import { HtmlHelpers } from './utils/HtmlHelpers'
import { changeLanguage } from './utils/i18n-config'
import { EngagementMaterialityReportController } from './controller/EngagementMaterialityReportController'
import { specialDocumentBoardRenderer } from './renderer/specialDocumentBoardRenderer';
import { notesAndFindingsRenderer } from './renderer/NotesAndFindingsRenderer'
import { notesRenderer } from './renderer/NotesRenderer'
import { AppNotificationRenderer } from './renderer/AppNotificationRenderer';
import { AttachmentsChatRenderer } from './renderer/AttachmentsChatRenderer';
import { GoingConcernMemoRenderer } from './renderer/GoingConcernMemoRenderer';
import { CompanySettingsService } from './services/business-logic/CompanySettingsService';
import { TrialBalanceVariations } from './services/business-logic/TrialBalanceVariations'
import { EngagementVariableFactory } from './factory/EngagementVariableFactory'
import { TrialBalanceUploadSessionController } from './controller/TrialBalanceUploadSessionController'
import { UserAvatarFactory } from './factory/UserAvatarFactory'
import { subsequentEventsRenderer } from './renderer/SubsequentEventsRenderer'


document.addEventListener('DOMContentLoaded', () => {
  const menuService = new MenuService();
  window.menuService = menuService

  const boardController = new BoardController();
  window.boardController = boardController;

  const accountFlowchartService = new AccountFlowchartService();
  window.accountFlowchartService = accountFlowchartService

  const htmlHelpers = new HtmlHelpers();
  window.htmlHelpers = htmlHelpers

  const engagementMaterialityReportController = new EngagementMaterialityReportController();
  window.engagementMaterialityReportController = engagementMaterialityReportController;

  window.specialDocumentBoardRenderer = specialDocumentBoardRenderer;

  window.notesAndFindingsRenderer = notesAndFindingsRenderer;

  window.subsequentEventsRenderer = subsequentEventsRenderer

  window.notesRenderer = notesRenderer;

  window.appNotificationRenderer = AppNotificationRenderer;

  window.attachmentsChatRenderer = AttachmentsChatRenderer;

  window.goingConcernMemoRenderer = GoingConcernMemoRenderer;

  const companySettingsService = new CompanySettingsService();
  window.companySettingsService = companySettingsService;

  const trialBalanceVariations = new TrialBalanceVariations();
  window.trialBalanceVariations = trialBalanceVariations;

  const trialBalanceUploadSessionController = new TrialBalanceUploadSessionController();
  window.trialBalanceUploadSessionController = trialBalanceUploadSessionController;

  const engagementVariableFactory = EngagementVariableFactory.getInstance();
  engagementVariableFactory.getAll();
  window.engagementVariableFactory = engagementVariableFactory;

  const userAvatarFactory = UserAvatarFactory.getInstance();
  window.userAvatarFactory = userAvatarFactory;

  window.changeLanguage = changeLanguage;
})
