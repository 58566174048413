import React, { useState, useEffect, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { Permissions } from '../../models/special-document/ElementBase'
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { ItemOpeningBalancesElement } from "../../models/special-document/ItemOpeningBalancesElement"
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeProps from './EditModeProps'
import EditModeBase from './EditModeBase'
import { ItemsOpeningBalances } from '../../models/special-document/ElementArgs'


export interface LedgerAccountTypeOpeningBalance {
  id: number;
  code: string;
  name: string;
}


/**
 * Component to edit the ItemOpeningBalances element
 * @param iElement - ItemOpeningBalancesElement
 * @param handleDeleteElement - Function to delete the element
 * @param handleUpElement - Function to move the element up
 * @param handleDownElement - Function to move the element down
 */
const ItemOpeningBalancesEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<ItemOpeningBalancesElement>(iElement as ItemOpeningBalancesElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [itemOpeningBalances, setItemOpeningBalances] = useState<ItemsOpeningBalances[]>(element.items_opening_balances)
  const [accounts, setAccounts] = useState<LedgerAccountTypeOpeningBalance[]>([])
  const [newItems, setNewItems] = useState<ItemsOpeningBalances[]>([]);
  const [accountsError, setAccountsError] = useState<string>("");

  /**
   * Get the accounts from the ledger
   */
  const getAccounts = async () => {
    const elementRepository = new ElementRepository()
    const result = await elementRepository.getLedgerAccountItemOpening(element.args.engagement_id, element.documentId, element.id)
    if (result.success) {
      setAccounts(result.data)
    } else {
      setAccounts([])
    }
  }

  useEffect(() => {
    getAccounts()
    updateDropdownOptions();
  }, [])

  /**
   * Update the dropdown options
   */
  const updateDropdownOptions = () => {
    const existingAccounts = element.items_opening_balances
      .map((item) => item.account_code)
      .filter((code) => code !== undefined);

    const selectedAccounts = newItems
      .map((item) => item.account_code)
      .filter((code) => code !== undefined);

    const filteredAccounts = accounts.filter(
      (account) => !existingAccounts.includes(Number(account.code)) && !selectedAccounts.includes(Number(account.code))
    );
    setAccounts(filteredAccounts);
  };

  /**
   * Handle the configurations modal cancel
   */
  function handleConfigurationsModalCancel() {
    setShowConfigurationsModal(false)
    setItemOpeningBalances(element.items_opening_balances)
    setNewItems([])
  }

  /**
   * Handle the edit button
   */
  const handleEdit = () => {
    setShowConfigurationsModal(true);
  };

  /**
   * Handle the configurations modal accept button
   */
  async function handleConfigurationsModalAccept() {
    if (accountsError !== "") {
      return
    }
    const elementRepository = new ElementRepository()
    const lastItems = [...itemOpeningBalances, ...newItems]
    element.items_opening_balances = lastItems
    const response = await elementRepository.saveElement("edition", element.args)
    if (!response) {
      window.htmlHelpers?.swalError()
    } else {
      setShowConfigurationsModal(false)
      setItemOpeningBalances(lastItems)
      setNewItems([])
    }
  }

  /**
   * Add a new item to the new items list
   */
  const addNewItem = () => {
    setNewItems((prevNewItems) => [
      ...prevNewItems,
      {
        id: Date.now(),
        title: "",
        account_code: undefined,
        test_performed: undefined,
        attachment_name: undefined,
        reference_substantive_test: undefined,
        status: undefined,
      } as ItemsOpeningBalances,
    ]);
  };

  /**
   * Handle the account change
   * @param itemId - Item id
   * @param selectedAccount - Selected account
   */
  const handleAccountChange = (itemId: number, selectedAccount: { code: number; name: string }) => {
    const newOptions = [...newItems]
    const itemIndex = newOptions.findIndex((item) => item.id === itemId);
    if (itemIndex !== -1) {
      newOptions[itemIndex].account_code = selectedAccount.code;
      newOptions[itemIndex].title = selectedAccount.name;
      setNewItems(newOptions);
    }
    setNewItems(newOptions)
    validateDuplicates(newOptions, "accounts")
  };

  /**
   * Validate the duplicates
   * @param items - Items to validate
   * @param reference - Reference
   */
  const validateDuplicates = (items: ItemsOpeningBalances[], reference: string) => {
    if (reference === "accounts") {
      const codes = items.map((item) => item.account_code).filter((code) => code !== null);
      const hasDuplicates = codes.length !== new Set(codes).size;
      if (hasDuplicates) {
        setAccountsError(T("Values from the list of duplicate accounts. To move forward make sure that each account is unique."));
      } else {
        setAccountsError("");
      }
    }
  };

  /**
   * Handle the delete item
   * @param itemId - Item id
   */
  const handleDeleteItem = (itemId: number) => {
    const updatedItems = newItems.filter((item) => item.id !== itemId);
    setNewItems(updatedItems);
    validateDuplicates(updatedItems, "accounts")
  };

  /**
   * Handle the delete current item
   * @param id - Id of the item
   */
  const handleDeleteCurrentItem = (id: number) => {
    const updatedItems = itemOpeningBalances.filter((item) => item.id !== id);
    setItemOpeningBalances(updatedItems);
  }

  return (
    <React.Fragment>
      {element.args.engagement_id ? (
        <React.Fragment>
          <EditModeBase
            iElement={iElement}
            isEditable={false}
            handleDeleteElement={handleDeleteElement}
            handleUpElement={handleUpElement}
            handleDownElement={handleDownElement}
            handleConfiguration={handleEdit}
            permissions={iElement.permissions as Permissions}>
            <div className="d-flex flex-column align-items-center">
              <table className="w-60 table-bordered">
                <thead>
                  <tr>
                    <th className="p-2">{T("Account Name")}</th>
                    <th className="p-2">{T("Test Performed")}</th>
                    <th className="p-2">{T("Account Code")}</th>
                  </tr>
                </thead>
                <tbody>
                  {itemOpeningBalances.map((item, index) => (
                    <tr key={`saved-${index}`}>
                      <td>{item.title}</td>
                      <td>{item.reference_substantive_test ? T("Substantive test") : T("Attachment")}</td>
                      <td>{item.account_code}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </EditModeBase>
          <ModalWithButtons
            showModal={showConfigurationsModal}
            title={T("Configuration")}
            size="xl"
            onAccept={handleConfigurationsModalAccept}
            onCancel={handleConfigurationsModalCancel}>
            <div className="d-flex flex-column align-items-center">
              <table className="w-80 table-auto border border-gray-300">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="p-2 border border-gray-300">{T("Account Name")}</th>
                    <th className="p-2 border border-gray-300">{T("Account Code")}</th>
                    <th className="p-2 border border-gray-300">{T("Actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {itemOpeningBalances.map((item, index) => (
                    <tr key={`saved-${index}`} className="border border-gray-300">
                      <td className="p-2 border border-gray-300">{item.title}</td>
                      <td className="p-2 border border-gray-300">{item.account_code}</td>
                      <td className="p-2 border border-gray-300 text-center">
                        <button
                          className="p-1 bg-transparent border-0 text-danger"
                          onClick={() => handleDeleteCurrentItem(item.id)}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {element.args.engagement_id && (
                <div className="w-80 mt-3">
                  <h3>{T("New Items")}</h3>
                  <table className="w-100">
                    <thead>
                      <tr>
                        <th className="w-50">{T("Account Code")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {newItems.map((item, index) => (
                        <tr key={`newItem-${item.account_code || index}`}>
                          <td className="w-95">
                            <select
                              id="dropdown"
                              className="form-select"
                              value={
                                item.account_code
                                  ? JSON.stringify({ code: item.account_code, name: item.title ?? "" })
                                  : ""
                              }
                              onChange={(e) => {
                                const selectedAccount = JSON.parse(e.target.value);
                                handleAccountChange(item.id, selectedAccount);
                              }}
                            >
                              <option value="" disabled>
                                {T("Select an option")}
                              </option>
                              {accounts &&
                                accounts.map((itemAccounts) => (
                                  <option
                                    key={`account-${itemAccounts.code}`}
                                    value={JSON.stringify({ code: itemAccounts.code, name: itemAccounts.name })}
                                  >
                                    {itemAccounts.code} - {itemAccounts.name}
                                  </option>
                                ))}
                            </select>
                          </td>
                          <td className="w-5">
                            <button
                              className="d-flex align-items-center p-1 bg-transparent border-0 text-danger"
                              onClick={() => handleDeleteItem(item.id)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div>
                    <div className="w-100 text-center">
                      {accountsError && <p style={{ color: "red" }}>{accountsError}</p>}
                    </div>
                  </div>
                  <div className="w-100 d-flex justify-content-end mt-1">
                    <button className="btn btn-primary" onClick={addNewItem}>{T("Add New Item")}</button>
                  </div>
                </div>
              )}
            </div>
          </ModalWithButtons>
        </React.Fragment>
      ) : (
        <div className="col-11">
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{T("Display component of audit procedures on opening balances. This component is edited from the engagement.")}</h6>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ItemOpeningBalancesEditMode;