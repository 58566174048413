import type { Question, QuestionAnswer } from './GoingConcernTypes';
import { useEffect, useState } from 'react';
import { GoingConcernRepository } from '../../repository/GoingConcernRepository';
import { T } from '../../utils/i18n-config';

function GoingConcernMemoDocument({ engagementId, onEdit }: { readonly engagementId: number, onEdit: (isEditing: boolean) => void }) {
  const [filteredQuestions, setFilteredQuestions] = useState<Question[]>([]);
  const [answers, setAnswers] = useState<QuestionAnswer[]>([]);

  useEffect(() => {
    const getQuestionsAndAnswers = async () => {
      const goingConcernRepository = new GoingConcernRepository();
      const answers = await goingConcernRepository.getRegisters<QuestionAnswer>(engagementId);
      const questions = await goingConcernRepository.getFormatQuestions<Question>(engagementId);

      const groupedQuestions = questions.map(question => {
        const hasSameTableId = answers.some(answer => answer.tableId === question.tableId);
        const questionAnswer = answers.find(answer => answer.id === question.id) ?? {};
        if(hasSameTableId) return { ...question, ...questionAnswer };
      }).filter(Boolean) as Question[];
      setFilteredQuestions(groupedQuestions);
      setAnswers(answers);
    };

    getQuestionsAndAnswers();
  }, []);

  /**
   * Gets the checked status for a question.
   * @param questionId The question id.
   * @returns The checked status.
   */
  function getCheckedStatus(questionId: number) {
    const answerDefault = answers.find(({ id }) => id === questionId);
    return (answerDefault?.answer != null && answerDefault?.answer === true) ? T("Yes") : T("No");
  }

  /**
   * Gets the input content for a question.
   * @param questionId The question id.
   * @returns The input content.
   */
  function getInputContent(questionId: number) {
    const textDefault = answers.find(({ id }) => id === questionId);
    return textDefault?.explanation;
  }

  /**
   * Redirects to the opinion view.
   */
  function goToOpinionView() {
    window.menuService?.breadcrumbAddLevel(
      `/audix/engagement_opinion/index.load/${engagementId}`, T("Opinion")
    );
  }

  return (
    <>
      <h4 className="text-center mb-3">{T("Memorandum Anwers")}</h4>
      <div className="d-flex flex-column gap-2 mx-auto px-4" style={{ maxWidth: "1000px" }}>
        {filteredQuestions.map(({ id, textContent, typeText, ignoreAnswer, answerYes, requiredTextBox, context }) => (
          <div key={id} className="text-dark">
            {(typeText === "title") && (
              <>
                <h4 className="mb-2 fw-bold text-center" style={{ textWrap: "balance" }}>{textContent}</h4>
                {(!ignoreAnswer && !!answerYes) && (
                  <span className="d-block ms-3 text-center">
                    <b>{T("Answer")}: </b>
                    <span className="text-uppercase">{getCheckedStatus(id)}</span>
                  </span>
                )}
              </>
            )}
            {(typeText === "subtitle") && (
              <h5 className="mb-1" style={{ textWrap: "balance" }}>{textContent}</h5>
            )}
            {(typeText === "final") && (
              <h5 className="my-4 mx-auto fw-bold text-center text-dark" style={{ width: "60ch", textWrap: "balance" }}>
                {textContent}
              </h5>
            )}
            {(typeText === "result_opinion") && (
              <pre className="mt-1 mb-2 fs-6" style={{ textWrap: "wrap", fontFamily: "sans-serif" }}>
                {textContent}
              </pre>
            )}
            {(typeText === "question") && (
              <p className="mb-0 text-dark">
                <span className="d-flex align-items-center gap-2" style={{ fontSize: "1.1rem"}}>
                  <span className="fs-4 fw-bold lh-1">&bull;</span>
                  {textContent}
                </span>
                {!ignoreAnswer && (
                  <span className="d-block ms-3">
                    <b>{T("Answer")}: </b>
                    <span className="text-uppercase">{getCheckedStatus(id)}</span>
                  </span>
                )}
              </p>
            )}
            {requiredTextBox && (
              <p className="mb-0 ms-3 text-dark" style={{ textWrap: "pretty" }}>
                <b>{T("Justification")}:</b>{" "}
                <span>{getInputContent(id)}</span>
              </p>
            )}
            {context && (
              <div className="card card-body pt-2 pb-3 px-3 ms-3 mb-3 bg-light rounded" style={{ width: "fit-content" }}>
                <strong className="text-sm">{T("Context")}: </strong>
                <p className="mb-0 text-sm text-muted">{context}</p>
              </div>
            )}
            {(typeText === "redirect") && (
              <p className="mb-1 fw-bold text-center text-dark" style={{ textWrap: "pretty" }}>{textContent}</p>
            )}
            {(typeText === "redirect") && (
              <div className="d-flex gap-2 justify-content-center mt-3">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={goToOpinionView}
                  style={{ display: 'block' }}
                >{T("Go to Opinion document")}</button>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center mt-4">
        <button
          type="submit"
          className="btn btn-primary"
          onClick={() => onEdit(true)}
        >{T("Back to Questions")}</button>
      </div>
    </>
  );
}

export default GoingConcernMemoDocument;