import { QuestionJustifiedArgs, type ItemJustifedOption } from './ElementArgs'
import { ElementBase, IElementBase } from './ElementBase'

export class QuestionJustifiedElement extends ElementBase implements IElementBase {
  private _question: string
  private _chosenOption: string
  private _itemsOnYes: ItemJustifedOption[]
  private _itemsOnNo: ItemJustifedOption[]

  constructor(args: QuestionJustifiedArgs) {
    super(args)
    this._question = args.question || ""
    this._itemsOnYes = args.itemsOnYes || []
    this._itemsOnNo = args.itemsOnNo || []
    this._chosenOption = args.chosenOption || ""
  }

  get question(): string {
    return this._question
  }

  get chosenOption(): string {
    return this._chosenOption
  }

  get itemsOnYes(): ItemJustifedOption[] {
    return this._itemsOnYes
  }

  get itemsOnNo(): ItemJustifedOption[] {
    return this._itemsOnNo
  }

  get args(): QuestionJustifiedArgs {
    return {
      ...super.args,
      question: this._question,
      itemsOnYes: this._itemsOnYes,
      itemsOnNo: this._itemsOnNo,
      chosenOption: this._chosenOption as "yes" | "no"
    }
  }

  set question(question: string) {
    this._question = question
  }

  set chosenOption(chosenOption: string) {
    this._chosenOption = chosenOption
  }

  set itemsOnYes(itemsOnYes: ItemJustifedOption[]) {
    this._itemsOnYes = itemsOnYes
  }

  set itemsOnNo(itemsOnNo: ItemJustifedOption[]) {
    this._itemsOnNo = itemsOnNo
  }

  render(): void {}
}