import { ElementArgs, DeterminationUseExpertWorkItem } from './ElementArgs'
import { ElementBase, IElementBase } from './ElementBase'


/**
* Brief description of DeterminationUseExpertWorkElement in English.
*/
export class DeterminationUseExpertWorkElement extends ElementBase implements IElementBase {
  private _options: DeterminationUseExpertWorkItem[]

  /**
  * Initializes a new instance of the DeterminationUseExpertWorkElement.
  * @param args - The element arguments including options.
  */
  constructor(args: ElementArgs) {
    super(args)
    this._options = args.options as DeterminationUseExpertWorkItem[]
  }

  /**
   * Gets the list of expert work options.
   * @returns {DeterminationUseExpertWorkItem[]} The list of expert work options.
   */
  get options(): DeterminationUseExpertWorkItem[] {
    return this._options
  }

  /**
   * Gets the element arguments including options.
   * @returns {ElementArgs} The arguments with updated options.
   */
  get args(): ElementArgs {
    return {
      ...super.args,
      options: this._options
    }
  }

  /**
   * Sets the list of expert work options.
   * @param {DeterminationUseExpertWorkItem[]} options - The new options to be set.
   */
  set options(options: DeterminationUseExpertWorkItem[]) {
    this._options = options;
    this.args.options = options;
  }

  /**
   * Renders the element.
   * Currently, this method does not perform any actions.
   */
  render(): void { }
}