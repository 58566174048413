import React, { useEffect, useState } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from './ViewModeProps';
import ViewModeBase from "./ViewModeBase";
import { decryptUniqueFilename } from '../../utils/utilities'
import { UseServiceOrganizationsElement } from '../../models/special-document/UseServiceOrganizationsElement';
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';
import { UseServiceOrganizationsArgs, UseServiceOrganizationsRepository } from '../../repository/special-document/UseServiceOrganizationsRepository';
import { ElementRepository } from '../../repository/special-document/ElementRepository';
import Dropzone from "../utils/Dropzone"


export interface RegisterType {
  id: string;
  reference_id: number;
  name_reconciling_items: string;
  extracted_value: string;
}

/**
 * Component for the view mode of the TypesUseServiceOrganizations element
 * @param props - The props for the component
 * @returns JSX.Element
 */
const TypesUseServiceOrganizationsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<UseServiceOrganizationsElement>(iElement as UseServiceOrganizationsElement)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isRefresh, setIsRefresh] = useState(false);
  const [isRefreshLocal, setIsRefreshLocal] = useState(false);
  const [registers, setRegisters] = useState<UseServiceOrganizationsArgs[]>([]);
  const [baseRegisters, setBaseRegisters] = useState<UseServiceOrganizationsArgs[]>([]);
  const [groupedData, setGroupedData] = useState<Record<number, UseServiceOrganizationsArgs[]>>({});
  const [isCorrect, setIsCorrect] = useState<boolean>(false);
  const [transactionFlowInfo, setTransactionFlowInfo] = useState<Record<number, { title: string, url: string }>>({});
  const [key, setKey] = useState(0);

  useEffect(() => {
    const handleEvent = () => {
      setIsRefresh(prev => !prev);
      setKey(prevKey => prevKey + 1);
    };
    emitter.on("useServiceOrganization", handleEvent);
    return () => {
      emitter.off("useServiceOrganization", handleEvent);
    };
  }, []);

  const answersYesNo = [
    {
      value: 1, label: T("Yes")
    },
    {
      value: 2, label: T("No")
    }
  ]

  const selectDropdown = [
    {
      value: 1, label: T("Type 1 report")
    },
    {
      value: 2, label: T("Type 2 report")
    },
    {
      value: 3, label: T("Do not plan to deliver")
    }
  ]

  /**
   * Function to get the registers of the complexity technology environment
   */
  const getRegistersServiceOrganizations = async () => {
    const useServiceOrganizationsRepository = new UseServiceOrganizationsRepository()
    const result = await useServiceOrganizationsRepository.getTypesUseServiceOrganizations(element.args.engagement_id, element.id)
    if (result.success) {

      const grouped = result.data.reduce((acc: Record<number, UseServiceOrganizationsArgs[]>, item: UseServiceOrganizationsArgs) => {
        const { transaction_flow_id } = item;

        if (!acc[transaction_flow_id]) {
          acc[transaction_flow_id] = [];
        }
        acc[transaction_flow_id].push(item);

        return acc;
      }, {});

      const elementRepository = new ElementRepository()

      const infoByTransactionFlow: Record<number, { title: string, url: string }> = {};

      await Promise.all(Object.keys(grouped).map(async (flowId) => {
        const redirectUrl = await elementRepository.getRedirectUrlMemoUseServiceOrganization(
          element.engagement_id, element.documentId, element.id, Number(flowId)
        );

        infoByTransactionFlow[Number(flowId)] = {
          title: redirectUrl.title,
          url: redirectUrl.url
        };
      }));
      setTransactionFlowInfo(infoByTransactionFlow);

      setGroupedData(grouped);
      setRegisters(result.data);
      setBaseRegisters(result.data);
    } else {
      setRegisters([]);
      setBaseRegisters([]);
    }
  }

  /**
   * Function to validate the registers of the complexity technology environment
   */
  const validateRegistersTransactionFlows = async () => {
    const useServiceOrganizationsRepository = new UseServiceOrganizationsRepository()
    const result = await useServiceOrganizationsRepository.validateTransactionFlowsMemorandumUseServiceOrganizations(element.args.engagement_id, element.id)
    if (result.success) {
      setIsCorrect(result.is_correct)
    }
  }

  useEffect(() => {
    validateRegistersTransactionFlows()
    getRegistersServiceOrganizations()
  }, [isRefreshLocal, isRefresh]);

  /**
   * Function to handle the event of opening the configuration modal
   */
  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  /**
   * Function to handle the event of accepting the configuration modal
   */
  const handleEditModalAccept = async () => {
    const useServiceOrganizationsRepository = new UseServiceOrganizationsRepository()
    const result = await useServiceOrganizationsRepository.updateTypeMemorandumUseServiceOrganizations(element.args.engagement_id, element.documentId, element.id, registers)
    setIsRefreshLocal(prev => !prev);
    setRegisters([]);
    if (result.success) {
      setShowConfigurationsModal(false);
    }

    const elementRepository = new ElementRepository()
    let success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      window.htmlHelpers?.swalError()
    }
  };

  /**
   * Function to handle the event of canceling the configuration modal
   */
  const handleEditModalCancel = () => {
    setIsRefreshLocal(prev => !prev);
    setRegisters(baseRegisters);
    const grouped = baseRegisters.reduce((acc: Record<number, UseServiceOrganizationsArgs[]>, item: UseServiceOrganizationsArgs) => {
      const { transaction_flow_id } = item;

      if (!acc[transaction_flow_id]) {
        acc[transaction_flow_id] = [];
      }
      acc[transaction_flow_id].push(item);

      return acc;
    }, {});
    setGroupedData(grouped);
    setShowConfigurationsModal(false)
    setKey(prevKey => prevKey + 1);
  };

  /**
   * Function to handle the event of selecting an answer
   * @param itemId - The id of the item
   * @param answerValue - The value of the answer
   */
  const handleAnswerSelected = (itemId: number, answerValue: number) => {
    setRegisters((prev) =>
      prev.map((item) =>
        item.id === itemId
          ? { ...item, answer_yes_no: answerValue, was_changed: true }
          : item
      )
    );
  }

  useEffect(() => {
    setGroupedData(() => {
      const grouped: Record<number, UseServiceOrganizationsArgs[]> = {};
      registers.forEach((item) => {
        if (!grouped[item.transaction_flow_id]) {
          grouped[item.transaction_flow_id] = [];
        }
        grouped[item.transaction_flow_id].push(item);
      });
      return grouped;
    });
  }, [registers]);

  /**
   * Function to handle the event of changing the input
   * @param itemId - The id of the item
   * @param answerText - The value of the answer
   */
  const handleAnswerTextChange = (itemId: number, answerText: string) => {
    setRegisters((prev) =>
      prev.map((item) =>
        item.id === itemId
          ? { ...item, answer_text: answerText, was_changed: true }
          : item
      )
    );
  };

  /**
   * Function to handle the event of changing the input
   * @param itemId - The id of the item
   * @param attachmentName - The value of the answer
   */
  const handleAttachmentChange = (itemId: number, attachmentName: string) => {
    setRegisters((prev) =>
      prev.map((item) =>
        item.id === itemId
          ? { ...item, attachment_name: attachmentName, was_changed: true }
          : item
      )
    );
  }

  /**
   * Function to handle the event of changing the input
   * @param itemId - The id of the item
   * @param field - The field to change
   * @param value - The value to set
   */
  const handleRedirection = (transactionFlowId: number) => {
    const transactionFlow = transactionFlowInfo[transactionFlowId];
    if (transactionFlow.url !== "") return window.menuService?.breadcrumbAddLevel(transactionFlow.url, transactionFlow.title)
    return window.htmlHelpers?.customSwalError(T("No transaction flow found"))
  }

  return (
    <div key={key}>
      <ViewModeBase
        isEditable={isCorrect}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        <div key={key} className="d-flex w-full flex-column mb-3">
          {(isCorrect && groupedData && Object.keys(groupedData).length > 0) ? (
            <div className="mb-3 w-100">
              {Object.entries(groupedData).map(([flowId, items]) => {
                const selectedSecondaryItem = items.find(
                  (item) => item.type_question === "secondary_type" && item.type_response === "select"
                );
                const selectedSecondaryAnswerYesNo = selectedSecondaryItem?.answer_yes_no || "";
                const selectedPrimaryItem = items.find(
                  (item) => item.type_question === "principal_type" && item.type_response === "select"
                );

                const selectedPrimaryAnswerYesNo = selectedPrimaryItem?.answer_yes_no || "";
                const answeredIndexes = items
                  .filter((item) => item.type_question === "secondary_type")
                  .slice(2, 5)
                  .every((item) => item.answer_text?.trim() !== "" && item.answer_text !== null);

                const firstItem = items[0];
                const transactionFlowId = firstItem.transaction_flow_id;

                return (
                  <div key={flowId}>
                    <h2>{items[0]?.title_name}</h2>

                    {items.some((item) => item.type_question === "normal_type") && (
                      <table className="table-bordered mb-2">
                        <thead>
                          <tr>
                            <th className="w-50">{T("Nature of service provision")}</th>
                            <th className="w-50">{T("Reply")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items
                            .filter((item) => item.type_question === "normal_type")
                            .map((item) => (
                              <tr key={item.id}>
                                <td>{item.title_text}</td>
                                <td>
                                  {item.type_response === "attachment" ? (
                                    decryptUniqueFilename(item.attachment_name || "")
                                  ) : item.type_response === "text" ? (
                                    item.answer_text
                                  ) : null}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}

                    {items.some((item) => item.type_question === "principal_type") && (
                      <>
                        <p>{T("Use of type 1 or type 2 reports to support the user's auditor's understanding of the service organization")}</p>
                        <table className="table-bordered mb-2">
                          <thead>
                            <tr>
                              <th className="w-50">{T("Use of type 1 or type 2 reports")}</th>
                              <th className="w-50">{T("Reply")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items
                              .filter((item) => item.type_question === "principal_type")
                              .map((item) => (
                                <tr key={item.id}>
                                  <td>{item.title_text}</td>
                                  <td>
                                    {answersYesNo.find((option) => option.value === item.answer_yes_no)?.label || T("No response")}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </>
                    )}
                    {selectedPrimaryAnswerYesNo == 1 ? (
                      <>
                        {items.some((item) => item.type_question === "secondary_type") && (
                          <table className="table-bordered mb-2">
                            <thead>
                              <tr>
                                <th className="w-50">{T("Type 1 or type 2 reporting")}</th>
                                <th className="w-50">{T("Reply")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {items
                                .filter((item) => item.type_question === "secondary_type")
                                .map((item, index) => (
                                  <tr key={item.id}>

                                    {item.type_response === "select" && index === 0 ? (
                                      <>

                                        <td>{item.title_text}</td>
                                        <td>
                                          {selectDropdown.find((option) => option.value === item.answer_yes_no)?.label || T("No response")}
                                        </td>
                                      </>
                                    ) : selectedSecondaryAnswerYesNo === 1 && index === 1 ? (
                                      <>
                                        <td colSpan={2}>
                                          {item.title_text}
                                          <br />
                                          <div className="d-flex justify-content-center mt-2">
                                            <button type="button" className="btn btn-primary" onClick={() => handleRedirection(Number(transactionFlowId))}>
                                              {T("Transaction Flow Control")}
                                            </button>
                                          </div>
                                        </td>
                                      </>
                                    ) : selectedSecondaryAnswerYesNo === 2 && (index === 2 || index === 3 || index === 4) ? (
                                      <>
                                        <td>{item.title_text}</td>
                                        <td>
                                          {item.answer_text}
                                        </td>
                                      </>
                                    ) : selectedSecondaryAnswerYesNo === 3 && index === 5 ? (
                                      <>
                                        <td colSpan={2}>
                                          {item.title_text}
                                          <br />
                                          <div className="d-flex justify-content-center mt-2">
                                            <button type="button" className="btn btn-primary" onClick={() => handleRedirection(Number(transactionFlowId))}>
                                              {T("Transaction Flow Control")}
                                            </button>
                                          </div>
                                        </td>
                                      </>
                                    ) : null}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        )}

                        {(answeredIndexes && selectedSecondaryAnswerYesNo === 2) && (
                          <>
                            {items.some((item) => item.type_question === "third_type") && (
                              <>
                                <p>{T("Adequacy of type 2 report:")}</p>
                                <table className="table-bordered mb-2">
                                  <thead>
                                    <tr>
                                      <th className="w-50">{T("Type 2 report")}</th>
                                      <th className="w-50">{T("Reply")}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {items
                                      .filter((item) => item.type_question === "third_type")
                                      .map((item) => (
                                        <tr key={item.id}>
                                          <td>{item.title_text}</td>
                                          <td className="text-center">
                                            <>
                                              {answersYesNo.find((option) => option.value === item.answer_yes_no)?.label || T("No response")}
                                              <br />
                                              {item.answer_text}
                                            </>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : selectedPrimaryAnswerYesNo == 2 ? (
                      <>
                        <p>{T("The service provider does not plan to deliver type 1 or type 2 reports for the audit, therefore, tests of controls should be applied by the audit team to obtain sufficient and appropriate audit evidence.")}</p>
                        <div className="d-flex justify-content-center">
                          <button type="button" className="btn btn-primary" onClick={() => handleRedirection(Number(transactionFlowId))}>
                            {T("Transaction Flow Control")}
                          </button>
                        </div>
                      </>
                    ) : null}

                  </div>
                );
              })}
            </div>
          ) : (
            <div className="card h-100 bg-light">
              <div className="card-body p-3">
                <h6>{T("Element for displaying the types of use service organization")}</h6>
              </div>
            </div>
          )}
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Use Service Organizations")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <div key={key} className="d-flex w-full flex-column mb-3">
          <div className="mb-3 w-100">
            {Object.entries(groupedData).map(([flowId, items]) => {
              const selectedSecondaryItem = items.find(
                (item) => item.type_question === "secondary_type" && item.type_response === "select"
              );
              const selectedSecondaryAnswerYesNo = selectedSecondaryItem?.answer_yes_no || "";
              const selectedPrimaryItem = items.find(
                (item) => item.type_question === "principal_type" && item.type_response === "select"
              );

              const selectedPrimaryAnswerYesNo = selectedPrimaryItem?.answer_yes_no || "";
              const answeredIndexes = items
                .filter((item) => item.type_question === "secondary_type")
                .slice(2, 5)
                .every((item) => item.answer_text?.trim() !== "" && item.answer_text !== null);

              return (
                <div key={flowId}>
                  <h2>{items[0]?.title_name}</h2>

                  {items.some((item) => item.type_question === "normal_type") && (
                    <table className="table-bordered mb-2">
                      <thead>
                        <tr>
                          <th className="w-50">{T("Nature of service provision")}</th>
                          <th className="w-50">{T("Reply")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items
                          .filter((item) => item.type_question === "normal_type")
                          .map((item) => (
                            <tr key={item.id}>
                              <td>{item.title_text}</td>
                              <td>
                                {item.type_response === "attachment" ? (
                                  <Dropzone
                                    elementId={element.id}
                                    attachmentName={item.attachment_name || ""}
                                    onUpload={value => handleAttachmentChange(item.id, value)}
                                  />
                                ) : item.type_response === "text" ? (
                                  <textarea
                                    className="form-control"
                                    defaultValue={item.answer_text || ""}
                                    onChange={(e) => handleAnswerTextChange(item.id, e.target.value)}
                                  />
                                ) : null}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  )}

                  {items.some((item) => item.type_question === "principal_type") && (
                    <>
                      <p>{T("Use of type 1 or type 2 reports to support the user's auditor's understanding of the service organization")}</p>
                      <table className="table-bordered mb-2">
                        <thead>
                          <tr>
                            <th className="w-50">{T("Use of type 1 or type 2 reports")}</th>
                            <th className="w-50">{T("Reply")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items
                            .filter((item) => item.type_question === "principal_type")
                            .map((item) => (
                              <tr key={item.id}>
                                <td>{item.title_text}</td>
                                <td>
                                  <select className="form-select" value={item.answer_yes_no || ""} onChange={(e) => handleAnswerSelected(item.id, Number(e.target.value))}>
                                    <option value="" disabled>{T("Select an option")}</option>
                                    {answersYesNo.map((answer) => {
                                      return (
                                        <option key={answer.value} value={answer.value}>{T(answer.label)}</option>
                                      )
                                    })}
                                  </select>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </>
                  )}
                  {selectedPrimaryAnswerYesNo == 1 ? (
                    <>
                      {items.some((item) => item.type_question === "secondary_type") && (
                        <table className="table-bordered mb-2">
                          <thead>
                            <tr>
                              <th className="w-50">{T("Type 1 or type 2 reporting")}</th>
                              <th className="w-50">{T("Reply")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items
                              .filter((item) => item.type_question === "secondary_type")
                              .map((item, index) => (
                                <tr key={item.id}>

                                  {item.type_response === "select" && index === 0 ? (
                                    <>

                                      <td>{item.title_text}</td>
                                      <td>
                                        <select
                                          className="form-select"
                                          value={item.answer_yes_no || ""}
                                          onChange={(e) => handleAnswerSelected(item.id, Number(e.target.value))}
                                        >
                                          <option value="" disabled>{T("Select an option")}</option>
                                          {selectDropdown.map((answer) => (
                                            <option key={answer.value} value={answer.value}>
                                              {T(answer.label)}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                    </>
                                  ) : selectedSecondaryAnswerYesNo === 1 && index === 1 ? (
                                    <td colSpan={2}>{item.title_text}</td>
                                  ) : selectedSecondaryAnswerYesNo === 2 && (index === 2 || index === 3 || index === 4) ? (
                                    <>
                                      <td>{item.title_text}</td>
                                      <td>
                                        <textarea
                                          className="form-control"
                                          defaultValue={item.answer_text || ""}
                                          onChange={(e) => handleAnswerTextChange(item.id, e.target.value)}
                                        />
                                      </td>
                                    </>
                                  ) : selectedSecondaryAnswerYesNo === 3 && index === 5 ? (
                                    <td colSpan={2}>{item.title_text}</td>
                                  ) : null}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}

                      {(answeredIndexes && selectedSecondaryAnswerYesNo === 2) && (
                        <>
                          {items.some((item) => item.type_question === "third_type") && (
                            <>
                              <p>{T("Adequacy of type 2 report:")}</p>
                              <table className="table-bordered mb-2">
                                <thead>
                                  <tr>
                                    <th className="w-50">{T("Type 2 report")}</th>
                                    <th className="w-50">{T("Reply")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {items
                                    .filter((item) => item.type_question === "third_type")
                                    .map((item) => (
                                      <tr key={item.id}>
                                        <td>{item.title_text}</td>
                                        <td>
                                          <>
                                            <select
                                              className="form-select"
                                              value={item.answer_yes_no || ""}
                                              onChange={(e) => handleAnswerSelected(item.id, Number(e.target.value))}
                                            >
                                              <option value="" disabled>{T("Select an option")}</option>
                                              {answersYesNo.map((answer) => (
                                                <option key={answer.value} value={answer.value}>
                                                  {T(answer.label)}
                                                </option>
                                              ))}
                                            </select>
                                            <textarea
                                              className="form-control"
                                              defaultValue={item.answer_text || ""}
                                              onChange={(e) => handleAnswerTextChange(item.id, e.target.value)}
                                            />
                                          </>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : selectedPrimaryAnswerYesNo == 2 ? (
                    <>
                      <p>{T("The service provider does not plan to deliver type 1 or type 2 reports for the audit, therefore, tests of controls should be applied by the audit team to obtain sufficient and appropriate audit evidence.")}</p>
                    </>
                  ) : null}

                </div>
              );
            })}
          </div>
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default TypesUseServiceOrganizationsViewMode;
