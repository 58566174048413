import { ElementArgs } from "./ElementArgs";
import { IElementBase, ElementBase } from "./ElementBase";


/**
 * Represents a basic opinion paragraph element.
 * This class extends `ElementBase` and implements `IElementBase`, providing 
 * functionality for handling reference type opinions within an element.
 */
export class BasisParagraphOpinionElement extends ElementBase implements IElementBase {
  _reference_type_opinion: string;

  /**
   * Creates an instance of `BasisParagraphOpinionElement`.
   * @param {ElementArgs} args - The arguments required to initialize the element.
   *        - `reference_type_opinion` (optional): A reference identifier for the opinion.
   */
  constructor(args: ElementArgs) {
    super(args);
    this._reference_type_opinion = args.reference_type_opinion ?? "";
  }

  /**
   * Gets the reference type opinion of the element.
   * @returns {string} The reference type opinion.
   */
  get reference_type_opinion(): string {
    return this._reference_type_opinion;
  }

  /**
   * Sets the reference type opinion of the element.
   * @param {string} reference_type_opinion - The new reference type opinion.
   */
  set reference_type_opinion(reference_type_opinion: string) {
    this._reference_type_opinion = reference_type_opinion;
    this.args.reference_type_opinion = reference_type_opinion;
  }

  /**
   * Renders the opinion paragraph element.
   * This method currently logs a message indicating the rendering action.
   */
  render(): void {
    console.log("render conditional basis paragraph opinion");
  }
}
