import React, { useEffect, useState, useRef } from 'react';
import { ParagraphsTypesOpinionsRepository } from "../../repository/special-document/ParagraphsTypesOpinionsRepository"
import { ElementParagraph } from "../../models/special-document/ElementParagraph"
import ViewModeBase from "./ViewModeBase";
import ViewModeProps from './ViewModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import { Constants } from '../../utils/Constants';
import DOMPurify from 'isomorphic-dompurify';


/**
 * Component to display the BasisParagraphOpinion in view mode
 * @param ViewModeProps - interface with the element to display
 */
const BasisParagraphOpinionViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<ElementParagraph>(iElement as ElementParagraph).current
  const [editorContent, setEditorContent] = useState('')

  /**
   * Function to get the data from the repository
   */
  const getTextContent = async () => {
    const paragraphsTypesOpinionsRepository = new ParagraphsTypesOpinionsRepository()
    const textContent = await paragraphsTypesOpinionsRepository.getOpinionByReferenceAndTypeOpinion(element.args.engagement_id, element.args.document_id, element.id, element.args.reference_type_opinion, "basis_paragraph", "view")
    if (textContent.success) {
      setEditorContent(textContent.data.text_content)
    }
  }

  useEffect(() => {
    getTextContent()
  },[]);

  return (
    <div>
      <ViewModeBase
        isEditable={false}
        permissions={element.permissions as Permissions}>
        <div className={`${Constants.CONTENT_CLASS}`}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(editorContent) }} />
      </ViewModeBase>
    </div>
  );
};

export default BasisParagraphOpinionViewMode;
