import { useState, useEffect, useRef } from 'react'
import { T } from "@/utils/i18n-config"
import { Constants } from '@/utils/Constants'
import { DisallowanceIncomesElement } from '@/models/special-document/DisallowanceIncomesElement'
import { DisallowanceIncomesRepository } from '@/repository/special-document/DisallowanceIncomesRepository'
import { ElementRepository } from '@/repository/special-document/ElementRepository'
import ModalWithButtons from '@/components/modals/AceptCancelModalBase'
import { type DeliveryLocation, DeliveryLocationTable, DeliveryLocationAdder } from './delivery-location'
import { InvoiceSelection } from './invoice-selection'
import { ButtonModal } from '@/components/commons/button-modal'
import { AttachmentZone } from '@/components/commons/attachment-zone'
import ToggleInputParagraph from '@/components/commons/toogle-input'

interface ExportDeliveriesProps {
  element: DisallowanceIncomesElement
}

/**
 * Component to manage the export deliveries of the disallowance income element.
 * @param element The disallowance income element
 */
function ExportDeliveries({ element }: ExportDeliveriesProps) {
  const [showModal, setShowModal] = useState(false)
  const [exports, setExports] = useState<Array<number | string | boolean>[]>([])
  const [destinations, setDestinations] = useState<DeliveryLocation[]>([])
  const [exportsAttachmentName, setExportsAttachmentName] = useState(element.exportsAttachmentName)
  const deliveryLocations = useRef<DeliveryLocation[]>([])
  const exportsTemplateName = element.exportsTemplateName
  const deliveryType = Constants.DELIVERY_TYPE_EXPORT
  const [exportsLabels, setExportsLabels] = useState(() => {
    if (element.exportsLabels.length > 0) return element.exportsLabels
    return [
      T("Invoice Number"),
      T("Shipment Date"),
      T("Country"),
      T("Incoterm Type"),
      T("Estimated Transfer Date"),
      T("Risk"),
      T("Delivery Date"),
      T("Amount"),
      T("Adjustment"),
    ]
  })

  /**
   * Handle the modal accept event.
   */
  async function handleModalAccept() {
    setShowModal(false)

    // Save destinations
    const disallowanceIncomesRepository = new DisallowanceIncomesRepository()
    const locations = destinations.map(({ location, deliveryDays }) => ({ location, delivery_days: deliveryDays, delivery_type: deliveryType }))
    await disallowanceIncomesRepository.saveDestinations(element.id, "view", locations)

    // Save invoices attachments
    const elementRepository = new ElementRepository()
    const lastExportsAttachmentName = element.exportsAttachmentName
    element.exportsAttachmentName = exportsAttachmentName
    element.exportsLabels = exportsLabels
    const success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      element.exportsAttachmentName = lastExportsAttachmentName
      setExportsAttachmentName(lastExportsAttachmentName)
      return window.htmlHelpers?.swalError()
    }
  }

  /**
   * Handle the modal cancel event.
   */
  function handleModalCancel() {
    setShowModal(false)
    setDestinations(deliveryLocations.current)
    setExportsAttachmentName(element.exportsAttachmentName)
  }

  /**
   * Generate the list of export deliveries based on the attachment.
   */
  async function handleGenerationExports() {
    const disallowanceIncomesRepository = new DisallowanceIncomesRepository()
    if (exports.length === 0) {
      const { success } = await disallowanceIncomesRepository.generateInvoicesSelection(
        element.id, "view", element.engagement_id, deliveryType, exportsAttachmentName
      )
      if (!success) return window.htmlHelpers?.swalError()
    }
    getExportDeliveries()
  }

  /**
   * Get the export deliveries of the element.
   */
  async function getExportDeliveries() {
    const disallowanceIncomesRepository = new DisallowanceIncomesRepository()
    const { success, invoices } = await disallowanceIncomesRepository.getInvoicesSelection(element.id, "view", deliveryType)
    if (!success) return window.htmlHelpers?.swalError()
    setExports(invoices.map(invoice => ([
      invoice.invoice_id,
      invoice.shipment_date,
      invoice.delivery_location,
      invoice.incoterm,
      invoice.estimated_date,
      invoice.risk,
      invoice.delivery_date,
      invoice.amount,
      invoice.adjustment
    ])))
  }

  /**
   * Update the delivery date of an export delivery.
   * @param invoiceId The invoice id
   * @param deliveryDate The new delivery date
   * @param adjustment The adjustment flag
   */
  async function updateExportDelivery(invoiceId: number, deliveryDate: Date, adjustment: boolean) {
    const disallowanceIncomesRepository = new DisallowanceIncomesRepository()
    const updatedData = { invoiceId, deliveryDate: deliveryDate.toDateString(), adjustment }
    const { success } = await disallowanceIncomesRepository.updateInvoiceSelection(element.id, "view", updatedData)
    if (!success) return window.htmlHelpers?.swalError()

    const updatedInvoices = exports.map((invoice) => {
      if (invoice[0] === invoiceId) {
        invoice[6] = deliveryDate.toDateString()
        invoice[8] = adjustment
      }
      return invoice
    })
    setExports(updatedInvoices)
  }

  /**
   * Get the destinations of the element.
   */
  async function getDestinations() {
    const disallowanceIncomesRepository = new DisallowanceIncomesRepository()
    const { success, destinations } = await disallowanceIncomesRepository.getDestinations(element.id, "view", deliveryType)
    if (!success) return window.htmlHelpers?.swalError();

    const locations = destinations.map(destination => ({
      location: destination.location,
      deliveryDays: destination.delivery_days,
      deliveryType: destination.delivery_type
    }))
    deliveryLocations.current = locations
    setDestinations(locations)
  }

  /**
   * Handle the addition of a new delivery location.
   * @param location The location
   * @param deliveryDays The delivery days
   */
  function handleAddLocation(location: string, deliveryDays: number) {
    setDestinations([...destinations, { location, deliveryDays, deliveryType }])
  }

  /**
   * Handle the update of a label.
   * @param index The label index
   * @param label The new label
   */
  async function handleUpdateLabels(index: number, label: string) {
    const updatedLabels = [...exportsLabels]
    updatedLabels[index] = label
    setExportsLabels(updatedLabels)
  }

  useEffect(() => {
    getExportDeliveries()
    getDestinations()
  }, [])

  useEffect(() => {
    setExports([])
  }, [exportsAttachmentName])

  return (
    <div className="position-relative">
      <ButtonModal onDisplayModal={() => setShowModal(true)} />
      <DeliveryLocationTable locationLabel="Country" locations={destinations} />
      <InvoiceSelection
        invoices={exports}
        labels={exportsLabels}
        inputColumns={[6]}
        typeColumns={[{ index: 6, type: "date" }, { index: 7, type: "float" }]}
        updateInvoice={updateExportDelivery}
      />
      <ModalWithButtons
        showModal={showModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleModalAccept}
        onCancel={handleModalCancel}
      >
        <div className="d-flex flex-column gap-5 px-4">
          <div className="d-flex flex-wrap justify-content-between align-items-center gap-5">
            <DeliveryLocationAdder
              locationLabel="Country"
              onAddDeliveryLocation={handleAddLocation}
            />
            <div>
              <h6>{T("Table Header")}</h6>
              <div
                className="justify-items-center align-items-center gap-2"
                style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", maxWidth: "50%" }}
              >
                {exportsLabels.map((label, index) => (
                  <ToggleInputParagraph key={index} initialText={label} onChange={(label) => handleUpdateLabels(index, label)} />
                ))}
              </div>
            </div>
          </div>
          {destinations.length > 0 && (
            <AttachmentZone
              elementId={element.id}
              templateName={exportsTemplateName}
              attachmentName={exportsAttachmentName}
              onUpdateAttachment={setExportsAttachmentName}
              onAction={handleGenerationExports}
            />
          )}
        </div>
      </ModalWithButtons>
    </div>
  )
}

export default ExportDeliveries
