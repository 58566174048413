import ReactDOM from 'react-dom';
import GoingConcernContainer from './../components/going-concern/GoingConcernContainer';


interface GoingConcernMemoRenderer {
  containerId: string;
  engagementId: number;
}

/**
* Renders the GoingConcernContainer component into the specified container.
* @param args - Object with containerId and engagementId.
*/
export function GoingConcernMemoRenderer(args: GoingConcernMemoRenderer) {
  ReactDOM.render(
    <GoingConcernContainer engagementId={args.engagementId} />,
    document.getElementById(args.containerId)
  );
}
