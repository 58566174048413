import React, { useState, useEffect, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { decryptUniqueFilename } from "../../utils/utilities"
import { type Permissions } from '../../models/special-document/ElementBase'
import { AttachDocumentElement } from "../../models/special-document/AttachDocumentElement"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { ElementAttachmentRepository } from "../../repository/special-document/ElementAttachmentRepository"
import DropzoneComponent from '@/components/commons/dropzone/dropzone-component'
import ViewModeProps from './ViewModeProps'
import ViewModeBase from "./ViewModeBase"
import ModalWithButtons from '../modals/AceptCancelModalBase'

const AttachDocumentViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<AttachDocumentElement>(iElement as AttachDocumentElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [attachmentName, setAttachmentName] = useState(element.attachmentName)
  const templateName = element.templateName

  async function handleDownloadAttachment() {
    const elementAttachmentRepository = new ElementAttachmentRepository();
    const attachment = await elementAttachmentRepository.getAttachment("view", element.id, element.templateName);
    if (!(attachment instanceof Blob)) return window.htmlHelpers?.swalError();
    const url = window.URL.createObjectURL(attachment);
    const a = document.createElement('a');
    a.href = url;
    a.download = decryptUniqueFilename(element.templateName);
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  async function handleRemoveAttachment() {
    const elementAttachmentRepository = new ElementAttachmentRepository()
    const response = await elementAttachmentRepository.removeAttachment("view", element.id, attachmentName)
    if (!response) {
      return window.htmlHelpers?.customSwalError(T("Either the file does not exist or you do not have permission to delete it"))
    }
    setAttachmentName("")
    await handleConfigurationsModalAccept()
  }

  function handleEdit() {
    setShowConfigurationsModal(true)
  }

  async function handleConfigurationsModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastAttachmentName = element.attachmentName
    element.attachmentName = attachmentName

    let success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      element.attachmentName = lastAttachmentName
      setAttachmentName(lastAttachmentName)
      window.htmlHelpers?.swalError()
    }
  }

  function handleConfigurationsModalCancel() {
    setAttachmentName(element.attachmentName)
    setShowConfigurationsModal(false)
  }

  return (
    <>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        currentElement={element}
        permissions={element.permissions as Permissions}
      >
        <div className="d-flex flex-column gap-2">
          <h6 className="m-0">{element.title}</h6>
          {attachmentName ? (
            <div className="d-flex justify-content-between align-items-center w-100">
              <p className="m-0">{T("Attachment")}: <em>{decryptUniqueFilename(attachmentName)}</em></p>
              <div className="d-flex flex-column">
                <button
                  type="button"
                  style={{ cursor: 'pointer' }}
                  className="d-flex align-items-center gap-2 bg-transparent border-0 text-secondary"
                  onClick={() => handleDownloadAttachment()}
                >
                  <i className="fa-solid fa-download"></i>
                  <span>{T("Download file")}</span>
                </button>
                <button
                  type="button"
                  style={{ cursor: 'pointer' }}
                  className="d-flex align-items-center gap-2 bg-transparent border-0 text-secondary"
                  onClick={() => handleRemoveAttachment()}
                >
                  <i className="fa-solid fa-trash"></i>
                  <span>{T("Remove file")}</span>
                </button>
              </div>
            </div>
          ) : (
            <p>{T("No attachment yet")}</p>
          )}
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}
      >
        <div className="d-flex flex-column gap-4">
          <h4 className="text-center">{element.title}</h4>
          {templateName && (
            <div className="d-flex justify-content-between align-items-center w-100">
              <p className="m-0 fs-6 fw-bold">{T("Template")}: <em>{decryptUniqueFilename(templateName)}</em></p>
              <button
                type="button"
                style={{ cursor: 'pointer' }}
                className="d-flex align-items-center gap-2 p-1 bg-transparent border-0 text-secondary"
                onClick={() => handleDownloadAttachment()}
              >
                <i className="fa-solid fa-download"></i>
                <span>{T("Download file")}</span>
              </button>
            </div>
          )}
          <div>
            <h6>{T("Upload file")}</h6>
            <DropzoneComponent
              elementId={element.id}
              renderingMode="view"
              attachmentName={attachmentName}
              onUpload={(attachmentName) => setAttachmentName(attachmentName)}
            />
          </div>
        </div>
      </ModalWithButtons>
    </>
  );
};

export default AttachDocumentViewMode;
