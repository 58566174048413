import React, { useState, useRef } from 'react'
import { T } from '@/utils/i18n-config'
import { decryptUniqueFilename, setImportantAttr } from '@/utils/utilities'
import { Permissions } from '@/models/special-document/ElementBase'
import { ElementRepository } from '@/repository/special-document/ElementRepository'
import { MinutesTableElement } from '@/models/special-document/MinutesTableElement'
import EditModeProps from '../special-document/EditModeProps'
import EditModeBase from '../special-document/EditModeBase'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import TableComponent from '../commons/TableComponent'
import DropzoneComponent from '@/components/commons/dropzone/dropzone-component'

const MinutesTableEditMode = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<MinutesTableElement>(iElement as MinutesTableElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [minutesTemplateName, setMinutesTemplateName] = useState<string>(element.minutesTemplateName)

  /**
   * Handle the configuration of the element
   */
  function handleConfiguration() {
    setShowConfigurationsModal(true)
  }

  /**
   * Handle the accept button of the configurations modal
   */
  async function handleConfigurationsModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastMinuteTemplate = element.minutesTemplateName
    element.minutesTemplateName = minutesTemplateName

    let success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      window.htmlHelpers?.swalError()
      element.minutesTemplateName = lastMinuteTemplate
      setMinutesTemplateName(lastMinuteTemplate)
    }
  }

  /**
   * Handle the cancel button of the configurations modal
   */
  function handleConfigurationsModalCancel() {
    setShowConfigurationsModal(false)
    setMinutesTemplateName(element.minutesTemplateName)
  }

  return (
    <>
      <EditModeBase
        iElement={iElement}
        isEditable={true}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}
      >
        <div className="mt-4">
          <b className="fs-6">{T("Table 1.1")}</b>
          <p>
            {T("Step 1. Obtain all the minutes of {{limitedCompany}} and {{stockCorporation}} meetings held during the audit period.",
              { limitedCompany: T("Shareholders' meeting"), stockCorporation: T("Board of directors") })}
          </p>
          <p className="mb-1">{T("Step 2. Read each minute in detail and look for related information regarding:")}</p>
          <ul>
            <li>{T("Key decisions such as significant transactions, structural changes, accounting policies.")}</li>
            <li>{T("Relevant resolutions related to contracts, guarantees, or litigations.")}</li>
            <li>{T("Link the minutes with the financial statements.")}</li>
          </ul>
          <p>{T("Step 3. Record the key decisions, identified risks, and relevant observations in the table:")}</p>
        </div>
        <TableComponent
          className="mt-2"
          labels={[
            T("Minute Number"),
            T("Minute Date"),
            T("Documented Key Decision"),
            T("Perceived Risk"),
            T("Impact on the Financial States"),
            T("Observations")
          ]}
          values={[[`${T("Minute")} #001`, "2025-01-01", "...", "...", "...", "..."]]}
        />
        <div className="mt-2 py-3 px-4 border border-2 rounded-3" ref={(el) => setImportantAttr(el, "border-style", "dashed")}>
          <p className="m-0 fs-6">{T("Minutes Template")}: <em>{decryptUniqueFilename(minutesTemplateName)}</em></p>
        </div>
        <div className="mt-5">
          <b className="fs-6">{T("Conclusion")}</b>
          <ul>
            <li>{T("The previous responses were positive and no updates were identified in the material contracts.")}</li>
            <li>{T("Updates were found in the reading of the minutes with material risk.")}</li>
          </ul>
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}
      >
        <div className="d-flex flex-column mx-auto w-full" style={{ maxWidth: "50%" }}>
          <h6 className="text-center">{T("Minutes Template")}</h6>
          <DropzoneComponent
            elementId={element.id}
            renderingMode="edition"
            attachmentName={minutesTemplateName}
            onUpload={(attachmentName) => setMinutesTemplateName(attachmentName as string)}
          />
        </div>
      </ModalWithButtons>
    </>
  )
}

export default MinutesTableEditMode