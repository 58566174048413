import type { QuestionAnswer } from './GoingConcernTypes';
import { GoingConcernRepository } from '../../repository/GoingConcernRepository';
import { T } from '../../utils/i18n-config';
import Summernote from '../utils/Summernote';
import useGoingConcernMemo from './useGoingConcernMemo';
import { useEffect, useRef, useState } from 'react';


interface GoingConcernMemoProps {
  engagementId: number;
  isEditing: boolean;
  onFinish: (value: boolean) => void;
}

function GoingConcernMemo({ engagementId, isEditing, onFinish }: Readonly<GoingConcernMemoProps>) {
  const {
    handleTableChange,
    handlePreviousTable,
    getCheckedStatus,
    getInputContent,
    groupQuestionAnswers,
    resetLastQuestion,
    currentTable,
    groupedQuestions,
    typeOpinion
  } = useGoingConcernMemo(engagementId);
  const [selectedOption, setSelectedOption] = useState("");
  const formRef = useRef<HTMLFormElement>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [generalError, setGeneralError] = useState<string>("");

  /**
   * Updates the answers for the memorandum.
   * @param answers The answers to update.
   */
  async function updateAnswers(answers: QuestionAnswer[]) {
    try {
      const repository = new GoingConcernRepository();
      await repository.updateRegisters(engagementId, answers);
      await repository.updateRegistersToFinished(engagementId);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Checks if the current table is a question table.
   * @returns `true` if the table is a question table, `false` otherwise.
   */
  function checkNextTable(): boolean {
    const current = groupedQuestions.find(([id]) => Number(id) === currentTable);
    if (!current) return false;
    return current[1].every(({ typeText }) => typeText !== "final" && typeText !== "redirect");
  }

  /**
   * Checks if the current table is a final table.
   * @returns `true` if the table is a final table, `false` otherwise.
   */
  function checkFinalTable(): boolean {
    const current = groupedQuestions.find(([id]) => Number(id) === currentTable);
    if (!current) return false;
    return current[1].some(({ typeText }) => typeText === "final");
  }

  /**
  * Updates the register with an adverse opinion using the repository.
  */
  async function saveTypeOpinionAdverse() {
    try {
      const repository = new GoingConcernRepository();
      await repository.updateTypeOpinionRegister(engagementId, { typeOpinion: "adverse_opinion" });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Checks if the current table is a redirect table.
   * @returns `true` if the table is a redirect table, `false` otherwise.
   */
  function checkRedirectTable(): boolean {
    const current = groupedQuestions.find(([id]) => Number(id) === currentTable);
    if (!current) return false;
    return current[1].every(({ typeText }) => typeText === "redirect");
  }

  async function saveTypeOpinion() {
    try {
      const repository = new GoingConcernRepository();
      await repository.updateTypeOpinionRegister(engagementId, { typeOpinion: selectedOption });
    } catch (error) {
      console.error(error);
    }
  }

  async function handleFinalTable(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();
    const form = ev.currentTarget;
    const formData = new FormData(form);
    const [questionAnswers] = groupQuestionAnswers(formData);
    if (selectedOption !== "") await saveTypeOpinion();
    await updateAnswers(questionAnswers);

    onFinish(true);
    if (checkFinalTable()) return window.menuService?.breadcrumbRefreshLevel();
    else if (checkRedirectTable()) {
      await saveTypeOpinionAdverse();
      window.menuService?.breadcrumbAddLevel(
        `/audix/engagement_opinion/index.load/${engagementId}`, T("Opinion")
    )}
  }

  const handleSelectChange = (event:React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedOption(event.target.value);
  }

  function handleSubmitTable(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const form = formRef.current;
    if (form) {
      const summernoteContainers = form.querySelectorAll<HTMLDivElement>(".summernote");
      const newErrors: Record<string, string> = {};
      let hasEmptyFields = false;

      // Verificar cada campo de Summernote
      summernoteContainers.forEach((container, index) => {
        const editableField = container.querySelector<HTMLDivElement>("[contenteditable='true']");

        if (editableField && !(editableField.textContent || '').trim()) {
          newErrors[`field_${index}`] = T("This field is required.");
          hasEmptyFields = true;
        }
      });

      setErrors(newErrors);

      if (hasEmptyFields) {
        setGeneralError(T("Please complete all fields before saving."));
      } else {
        setGeneralError("");
        if (checkNextTable()) return handleTableChange(event);
        return handleFinalTable(event);
      }
    }
  }

  useEffect(() => {
    async function loadAnswers() {
      const repository = new GoingConcernRepository();
      const answers = await repository.getRegisters(engagementId);
      resetLastQuestion(answers);
    }

    if (isEditing) loadAnswers();
  }, [isEditing]);

  return (
    <div className="mx-auto px-4" style={{ maxWidth: "1000px" }}>
      {groupedQuestions.slice(currentTable, currentTable + 1).map(([tableId, items]) => (
        <form key={tableId} onSubmit={handleSubmitTable} ref={formRef}>
          {
            items.map(({ id, textContent, answerYes, answerNo, typeText, requiredTextBox, context, ignoreAnswer }) => {
              return (
                <>
                  <QuestionTitle id={id} textContent={textContent} typeText={typeText} />
                  {context && (
                    <div className="card card-body pt-2 pb-3 px-3 mb-3 bg-light rounded" style={{ width: "fit-content" }}>
                      <strong className="text-sm">{T("Context")}: </strong>
                      <p className="mb-0 text-sm text-muted">{context}</p>
                    </div>
                  )}
                  {(answerYes != null && answerNo != null) && (
                    <QuestionAnswer
                      id={id}
                      answerYes={answerYes}
                      answerNo={answerNo}
                      ignoreAnswer={ignoreAnswer}
                      typeText={typeText}
                      getCheckedStatus={getCheckedStatus}
                    />
                  )}
                  {(typeText === "type_opinion") && (
                    <select className="form-select" defaultValue={getInputContent(id)} onChange={handleSelectChange} required>
                      <option value="" disabled>{T("Select an option")}</option>
                      <option value="qualified_opinion">{T("Qualified Opinion")}</option>
                      <option value="adverse_opinion">{T("Adverse Opinion")}</option>
                    </select>
                  )}
                  {(typeText === "result_opinion") && (
                    <input type="hidden" name={`answer_${id}`} value="" />
                  )}
                  {requiredTextBox && (
                    <div className="mt-1 summernote">
                      <label htmlFor={`answer_${id}`} className="text-sm">{T("Justification")}</label>
                      <Summernote inputName={`content_${id}`} value={getInputContent(id)} maxLength={2000} />
                      {errors["field_1"] && <p className="text-red-500 text-sm">{errors["field_1"]}</p>}
                    </div>
                  )}
                </>
              )
            })
          }
          {generalError && <p className="text-red-500 text-sm mt-1 text-danger">{generalError}</p>}
          <ActionsButtons
            currentTable={currentTable}
            checkNextTable={checkNextTable}
            checkFinalTable={checkFinalTable}
            checkRedirectTable={checkRedirectTable}
            handlePreviousTable={handlePreviousTable}
          />
        </form>
      ))}
    </div>
  )
}


interface QuestionTitleProps {
  readonly id: number;
  readonly textContent: string;
  readonly typeText: string;
}

function QuestionTitle({ id, textContent, typeText }: Readonly<QuestionTitleProps>) {
  return (
    <>
      {(typeText === "title") && (
        <h4 key={id} className="mb-2 fw-bold text-center" style={{ textWrap: "balance" }}>{textContent}</h4>
      )}
      {(typeText === "subtitle") && (
        <h5 key={id} style={{ textWrap: "balance" }}>{textContent}</h5>
      )}
      {(typeText === "question") && (
        <p key={id} className="mb-1 text-dark" style={{ textWrap: "pretty" }}>{textContent}</p>
      )}
      {(typeText === "type_opinion") && (
        <p className="mb-2 text-dark fs-6">{textContent}</p>
      )}
      {(typeText === "result_opinion") && (
        <pre
          className="mb-2 text-dark fs-6"
          style={{ fontFamily: "sans-serif", textWrap: "wrap" }}
        >{textContent}</pre>
      )}
      {(typeText === "final") && (
        <p key={id} className="mb-1 mx-auto fw-bold text-center text-dark" style={{ width: "60ch", textWrap: "pretty" }}>
          {textContent}
          <input type="hidden" name={`answer_${id}`} value="Yes" />
        </p>
      )}
      {(typeText === "redirect") && (
        <p key={id} className="mb-1 fw-bold text-center text-dark" style={{ textWrap: "pretty" }}>
          {textContent}
          <input type="hidden" name={`answer_${id}`} value="Yes" />
        </p>
      )}
    </>
  )
}


interface QuestionAnswerProps {
  id: number;
  answerYes: number;
  answerNo: number;
  ignoreAnswer: boolean | null;
  typeText: string;
  getCheckedStatus(id: number, answer: string): boolean;
}

function QuestionAnswer({ id, answerYes, answerNo, ignoreAnswer, typeText, getCheckedStatus }: Readonly<QuestionAnswerProps>) {
  return (
    <div className="mt-1">
      <input type="hidden" name="answer_Yes" value={answerYes} />
      <input type="hidden" name="answer_No" value={answerNo} />
      {
        !ignoreAnswer && (
          <div className={typeText === "title" ? 'd-flex justify-content-center' : ''}>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                id={`Yes_${id}`}
                name={`answer_${id}`}
                className="form-check-input me-1"
                value="Yes"
                defaultChecked={getCheckedStatus(id, "Yes")}
                required
              />
              <label className="form-check-label" htmlFor={`Yes_${id}`}>{T("Yes")}</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                id={`No_${id}`}
                name={`answer_${id}`}
                className="form-check-input me-1"
                value="No"
                defaultChecked={getCheckedStatus(id, "No")}
                required
              />
              <label className="form-check-label" htmlFor={`No_${id}`}>{T("No")}</label>
            </div>
          </div>
        )
      }
    </div>
  )
}


interface ActionsButtonsProps {
  currentTable: number;
  checkNextTable: () => boolean;
  checkFinalTable: () => boolean;
  checkRedirectTable: () => boolean;
  handlePreviousTable: () => void;
}

function ActionsButtons({
  currentTable, handlePreviousTable, checkNextTable, checkFinalTable, checkRedirectTable
}: Readonly<ActionsButtonsProps>) {
  return (
    <div className="d-flex gap-2 justify-content-center mt-3">
      <button
        type="button"
        className="btn btn-secondary"
        onClick={handlePreviousTable}
        style={{ display: currentTable > 0 ? 'block' : 'none' }}
      >{T("Back")}</button>
      <button
        type="submit"
        className="btn btn-primary"
        style={{ display: checkNextTable() ? 'block' : 'none' }}
      >{T("Next")}</button>
      <button
        type="submit"
        className="btn btn-primary"
        style={{ display: checkFinalTable() ? 'block' : 'none' }}
      >{T("View Results")}</button>
      <button
        type="submit"
        className="btn btn-primary"
        style={{ display: checkRedirectTable() ? 'block' : 'none' }}
      >{T("Go to Opinion document")}</button>
    </div>
  )
}

export default GoingConcernMemo;