import { ElementType } from "../../models/special-document/ElementType";
import { ElementTypeRepository } from "../../repository/special-document/ElementTypeRepository";
import { Constants } from "../../utils/Constants";


export class ElementTypeFactory {
  private _elementTypeRepository: ElementTypeRepository

  /**
   * Creates an instance of ElementTypeFactory.
   * @param {ElementTypeRepository} elementTypeRepository - An instance of ElementTypeRepository.
   */
  constructor(elementTypeRepository: ElementTypeRepository = new ElementTypeRepository()) {
    this._elementTypeRepository = elementTypeRepository
  }

  /**
   * Retrieves a single ElementType based on the provided numeric type.
   * @param type The numeric identifier for the ElementType.
   * @returns The corresponding ElementType instance.
   */
  getElementType(type: number): ElementType {
    return this._elementTypeRepository.getElementByType(type)
  }

  /**
   * Retrieves a list of ElementType instances filtered based on the provided reference string.
   * The filter criteria depend on specific constants and reference values.
   * @param reference The reference string used to determine the filtering logic.
   * @returns An array of filtered ElementType instances.
   */
  getElementTypes(reference: string): ElementType[] {
    if (reference.includes(Constants.FINDING_REFERENCE)) {
      return this._elementTypeRepository.getAllElements().filter(element =>
        element.type === Constants.PARAGRAPH_ELEMENT_TYPE ||
        element.type === Constants.FINDING_ELEMENT_TYPE ||
        element.type === Constants.FINDING_EVALUATION_ELEMENT_TYPE
      )
    }
    else if (reference.includes(Constants.RISK_REFERENCE)) {
      return this._elementTypeRepository.getAllElements().filter(element =>
        element.type !== Constants.FINDING_ELEMENT_TYPE &&
        element.type !== Constants.FINDING_EVALUATION_ELEMENT_TYPE
      )
    }
    else if (!reference.includes(Constants.SUBSTANTIVE_REFERENCE)) {
      return this._elementTypeRepository.getAllElements().filter(element =>
        !Constants.SUBSTANTIVE_ELEMENT_TYPES.includes(element.type)
      )
    }
    return this._elementTypeRepository.getAllElements().filter(element =>
      element.type !== Constants.FINDING_ELEMENT_TYPE &&
      element.type !== Constants.FINDING_EVALUATION_ELEMENT_TYPE &&
      element.type !== Constants.VARIANCE_ANALYSIS_NOTES_TYPE &&
      element.type !== Constants.PROBABILITY_ANALYSIS_TYPE && 
      element.type !== Constants.MEMORANDUM_OTHER_RISKS_TYPE
    )
  }

}