import { Minute, MinutesTableArgs } from './ElementArgs'
import { ElementBase, IElementBase } from './ElementBase'

/**
 * Class representing a minutes table element
 * @param {MinutesTableArgs} args The arguments to initialize the MinutesTableElement
 */
export class MinutesTableElement extends ElementBase implements IElementBase {
  private _limited_companies: string
  private _stock_corporations: string
  private _limited_minutes: Minute[]
  private _stock_minutes: Minute[]
  private _conclusion: string
  private _minutes_template_name: string
  private _limited_attachment_name: string
  private _stock_attachment_name: string

  /**
   * Initializes a new instance of the MinutesTableElement class
   * @param args The arguments to initialize the MinutesTableElement
   */
  constructor(args: MinutesTableArgs) {
    super(args)
    this._limited_companies = args.limitedCompanies || ''
    this._stock_corporations = args.stockCorporations || ''
    this._limited_minutes = args.limitedMinutes || []
    this._stock_minutes = args.stockMinutes || []
    this._conclusion = args.conclusion || ''
    this._minutes_template_name = args.minutes_template_name || ''
    this._limited_attachment_name = args.limited_attachment_name || ''
    this._stock_attachment_name = args.stock_attachment_name || ''
  }

  get limitedCompanies(): string {
    return this._limited_companies
  }

  get stockCorporations(): string {
    return this._stock_corporations
  }

  get limitedMinutes(): Minute[] {
    return this._limited_minutes
  }

  get stockMinutes(): Minute[] {
    return this._stock_minutes
  }

  get conclusion(): string {
    return this._conclusion
  }

  get minutesTemplateName(): string {
    return this._minutes_template_name
  }

  get limitedAttachmentName(): string {
    return this._limited_attachment_name
  }

  get stockAttachmentName(): string {
    return this._stock_attachment_name
  }

  get args(): MinutesTableArgs {
    return {
      ...super.args,
      limitedCompanies: this._limited_companies,
      stockCorporations: this._stock_corporations,
      limitedMinutes: this._limited_minutes,
      stockMinutes: this._stock_minutes,
      conclusion: this._conclusion,
      minutes_template_name: this._minutes_template_name,
      limited_attachment_name: this._limited_attachment_name,
      stock_attachment_name: this._stock_attachment_name
    }
  }

  set limitedCompanies(limitedCompanies: string) {
    this._limited_companies = limitedCompanies
  }

  set stockCorporations(stockCorporations: string) {
    this._stock_corporations = stockCorporations
  }

  set limitedMinutes(minutes: Minute[]) {
    this._limited_minutes = minutes
  }

  set stockMinutes(minutes: Minute[]) {
    this._stock_minutes = minutes
  }

  set conclusion(conclusion: string) {
    this._conclusion = conclusion
  }

  set minutesTemplateName(template: string) {
    this._minutes_template_name = template
  }

  set limitedAttachmentName(attachment: string) {
    this._limited_attachment_name = attachment
  }

  set stockAttachmentName(attachment: string) {
    this._stock_attachment_name = attachment
  }

  render(): void {}
}