import { useState, useRef } from 'react'
import { T } from '../../utils/i18n-config'
import { decryptUniqueFilename } from "../../utils/utilities"
import { Permissions } from '../../models/special-document/ElementBase'
import { JournalEntriesElement } from '../../models/substantive-tests/JournalEntriesElement'
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { ElementAttachmentRepository } from '../../repository/special-document/ElementAttachmentRepository'
import ViewModeProps from '../special-document/ViewModeProps'
import ViewModeBase from '../special-document/ViewModeBase'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import DropzoneComponent from '@/components/commons/dropzone/dropzone-component'

/**
 * A React component to render and manage the view mode for journal entry attachments.
 */
const JournalEntriesAttachmentViewMode = ({ iElement }: ViewModeProps) => {
  const element = useRef<JournalEntriesElement>(iElement as JournalEntriesElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [attachmentName, setAttachmentName] = useState<string>(element.attachmentName)
  const title = element.attachmentTitle

  /**
   * Opens the modal to edit the attachment
   */
  function handleConfiguration() {
    setShowConfigurationsModal(true)
  }

  /**
   * Downloads the attachment from the element
   */
  async function handleDownloadAttachment() {
    const elementAttachmentRepository = new ElementAttachmentRepository();
    const attachment = await elementAttachmentRepository.getAttachment("view", element.id, attachmentName);
    if (!(attachment instanceof Blob)) return window.htmlHelpers?.swalError();
    const url = window.URL.createObjectURL(attachment);
    const a = document.createElement('a');
    a.href = url;
    a.download = decryptUniqueFilename(attachmentName);
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  /**
   * Removes the attachment from the element and database
   */
  async function handleRemoveAttachment() {
    const elementAttachmentRepository = new ElementAttachmentRepository()
    const response = await elementAttachmentRepository.removeAttachment("view", element.id, attachmentName)

    if (!response) {
      return window.htmlHelpers?.customSwalError(T("Either the file does not exist or you do not have permission to delete it"))
    }
    setAttachmentName("")
    await handleEditModalAccept()
  }

  /**
   * Saves the changes made in the modal
   */
  async function handleEditModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastAttachmentName = element.attachmentName
    element.attachmentName = attachmentName

    let success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      element.attachmentName = lastAttachmentName
      setAttachmentName(lastAttachmentName)
      window.htmlHelpers?.swalError()
    }
  }

  /**
   * Cancels the modal and resets the attachment name
   */
  function handleEditModalCancel() {
    setAttachmentName(element.attachmentName)
    setShowConfigurationsModal(false)
  }

  return (
    <>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleConfiguration}
        currentElement={element}
        permissions={element.permissions as Permissions}
      >
        <div className="card card-body d-flex flex-column bg-light p-3">
          <h6 className="m-0">{title}</h6>
          {attachmentName ? (
            <div className="d-flex justify-content-between align-items-center w-100">
              <p className="m-0 w-75 text-wrap">{T("Attachment")}: <em>{decryptUniqueFilename(attachmentName)}</em></p>
              <div className="d-flex flex-column">
                <button
                  type="button"
                  style={{ cursor: 'pointer' }}
                  className="d-flex align-items-center gap-2 bg-transparent border-0 text-secondary"
                  onClick={() => handleDownloadAttachment()}
                >
                  <i className="fa-solid fa-download"></i>
                  <span>{T("Download file")}</span>
                </button>
                <button
                  type="button"
                  style={{ cursor: 'pointer' }}
                  className="d-flex align-items-center gap-2 bg-transparent border-0 text-secondary"
                  onClick={() => handleRemoveAttachment()}
                >
                  <i className="fa-solid fa-trash"></i>
                  <span>{T("Remove file")}</span>
                </button>
              </div>
            </div>
          ) : (
            <p>{T("No attachment yet")}</p>
          )}
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}
      >
        <div className="d-flex flex-column gap-2">
          <h4 className="text-center">{title}</h4>
          <div>
            <label className="fs-6">{T("Upload file")}</label>
            <DropzoneComponent
              elementId={element.id}
              attachmentName={attachmentName}
              acceptedFiles={["xlsx", "xls"]}
              onUpload={(attachmentName) => setAttachmentName(attachmentName)}
            />
          </div>
        </div>
      </ModalWithButtons>
    </>
  );
}

export default JournalEntriesAttachmentViewMode;