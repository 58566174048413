import React, { useState } from 'react';
import { T } from "../../utils/i18n-config"
import EditModeBase from "./EditModeBase";
import EditModeProps from './EditModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import ModalWithButtons from '../modals/AcceptModalBase';


/**
* Component for editing memorandum complexity technology general controls in edit mode.
* @param iElement - Element object containing configuration and permissions.
* @param handleDeleteElement - Function to handle element deletion.
* @param handleUpElement - Function to handle moving the element up.
* @param handleDownElement - Function to handle moving the element down.
*/
const MemorandumComplexityTechnologyGeneralControlsEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)

  /**
   * Handles configuration initiation by enabling the configuration modal.
   */
  const handleConfiguration = () => {
    setShowConfigurationsModal(true);
  };

  /**
   * Closes the configuration modal upon acceptance.
   */
  const handleConfigurationsModalAccept = async () => {
    setShowConfigurationsModal(false);
  };

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{T("Item display memorandum complexity technology general controls")}</h6>
            </div>
          </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Item display memorandum complexity technology general controls")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}>
        <div style={{display: "flex", justifyContent: "flex-end"}}></div>
        <div className="card-body p-3">
          <h6>{T("Item display memorandum complexity technology general controls")}</h6>
        </div>
      </ModalWithButtons>
    </div>
  )
}
export default MemorandumComplexityTechnologyGeneralControlsEditMode;
