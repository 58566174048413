import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from '../special-document/ViewModeProps';
import ViewModeBase from "../special-document/ViewModeBase";
import { ConditionalParagraphElementWithSelector } from '../../models/special-document/ConditionalParagraphElementWithSelector';
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { decryptUniqueFilename, parseObject } from '../../utils/utilities';
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';
import { RegistersDifferenceReconcilingItems, DifferenceReconcilingItemsRepository } from '../../repository/substantive-tests/DifferenceReconcilingItemsRepository';
import { DocumentSampleRepository, RegistersDocumentSample } from '../../repository/substantive-tests/DocumentSampleRepository';
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import DropzoneComponentSamplesView from '../commons/DropzoneComponentSamplesView';
import { ElementAttachmentRepository } from "../../repository/special-document/ElementAttachmentRepository"


export interface AccountData {
  id: number;
  consignment_number: number;
  type_account: string;
  account: string;
  name: string;
  amount: number;
  amount_in_other_accounts: number;
  amount_per_statement: number;
  difference_amount: number;
}

export interface RegisterType {
  id: string;
  reference_id: number;
  name_reconciling_items: string;
  extracted_value: string;
}

const DocumentSampleViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isRefresh, setIsRefresh] = useState(false);
  const [isRefreshLocal, setIsRefreshLocal] = useState(false);
  const [registersFather, setRegistersFather] = useState<RegistersDocumentSample[]>([]);
  const [subsTestFinished, setSubsTestFinished] = useState<boolean>(false);

  useEffect(() => {
    const handleEvent = () => {
      setIsRefresh(prev => !prev);
    };
    emitter.on("refreshSubstantiveBalanceData", handleEvent);
    emitter.on("refreshDifferenceReconcilingItems", handleEvent);
    return () => {
      emitter.off("refreshSubstantiveBalanceData", handleEvent);
      emitter.off("refreshDifferenceReconcilingItems", handleEvent);
    };
  }, []);

  const [groupedRegisters, setGroupedRegisters] = useState<Record<number, typeof registersFather>>({});
  const [newGroupedRegisters, setNewGroupedRegisters] = useState<Record<number, typeof registersFather>>({});
  const [typeConclusion, setTypeConclusion] = useState<string>("");

  const [contentConclusion, setContentConclusion] = useState<string>("");

  /**
  * Retrieves document samples and updates state according to the user's permissions
  * and the existence of records. It also sets conclusions based on the test outcome.
  */
  const validateRecordsCreation = async () => {
    const documentSampleRepository = new DocumentSampleRepository()
    const result = await documentSampleRepository.getDocumentSamples(element.args.engagement_id, element.args.document_id, element.reference)

    if (result.can_create) {
      setRegistersFather(result.data);
      const groupedByDifferenceId = result.data.reduce((acc, item) => {
        if (item.substantive_test_difference_reconciling_items_id) {
        if (!acc[item.substantive_test_difference_reconciling_items_id]) {
          acc[item.substantive_test_difference_reconciling_items_id] = [];
        }
        acc[item.substantive_test_difference_reconciling_items_id].push(item);
      }
        return acc;
      }, {} as Record<number, typeof result.data>);
      setGroupedRegisters(groupedByDifferenceId);
      const allItemsFlatArray = Object.values(groupedByDifferenceId).flat();

      const initialFileNames = allItemsFlatArray.reduce<Record<number, {id: number, name: string}[]>>((acc, item) => {
        if (item.attachment && Array.isArray(item.attachment)) {
            acc[item.id] = item.attachment.map(att => ({ id: att.id, name: att.name }));
        } else {
            acc[item.id] = [];
        }
        return acc;
    }, {});
      
      setFileNames(initialFileNames);
      const allHaveNames = allItemsFlatArray.every(item => item.description && item.description.trim() !== '' && item.attachment && item.attachment.length > 0);
      setSubsTestFinished(allHaveNames);
    } else {
      setRegistersFather([]);
      setGroupedRegisters({});
      setNewGroupedRegisters({});
      setFileNames({});
      setSubsTestFinished(false);
    }

    if (result.type_conclusion === "material_error") {
      setTypeConclusion("material_error");
      setContentConclusion(T("Based on the work performed in the testing of bank reconciliations, we conclude that material errors have been identified that affect the reasonableness of the account and have an impact on the audit opinion. These errors should be documented in the error log."));
    } else if (result.type_conclusion === "inmaterial_error") {
      setTypeConclusion("inmaterial_error");
      setContentConclusion(T("Based on the work performed in the testing of bank reconciliations, we conclude that non-material errors have been identified that may compromise the reasonableness of the account. These errors should be documented in the error log."));
    } else {
      setTypeConclusion("finished");
      setContentConclusion(T("Based on the work performed in the testing of bank reconciliations, we conclude that no material errors have been identified that could compromise the reasonableness of the account."))
    }
  }

  useEffect(() => {
    validateRecordsCreation()   
  }, [isRefresh, isRefreshLocal]);


  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  const handleEditModalAccept = async () => {
    const documentSampleRepository = new DocumentSampleRepository()
    const result = await documentSampleRepository.updateDocumentSamples(element.args.engagement_id, element.args.document_id, newGroupedRegisters)
    setNewGroupedRegisters({});
    setIsRefreshLocal(prev => !prev);
    setShowConfigurationsModal(false);
  };
  const [key, setKey] = useState(0);

  const handleEditModalCancel = () => {
    setIsRefreshLocal(prev => !prev);
    setShowConfigurationsModal(false)
    setKey(prevKey => prevKey + 1);
  };

  /**
 * Handles the change of an input field by updating the registers state based on the provided id and field.
 * @param id - The identifier for the register
 * @param field - The field in the register to update
 * @param value - The new value for the field
 */
  const handleInputChange = (id: number, field: keyof RegistersDocumentSample, value: string | undefined | null) => {
    setNewGroupedRegisters(prev => {
      const newGroupedRegisters = { ...prev };

        if (!newGroupedRegisters[id]) {
            newGroupedRegisters[id] = [];
        }

        let found = false;
        const updatedGroup = newGroupedRegisters[id].map((item) => {
            if (item.id === id) {
                found = true;
                return { ...item, [field]: value };
            }
            return item;
        });

        if (!found) {
            updatedGroup.push({
                id: id,
                [field]: value,
            });
        }

        newGroupedRegisters[id] = updatedGroup;
        return newGroupedRegisters;
    });

  };
  
  const [fileNames, setFileNames] = useState<Record<number, {id: number, name: string}[]>>({});

  useEffect(() => {
  }, [fileNames]);


  const saveAttachment = async (itemId: number, registers: Record<number, typeof registersFather>) => {
    const documentSampleRepository = new DocumentSampleRepository()
    const result = await documentSampleRepository.updateDocumentSamples(element.args.engagement_id, element.args.document_id, registers)
    setIsRefreshLocal(prev => !prev);
  }

  function handleSavingAttachment(itemId: number, attachmentName:  string[]) {
    
    const newAttachment: Record<number, typeof registersFather> = {};
    if (!newAttachment[itemId]) {
        newAttachment[itemId] = [];
    }
    let found = false;
    const updatedGroup = newAttachment[itemId].map((item) => {
        if (item.id === itemId) {
            found = true;
            return { ...item, ["attachment_name"]: attachmentName };
        }
        return item;
    });
    if (!found) {
        updatedGroup.push({
            id: itemId,
            ["attachment_name"]: attachmentName,
        });
    }

    newAttachment[itemId] = updatedGroup;
    saveAttachment(itemId, newAttachment);
    setIsRefreshLocal(prev => !prev);
  }

  const redirectErrorSheet = () => {
    const url = `/audix/engagement_error_sheet/index.load/${element.args.engagement_id}/${element.reference}/${element.reference}`;
    window.menuService?.breadcrumbAddLevel(url, T('Error Sheet'))
  }

  async function handleRemoveAttachment(attachment: {id:number , name:string}) {
    const elementAttachmentRepository = new ElementAttachmentRepository()
    const response = await elementAttachmentRepository.removeAttachment("view", element.id, attachment.name)
    if (!response) {
      return window.htmlHelpers?.customSwalError(T("Either the file does not exist or you do not have permission to delete it"))
    }
    setFileNames(prev => {
      const updated = { ...prev };
      for (const key in updated) {
        updated[key] = updated[key].filter(att => att.id !== attachment.id);
      }
      return updated;
    })
    
    const documentSampleRepository = new DocumentSampleRepository()
    const result = await documentSampleRepository.deleteAttachmentDocumentSample(element.args.engagement_id, element.args.document_id, attachment.id)
    setIsRefreshLocal(prev => !prev);
  }

  return (
    <div>
      {Object.keys(groupedRegisters).length > 0 && (
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        <div className="d-flex w-full flex-column mb-3">
          <h4>{T("Documents Samples")}</h4>
          {Object.entries(groupedRegisters).map(([differenceId, items]) => (
            <div key={differenceId} className="mb-3">
              <div className="d-flex justify-content-center w-100">
                <table className="table-bordered w-100">
                  <thead>
                    <tr>
                      <th>{T("Fixed Assets")}</th>
                      <th className="w-35">{T("Description")}</th>
                      <th className="w-35">{T("Attachments")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map(item => (
                      <tr key={item.id}>
                        <td>{item.name_reconciling_items}</td>
                        <td>{item.description}</td>
                        <td>
                        {fileNames[item.id]?.map((attachment, index) => (
                          <p key={index} className="m-0">{T("Attachment")}: <em>{decryptUniqueFilename(attachment.name)}</em></p>
                        ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}

          <div className="w-100 d-flex align-items-center text-center flex-column mt-2">
            {subsTestFinished && (
              <>
                <p>{contentConclusion}</p>
                {(typeConclusion === "material_error" || typeConclusion === "inmaterial_error") && (
                  <button className="btn btn-primary" onClick={redirectErrorSheet}>{T("Error Sheet")}</button>
                )}
              </>
            )}
          </div>
        </div>
      </ViewModeBase>
      )}
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Fixed Assets")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <div className="d-flex w-full flex-column mb-3">
          <h4>{T("Documents Sample")}</h4>
          {Object.entries(groupedRegisters).map(([differenceId, items]) => (
            <div key={differenceId} className="mb-3">
              <div className="d-flex justify-content-center w-100">
                <table className="table-bordered w-100">
                  <thead>
                    <tr>
                    <th>{T("Fixed Assets")}</th>
                      <th>{T("Description")}</th>
                      <th>{T("Attachments")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map(item => (
                      <tr key={`${item.id}-${key}`}>
                        <td>{item.name_reconciling_items}</td>
                        <td className="p-1">
                          <textarea
                            className="form-control"
                            defaultValue={item.description}
                            onChange={e => handleInputChange(item.id, 'description', e.target.value)}
                          />
                        </td>
                        <td className="p-1">
                              <DropzoneComponentSamplesView
                                elementId={element.id}
                                attachmentName={fileNames[item.id] || []}
                                onUpload={(newFileNames) => handleSavingAttachment(item.id, newFileNames)}
                                onRemove={(fileNamee) => handleRemoveAttachment(fileNamee)}
                              />
                              
                          </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default DocumentSampleViewMode;
