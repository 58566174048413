import { useState, useEffect, useCallback } from 'react'
import { T } from "@/utils/i18n-config"
import { DisallowanceIncomesElement } from '@/models/special-document/DisallowanceIncomesElement'
import { DisallowanceIncomesRepository } from '@/repository/special-document/DisallowanceIncomesRepository'
import { ElementRepository } from '@/repository/special-document/ElementRepository'
import ModalWithButtons from '@/components/modals/AceptCancelModalBase'
import { ButtonModal } from '@/components/commons/button-modal'
import { AttachmentZone } from '@/components/commons/attachment-zone'
import TableComponent from "@/components/commons/TableComponent"

type ReturnDelivery = string | number | boolean

interface ReturnDeliveriesProps {
  element: DisallowanceIncomesElement
}

/**
 * Component to manage the return deliveries of the disallowance income element.
 * @param element The disallowance income element
 */
function ReturnDeliveries({ element }: ReturnDeliveriesProps) {
  const [showModal, setShowModal] = useState(false)
  const [returns, setReturns] = useState<ReturnDelivery[][]>([])
  const [returnsAttachmentName, setReturnsAttachmentName] = useState(element.returnsAttachmentName)
  const returnsTemplateName = element.returnsTemplateName
  const labels = [
    T("Invoice Number"),
    T("Note Date"),
    T("Delivery Date"),
    T("Adjustment")
  ]

  /**
   * Handle the modal accept event.
   */
  async function handleModalAccept() {
    setShowModal(false)
    const elementRepository = new ElementRepository()
    const lastReturnsAttachmentName = element.returnsAttachmentName
    element.returnsAttachmentName = returnsAttachmentName
    const success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      element.returnsAttachmentName = lastReturnsAttachmentName
      setReturnsAttachmentName(lastReturnsAttachmentName)
      return window.htmlHelpers?.swalError()
    }
  }

  /**
   * Handle the modal cancel event.
   */
  function handleModalCancel() {
    setShowModal(false)
    setReturnsAttachmentName(element.returnsAttachmentName)
  }

  /**
   * Generates the returns selection.
   */
  async function handleGenerateReturns() {
    const disallowanceIncomesRepository = new DisallowanceIncomesRepository()
    if (returns.length === 0) {
      const { success } = await disallowanceIncomesRepository.generateReturnsSelection(
        element.id, "view", element.engagement_id, returnsAttachmentName
      )
      if (!success) return window.htmlHelpers?.swalError()
    }
    getReturns()
  }

  /**
   * Retrieves the returns from the repository and updates the state.
   * If retrieval fails, displays an error
   */
  const getReturns = useCallback(async () => {
    const disallowanceIncomesRepository = new DisallowanceIncomesRepository()
    const { success, returns } = await disallowanceIncomesRepository.getReturnsSelection(element.id, "view")
    if (!success) return window.htmlHelpers?.swalError()
    setReturns(returns.map(item => ([
      item.return_id,
      item.note_date,
      item.delivery_date,
      item.adjustment
    ])))
  }, [element.id])

  useEffect(() => { getReturns() }, [getReturns])
  useEffect(() => { setReturns([]) }, [returnsAttachmentName])

  return (
    <div className="position-relative">
      <ButtonModal onDisplayModal={() => setShowModal(true)} />
      <div className="d-flex flex-column gap-2">
        <h6>{T("Selection of credit notes")}</h6>
        <p>{T("Request the credit notes report corresponding to the beginning of the next period (January). Download the template provided and complete the required information to select the credit notes to be tested.")}</p>
        <TableComponent labels={labels} values={returns} />
      </div>
      <ModalWithButtons
        showModal={showModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleModalAccept}
        onCancel={handleModalCancel}
      >
        <div className="d-flex flex-column gap-5 px-4">
          <AttachmentZone
            elementId={element.id}
            templateName={returnsTemplateName}
            attachmentName={returnsAttachmentName}
            onUpdateAttachment={setReturnsAttachmentName}
            onAction={handleGenerateReturns}
          />
        </div>
      </ModalWithButtons>
    </div>
  )
}

export default ReturnDeliveries
