import { useState } from "react"
import { T } from "@/utils/i18n-config"
import TableComponent from "@/components/commons/TableComponent"

export type Destination = { location: string, deliveryDays: number }
export type DeliveryLocation = Destination & { deliveryType: number }

interface DeliveryLocationTableProps {
  locationLabel: string
  locations: DeliveryLocation[]
}

/**
 * Table to display the delivery locations and their average delivery time.
 * @param locationLabel The label for the location column
 * @param locations The list of delivery locations
 */
export function DeliveryLocationTable({ locationLabel, locations }: DeliveryLocationTableProps) {
  return (
    <div className="d-flex flex-column gap-2">
      <h6>{T("Destination determination and average delivery time")}</h6>
      <p>{T("Find out which are the most distant delivery destinations and the average time required to complete deliveries to each of them.")}</p>
      <div className="d-flex flex-column gap-4">
        <TableComponent
          labels={[T(locationLabel), T("Average delivery time (in days)")]}
          values={locations.map(delivery => [delivery.location, delivery.deliveryDays])}
        />
      </div>
    </div>
  )
}


interface DeliveryLocationAdderProps {
  locationLabel: string
  onAddDeliveryLocation: (location: string, deliveryDays: number) => void
}

/**
 * Form to add a new delivery location to the list.
 * @param locationLabel The label for the location input
 * @param onAddDeliveryLocation The function to add a new delivery location
 */
export function DeliveryLocationAdder({ locationLabel, onAddDeliveryLocation }: DeliveryLocationAdderProps) {
  const [location, setLocation] = useState("")
  const [deliveryDays, setTimeDays] = useState(1)

  /**
   * Add new valid delivery location to the list
   */
  function handleAddDeliveryLocation() {
    if (location && deliveryDays > 0) {
      onAddDeliveryLocation(location, deliveryDays)
      setLocation("")
      setTimeDays(1)
    }
  }

  return (
    <div className="d-flex flex-column align-items-center gap-3">
      <h6>{T("Add new delivery location")}</h6>
      <div className="d-flex align-items-end gap-2">
        <div className="d-flex flex-column">
          <label>{T(locationLabel)}</label>
          <input type="text" value={location} className="form-control" onChange={(e) => setLocation(e.target.value)} />
        </div>
        <div className="d-flex flex-column">
          <label>{T("Average delivery time (in days)")}</label>
          <input type="number" value={deliveryDays} min={1} max={365} className="form-control" onChange={(e) => setTimeDays(parseInt(e.target.value))} />
        </div>
      </div>
      <button className="btn btn-primary mb-0" style={{ width: "fit-content", paddingInline: "2rem" }} onClick={handleAddDeliveryLocation}>
        {T("Add")}{" "}{T(locationLabel)}
      </button>
    </div>
  )
}