import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import { Options, ParagraphsTypesOpinionsRepository } from "../../repository/special-document/ParagraphsTypesOpinionsRepository"
import { ElementParagraph } from "../../models/special-document/ElementParagraph"
import ViewModeBase from "./ViewModeBase";
import ViewModeProps from './ViewModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import { Constants } from '../../utils/Constants';
import DOMPurify from 'isomorphic-dompurify';
import ModalWithButtons from '../modals/AceptCancelModalBase'


/**
 * Component to display the KamParagraphOpinion in view mode
 * @param ViewModeProps - interface with the element to display
 */
const KamParagraphOpinionViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<ElementParagraph>(iElement as ElementParagraph).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [optionIdSelected, setOptionIdSelected] = useState<number | undefined>(undefined);
  const [optionIdSelectedBase, setOptionIdSelectedBase] = useState<number | undefined>(undefined);
  const [optionBaseSelected, setOptionBaseSelected] = useState<Options | undefined>(undefined);
  const [notNecessaryKamParagraph, setNotNecessaryKamParagraph] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(false)
  const [question, setQuestion] = useState('')
  const [options, setOptions] = useState<Options[]>([]);
  const [key, setKey] = useState(0);

  /**
   * Function to get the data from the repository
   */
  const getData = async () => {
    const paragraphsTypesOpinionsRepository = new ParagraphsTypesOpinionsRepository()
    const textContent = await paragraphsTypesOpinionsRepository.getOpinionByReferenceAndTypeOpinion(element.args.engagement_id, element.args.document_id, element.id, element.args.reference_type_opinion, "kam_paragraph", "view")
    const newOptions = [
      {
        id: 1,
        answer: textContent.data.answer_yes,
        justification: textContent.data.justification_yes
      },
      {
        id: 2,
        answer: textContent.data.answer_no,
        justification: textContent.data.justification_no
      }
    ];
    if (textContent.success && textContent.not_necessary_kam) {
      setNotNecessaryKamParagraph(true);
    }
    setQuestion(textContent.data.text_content)
    setOptions(newOptions)

    const OptionContent = await paragraphsTypesOpinionsRepository.getOptionKamParagraphOpinion(element.args.engagement_id, element.args.document_id)
    if (OptionContent.success) {
      const selectedOption = newOptions.find(option => option.id === Number(OptionContent.selected_id)) || undefined;
      setOptionBaseSelected(selectedOption);
      setOptionIdSelected(Number(OptionContent.selected_id));
      setOptionIdSelectedBase(Number(OptionContent.selected_id));
    }
  }

  useEffect(() => {
    getData()
  }, [refresh]);

  /**
   * Function to handle the modal accept button
   */
  const handleEditModalAccept = async () => {
    setShowConfigurationsModal(false);
    const paragraphsTypesOpinionsRepository = new ParagraphsTypesOpinionsRepository()
    const textContent = await paragraphsTypesOpinionsRepository.UpdateOptionKamParagraphOpinion(element.args.engagement_id, element.args.document_id, "view", { selectedId: optionIdSelected })
    setRefresh(!refresh)
  };

  /**
   * Function to handle the modal cancel button
   */
  const handleEditModalCancel = () => {
    setKey(prevKey => prevKey + 1);
    setOptionIdSelected(optionIdSelectedBase)
    setShowConfigurationsModal(false)
  };

  /**
   * Function to handle the edit button
   */
  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  /**
   * Function to handle the select change
   * @param e - event
   */
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedId = Number(e.target.value);
    setOptionIdSelected(newSelectedId);
  };

  return (
    <div>
      {notNecessaryKamParagraph ? (
        <>
          <div>
            <ViewModeBase
              isEditable={true}
              handleEdit={handleEdit}
              permissions={element.permissions as Permissions}
            >
              {options.length === 0 &&
                <div className="card h-100 bg-light">
                  <div className="card-body p-3">
                    <h6>{T("Without configuration")}</h6>
                    <div className="chart pt-3">
                      <p>{T("Configure this element from the template.")}</p>
                    </div>
                  </div>
                </div>
              }
              {options.length > 0 &&
                <div className="card h-100 bg-light">
                  <div className="card-body p-3">
                    <h6>{question}</h6>
                    <div key={key} className="chart pt-3">
                      {optionBaseSelected ? (
                        <div
                          className={`${Constants.CONTENT_CLASS}`}
                          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(optionBaseSelected.justification ?? "") }}
                        />
                      ) : (
                        <p>{T("Select an answer.")}</p>
                      )}
                    </div>
                  </div>
                </div>
              }
            </ViewModeBase>
            <ModalWithButtons
              showModal={showConfigurationsModal}
              title={question}
              size="xl"
              onAccept={handleEditModalAccept}
              onCancel={handleEditModalCancel}>
              <select key={key} value={optionIdSelected ?? ""} onChange={handleSelectChange} className="form-select">
                <option value="">{T("Select an answer.")}</option>
                {options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {T(option.answer ?? "")}
                  </option>
                ))}
              </select>
            </ModalWithButtons>
          </div>
        </>
      ) : (
        <div className="card h-100 bg-light col-11 mb-3">
          <div className="card-body p-3">
            <h6>{T("Element for displaying the kam paragraph")}</h6>
          </div>
        </div>
      )}
    </div>  
  );
};

export default KamParagraphOpinionViewMode;
