import { RequiredAccountConfigurationBankConciliations } from '../../models/special-document/ElementArgs'
import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'

export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface AuditPlanVarianceAnalysis {
  id: number
  name: string
}

export class SelectAuditPlanFixedAssetsRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  /**
  * Retrieve Fixed Assets Audit Plan Files for a given engagement and document.
  * @param {number|null|undefined} engagementId - The engagement identifier.
  * @param {number} documentId - The document identifier.
  * @returns {Promise<{success: boolean, data: AuditPlanVarianceAnalysis[]}>} Result object with operation status and the related data.
  */
  async getAuditPlanFilesFixedAssets(engagementId: number|null| undefined, documentId: number): Promise<{success: boolean, data: AuditPlanVarianceAnalysis[]}> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/get_audit_plan_files_variance_analysis/'+ engagementId + '/' + documentId)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {success: boolean, data: AuditPlanVarianceAnalysis[]} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Update Audit Plan Variance Analysis for a given engagement and document.
   * @param engagementId - The engagement identifier (nullable or undefined).
   * @param documentId - The document identifier.
   * @param data_changes - An object containing the reference and the audit plan variance analysis ID.
   * @returns A Promise resolving to a BasicResponse indicating the status of the update.
   */
  async updateAuditPlanVarianceAnalysis(engagementId: number|null | undefined, documentId: number,data_changes: {reference: string | undefined, audit_plan_variance_analysis_id: number| undefined}): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/update_audit_plan_variance_analysis/' + engagementId + '/' + documentId )
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(data_changes)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
  * Retrieve Fixed Assets Audit Plan Files for a given engagement and document.
  * @param {number|null|undefined} engagementId - The engagement identifier.
  * @param {number} documentId - The document identifier.
  * @param {string|undefined} reference - An optional reference string.
  * @returns {Promise<{success: boolean, audit_plan_id: number| undefined}>} Result object with operation status and the related data.
  * */
  async getSelectedAuditPlanFilesFixedAssets(engagementId: number|null| undefined, documentId: number, reference: string | undefined): Promise<{success: boolean, audit_plan_id: number| undefined}> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/get_selected_audit_plan_files_variance_analysis/'+ engagementId + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {success: boolean, audit_plan_id: number| undefined} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
  * Updates the account configuration for a specified engagement and document.
  * @param engagementId - The engagement identifier (nullable or undefined).
  * @param documentId - The document identifier.
  * @param data_changes - Object containing the reference and ledger account id.
  * @returns A promise that resolves to a BasicResponse indicating the update status.
  */
  async updateAccountConfiguration(engagementId: number|null | undefined, documentId: number,data_changes: {reference: string | undefined, ledger_account_id: number| undefined}): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/update_account_configuration/' + engagementId + '/' + documentId )
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(data_changes)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async deleteFinding(engagementId: number|null, documentId: number, findingId: number): Promise<BasicResponse> {
    const apiUrl = getUrl('finding_element_api/delete_finding/' + engagementId + '/' + documentId + '/' + findingId)
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async createFinding(engagementId: number|null, documentId: number, elementId: number, reference: string | undefined): Promise<CreateResponse> {
    const apiUrl = getUrl('finding_element_api/create_finding/' + engagementId + '/' + documentId + '/' + elementId + '/' + reference)
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: CreateResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async getLedgerAccounts(engagementId: number|null): Promise<RequiredAccountConfigurationBankConciliations[]> {
    const apiUrl = getUrl('substantive_test_api/get_ledger_accounts/' + engagementId)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: RequiredAccountConfigurationBankConciliations[] = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async addAccountConfiguration(engagementId: number | null | undefined, document_id: number, ledger_account_id: number|null, reference: string | undefined): Promise<CreateResponse> {
    const apiUrl = getUrl('substantive_test_api/add_account_configuration/' + engagementId + '/' + document_id + '/' + ledger_account_id+ '/' + reference)
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: CreateResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async deleteAccountConfiguration(engagementId: number|null | undefined, register_id: number|undefined, documentId: number | undefined, reference: string | undefined): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_api/delete_account_configuration/' + engagementId + '/' + documentId + '/' + register_id + '/' + reference)
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async updateNumberAccountDigits(engagementId: number|null| undefined, documentId: number, reference: string | undefined, accountLength: number): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_api/update_number_account_digits/' + engagementId + '/' + documentId + '/' + reference + '/' + accountLength)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }    
}