import React, { useState, useRef } from 'react'
import { T } from '@/utils/i18n-config'
import { decryptUniqueFilename, setImportantAttr } from '@/utils/utilities'
import { Permissions } from '@/models/special-document/ElementBase'
import { ElementRepository } from '@/repository/special-document/ElementRepository'
import { ContractsTableElement } from '@/models/special-document/ContractsTableElement'
import EditModeProps from '../special-document/EditModeProps'
import EditModeBase from '../special-document/EditModeBase'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import TableComponent from '../commons/TableComponent'
import DropzoneComponent from '../commons/dropzone/dropzone-component'

const ContractsTableEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<ContractsTableElement>(iElement as ContractsTableElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [contractsTemplateName, setContractsTemplateName] = useState(element.contractsTemplateName)

  /**
   * Handle the configuration of the element
   */
  function handleConfiguration() {
    setShowConfigurationsModal(true)
  }

  /**
   * Handle the accept button of the configurations modal
   */
  async function handleConfigurationsModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastContractTemplate = element.contractsTemplateName
    element.contractsTemplateName = contractsTemplateName

    let success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      window.htmlHelpers?.swalError()
      element.contractsTemplateName = lastContractTemplate
      setContractsTemplateName(lastContractTemplate)
    }
  }

  /**
   * Handle the cancel button of the configurations modal
   */
  function handleConfigurationsModalCancel() {
    setShowConfigurationsModal(false)
    setContractsTemplateName(element.contractsTemplateName)
  }

  return (
    <>
      <EditModeBase
        iElement={iElement}
        isEditable={true}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}
      >
        <div className="d-flex flex-column gap-3">
          <div>
            <p>{T("1. Request relevant contracts with customers and suppliers during the audited year:")}</p>
            <p className="ms-4">{T("a. Attach the list of contracts, if no list is provided, see the physical documents and summarize as follows:")}</p>
          </div>
          <TableComponent
            labels={[T("Contract Number"), T("Third Party Name"), T("Subscription Date"), T("Expiration Date"), T("Object of Contract"), T("Contract Amount")]}
            values={[
              ["C-001", T("Example S.A.S"), "01/01/2021", "01/01/2022", T("Product purchasing"), 100_000]
            ]}
          />
          <div className="mt-2 py-3 px-4 border border-2 rounded-3" ref={(el) => setImportantAttr(el, "border-style", "dashed")}>
            <p className="m-0 fs-6">{T("Contracts Template")}: <em>{decryptUniqueFilename(contractsTemplateName)}</em></p>
          </div>
          <p>{T("2. Based on the list of contracts and performance materiality, make a selection of the most significant contracts.")}</p>
          <div>
            <p>{T("3. For selected contracts, request a copy and perform the following steps:")}</p>
            <p className="ms-4">{T("Perform a complete reading of the contract to understand its purpose, scope, and the commitments assumed by the entity.")}</p>
            <p className="ms-4">{T("Identifies key sections of the contract that may involve risks or affect the financial statements, such as:")}</p>
            <ul className="ms-4 ps-4">
              <li>{T("Obligations of the parties (what the entity must comply with and what it receives in return).")}</li>
              <li>{T("Penalty or noncompliance clauses.")}</li>
              <li>{T("Payment terms or financial conditions.")}</li>
              <li>{T("Guarantees offered or required.")}</li>
              <li>{T("Contract terms (term, expiration).")}</li>
              <li>{T("Special clauses (renegotiations, early termination, exclusivity, etc.).")}</li>
            </ul>
          </div>
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}
      >
        <div className="d-flex flex-column mx-auto w-full" style={{ maxWidth: "50%" }}>
          <h6 className="text-center">{T("Contracts Template")}</h6>
          <DropzoneComponent
            elementId={element.id}
            renderingMode="edition"
            attachmentName={contractsTemplateName}
            onUpload={(attachmentName) => setContractsTemplateName(attachmentName as string)}
          />
        </div>
      </ModalWithButtons>
    </>
  )
}

export default ContractsTableEditMode