import React, { useState, useEffect, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { Constants } from "../../utils/Constants"
import { Permissions } from '../../models/special-document/ElementBase'
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { RedirectionButtonElement } from "../../models/special-document/RedirectionButtonElement"
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeProps from './EditModeProps'
import EditModeBase from './EditModeBase'

const RedirectionButtonEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<RedirectionButtonElement>(iElement as RedirectionButtonElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState(-1)
  const [isEditable, setIsEditable] = useState(false)
  const [context, setContext] = useState(element.context)
  const [title, setTitle] = useState(element.title)

  function handleConfiguration() {
    setShowConfigurationsModal(true)
  }

  function handleConfigurationsModalCancel() {
    setShowConfigurationsModal(false)
  }

  async function handleConfigurationsModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    element.context = context
    element.title = title

    const response = await elementRepository.saveElement("edition", element.args)
    if (!response) {
      setContext(element.context)
      setTitle(element.title)
      window.htmlHelpers?.swalError()
    }
  }

  function handleSelectOption(event: React.ChangeEvent<HTMLSelectElement>) {
    const index = parseInt(event.target.value)
    const selectedOption = Constants.REDIRECTION_FORMULARIES[index]
    setSelectedOption(index)
    setContext(selectedOption.value)
    setTitle(selectedOption.text)
  }

  useEffect(() => {
    if (element.state === "closed") {
      setIsEditable(false)
    }

    const optionIndex = Constants.REDIRECTION_FORMULARIES.findIndex(option => option.value === context)
    setSelectedOption(optionIndex)
  }, [])

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={isEditable}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}
        >
        <div className="card h-100 bg-light">
          <div className="card-body p-3">
            <span className="text-dark fw-bold mb-0">{T("Redirect to")}:</span>
            <p className="m-0">
              {title !== "" ? T(title) : T("No document/formulary selected")}
            </p>
          </div>
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Redirection Button")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}
      >
        <div className="form-group">
          <label>{T("Option")}</label>
          <select value={selectedOption} onChange={handleSelectOption} className="form-select">
            <option selected disabled>{T("No document/formulary selected")}</option>
            {Constants.REDIRECTION_FORMULARIES.map((option, index) => (
              <option key={`option-${index}`} value={index}>
                {T(option.text)}
              </option>
            ))}
          </select>
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default RedirectionButtonEditMode;
