import { QuestionTextFileWithYesNoArgs } from './ElementArgs'
import { ElementBase, IElementBase } from './ElementBase'

export class QuestionTextFileWithYesNoElement extends ElementBase implements IElementBase {
  private _question: string
  private _answer_yes_no: string
  private _answer: string
  private _attachment: string

  constructor(args: QuestionTextFileWithYesNoArgs) {
    super(args)
    this._question = args.question || ""
    this._answer = args.answer || ""
    this._attachment = args.attachment || ""
    this._answer_yes_no = args.answer_yes_no || ""
  }

  get question(): string {
    return this._question
  }

  get answer(): string {
    return this._answer
  }

  get attachment(): string {
    return this._attachment
  }

  get answer_yes_no(): string {
    return this._answer_yes_no
  }

  get args(): QuestionTextFileWithYesNoArgs {
    return {
      ...super.args,
      question: this._question,
      answer: this._answer,
      attachment: this._attachment,
      answer_yes_no: this._answer_yes_no
    }
  }

  set question(question: string) {
    this._question = question
  }

  set answer(answer: string) {
    this._answer = answer
  }

  set attachment(attachment: string) {
    this._attachment = attachment
  }

  set answer_yes_no(answer_yes_no: string) {
    this._answer_yes_no = answer_yes_no
  }

  render(): void {}
}