import React, { useState, useRef } from 'react'
import { T } from '../../utils/i18n-config'
import { decryptUniqueFilename } from '../../utils/utilities'
import { Permissions } from '../../models/special-document/ElementBase'
import type { AttachmentOption } from '../../models/special-document/ElementArgs'
import { ElementRepository } from '../../repository/special-document/ElementRepository'
import { MemoReportsAttachmentsElement } from '../../models/special-document/MemoReportsAttachmentsElement'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import ViewModeProps from '../special-document/ViewModeProps'
import ViewModeBase from '../special-document/ViewModeBase'
import DropzoneComponent from '@/components/commons/dropzone/dropzone-component'

type EditableAttachmentOption = AttachmentOption & { editable?: boolean }

const MemoReportsAttachmentsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<MemoReportsAttachmentsElement>(iElement as MemoReportsAttachmentsElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [attachmentOptions, setAttachmentOptions] = useState<EditableAttachmentOption[]>(element.attachmentOptions)

  function handleConfiguration() {
    setShowConfigurationsModal(true)
  }

  async function handleOnAcceptModal() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastAttachmentOptions = element.attachmentOptions
    element.attachmentOptions = attachmentOptions

    const success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      element.attachmentOptions = lastAttachmentOptions
      setAttachmentOptions(lastAttachmentOptions)
      window.htmlHelpers?.swalError()
    }
  }

  function handleOnCancelModal() {
    setShowConfigurationsModal(false)
    setAttachmentOptions(element.attachmentOptions)
  }

  function handleChangeAttachment(index: number, attachmentName: string) {
    const newItems = attachmentOptions.toSpliced(index, 1, { ...attachmentOptions[index], filename: attachmentName })
    setAttachmentOptions(newItems)
  }

  function handleEditItemTitle(index: number, title: string) {
    const newItems = attachmentOptions.toSpliced(index, 1, { ...attachmentOptions[index], title })
    setAttachmentOptions(newItems)
  }

  function newAttachmentItem() {
    const newOption = { title: "New Report", filename: "", editable: true }
    setAttachmentOptions([...attachmentOptions, newOption])
  }

  return (
    <>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleConfiguration}
        permissions={element.permissions as Permissions}
      >
        <table className="table table-responsive table-striped table-hover" style={{ borderCollapse: "collapse" }}>
          <thead className="table-dark border">
            <tr>
              <th scope="col" className="text-center" style={{ width: "300px" }}>{T("Title")}</th>
              <th scope="col" className="text-center">{T("Attachment")}</th>
            </tr>
          </thead>
          <tbody>
            {attachmentOptions.map(({ title, subtitle, filename }, index) => (
              <tr key={index}>
                <th scope="row" className="text-center border-start border-end border-bottom">
                  {subtitle ? subtitle : title}
                </th>
                <td className="ps-4 text-wrap border-bottom border-end">{decryptUniqueFilename(filename)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleOnAcceptModal}
        onCancel={handleOnCancelModal}
      >
        <div className="d-grid align-items-center gap-4" style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}>
          {attachmentOptions.map(({ title, filename, editable }, index) => (
            <div key={index} className="d-flex flex-column gap-2">
              <EditableAttachmentTitle
                defaultTitle={title}
                itemIndex={index}
                isEditable={editable ?? false}
                onEditTitle={handleEditItemTitle}
              />
              <DropzoneComponent
                elementId={element.id}
                renderingMode="view"
                attachmentName={filename}
                onUpload={(attachmentName) => handleChangeAttachment(index, attachmentName)}
              />
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4 mb-2">
          <button className="btn btn-primary m-0" onClick={newAttachmentItem}>
            {T("Add new report")}
          </button>
        </div>
      </ModalWithButtons>
    </>
  );
}


interface EditableAttachmentTitleProps {
  defaultTitle: string
  itemIndex: number
  isEditable: boolean
  onEditTitle: (index: number, title: string) => void
}

function EditableAttachmentTitle({ defaultTitle, itemIndex, isEditable, onEditTitle }: EditableAttachmentTitleProps) {
  const [title, setTitle] = useState(defaultTitle)
  const [isEditMode, setIsEditMode] = useState(false)

  function handleSaveItemTitle() {
    const editState = !isEditMode
    setIsEditMode(editState)
    if (!editState) onEditTitle(itemIndex, title)
  }

  if (!isEditable) return <h6 className="m-0 text-center">{title}</h6>
  return (
    <div className="d-flex align-items-center gap-2">
      {isEditMode ? (
        <input
          type="text"
          className="flex-grow-1 form-control"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      ) : (
        <h6 className="flex-grow-1 m-0 text-center">{title}</h6>
      )}
      <button className="p-2 bg-secondary border-0 rounded text-white text-sm lh-1" onClick={handleSaveItemTitle}>
        {isEditMode ? (
          <i className="fa-solid fa-check" />
        ) : (
          <i className="fa-solid fa-pencil" />
        )}
      </button>
    </div>
  )
}

export default MemoReportsAttachmentsViewMode;