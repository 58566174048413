import { RequiredAccountConfigurationBankConciliations } from '../../models/special-document/ElementArgs'
import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'

export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export class RequiredAccountConfigurationBankConciliationsRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  async deleteFinding(engagement_id: number|null, documentId: number, findingId: number): Promise<BasicResponse> {
    const apiUrl = getUrl('finding_element_api/delete_finding/' + engagement_id + '/' + documentId + '/' + findingId)
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async createFinding(engagement_id: number|null, documentId: number, elementId: number, reference: string | undefined): Promise<CreateResponse> {
    const apiUrl = getUrl('finding_element_api/create_finding/' + engagement_id + '/' + documentId + '/' + elementId + '/' + reference)
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: CreateResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async getAccountsConfiguration(engagementId: number|null| undefined, documentId: number, reference: string | undefined, renderingMode: string): Promise<{data: RequiredAccountConfigurationBankConciliations[], number_account_digits: number}> {
    const apiUrl = getUrl('substantive_test_api/get_configurable_accounts/' + engagementId + '/'+ documentId +'/'+ reference + '/' + renderingMode)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {data: RequiredAccountConfigurationBankConciliations[], number_account_digits: number} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async getLedgerAccounts(): Promise<RequiredAccountConfigurationBankConciliations[]> {
    const apiUrl = getUrl('substantive_test_api/get_ledger_accounts')
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: RequiredAccountConfigurationBankConciliations[] = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async addAccountConfiguration(engagement_id: number | null | undefined, document_id: number, ledger_account_id: number|null, reference: string | undefined): Promise<CreateResponse> {
    const apiUrl = getUrl('substantive_test_api/add_account_configuration/' + engagement_id + '/' + document_id + '/' + ledger_account_id+ '/' + reference)
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: CreateResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async deleteAccountConfiguration(engagement_id: number|null | undefined, register_id: number|undefined, documentId: number | undefined, reference: string | undefined): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_api/delete_account_configuration/' + engagement_id + '/' + documentId + '/' + register_id + '/' + reference)
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async updateNumberAccountDigits(engagementId: number|null| undefined, documentId: number, reference: string | undefined, accountLength: number): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_api/update_number_account_digits/' + engagementId + '/' + documentId + '/' + reference + '/' + accountLength)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }    
}