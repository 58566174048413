import React from "react"
import MultipleDropzoneComponent from "../utils/MultipleDropzoneComponent";


export interface DropFile {
  file: File;
  preview: string;
}

interface DropzoneComponentProps {
  elementId: number;
  attachmentName?: { id: number, name: string }[];
  onUpload: (attachmentName: string[], originalFilename: DropFile[]) => void;
  onRemove: (attachmentName: { id: number, name: string }) => void;
}


/**
* Renders the dropzone component for meetings and members view.
* @param {DropzoneComponentProps} props - Component properties.
*/
const DropzoneComponentMeetingsMembersView: React.FC<DropzoneComponentProps> = ({ elementId, attachmentName, onUpload, onRemove }) => {

  return (
    <div>
      <MultipleDropzoneComponent
        elementId={elementId}
        attachmentName={attachmentName}
        onUpload={onUpload}
        onRemove={onRemove}
        fixedHeight={280}
        percentageHeight={70}
      />
    </div>
  );
}

export default DropzoneComponentMeetingsMembersView;
