import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from '../special-document/ViewModeProps';
import ViewModeBase from "../special-document/ViewModeBase";
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { formatCurrency } from '../../utils/utilities';
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';
import { RegistersDifferenceReconcilingItems, DifferenceReconcilingItemsRepository } from '../../repository/substantive-tests/DifferenceReconcilingItemsRepository';
import { DocumentSampleRepository } from '../../repository/substantive-tests/DocumentSampleRepository';
import { PaeMovementFixedAssetsRepository, RegistersPaeMovemement as RegistersPaeMovement } from '../../repository/substantive-tests/PaeMovementFixedAssetsRepository';
import { PaeMovementDifferenceReconcilingItems, PaeMovementReconcilingItemsRepository } from '../../repository/substantive-tests/PaeMovementReconcilingItemsRepository';


export interface AccountData {
  id: number;
  consignment_number: number;
  type_account: string;
  account: string;
  name: string;
  amount: number;
  amount_in_other_accounts: number;
  amount_per_statement: number;
  difference_amount: number;
}

export interface RegisterType {
  id: string;
  reference_id: number;
  name_reconciling_items: string;
  extracted_value: string;
}

type MovementType = "additions" | "withdrawals" | "reclassifications" | "depreciation" | "impairment" | "valuation";

export type AddNewRegisters = Record<
  string,
  Record<string, Array<PaeMovementDifferenceReconcilingItems>>
>;


const PaeMovementReconcilingItemsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isRefresh, setIsRefresh] = useState(false);
  const [isRefreshLocal, setIsRefreshLocal] = useState(false);
  const [registers, setRegisters] = useState<PaeMovementDifferenceReconcilingItems[]>([]);
  const [registersSon, setRegistersSon] = useState<PaeMovementDifferenceReconcilingItems[]>([]);
  const [registersFather, setRegistersFather] = useState<RegistersPaeMovement[]>([]);
  const [newRegisters, setNewRegisters] = useState<AddNewRegisters>({});
  const [addNewRegisters, setAddNewRegisters] = useState<AddNewRegisters>({});
  const [registersToAddTotalState, setRegistersToAddTotalState] = useState<PaeMovementDifferenceReconcilingItems[]>([]);
  const [filteredData, setFilteredData] = useState<RegistersPaeMovement[]>([]);
  const [totalColumnExtractedValue, setTotalColumnExtractedValue] = useState(0);

  useEffect(() => {
    const handleEvent = () => {
      setIsRefresh(prev => !prev);
      setKey(prevKey => prevKey + 1);
    };
    emitter.on("refreshSubstantiveBalanceData", handleEvent);
    return () => {
      emitter.off("refreshSubstantiveBalanceData", handleEvent);
    };
  }, []);

  /**
  * Fetches registers difference reconciling items and updates related state variables.
  * Uses element.args.engagement_id, element.args.document_id and element.reference as parameters.
  * @returns {Promise<void>}
  */
  const getRegistersDifferenceReconcilingItems = async () => {
    const paeMovementReconcilingItemsRepository = new PaeMovementReconcilingItemsRepository()
    const result = await paeMovementReconcilingItemsRepository.getRegistersDifferenceReconcilingItems(element.args.engagement_id, element.args.document_id, element.reference)

    if (result.success) {
      setRegistersSon(result.data);
      setTotalColumnExtractedValue(
        result.data.reduce(
          (acc: number, item) =>
            acc + (item.extracted_value ? item.extracted_value : 0),
          0
        )
      )
      setRegistersToAddTotalState(result.data);
    }

  }

  /**
 * Validates and retrieves records related to fixed assets, updating the state accordingly.
 * Uses element.args.engagement_id, element.args.document_id, and element.reference as parameters.
 */
  const validateRecordsCreation = async () => {
    const paeMovementFixedAssetsRepository = new PaeMovementFixedAssetsRepository()
    const result = await paeMovementFixedAssetsRepository.getPaeMovementFixedAssets(element.args.engagement_id, element.args.document_id, element.reference)
    
    if (result.success) {
      setRegistersFather(result.data);
      
      getRegistersDifferenceReconcilingItems()
      const filteredResultData = result.data.filter(item =>
        item.additions !== null && item.additions !== 0 ||
        item.withdrawals !== null && item.withdrawals !== 0 ||
        item.reclassifications !== null && item.reclassifications !== 0 ||
        item.depreciation !== null && item.depreciation !== 0 ||
        item.impairment !== null && item.impairment !== 0 ||
        item.valuation !== null && item.valuation !== 0
      );

      setFilteredData(filteredResultData);

    } else {
      setRegistersFather([]);
      setRegistersSon([]);
      setRegistersToAddTotalState([]);
    }
  }

  useEffect(() => {
    validateRecordsCreation()
  }, [isRefresh, isRefreshLocal]);

  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

    /**
 * Handles the acceptance action in the modal by updating registers and resetting related state variables.
 * After a successful update, it triggers refresh events via emitter and closes the modal.
 * @returns {Promise<void>}
 */
  const handleEditModalAccept = async () => {

    const paeMovementReconcilingItemsRepository = new PaeMovementReconcilingItemsRepository()
    const result = await paeMovementReconcilingItemsRepository.updateFixedAssetsDifferenceReconcilingItems(element.args.engagement_id, element.args.document_id, element.reference, addNewRegisters, registers)
    setIsRefreshLocal(prev => !prev);
    setNewRegisters({});
    setAddNewRegisters({});
    setRegisters([]);

    if (result.success) {
      setIsRefreshLocal(prev => !prev);
      emitter.emit("refreshDifferenceReconcilingItems");
      setShowConfigurationsModal(false);
    }
  };

  const [key, setKey] = useState(0);

  const handleEditModalCancel = () => {
    setIsRefreshLocal(prev => !prev);
    setNewRegisters({});
    setAddNewRegisters({});
    setRegisters([]);
    setShowConfigurationsModal(false)
    setKey(prevKey => prevKey + 1);
  };

  const handleNumericInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let cleanedValue = event.target.value.replace(/[^0-9.-]/g, '');
    const parts = cleanedValue.split(".");
    if (parts.length > 1 && parts[1].length > 2) {
      parts[1] = parts[1].substring(0, 2);
      cleanedValue = parts.join(".");
    }

    event.target.value = cleanedValue;
  };

  const addNewEntry = <T extends Record<number, Record<string, PaeMovementDifferenceReconcilingItems[]>>>(
    prev: T, 
    testId: number, 
    movementType: keyof RegistersPaeMovement
  ): T => {
    const updated = { ...prev };
  
    if (!updated[testId]) {
      updated[testId] = {} as Record<string, PaeMovementDifferenceReconcilingItems[]>;
    }
  
    if (!updated[testId][movementType]) {
      updated[testId][movementType] = [];
    }
  
    const newId = Date.now();
    const newRegister: PaeMovementDifferenceReconcilingItems = {
      id: newId,
      type_movement: movementType,
      pae_movement_fixed_asset_id: testId,
      name_reconciling_items: '',
      extracted_value: 0,
    };
  
    updated[testId][movementType] = [...updated[testId][movementType], newRegister];
  
    return updated;
  };
  
  const addRegister = (testId: number, movementType: keyof RegistersPaeMovement) => {
    setNewRegisters((prev) => addNewEntry(prev, testId, movementType));
    setAddNewRegisters((prev) => addNewEntry(prev, testId, movementType));
  };

  const markError = async (testId: number) => {
    const differenceReconcilingItemsRepository = new DifferenceReconcilingItemsRepository()
    const result = await differenceReconcilingItemsRepository.markErrorDifferenceReconcilingItems(element.args.engagement_id, element.args.document_id, testId)
    setIsRefreshLocal(prev => !prev);
    emitter.emit("refreshDifferenceReconcilingItems");
  }


  const handleNewInputChange = (itemId: string | number | symbol, movementType: keyof RegistersPaeMovement, field: keyof RegistersDifferenceReconcilingItems, value: number | string | undefined | null, referenceId: number) => {
    setNewRegisters((prev) => {
      const updated = { ...prev };

      if (!updated[referenceId]) {
        updated[referenceId] = {};
      }

      if (!updated[referenceId][movementType]) {
        updated[referenceId][movementType] = [];
      }

      updated[referenceId][movementType] = updated[referenceId][movementType].map((item) =>
        item.id === itemId
          ? {
            ...item,
            [field]: value,
          }
          : item
      );
      return updated;
    });

    setAddNewRegisters((prev) => {
      const updated = { ...prev };

      if (!updated[referenceId]) {
        updated[referenceId] = {};
      }

      if (!updated[referenceId][movementType]) {
        updated[referenceId][movementType] = [];
      }

      updated[referenceId][movementType] = updated[referenceId][movementType].map((item) =>
        item.id === itemId
          ? {
            ...item,
            [field]: value,
          }
          : item
      );
      return updated;
    });
  };

  const handleInputChange = (itemId: string | number | symbol | any, field: keyof RegistersDifferenceReconcilingItems, value: number | string | undefined | null, referenceId: number) => {
    setRegisters(prev =>
      prev.map(item => item.id === itemId ? { ...item, reference_id: referenceId, [field]: value } : item)
    );

    setRegistersToAddTotalState(prev => {
      const updatedRegisters = prev.map(item => {
        if (item.id === itemId) {
          return { ...item, [field]: value }
        }
        return item
      });
      return updatedRegisters
    }
    )
  };

  const handleRemoveNewRegister = (itemId: string | number, movementType: string) => {
    setNewRegisters((prev) => {
      const updatedRegisters = { ...prev };

      if (updatedRegisters[itemId]?.[movementType]) {
        updatedRegisters[itemId][movementType] = updatedRegisters[itemId][movementType]?.filter(
          (item) => item.id !== itemId
        );

        if (updatedRegisters[itemId][movementType]?.length === 0) {
          delete updatedRegisters[itemId][movementType];
        }

        if (Object.keys(updatedRegisters[itemId]).length === 0) {
          delete updatedRegisters[itemId];
        }
      }

      return updatedRegisters;
    });

    setAddNewRegisters((prev) => {
      const updated = { ...prev };

      if (updated[itemId]?.[movementType]) {
        updated[itemId][movementType] = updated[itemId][movementType]?.filter(
          (item) => item.id !== itemId
        );

        if (updated[itemId][movementType]?.length === 0) {
          delete updated[itemId][movementType];
        }

        if (Object.keys(updated[itemId]).length === 0) {
          delete updated[itemId];
        }
      }

      return updated;
    });
  };

  const handleRemoveOldRegister = async (itemId: number) => {
    const differenceReconcilingItemsRepository = new DifferenceReconcilingItemsRepository()
    const result = await differenceReconcilingItemsRepository.deleteDifferenceReconcilingItems(element.args.engagement_id, element.args.document_id, element.reference, itemId)
    if (result.success) {
      setIsRefreshLocal(prev => !prev);
      emitter.emit("refreshDifferenceReconcilingItems");
    }
  }

  const calculateCurrentTotal = (testId: number, typeMovement: string) => {
    const filteredItems = registersToAddTotalState.filter(item => item.pae_movement_fixed_asset_id === testId && item.type_movement === typeMovement);
    
    const total = filteredItems.reduce((sum, item) => {
      const value = Number(item.extracted_value) || 0;
      const roundedValue = Number(value.toFixed(2));
      return parseFloat((sum + roundedValue).toFixed(2));
    }, 0);

    const sumFromNewRegisters = Object.values(addNewRegisters[testId] || {})
      .flat()
      .filter(item => item.pae_movement_fixed_asset_id === testId && item.type_movement === typeMovement)
      .reduce((acc, item) => {
        const itemValue = Number(item.extracted_value || 0);
        return parseFloat((acc + itemValue).toFixed(2));
      }, 0);
      
    const result = Math.round((total + sumFromNewRegisters) * 100) / 100;
    return result;
  }

  function handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
    const value = parseFloat(e.target.value.replace(/[^0-9.-]/g, ''));
    e.target.value = formatCurrency(value);
  }

  function handleFocus(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.value = e.target.value.replace(/[^0-9.-]/g, '');
  }

  return (
    <div key={key}>
      {registersFather.length > 0 && (
        <ViewModeBase
          isEditable={true}
          handleEdit={handleEdit}
          permissions={element.permissions as Permissions}
        >
          <div key={key} className="d-flex w-full flex-column mb-3">
            {filteredData.map((test) => (
              <div key={test.id} className="mb-4 w-100">
                <h3>
                  {T("Account")}: {test.code}
                </h3>
                {(
                  ["additions", "withdrawals", "reclassifications", "depreciation", "impairment", "valuation"] as (keyof RegistersPaeMovement)[]
                ).map(
                  (movementType) => {
                    const childItems = registersSon.filter((item) => {
                      const isMatchingParent = Number(item.pae_movement_fixed_asset_id) === Number(test.id);
                      const isMatchingType = item.type_movement === movementType;

                      return isMatchingParent && isMatchingType;
                    });


                    if (!test[movementType] && childItems.length === 0) {
                      return null;
                    }

                    return (
                      <div key={`${test.id}-${movementType}`} className="mb-2">
                        <h4>
                          {T(movementType.charAt(0).toUpperCase() + movementType.slice(1))} {T("Difference")}: {formatCurrency(test[movementType] || 0)}
                        </h4>
                        <table className="table-bordered w-100">
                          <thead>
                            <tr>
                              <th className="w-50 p-2">{T("Fixed Assets")}</th>
                              <th className="w-50 p-2">{T("Extracted Value")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {childItems.length > 0 && (
                              <>
                                {childItems.map((child) => (
                                  <tr>
                                    <td className="p-1">{child.name_reconciling_items}</td>
                                    <td className="p-1">{formatCurrency(child.extracted_value)}</td>
                                  </tr>
                                ))}
                              </>
                            )}
                            <tr key={test.id}>
                              <td className="p-1">Total</td>
                              <td className="p-1">
                                <span>
                                  {formatCurrency(calculateCurrentTotal(test.id, movementType))}
                                </span>
                                </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  }
                )}
              </div>
            ))}
          </div>
        </ViewModeBase>
      )}
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Fixed Assets")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <div>
          {filteredData.map((test) => (
            <div style={{ width: "100%" }} key={test.id}>
              <h4>
                {T("Account")}: {test.code} - {T("Difference")}: {formatCurrency(test.justification_differences)}
              </h4>
              <div className="mb-3">
                <div className="d-flex flex-column align-items-center w-100">
                  {(
                    ["additions", "withdrawals", "reclassifications", "depreciation", "impairment", "valuation"] as (keyof RegistersPaeMovement)[]
                  ).map(
                    (movementType) => {
                      const childItems = (registersSon || []).filter(
                        (item) => {
                          return item.pae_movement_fixed_asset_id === Number(test.id) &&
                            item.type_movement === movementType;
                        }
                      );
                      
                      if (!test[movementType] && childItems.length === 0) {
                        return null;
                      }

                      return (
                        <div key={`${test.id}-${movementType}`} className="mb-4 w-80">
                          <h5>
                            {T(movementType.charAt(0).toUpperCase() + movementType.slice(1))} {T("Difference")}: {formatCurrency(test[movementType] || 0)}
                          </h5>
                          <table className="table-bordered w-100">
                            <thead>
                              <tr>
                                <th className="w-45 p-2">{T("Fixed Assets")}</th>
                                <th className="w-45 p-2">{T("Extracted Value")}</th>
                                <th className="w-10 p-2">{T("Actions")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {childItems.map((item) => (
                                <tr key={`${item.id}-${movementType}`}>
                                  <td className="p-1">
                                  {movementType != "additions" ? (   
                                    <input
                                      className="form-control"
                                      type="text"
                                      defaultValue={item.name_reconciling_items}
                                      onChange={(e) =>
                                        handleInputChange(item.id, "name_reconciling_items", e.target.value, test.id)
                                      }
                                    />
                                  ) : (
                                    <p>{item.name_reconciling_items}</p>
                                  )}
                                  </td>
                                  <td className="p-1">
                                  {movementType != "additions" ? (   
                                    <input
                                      className="form-control"
                                      type="text"
                                      defaultValue={formatCurrency(item.extracted_value)}
                                      onInput={handleNumericInput}
                                      onChange={(e) =>
                                        handleInputChange(item.id, "extracted_value", e.target.value, test.id)
                                      }
                                      onFocus={handleFocus}
                                      onBlur={handleBlur}
                                    />
                                  ) : (
                                    <p>{formatCurrency(item.extracted_value)}</p>
                                  )}
                                  </td>
                                  <td className="p-1">
                                    <button
                                      className="btn btn-icon btn-primary element-card-buttons"
                                      onClick={() => handleRemoveOldRegister(item.id)}
                                    >
                                      <span className="btn-inner--icon">
                                        <i className="fa fa-trash py-2" aria-hidden="true"></i>
                                      </span>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                              {newRegisters[test.id]?.[movementType]?.length > 0 && (
                                newRegisters[test.id][movementType].map((item) => (
                                  <tr key={`${item.id}-${movementType}-new`}>
                                    <td className="p-1 bg-light">
                                      <input
                                        className="form-control"
                                        type="text"
                                        defaultValue={item.name_reconciling_items}
                                        onChange={(e) =>
                                          handleNewInputChange(item.id, movementType, "name_reconciling_items", e.target.value, test.id)
                                        }
                                      />
                                    </td>
                                    <td className="p-1 bg-light">
                                      <input
                                        className="form-control"
                                        type="text"
                                        defaultValue={formatCurrency(item.extracted_value)}
                                        onInput={handleNumericInput}
                                        onChange={(e) =>
                                          handleNewInputChange(item.id, movementType, "extracted_value", e.target.value, test.id)
                                        }
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                      />
                                    </td>
                                    <td className="p-1">
                                      <button
                                        className="btn btn-icon btn-primary element-card-buttons"
                                        onClick={() => handleRemoveNewRegister(item.id, movementType)}
                                      >
                                        <span className="btn-inner--icon">
                                          <i className="fa fa-trash py-2" aria-hidden="true"></i>
                                        </span>
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              )}
                              <tr key={`${test.id}-${movementType}-total`}>
                                <td className="p-1">
                                  <b>{T("Total")}</b>
                                </td>
                                <td className="p-1">
                                  <span>{formatCurrency(calculateCurrentTotal(test.id, movementType))}</span>
                                </td>
                                <td className="p-1"></td>
                              </tr>
                            </tbody>
                          </table>
                              <div className="d-flex justify-content-end w-80 gap-2 mt-2">
                                <button className="btn btn-secondary" onClick={() => addRegister(test.id, movementType)}>
                                  {T("Add New")}
                                </button>
                              </div>
                        </div>
                      );
                    }
                  )}

                </div>
              </div>
            </div>
          ))}



          <div>
            <hr />
          </div>
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default PaeMovementReconcilingItemsViewMode;
