import { RedirectionButtonArgs } from './ElementArgs'
import { ElementBase, IElementBase } from './ElementBase'

export class RedirectionButtonElement extends ElementBase implements IElementBase {
  private _title: string
  private _url: string
  private _context: string
  private _transaction_flow_category: number

  constructor(args: RedirectionButtonArgs) {
    super(args)
    this._title = args.title ? args.title : ""
    this._url = args.url ? args.url : ""
    this._context = args.context ? args.context : ""
    this._transaction_flow_category = args.transaction_flow_category ?? 0
  }

  get title(): string {
    return this._title
  }

  get url(): string {
    return this._url
  }

  get context(): string {
    return this._context
  }

  get args(): RedirectionButtonArgs {
    return {
      ...super.args,
      title: this._title,
      url: this._url,
      context: this._context,
      transaction_flow_category: this._transaction_flow_category
    }
  }

  set title(value: string) {
    this._title = value
  }

  set url(value: string) {
    this._url = value
  }

  set context(value: string) {
    this._context = value
  }

  set transaction_flow_category(value: number) {
    this._transaction_flow_category = value
  }

  render(): void {}
}