import { useState } from 'react'
import { T } from '../../utils/i18n-config'
import { Permissions } from '../../models/special-document/ElementBase'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeProps from '../special-document/EditModeProps'
import EditModeBase from '../special-document/EditModeBase'

/**
 * A functional component to render the edit mode for journal entries answers.
 * It provides configuration handlers and passes necessary props to the EditModeBase component.
 */
const JournalEntriesAnswersEditMode = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  
  /**
   * Opens the configuration modal.
   */
  function handleConfiguration() {
    setShowConfigurationsModal(true)
  }

  /**
   * Closes the configuration modal.
   */
  function handleAccept() {
    setShowConfigurationsModal(false)
  }

  return (
    <>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}
      >
        <div className="card card-body p-3 bg-light">
          <p className="m-0">{T("Table of Responses for Selected Attributes")}</p>
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleAccept}
        onCancel={handleAccept}
      >
        <p>{T("There are no configurations for this element")}</p>
      </ModalWithButtons>
    </>
  );
}

export default JournalEntriesAnswersEditMode;