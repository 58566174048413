import React, { useState, useId } from 'react';
import { T } from "../../utils/i18n-config"
import DOMPurify from 'isomorphic-dompurify';
import { decryptUniqueFilename } from '../../utils/utilities';
import Summernote from '../utils/Summernote';
import Dropzone from '@/components/commons/dropzone/dropzone-component';
import { InternalControlComponentsData } from './InternalControlComponentsQuestionsEditMode';


interface InternalControlComponentsQuestionsOptionProps {
  index: number;
  elementId: number;
  question: string;
  dataAnswers: InternalControlComponentsData[];
  selectAnswerSelectedId: number | undefined;
  isParentQuestion: boolean;
  notHasDropdown: boolean;
  hasYesNoInverse: boolean;
  justification: string | undefined;
  requiredFile: boolean | undefined;
  attachmentName: string;
  showModal: boolean;
  handleAnswerSelected: (index: number, value: number, is_required_file?: boolean, is_required_finding?: boolean) => void;
  handleEditJustification: (index: number, justification: string) => void;
  handleEditAttachmentName: (index: number, attachment_name: string) => void;
}


const InternalControlComponentsQuestionsOptionView = ({
  index, elementId, question, dataAnswers, selectAnswerSelectedId, justification, isParentQuestion, notHasDropdown, hasYesNoInverse, requiredFile, attachmentName, showModal,
  handleAnswerSelected, handleEditJustification, handleEditAttachmentName
}: InternalControlComponentsQuestionsOptionProps) => {

  const [attachment, setAttachment] = useState(attachmentName);

  const handleEditorJustification = (content: string) => {
    handleEditJustification(index, content);
  };

  console.log("requiredFile", requiredFile);


  function handleSetAttachment(attachmentName: string, originalFilename: string) {
    setAttachment(originalFilename);
    handleEditAttachmentName(index, attachmentName);
  }

  const setHandleAnswerSelected = (index: number, value: number) => {
    const answer = dataAnswers.find((answer) => answer.id === value);
    console.log("answer", answer);
    handleAnswerSelected(index, value, answer?.is_required_file, answer?.is_question_finding);
  }

  return (
    <div>
      <div className="form-group">
        <h4 className="mb-0">{T("Question")}</h4>
        <div className="mt-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question ?? "") }} />
      </div>

      <div className="form-group">
        <label>{T("Select Answer")}</label>
        <select className="form-select" value={selectAnswerSelectedId} onChange={(e) => setHandleAnswerSelected(index, Number(e.target.value))}>
          <option value="">{T("Select an option")}</option>
          {dataAnswers.map((type: InternalControlComponentsData) => {
            return (
              <option key={type.id} value={type.id}>{type.name}</option>
            )
          })}
        </select>
      </div>
      <label className="mb-0">{T("Justification")}</label>
      <Summernote value={justification ?? ""} onChange={handleEditorJustification} showModal={showModal} />
      {
        attachment && (
          <div className="d-flex align-items-center gap-2 py-1 px-2 border border-2 border-secondary rounded" style={{ width: 'fit-content' }}>
            <span className="text-sm fw-bold text-truncate text-uppercase" style={{ maxWidth: '150px' }}>
              {decryptUniqueFilename(attachment)}
            </span>
            <button
              type="button"
              style={{ cursor: 'pointer' }}
              className="p-1 bg-transparent border-0 text-secondary"
              onClick={() => handleSetAttachment('', '')}
            >
              <i className="fa-regular fa-trash-can"></i>
              <span className="visually-hidden">{T("Remove file")}</span>
            </button>
          </div>
        )
      }
      {
        !attachment && (
          <>
            <Dropzone elementId={elementId} onUpload={handleSetAttachment} />
            <div className="text-center">
              {(isParentQuestion && requiredFile) && (
                <p className="text-danger">{T("You must upload a file on this question.")}</p>
              )}
            </div>
          </>
        )
      }
      <hr className="horizontal dark my-3"></hr>
    </div>
  );
};

export default InternalControlComponentsQuestionsOptionView
