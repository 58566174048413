import React from 'react';
import ReactDOM from 'react-dom';
import SubsequentEvents from '../components/subsequent-events/SubsequentEvents';


interface SubsequentEventsArgs {
  containerId: string;
  engagementId: number;
  readPermission: boolean;
  updatePermission: boolean;
}


/**
 * Renders the SubsequentEvents component into the specified DOM container and sets the breadcrumb container via window.menuService.
 * @param args - Object containing the containerId, engagementId, readPermission, and updatePermission.
 * @returns void
 */
export function subsequentEventsRenderer(args: SubsequentEventsArgs): void {
  ReactDOM.render(
  <SubsequentEvents 
    engagementId={args.engagementId}
    readPermission={args.readPermission}
    updatePermission={args.updatePermission}
  />, 
  document.getElementById(args.containerId)
  );
  window.menuService?.breadcrumbSetReactContainerId(args.containerId);
}
