import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from './ViewModeProps';
import ViewModeBase from "./ViewModeBase";
import { UseServiceOrganizationsElement } from '../../models/special-document/UseServiceOrganizationsElement';
import ModalWithButtons from '../modals/AceptCancelModalBase'
import Select, { type Option } from "../commons/Select"
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';
import { UseServiceOrganizationsArgs, UseServiceOrganizationsRepository } from '../../repository/special-document/UseServiceOrganizationsRepository';
import { ElementRepository } from '../../repository/special-document/ElementRepository';


export interface RegisterType {
  id: string;
  reference_id: number;
  name_reconciling_items: string;
  extracted_value: string;
}


/**
 * Component for the view mode of the TransactionFlowsUseServiceOrganizations element
 * @param props - The props for the component
 */
const TransactionFlowsUseServiceOrganizationsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<UseServiceOrganizationsElement>(iElement as UseServiceOrganizationsElement)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isRefreshLocal, setIsRefreshLocal] = useState(false);
  const [registers, setRegisters] = useState<UseServiceOrganizationsArgs[]>([]);
  const [baseRegisters, setBaseRegisters] = useState<UseServiceOrganizationsArgs[]>([]);
  const [elementAnswerYesNo, setElementAnswerYesNo] = useState<number | string | undefined>(element.answer_yes_no);
  const [key, setKey] = useState(0);

  const answersYesNo = [
    {
      value: 1, label: T("Yes")
    },
    {
      value: 2, label: T("No")
    }
  ]

  /**
   * Function to get the registers of the complexity technology environment
   */
  const getRegistersComplexityTechnologyEnvironment = async () => {
    const useServiceOrganizationsRepository = new UseServiceOrganizationsRepository()
    const result = await useServiceOrganizationsRepository.getTransactionFlowsMemorandumUseServiceOrganizations(element.args.engagement_id, element.id)
    if (result.success) {
      setRegisters(result.data);
      setBaseRegisters(result.data);
    } else {
      setRegisters([]);
      setBaseRegisters([]);
    }
  }

  useEffect(() => {
    getRegistersComplexityTechnologyEnvironment()
  }, [isRefreshLocal]);

  /**
   * Function to handle the edit button click
   */
  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  /**
   * Function to handle the accept button click in the modal
   */
  const handleEditModalAccept = async () => {
    const useServiceOrganizationsRepository = new UseServiceOrganizationsRepository()
    const result = await useServiceOrganizationsRepository.updateTransactionFlowsMemorandumUseServiceOrganizations(element.args.engagement_id, element.documentId, element.id, registers)
    setRegisters([]);
    if (result.success) {
      setIsRefreshLocal(prev => !prev);
      setShowConfigurationsModal(false);
    }

    const elementRepository = new ElementRepository()
    const lastAnswerYesNo = element.answer_yes_no
    element.answer_yes_no = Number(elementAnswerYesNo)

    let success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      element.answer_yes_no = Number(lastAnswerYesNo)
      window.htmlHelpers?.swalError()
    } else {
      emitter.emit("useServiceOrganization");
    }
  };

  /**
   * Function to handle the cancel button click in the modal
   */
  const handleEditModalCancel = () => {
    setIsRefreshLocal(prev => !prev);
    setRegisters([]);
    setShowConfigurationsModal(false)
    setKey(prevKey => prevKey + 1);
  };

  /**
   * Function to handle the change of the answer yes/no
   * @param value - The value of the answer
   */
  const handleAnswerYesNo = (value: number) => {
    setElementAnswerYesNo(value);
  }

  /**
   * Function to handle the change of the answer yes/no
   * @param itemId - The id of the item
   * @param value - The value of the answer
   */
  const handleAnswerSelected = (itemId: number, answerValue: number) => {
    setRegisters((prev) =>
      prev.map((item) =>
        item.id === itemId
          ? { ...item, answer_yes_no: answerValue, was_changed: true }
          : item
      )
    );
  }

  /**
   * Function to handle the change of the justification
   * @param itemId - The id of the item
   * @param justification - The justification
   */
  const handleJustificationChange = (itemId: number, justification: string) => {
    setRegisters((prev) =>
      prev.map((item) =>
        item.id === itemId ? { ...item, service_organization_name: justification, was_changed: true } : item
      )
    );
  };

  const options = [
    { id: "1", label: T("Asset management") },
    { id: "2", label: T("Transaction processing as agent for the user entity") }
  ];

  /**
   * Function to handle the change of the type of service
   * @param itemId - The id of the item
   * @param selected - The selected options
   */
  const handleChange = (itemId: number, selected: typeof options) => {
    const values = selected.map((opt: Option) => opt.id);

    setRegisters((prev) =>
      prev.map((item) =>
        item.id === itemId
          ? { ...item, type_services_select: values.join(" - "), was_changed: true }
          : item
      )
    );
  };


  return (
    <div key={key}>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        <div key={key} className="d-flex w-full flex-column mb-3">
          <div className="mb-3 w-100">
            <h3>{T("Procedure")}:</h3>
            <h3>{T("Existence of service organizations")}</h3>
            <h3>{T("Knowledge of the client")}</h3>
            <p>{T("According to the knowledge of the entity and the management of its operations, are there service organizations within the entity responsible for recording and adjusting transactions?")}</p>
            {element.answer_yes_no === undefined ? (
              <p>{T("No response")}</p>
            ) : (
              <p>{element.answer_yes_no === 1 ? T("Yes") : T("No")}</p>
            )}
            {baseRegisters[0]?.answer_yes_no != null ? (
              <>
                {element.answer_yes_no === 1 ? (
                  <>
                    <h3 className="mt-3">{T("Use of service organizations")}</h3>
                    <p>{T("Having identified knowledge about the existence of service organizations in the entity, associate these organizations with the relevant major transaction flows to assess their impact on the entity's operational and financial processes.")}</p>
                    <div className="d-flex justify-content-center w-100">
                      <table className="table-bordered w-100">
                        <thead>
                          <tr>
                            <th className="w-25 p-2">{T("Transaction Flow")}</th>
                            <th className="w-25 p-2">{T("Audit strategy")}</th>
                            <th className="w-25 p-2">{T("Application")}</th>
                            <th className="w-25 p-2">{T("Conclusion")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {baseRegisters.map((item) => (
                            <tr key={item.id}>
                              {item.is_accounting === false ? (
                                <td className="p-1">{item.title_name}</td>
                              ) : (
                                <td className="p-1">{T(item.title_name || "")}</td>
                              )}
                              <td className="p-1">
                                {item.is_accounting === false ? (
                                  <div>
                                    {item.answer_yes_no === 1
                                      ? T("Yes")
                                      : item.answer_yes_no === 2
                                        ? T("No")
                                        : null}

                                    {item.service_organization_name && (
                                      <> - <span>{item.service_organization_name}</span></>
                                    )}
                                  </div>
                                ) : (
                                  item.answer_yes_no === 1
                                    ? T("Yes")
                                    : item.answer_yes_no === 2
                                      ? T("No")
                                      : null
                                )}
                              </td>
                              <td className="p-1">
                                {item.is_accounting === false && (
                                  <>
                                    {item.materiality === true
                                      ? T("Exceeds materiality")
                                      : item.materiality === false
                                        ? T("Does not exceed materiality")
                                        : null}
                                  </>
                                )}
                              </td>
                              <td className="p-1">
                                {item.is_accounting === false ? (
                                  <>
                                    {item.required_type_service === 1 ? (
                                      <div>
                                        {item.type_services_select ? (
                                          <p><strong>{T("Selected options:")}</strong>
                                            {item.type_services_select
                                              .split(" - ")
                                              .map((id) => options.find((opt) => opt.id === id)?.label || id)
                                              .join(", ")}</p>
                                        ) : (
                                          <p>{T("No options selected")}</p>
                                        )}
                                      </div>
                                    ) : item.required_type_service === 2 ? (
                                      <p>
                                        {T(
                                          "Transaction processing as agent for the user entity."
                                        )}
                                      </p>
                                    ) : null}
                                  </>
                                ) : (
                                  <p>
                                    {T(
                                      "Keeping the accounting records of the user entity."
                                    )}
                                  </p>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : elementAnswerYesNo === 2 ? (
                  <div className="d-flex justify-content-center w-100">
                    <p>
                      {T(
                        "The entity does not have service organizations; therefore, all transaction recordings and adjustments are handled by internal staff."
                      )}
                    </p>
                  </div>
                ) : null}
              </>
            ) : (
              <div className="card h-100 bg-light">
                <div className="card-body p-3">
                  <h6>{T("This item will not be displayed until the materiality is entered, in the materiality section.")}</h6>
                </div>
              </div>
            )}
          </div>
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Use of service organizations")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <div>
          <div style={{ width: "100%" }}>
            <div className="mb-3">
              <div className="mb-3">
                <h3>{T("Knowledge of the client")}</h3>
                <p>{T("According to the knowledge of the entity and the management of its operations, are there service organizations within the entity responsible for recording and adjusting transactions?")}</p>
                <select
                  className="form-select"
                  value={elementAnswerYesNo ?? ""}
                  onChange={(e) => handleAnswerYesNo(Number(e.target.value))}
                >
                  <option value="" disabled>{T("Select an option")}</option>
                  {answersYesNo.map((answer) => (
                    <option key={answer.value} value={answer.value}>{T(answer.label)}</option>
                  ))}
                </select>
              </div>
              {registers[0]?.answer_yes_no != null ? (
                <>
                  {elementAnswerYesNo === 1 ? (

                    <div className="d-flex flex-column align-items-center w-100">
                      <table className="table-bordered w-80">
                        <thead>
                          <tr>
                            <th className="w-25 p-2">{T("Transaction Flow")}</th>
                            <th className="w-25 p-2">{T("Test")}</th>
                            <th className="w-25 p-2">{T("Materiality")}</th>
                            <th className="w-25 p-2">{T("Type of service")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {registers.map((item) => (
                            <tr key={`${item.id}-${key}`}>
                              {item.is_accounting === false ? (
                                <td className="p-1">{item.title_name}</td>
                              ) : (
                                <td className="p-1">{T(item.title_name || "")}</td>
                              )}                              
                              <td className="p-1">
                                <select
                                  className="form-select"
                                  value={item.answer_yes_no ?? ""}
                                  onChange={(e) => handleAnswerSelected(item.id, Number(e.target.value))}
                                >
                                  <option value="" disabled>{T("Select an option")}</option>
                                  {answersYesNo.map((answer) => (
                                    <option key={answer.value} value={answer.value}>{T(answer.label)}</option>
                                  ))}
                                </select>
                                {item.answer_yes_no === 1 && (
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={T("Enter service organization name")}
                                    value={item.service_organization_name ?? ""}
                                    onChange={(e) => handleJustificationChange(item.id, e.target.value)}
                                  />
                                )}
                              </td>
                              <td className="p-1">
                                {(item.is_accounting === false) ? (
                                  <>
                                    {item.materiality === true
                                      ? T("Exceeds materiality")
                                      : item.materiality === false
                                        ? T("Does not exceed materiality")
                                        : null}
                                  </>
                                ) : null}
                              </td>
                              <td className="p-1">
                                {item.is_accounting === true && item.answer_yes_no === 1 ? (
                                  <p>
                                    {T("Keeping the accounting records of the user entity.")}
                                  </p>
                                ) : item.is_accounting === false ? (
                                  <>
                                    {item.required_type_service === 1 ? (
                                      <MultiSelectDropdown item={item} options={options} handleChange={handleChange} />
                                    ) : item.required_type_service === 2 ? (
                                      <p>{T("Transaction processing as agent for the user entity.")}</p>
                                    ) : null}
                                  </>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : elementAnswerYesNo === 2 ? (
                    <p>
                      {T(
                        "The entity does not have service organizations; therefore, all transaction recordings and adjustments are handled by internal staff."
                      )}
                    </p>
                  ) : null}
                </>
              ) : (
                <div className="card h-100 bg-warning">
                  <div className="card-body p-3">
                    <h6>{T("This item will not be displayed until the materiality is entered, in the materiality section.")}</h6>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <hr />
          </div>
        </div>
      </ModalWithButtons>
    </div>
  );
};

type MultiSelectDropdownProps = {
  item: UseServiceOrganizationsArgs;
  options: { id: string; label: string }[];
  handleChange: (itemId: number, selected: { id: string; label: string }[]) => void;
};


/**
 * Component for the multi-select dropdown
 * @param props - The props for the component
 */
const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({ item, options, handleChange }) => {
  const [selectedOptions, setSelectedOptions] = useState<{ id: string; label: string }[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (item.type_services_select) {
      const valuesArray = item.type_services_select.split(" - ");
      const preSelected = options.filter((opt) => valuesArray.includes(opt.id));
      setSelectedOptions(preSelected);
    }
    setIsLoading(false);
  }, [item.type_services_select, options]);

  return isLoading ? (
    <p>{T("Loading...")}</p>
  ) : (
    <Select
      multiple
      options={options}
      defaultValue={selectedOptions}
      onChange={(selected) => handleChange(item.id, selected)}
      placeholder={T("Select options")}
      className="basic-multi-select"
    />
  );
};

export default TransactionFlowsUseServiceOrganizationsViewMode;