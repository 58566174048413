import React, { useEffect, useState } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from './ViewModeProps';
import ViewModeBase from "./ViewModeBase";
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';
import { RegistersMemorandumComplexityTechnologyFirstStageArgs, MemorandumComplexityTechnologyControlsRepository } from '../../repository/special-document/MemorandumComplexityTechnologyControlsRepository';


export interface RegisterType {
  id: string;
  reference_id: number;
  name_reconciling_items: string;
  extracted_value: string;
}


/**
 * View mode for the Memorandum Complexity Technology Application element.
 * @param iElement - The element to display.
 */
const MemorandumComplexityTechnologyApplicationViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isRefreshLocal, setIsRefreshLocal] = useState(false);
  const [changes, setChanges] = useState<Record<number, Partial<RegistersMemorandumComplexityTechnologyFirstStageArgs>>>({});
  const [referenceValidation, setReferenceValidation] = useState<string | undefined>(undefined);
  const [isFinished, setIsFinished] = useState<boolean>(true);
  const [groupedData, setGroupedData] = useState<Record<number, RegistersMemorandumComplexityTechnologyFirstStageArgs[]>>({});
  const [key, setKey] = useState(0);


  useEffect(() => {
    const handleEvent = () => {
      setIsRefreshLocal(prev => !prev);
      setKey(prevKey => prevKey + 1);
    };
    emitter.on("refreshComplexityTechnologyControls", handleEvent);
    return () => {
      emitter.off("refreshComplexityTechnologyControls", handleEvent);
    };
  }, []);


  /**
   * Fetches the registers for the complexity technology environment, updates grouped data and validation state.
   */
  const getRegistersComplexityTechnologyEnvironment = async (): Promise<void> => {
    const memorandumComplexityTechnologyControlsRepository = new MemorandumComplexityTechnologyControlsRepository()
    const result = await memorandumComplexityTechnologyControlsRepository.getMemorandumComplexityTechnologyEnvironmentByStage(element.args.engagement_id, element.id, 4)
    if (result.success) {
      const grouped = result.data.reduce((acc: Record<number, RegistersMemorandumComplexityTechnologyFirstStageArgs[]>, item: RegistersMemorandumComplexityTechnologyFirstStageArgs) => {
        const { transaction_flow_id } = item;

        if (!acc[transaction_flow_id]) {
          acc[transaction_flow_id] = [];
        }
        acc[transaction_flow_id].push(item);

        return acc;
      }, {});

      setGroupedData(grouped);
    }

    const resultValidation = await memorandumComplexityTechnologyControlsRepository.getValidationControlsComplexityTechnology(element.args.engagement_id, element.id)
    if (resultValidation.success) {
      setReferenceValidation(resultValidation.reference);
      setIsFinished(resultValidation.is_finished);
    } else {
      setReferenceValidation(undefined);
      setIsFinished(false);
    }
  }

  useEffect(() => {
    getRegistersComplexityTechnologyEnvironment()
  }, [isRefreshLocal]);

  /**
   * Function to open the modal to edit the registers of the complexity technology environment
   */
  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  /**
   * Handles acceptance of the edit modal by updating the controls repository,
   * refreshing the state, and closing the modal if the update succeeds.
   */
  const handleEditModalAccept = async (): Promise<void> => {
    const memorandumComplexityTechnologyControlsRepository = new MemorandumComplexityTechnologyControlsRepository()
    const result = await memorandumComplexityTechnologyControlsRepository.updateMemorandumComplexityTechnologyEnvironmentByStage(element.args.engagement_id, element.args.document_id, element.id, changes)
    setIsRefreshLocal(prev => !prev);
    setChanges({});
    if (result.success) {
      setShowConfigurationsModal(false);
    }
  };

  /**
   * Function to cancel the changes of the registers of the complexity technology environment
   */
  const handleEditModalCancel = () => {
    setIsRefreshLocal(prev => !prev);
    setShowConfigurationsModal(false)
    setKey(prevKey => prevKey + 1);
  };

  /**
   * Function to handle the changes of the registers of the complexity technology environment
   * @param itemId Id of the item
   * @param field Field to change
   * @param value Value to change
   */
  const handleInputChange = (itemId: number , field: keyof RegistersMemorandumComplexityTechnologyFirstStageArgs, value: number | string | undefined | null) => {
    setChanges(prev => ({
      ...prev,
      [itemId]: { ...prev[itemId], [field]: value }
    }));
  };

  return (

    <div key={key}>
      {(isFinished == false && referenceValidation == "second_table") && (
        <>
          <ViewModeBase
            isEditable={true}
            handleEdit={handleEdit}
            permissions={element.permissions as Permissions}
          >
            <div key={key} className="d-flex w-full flex-column mb-3">
              <div className="mb-3 w-100">
              {T("Applications")}
                {Object.entries(groupedData).map(([flowId, items]) => (
                  <div key={flowId}>
                    
                    <h4>
                    {items[0]?.title_name === "Accounting" ? T(items[0]?.title_name || "") : items[0]?.title_name}
                    </h4>
                    {items.map((item) => (
                      <div className="form-group">
                        <h6>{item.subtitle_name}</h6>
                        <label>{item.question}</label>
                        <p>
                          {item.value_description}
                        </p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </ViewModeBase>
          <ModalWithButtons
            showModal={showConfigurationsModal}
            title={T("Applications")}
            size="xl"
            onAccept={handleEditModalAccept}
            onCancel={handleEditModalCancel}>
            <div>
              <div style={{ width: "100%" }}>
                <div className="mb-3">
                  <div className="d-flex flex-column w-100">
                    {Object.entries(groupedData).map(([flowId, items]) => (
                      <div key={flowId}>
                        <h4>{items[0]?.title_name}</h4>
                        {items.map((item) => (
                          <div className="form-group">
                            <h6>{item.subtitle_name}</h6>
                            <label>{item.question}</label>
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                defaultValue={item.value_description || ""}
                                onChange={(e) => handleInputChange(item.id, "value_description", e.target.value)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}

                  </div>
                </div>
              </div>
              <div>
                <hr />
              </div>
            </div>
          </ModalWithButtons>
        </>
      )
      }
    </div >
  );
};

export default MemorandumComplexityTechnologyApplicationViewMode;
