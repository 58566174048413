import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'


export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface RegistersSummaryDepreciationFixedAssetsCost {
  id: number
  name: string
  code: string
  sub_code: string
  variation_amount: number
  annual_depreciation_expense: number
} 

export class SummaryDepreciationExpenseFixedAssetsRepository {

  /**
  * Constructs a new instance of SummaryDepreciationExpenseFixedAssetsRepository.
  * @param fetcher - A custom fetch API, bound to window.
  */
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  /**
  * Retrieves the summary depreciation expense from the API.
  *
  * @param engagement_id - The engagement identifier (nullable or undefined).
  * @param documentId - The document identifier.
  * @param reference - An optional reference string.
  * @returns A promise that resolves with the API response containing a success flag and data array.
  */
  async getSummaryDepreciationExpense(engagement_id: number|null | undefined, documentId: number, reference: string | undefined): Promise<{success: boolean, data: RegistersSummaryDepreciationFixedAssetsCost[]}> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/get_summary_depreciation_expense/' + engagement_id + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {success: boolean, data: RegistersSummaryDepreciationFixedAssetsCost[]} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}
