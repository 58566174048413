import React, { useState, useEffect, useRef, useMemo } from 'react'
import { T } from '../../utils/i18n-config'
import { Permissions } from '../../models/special-document/ElementBase'
import type { Minute } from '../../models/special-document/ElementArgs'
import { ElementRepository } from '../../repository/special-document/ElementRepository'
import { MinutesContractsRepository } from '../../repository/special-document/MinutesContractsRepository'
import { MinutesTableElement } from '../../models/special-document/MinutesTableElement'
import ViewModeProps from '../special-document/ViewModeProps'
import ViewModeBase from '../special-document/ViewModeBase'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import TableComponent from '../commons/TableComponent'
import Conclusion from '../commons/Conclusion'
import DropzoneComponent from '@/components/commons/dropzone/dropzone-component'
import TemplateDownload from '../utils/TemplateDownload'

/**
 * View mode for the minutes table
 * @param iElement The element
 */
const MinutesTableViewMode = ({ iElement }: ViewModeProps) => {
  const element = useRef<MinutesTableElement>(iElement as MinutesTableElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)

  const [limitedCompany, setLimitedCompany] = useState(element.limitedCompanies)
  const [stockCorporation, setStockCorporation] = useState(element.stockCorporations)

  const [limitedMinutes, setLimitedMinutes] = useState<Minute[]>(element.limitedMinutes)
  const [stockMinutes, setStockMinutes] = useState<Minute[]>(element.stockMinutes)

  const limitedMinutesList = useMemo(() => formatMinutes(limitedMinutes), [limitedMinutes])
  const stockMinutesList = useMemo(() => formatMinutes(stockMinutes), [stockMinutes])

  /**
   * Handle the configuration button
   */
  function handleConfiguration() {
    setShowConfigurationsModal(true)
  }

  /**
   * Handle the accept configurations
   * Save the minutes in the element
   */
  async function handleConfigurationsModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastLimitedCompanies = element.limitedCompanies
    const lastStockCorporations = element.stockCorporations
    const lastLimitedMinutes = element.limitedMinutes
    const lastStockMinutes = element.stockMinutes

    element.limitedCompanies = limitedCompany
    element.stockCorporations = stockCorporation
    element.limitedMinutes = limitedMinutes
    element.stockMinutes = stockMinutes

    let success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      window.htmlHelpers?.swalError()
      element.limitedCompanies = lastLimitedCompanies
      element.stockCorporations = lastStockCorporations
      element.limitedMinutes = lastLimitedMinutes
      element.stockMinutes = lastStockMinutes

      setLimitedCompany(lastLimitedCompanies)
      setStockCorporation(lastStockCorporations)
      setLimitedMinutes(lastLimitedMinutes)
      setStockMinutes(lastStockMinutes)
    }
  }

  /**
   * Handle the cancel configurations
   */
  function handleConfigurationsModalCancel() {
    setShowConfigurationsModal(false)
    setLimitedMinutes(element.limitedMinutes)
    setStockMinutes(element.stockMinutes)
  }

  /**
   * Format the minutes in a table format
   * @param minutes The minutes
   */
  function formatMinutes(minutes: Minute[]) {
    return minutes.map((minute) => [
      `${T("Minute")} #${minute.minuteId}`,
      minute.date,
      minute.decision,
      minute.risk,
      minute.impact,
      minute.observations
    ])
  }

  useEffect(() => {
    async function getCompanyMetadata() {
      const elementRepository = new ElementRepository()
      const socialBody = await elementRepository.getGlobalVariable(element.engagement_id, element.id, "cuerpo_social", element.reference)
      const boardDirectors = await elementRepository.getGlobalVariable(element.engagement_id, element.id, "board_of_directors", element.reference)

      if (socialBody) setLimitedCompany(socialBody.variable.join(", "))
      if (boardDirectors) setStockCorporation(boardDirectors.variable[0] !== "" ? T("Board of directors") : "")

      let success = await elementRepository.saveElement("edition", element.args)
      if (!success) window.htmlHelpers?.customSwalInfo(`${T("There was an error while getting the data")}. ${T("Please try again")}`)
    }
    if (!limitedCompany) getCompanyMetadata()
  }, [])

  return (
    <>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleConfiguration}
        permissions={iElement.permissions as Permissions}
      >
        <div className="mt-4">
          <b className="d-block mb-3 fs-6">{T("Table 1.1")}</b>
          <p>
            {T("Step 1. Obtain all the minutes of {{limitedCompany}} and {{stockCorporation}} meetings held during the audit period.",
              { limitedCompany, stockCorporation })}
          </p>
          <p className="mb-1">{T("Step 2. Read each minute in detail and look for related information regarding:")}</p>
          <ul>
            <li>{T("Key decisions such as significant transactions, structural changes, accounting policies.")}</li>
            <li>{T("Relevant resolutions related to contracts, guarantees, or litigations.")}</li>
            <li>{T("Link the minutes with the financial statements.")}</li>
          </ul>
          <p>{T("Step 3. Record the key decisions, identified risks, and relevant observations in the table:")}</p>
        </div>
        <div className="d-flex flex-column gap-4 mt-4">
          {limitedCompany && (
            <div>
              <h6>{T("Table 1.1")}{" - "}{limitedCompany}</h6>
              <TableComponent
                className="mt-2"
                labels={[
                  T("Minute Number"),
                  T("Minute Date"),
                  T("Documented Key Decision"),
                  T("Perceived Risk"),
                  T("Impact on the Financial States"),
                  T("Observations")
                ]}
                values={limitedMinutesList}
              />
            </div>
          )}
          {stockCorporation && (
            <div>
              <h6>{T("Table 1.1")}{" - "}{stockCorporation}</h6>
              <TableComponent
                className="mt-2"
                labels={[
                  T("Minute Number"),
                  T("Minute Date"),
                  T("Documented Key Decision"),
                  T("Perceived Risk"),
                  T("Impact on the Financial States"),
                  T("Observations")
                ]}
                values={stockMinutesList}
              />
            </div>
          )}
        </div>
        <Conclusion element={element} />
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}
      >
        {limitedCompany && (
          <MinutesForm
            element={element}
            groupName={"limited"}
            groupValue={limitedCompany}
            onUploaded={(minutes) => setLimitedMinutes(minutes)}
          />
        )}
        <hr className="mb-5 mx-auto w-75 border" />
        {stockCorporation && (
          <MinutesForm
            element={element}
            groupName={"stock"}
            groupValue={stockCorporation}
            onUploaded={(minutes) => setStockMinutes(minutes)}
          />
        )}
      </ModalWithButtons>
    </>
  )
}


interface MinutesFormProps {
  element: MinutesTableElement;
  groupName: string;
  groupValue: string;
  onUploaded: (minutes: Minute[]) => void;
}

/**
 * Form to upload the minutes attachment and extract the information
 * @param element The element
 * @param groupName The group name
 * @param groupValue The group value
 * @param onUploaded The callback to update the minutes
 */
function MinutesForm({ element, groupName, groupValue, onUploaded }: MinutesFormProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [minutesAttachmentName, setMinutesAttachmentName] = useState(() => {
    if (groupName === "limited") return element.limitedAttachmentName
    if (groupName === "stock") return element.stockAttachmentName
    return element.minutesTemplateName
  })
  const hasMinutes = useMemo(() => {
    if (groupName === "limited") return element.limitedMinutes.length > 0
    if (groupName === "stock") return element.stockMinutes.length > 0
    return false
  }, [element.limitedMinutes.length, element.stockMinutes.length])

  /**
   * Get the minutes information from the attachment and save it in the element
   */
  async function getMinutesInformation() {
    setIsLoading(true)
    const minutesRepository = new MinutesContractsRepository()
    const { success, data } = await minutesRepository.getMinutesInformation(element.engagement_id, element.id, minutesAttachmentName)
    if (!success) {
      setIsLoading(false)
      return window.htmlHelpers?.swalError()
    }

    const elementRepository = new ElementRepository()
    if (groupName === "limited") element.limitedMinutes = data
    if (groupName === "stock") element.stockMinutes = data

    const successSave = await elementRepository.saveElement("edition", element.args)
    if (!successSave) return window.htmlHelpers?.customSwalError(`${T("There was an error while getting the data")}. ${T("Please try again")}`)
    onUploaded(data)
    setIsLoading(false)
  }

  /**
   * Save the minutes attachment name in the element
   * @param attachmentName The attachment name
   */
  async function saveMinutesAttachment(attachmentName: string) {
    const elementRepository = new ElementRepository()
    const lastMinutesAttachmentName = groupName === "limited" ? element.limitedAttachmentName : element.stockAttachmentName
    if (lastMinutesAttachmentName === attachmentName) return

    if (groupName === "limited") element.limitedAttachmentName = attachmentName
    if (groupName === "stock") element.stockAttachmentName = attachmentName

    const success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      window.htmlHelpers?.swalError()
      if (groupName === "limited") element.limitedAttachmentName = lastMinutesAttachmentName
      if (groupName === "stock") element.stockAttachmentName = lastMinutesAttachmentName
      setMinutesAttachmentName(lastMinutesAttachmentName)
    }
  }

  /**
   * Handle the save attachment event
   * @param attachmentName The attachment name
   */
  async function handleSaveAttachment(attachmentName: string) {
    saveMinutesAttachment(attachmentName)
    setMinutesAttachmentName(attachmentName)
  }

  return (
    <div className="d-flex flex-column gap-2 mx-auto" style={{ maxWidth: "50%" }}>
      <h5>{T("Table 1.1")}{" - "}{groupValue}</h5>
      <TemplateDownload elementId={element.id} templateName={element.minutesTemplateName} />
      <DropzoneComponent
        elementId={element.id}
        renderingMode="view"
        attachmentName={minutesAttachmentName}
        onUpload={(attachmentName) => handleSaveAttachment(attachmentName as string)}
      />
      <button
        type="submit"
        disabled={isLoading || hasMinutes}
        className="btn btn-primary mx-auto px-4"
        style={{ width: "fit-content" }}
        onClick={getMinutesInformation}
      >
        {!isLoading ? T("Extract Information") : (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">{T("Loading")}</span>
          </div>
        )}
      </button>
    </div>
  )
}

export default MinutesTableViewMode
