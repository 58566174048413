import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'

type DeliveryLocation = {
  delivery_type: number
  location: string
  delivery_days: number
}

type Invoices = {
  invoice_id: number
  delivery_location: string
  delivery_type: number
  incoterm: string
  shipment_date: string
  estimated_date: string
  delivery_date: string
  risk: boolean
  amount: number
  adjustment: boolean
}

type Returns = {
  return_id: number
  note_date: string
  delivery_date: string
  adjustment: boolean
}

type InvoiceUpdateData = {
  invoiceId: number
  deliveryDate: string
  adjustment: boolean
}

interface DestinationsPayload {
  success: boolean
  destinations: DeliveryLocation[];
}

interface InvoicesSelectionPayload {
  success: boolean
  invoices: Invoices[];
}

interface ReturnsSelectionPayload {
  success: boolean
  returns: Returns[];
}

export class DisallowanceIncomesRepository {
  private _jsonContentType = { 'Content-Type': 'application/json' }
  private readonly getDestinationsApiUrl: string = getUrl("elements_api/get_destination_target")
  private readonly createDestinationApiUrl: string = getUrl("elements_api/save_destination_target")
  private readonly getInvoicesApiUrl: string = getUrl("elements_api/get_invoices_selection")
  private readonly generateInvoicesApiUrl: string = getUrl("elements_api/generate_invoices_selection")
  private readonly updateInvoiceApiUrl: string = getUrl("elements_api/update_invoice_selection")
  private readonly getReturnsApiUrl: string = getUrl("elements_api/get_returns_selection")
  private readonly generateReturnsApiUrl: string = getUrl("elements_api/generate_returns_selection")
  private readonly getConclusionsApiUrl: string = getUrl("elements_api/get_income_conclusion")

  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) { }

  /**
    * Gets destination targets for the element.
    * @param elementId The id of the element to get the attachment.
    * @param renderingMode The rendering mode of the element.
    * @returns The destination targets for the element.
   */
  async getDestinations(elementId: number, renderingMode: string, deliveryType: number): Promise<DestinationsPayload> {
    const apiUrl = `${this.getDestinationsApiUrl}/${elementId}/${renderingMode}/${deliveryType}`
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, destinations: [] }
    }
  }

  /**
    * Saves destination targets for the element.
    * @param elementId The id of the element to upload the attachment.
    * @param renderingMode The rendering mode of the element.
    * @param destinations The destinations to save.
   */
  async saveDestinations(elementId: number, renderingMode: string, destinations: DeliveryLocation[]): Promise<{ success: boolean }> {
    const apiUrl = `${this.createDestinationApiUrl}/${elementId}/${renderingMode}`
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({ destinations }),
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false }
    }
  }

  /**
   * Gets invoices selection for the element. 
   * @param elementId The id of the element to get the invoices selection.
   * @param renderingMode The rendering mode of the element.
   * @param deliveryType The delivery type of the element.
   * @returns The invoices selection for the element.
   */
  async getInvoicesSelection(elementId: number, renderingMode: string, deliveryType: number): Promise<InvoicesSelectionPayload> {
    const apiUrl = `${this.getInvoicesApiUrl}/${elementId}/${renderingMode}/${deliveryType}`
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, invoices: [] }
    }
  }

  /**
   * Generates invoices selection for the element. 
   * @param elementId The id of the element to generate the invoices selection.
   * @param renderingMode The rendering mode of the element.
   * @param deliveryType The delivery type of the element.
   */
  async generateInvoicesSelection(
    elementId: number, renderingMode: string, engagementId: number, deliveryType: number, attachmentName: string
  ): Promise<{ success: boolean }> {
    const apiUrl = `${this.generateInvoicesApiUrl}/${elementId}/${renderingMode}/${engagementId}`
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({ deliveryType, attachmentName }),
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false }
    }
  }

  /**
   * Updates invoices selection for the element. 
   * @param elementId The id of the element to update the invoices selection.
   * @param renderingMode The rendering mode of the element.
   * @param invoiceData The invoice data to update.
   */
  async updateInvoiceSelection(elementId: number, renderingMode: string, invoiceData: InvoiceUpdateData): Promise<{ success: boolean }> {
    const apiUrl = `${this.updateInvoiceApiUrl}/${elementId}/${renderingMode}`
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({ invoice: invoiceData }),
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false }
    }
  }

  /**
   * Gets returns selection for the element. 
   * @param elementId The id of the element to get the returns selection.
   * @param renderingMode The rendering mode of the element.
   * @returns The returns selection for the element.
   */
  async getReturnsSelection(elementId: number, renderingMode: string): Promise<ReturnsSelectionPayload> {
    const apiUrl = `${this.getReturnsApiUrl}/${elementId}/${renderingMode}`
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, returns: [] }
    }
  }

  /**
   * Generates returns selection for the element. 
   * @param elementId The id of the element to generate the returns selection.
   * @param renderingMode The rendering mode of the element.
   * @param engagementId The engagement id of the element.
   * @param attachmentName The attachment name of the element.
   */
  async generateReturnsSelection(
    elementId: number, renderingMode: string, engagementId: number, attachmentName: string
  ): Promise<{ success: boolean }> {
    const apiUrl = `${this.generateReturnsApiUrl}/${elementId}/${renderingMode}/${engagementId}`
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({ attachmentName }),
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false }
    }
  }

  /**
   * Gets income conclusion for the element. 
   * @param elementId The id of the element to get the income conclusion.
   * @param renderingMode The rendering mode of the element.
   * @param engagementId The engagement id of the element.
   * @returns The income conclusion for the element.
   */
  async getIncomeConclusion(elementId: number, renderingMode: string, engagementId: number): Promise<{ success: boolean, conclusionType: string }> {
    const apiUrl = `${this.getConclusionsApiUrl}/${elementId}/${renderingMode}/${engagementId}`
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, conclusionType: "" }
    }
  }
}
