
export const Constants = {

  // Other constants
  CTR_POP_UP: 'ctr-pop-up',
  NOTIFICATION_FETCH_INTERVAL: 1000 * 60,
  CONTENT_CLASS: 'document-content',
  //--------------------------------------------

  // Paragraph
  PARAGRAPH_ELEMENT_TYPE : 1,
  //--------------------------------------------

  // Findings
  FINDING_ELEMENT_TYPE : 15,
  FINDING_EVALUATION_ELEMENT_TYPE : 16,
  FINDING_REFERENCE: 'finding_template',
  //--------------------------------------------

  // Risks and Variance Analysis
  VARIANCE_ANALYSIS_NOTES_TYPE: 12,
  RISK_REFERENCE: 'risk',
  //--------------------------------------------

  // Probability Analysis
  PROBABILITY_ANALYSIS_TYPE: 18,
  //--------------------------------------------

   // Memorandum Other Risks
   MEMORANDUM_OTHER_RISKS_TYPE: 56,
   //--------------------------------------------

  // Substantive Test Account Configuration Bank Conciliations
  SUBSTANTIVE_TEST_BANK_CONCILIATIONS_ACCOUNT_CONFIGURATION_TYPE: 20,

  // Dropzone File Images
  MAX_FILES: 10,
  FILE_IMAGE_PATH: '/audix/static/images/thumbnail',
  FILE_IMAGES: {
    'application/vnd.ms-excel': 'excel-120x120.png',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel-120x120.png',
    'application/msword': 'word-120x120.png',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'word-120x120.png',
    'application/pdf': 'pdf-120x120.png',
  },
  FILE_EXTENSIONS: {
    "xls": "excel-120x120.png",
    "xlsx": "excel-120x120.png",
    "doc": "word-120x120.png",
    "docx": "word-120x120.png",
    "pdf": "pdf-120x120.png",
  },
  //--------------------------------------------

  // Redirection Button
  REDIRECTION_FORMULARIES: [
    {
      text: "Income Disallowance Memorandum",
      value: "income_disallowance_memo"
    },
    {
      text: "Going Concern Hypothesis",
      value: "going_concern_memorandum"
    },
    {
      text: "Laws and Regulations",
      value: "laws_and_regulations"
    },
    {
      text: "Subsequent Events",
      value: "subsequent_events"
    },
    {
      text: "Findings",
      value: "findings"
    },
    {
      text: "Analysis of planning variations",
      value: "most_recent_variance_analysis"
    },
    {
      text: "Analysis of intermediate variations",
      value: "intermediate_variance_analysis"
    },
    {
      text: "Analysis of closing variations",
      value: "variance_analysis"
    },
    {
      text: "Opinion",
      value: "opinion"
    },
    {
      text: "Error Sheet",
      value: "error_sheet"
    },
    {
      text: "Override of Controls - D&I",
      value: "override_of_controls_dae"
    },
    {
      text: "Override of Controls - OE",
      value: "override_of_controls_oe"
    },
    {
      text: "Knowledge of customer",
      value: "knowledge_customer"
    },
    {
      text: "Customer acceptance",
      value: "customer_acceptance"
    }
  ],
  //--------------------------------------------  

  ITEM_TYPE_OPTIONS: ["Title", "Subtitle", "Question"] as const,
  ANSWER_TYPE_OPTIONS: ["Textbox", "Attachment", "Yes/No", "Unique", "External"] as const,
  ANSWER_REFERENCE_OPTIONS: ["OFAC", "Budgeted_Hours", "Independence_Format"] as const,

  // Substantive Test Disallowance Income
  /**
   * Delivery type for local deliveries.
   */
  DELIVERY_TYPE_LOCAL: 1 as const,
  /**
   * Delivery type for international exports.
   */
  DELIVERY_TYPE_EXPORT: 2 as const,
  
  // Substantive Test Element Types
  SUBSTANTIVE_REFERENCE: 'substantive_test',
  SUBSTANTIVE_ELEMENT_TYPES: [20, 21, 22, 23, 24, 25, 26, 42, 43, 44, 67, 68, 69, 70],
  JOURNAL_DATE_RANGE_ALL_YEAR: "all_year",
  JOURNAL_DATE_RANGE_LAST_MONTH: "last_month",
}
