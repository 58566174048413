import React, { useEffect, useState } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from '../special-document/ViewModeProps';
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import ViewModeBase from "../special-document/ViewModeBase";
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { formatCurrency } from '../../utils/utilities';
import { SummaryFixedAssetsCostRepository, RegistersSummaryFixedAssetsCost } from '../../repository/special-document/SummaryFixedAssetsCostRepository';
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';


export interface AccountData {
  id: number;
  consignment_number: number;
  type_account: string;
  account: string;
  name: string;
  amount: number;
  amount_in_other_accounts: number;
  amount_per_statement: number;
  difference_amount: number;
}


const SummaryFixedAssetsCostViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isRefresh, setIsRefresh] = useState(false);
  const [isRefreshLocal, setIsRefreshLocal] = useState(false);
  const [registersOfTable, setRegistersOfTable] = useState<RegistersSummaryFixedAssetsCost[]>([]);
  const [totalColumnBalance, setTotalColumnBalance] = useState<number>(0);
  const [totalColumnbalanceOfTheCutoff, setTotalColumnBalanceOfTheCutoff] = useState<number>(0);
  const [totalVariation, setTotalVariation] = useState<number>(0);
  const [editorContents, setEditorContents] = useState<{ [key: string]: string }>({});

  const handleEditorChange = (content: string, registerId: number) => {
    setEditorContents((prev) => ({
      ...prev,
      [registerId]: content,
    }));
  };

  useEffect(() => {
    const handleEvent = () => {
      setIsRefresh(prev => !prev);
    };
    emitter.on("refreshSubstantiveBalanceData", handleEvent);
    return () => {
      emitter.off("refreshSubstantiveBalanceData", handleEvent);
    };
  }, []);

  /**
  * Fetches registers from the trial balance file and updates state values (totals and registers) based on the retrieved data.
  * This function calls the repository and processes the result asynchronously.
  */
  const getRegistersOfTrialBalanceFile = async () => {
    const summaryFixedAssetsCostRepository = new SummaryFixedAssetsCostRepository()
    const result = await summaryFixedAssetsCostRepository.getRegistersSummaryFixedCostCalculation(element.args.engagement_id, element.args.document_id, element.reference)
    
    if (result.success) {
      setTotalColumnBalance(result.data.reduce((acc, item) => acc + (item.balances?.balance || 0), 0));
      setTotalColumnBalanceOfTheCutoff(result.data.reduce((acc, item) => acc + (item.balance_of_the_cutoff_module || 0), 0));
      
      const totalDifference = result.data.reduce((acc, item) => {
        const balance = item.balances?.balance || 0;
        const balanceOfTheCutoffModule = item.balance_of_the_cutoff_module || 0;
        return acc + (balance - balanceOfTheCutoffModule);
      }, 0);
      setTotalVariation(totalDifference);
      setRegistersOfTable(result.data);
    }
  }

  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  useEffect(() => {
    getRegistersOfTrialBalanceFile()
  }, [isRefresh, isRefreshLocal]);


  /**
  * Accepts the modal edit, updates the observation data via the repository,
  * resets the editor state and triggers a refresh by emitting an event.
  */
  const handleEditModalAccept = async () => {
    const summaryFixedAssetsCostRepository = new SummaryFixedAssetsCostRepository()
    const result = await summaryFixedAssetsCostRepository.updateObservationFixedAssetsSummary(element.args.engagement_id, element.args.document_id, element.reference, editorContents)
    setEditorContents({})
    if (result.success) {
      setIsRefreshLocal(prev => !prev);
      emitter.emit("refreshSubstantiveBalanceData");
      setShowConfigurationsModal(false);
    }
  };

  const handleEditModalCancel = () => {
    setShowConfigurationsModal(false)
  };

  const calculateVariation = (amount: number |  undefined, amount_per_statement: number | undefined) => {
    amount = amount || 0
    amount_per_statement = amount_per_statement || 0
    const total = amount - amount_per_statement
    return total
  }

  return (
    <div>
      <ViewModeBase
        handleEdit={handleEdit}
        isEditable={true}
        permissions={element.permissions as Permissions}
      >
        <div className="d-flex w-full flex-column mb-3 card">
          <div className="card-body">
            <h4>{T("Cost of fixed assets")}</h4>
            <table className="table-bordered">
              <thead>
                <tr>
                  <th className="p-2">{T("Account")}</th>
                  <th className="p-2">{T("Description")}</th>
                  <th className="p-2">{T("Accounting record cut")}</th>
                  <th className="p-2">{T("Balance of the cut-off module")}</th>
                  <th className="p-2">{T("Variation")}</th>
                  <th className="p-2">{T("Observation")}</th>
                </tr>
              </thead>
              <tbody>
                {registersOfTable.map((item) => (
                  <tr key={item.code}>
                    <td className="p-1">{item.code}</td>
                    <td className="p-1">{item.name}</td>
                    <td className="p-1">
                    {item.balances && (
                      <span>{item.balances.balance}</span>
                    )}                  
                    </td>               
                    <td className="p-1">{item.balance_of_the_cutoff_module}</td>
                    <td className="p-1">{formatCurrency(calculateVariation(item.balances?.balance, item.balance_of_the_cutoff_module))}</td>
                    <td className="p-1">{item.observation_fixed_assets_summary}</td>
                  </tr>
                ))}
                <tr key={"total"}>
                  <td className="p-1">{ }</td>
                  <td className="p-1">{T("Total")}</td>
                  <td className="p-1">{ formatCurrency(totalColumnBalance.toFixed(2))}</td>
                  <td className="p-1">{ formatCurrency(totalColumnbalanceOfTheCutoff.toFixed(2))}</td>
                  <td className="p-1">{ formatCurrency(totalVariation.toFixed(2))}</td>
                  <td className="p-1">{ }</td>
                </tr>
              </tbody>
            </table>
        </div>
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Bank Reconciliation Review Worksheet")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>

        <div>
            <table className="table-bordered w-100">
              <thead>
                <tr>
                  <th className="p-2">{T("Account")}</th>
                  <th className="p-2">{T("Description")}</th>
                  <th className="p-2">{T("Observation")}</th>
                </tr>
              </thead>
              <tbody>
                {registersOfTable.map((item) => (
                  <tr key={item.code}>
                    <td style={{width: "20%"}} className="p-1">{item.code}</td>
                    <td style={{width: "40%"}} className="p-1">{item.name}</td>
                    <td style={{width: "40%"}} className="p-1"> 
                    <textarea
                      className="form-control"
                      defaultValue={item.observation_fixed_assets_summary}
                      onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => handleEditorChange(event.target.value, item.id)}
                    />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default SummaryFixedAssetsCostViewMode;
