import React from 'react';
import type { DropFile } from './use-dropzone-upload';
import { T } from '@/utils/i18n-config';

interface FilePreviewListProps {
  files: DropFile[];
  removeFile: (fileName: string) => void;
}

/**
 * Component for displaying file previews.
 *
 * @param files - The list of files to display.
 * @param removeFile - Callback to remove a file.
 */
function FilePreviewList({ files, removeFile }: FilePreviewListProps) {
  return (
    <>
      {files.map(({ file, preview }) => (
        <div key={file.name} className="position-relative m-2 d-flex flex-column align-items-center" style={{ maxWidth: '200px' }}>
          <img
            src={preview}
            alt={file.name}
            className="w-100 rounded"
            style={{ maxWidth: '120px', aspectRatio: '1 / 1', objectFit: 'cover' }} />
          <p className="m-0 px-2 fw-bold text-dark text-sm text-wrap text-center">{file.name}</p>
          <span
            role="button"
            tabIndex={0}
            style={{ cursor: 'pointer' }}
            className="text-muted text-sm text-center fw-bold text-decoration-underline"
            onClick={() => removeFile(file.name)}
          >
            {T("Remove file")}
          </span>
        </div>
      ))}
    </>
  );
}

export default FilePreviewList;
