import React, { useEffect, useState } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from '../special-document/ViewModeProps';
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import ViewModeBase from "../special-document/ViewModeBase";
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { formatCurrency } from '../../utils/utilities';
import { Balances, RegistersFixedAssetsFile, TrialBalanceFixedAssetsRepository } from '../../repository/substantive-tests/TrialBalanceFixedAssetsRepository';
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';


export interface AccountData {
  id: number;
  consignment_number: number;
  type_account: string;
  account: string;
  name: string;
  amount: number;
  amount_in_other_accounts: number;
  amount_per_statement: number;
  difference_amount: number;
}


const TablePrincipalFixedAssetsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isRefresh, setIsRefresh] = useState(false);
  const [totalColumnVariation, setTotalColumnVariation] = useState<number>(0);
  const [totalColumnCostVariation, setTotalColumnCostVariation] = useState<number>(0);
  const [columnSums, setColumnSums] = useState<number[]>([]);
  const [columnSumsCosts, setColumnSumsCosts] = useState<number[]>([]);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [totalPercentageCosts, setTotalPercentageCosts] = useState(0);
  const [dataBase, setDataBase] = useState<RegistersFixedAssetsFile[]>();
  const [dataCosts, setDataCosts] = useState<RegistersFixedAssetsFile[]>();
  const [periods, setPeriods] = useState<string[] | undefined>(undefined);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const handleEvent = () => {
      setIsRefresh(prev => !prev);
    };
    emitter.on("refreshSubstantiveBalanceData", handleEvent);
    return () => {
      emitter.off("refreshSubstantiveBalanceData", handleEvent);
    };
  }, []);

  useEffect(() => {
  }, [periods]);

  /**
 * Calculates the percentage based on the provided balance and variation.
 * @param {Balances | undefined} amount - The balance data.
 * @param {number | undefined} variation - The variation value.
 * @returns {string} The percentage formatted to two decimals.
 */
  const getPercentage = (amount: Balances | undefined, variation: number | undefined): string => {
    if (!amount || variation === undefined || amount.balance === 0) {
      return "0.00";
    }

    const porcentaje = amount && variation
      ? (variation / amount.balance) * 100
      : 0;
    return porcentaje.toFixed(2);
  }

  /**
 * Retrieves registers for the Trial Balance File and updates state accordingly.
 * @returns {Promise<void>}
 */
  const getRegistersOfTrialBalanceFile = async () => {
    const trialBalanceFixedAssetsRepository = new TrialBalanceFixedAssetsRepository()
    const result = await trialBalanceFixedAssetsRepository.getTableFixedAssetsSubstantiveTests(element.args.document_id, element.args.engagement_id,  element.reference)

    if (result.success) {
      setDataCosts(result.data_costs)
      setDataBase(result.data)
      const balances = result.data?.[0]?.balances;

      if (balances && result.data.length > 0) {
        const periods = result.data && result.data.length > 0 && result.data[0].balances
          ? result.data[0].balances
            .map(balance => {
              if (balance.period_end) {
                const date = new Date(balance.period_end);
                return date.toISOString().slice(0, 10);
              }
              return undefined;
            })
            .filter((date): date is string => date !== undefined)
          : [];

        const totalVariationResult = result.data.reduce(
          (acc: number, item) =>
            acc + (item.variation_amount ? parseFloat(String(item.variation_amount)) : 0),
          0
        )
        setTotalColumnVariation(totalVariationResult);
        setPeriods(periods);
        const sums: number[] = [];
        result.data?.forEach((item) => {
          item.balances?.forEach((balance, index) => {
            sums[index] = (sums[index] || 0) + (balance.balance || 0);
          });
        });

        setColumnSums(sums);
        const lastSum = sums[sums.length - 1];
        const totalColumnPercentage = totalColumnVariation && lastSum != 0 ? (totalColumnVariation / lastSum) : 0;
        setTotalPercentage(totalColumnPercentage)

        const totalColumnCostVariationResult = result.data_costs.reduce(
          (acc: number, item) =>
            acc + (item.variation_amount ? parseFloat(String(item.variation_amount)) : 0),
          0
        )

        setTotalColumnCostVariation(totalColumnCostVariationResult);
        const sumsCosts: number[] = [];
        result.data_costs?.forEach((item) => {
          item.balances?.forEach((balance, index) => {
            sumsCosts[index] = (sumsCosts[index] || 0) + (balance.balance || 0);
          });
        });

        setColumnSumsCosts(sumsCosts);
        const lastSumCost = sumsCosts[sumsCosts.length - 1];
        const totalColumnCostPercentage = totalColumnCostVariation && lastSumCost != 0 ? (totalColumnCostVariation / lastSumCost) : 0;
        setTotalPercentageCosts(totalColumnCostPercentage)
      } else {
        setPeriods(undefined);
      }
    } else {
      setDataCosts([])
      setPeriods(undefined);
    }
  }

  /**
 * Opens the configuration modal for editing.
 * @returns {void}
 */
  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  useEffect(() => {
    getRegistersOfTrialBalanceFile()
  }, [isRefresh]);

  /**
   * Handles the acceptance action of the edit modal and then closes it.
   * @returns {Promise<void>}
   */
  const handleEditModalAccept = async () => {
    setShowConfigurationsModal(false);
  };

  /**
 * Handles the cancellation of the edit modal, resetting related state.
 * @returns {void}
 */
  const handleEditModalCancel = () => {
    setShowConfigurationsModal(false)
  };

  return (
    <div>
      <ViewModeBase
        handleEdit={handleEdit}
        isEditable={true}
        permissions={element.permissions as Permissions}
      >
        <div className="d-flex w-full flex-column mb-3">
          <div className="d-flex justify-content-between mb-3">
            <h6>{T("Balance Sheet Accounts")}</h6>
          </div>
          <table className="table-bordered">
            <thead>
              <tr>
                <th className="p-2 w-10">{T("Code")}</th>
                <th className="p-2 w-30">{T("Name")}</th>
                {periods?.map((period, index) => (
                  <th key={index} className="p-2 w-15">{period}</th>
                ))}
                <th className="p-2 w-20">{T("Variation Amount")}</th>
                <th className="p-2 w-10">%</th>
              </tr>
            </thead>
            <tbody>
              {dataBase?.map((item, index) => (
                <tr key={index}>
                  <td className="p-1">{item.code}</td>
                  <td className="p-1">{item.name}</td>
                  {item.balances?.map((balance, balanceIndex) => (
                    <td key={balanceIndex} className="p-1">{formatCurrency(balance.balance.toFixed(2))}</td>
                  ))}
                  <td className="p-1">{formatCurrency(item.variation_amount?.toFixed(2))}</td>
                  <td className="p-1">{getPercentage(item.balances?.[item.balances.length - 1], item.variation_amount)}</td>
                </tr>
              ))}
              <tr key={"total"}>
                <td className="p-1">{ }</td>
                <td className="p-1">{ }</td>
                {columnSums.map((sum, index) => (
                  <td key={index} className="p-1">{formatCurrency(sum.toFixed(2))}</td>
                ))}
                <td className="p-1">{formatCurrency(totalColumnVariation.toFixed(2))}</td>
                <td className="p-1">{totalPercentage.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
          <div className="d-flex justify-content-between mt-3">
            <h6>{T("PyG Accounts")}</h6>
          </div>
          <div style={{ width: "100%", overflowX: "auto", minWidth: "100%", borderRight: "1px solid #808080" }}>
            <table className="table-bordered w-100" style={{ borderCollapse: "collapse", minWidth: "100%" }}>
              <thead>
                <tr>
                  <th className="p-2 w-10">{T("Code")}</th>
                  <th className="p-2 w-30">{T("Name")}</th>
                  {periods?.map((period, index) => (
                    <th key={index} className="p-2 w-15">{period}</th>
                  ))}
                  <th className="p-2 w-20">{T("Variation Amount")}</th>
                  <th className="p-2 w-10">%</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={6} className="text-center bg-light">
                    <button
                      onClick={() => setIsExpanded(!isExpanded)}
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                      className="p-2"
                    >
                      <i className={`fa ${isExpanded ? "fa-chevron-down" : "fa-chevron-right"}`} style={{ marginRight: "4px" }} aria-hidden="true"></i>
                      {isExpanded ? T("Fold Records") : T("Unfold Records")}
                    </button>
                  </td>
                </tr>
                {isExpanded && dataCosts?.map((item, index) => (
                  <tr key={index}>
                    <td className="p-1">{item.code}</td>
                    <td className="p-1">{item.name}</td>
                    {item.balances?.map((balance, balanceIndex) => (
                      <td key={balanceIndex} className="p-1">{formatCurrency(balance.balance.toFixed(2))}</td>
                    ))}
                    <td className="p-1">{formatCurrency(item.variation_amount?.toFixed(2))}</td>
                    <td className="p-1">{getPercentage(item.balances?.[item.balances.length - 1], item.variation_amount)}</td>
                  </tr>
                ))}
                <tr key={"total"}>
                  <td className="p-1">{ }</td>
                  <td className="p-1">{ }</td>
                  {columnSumsCosts.map((sum, index) => (
                    <td key={index} className="p-1">{formatCurrency(sum.toFixed(2))}</td>
                  ))}
                  <td className="p-1">{formatCurrency(totalColumnCostVariation.toFixed(2))}</td>
                  <td className="p-1">{totalPercentageCosts.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Bank Reconciliation Review Worksheet")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <div>
          <table className="table-bordered w-100">
            <thead>
              <tr>
                <th className="p-2">{T("Code")}</th>
                <th className="p-2">{T("Name")}</th>
                {periods?.map((period, index) => (
                  <th key={index} className="p-2">{period}</th>
                ))}
                <th className="p-2">{T("Variation Amount")}</th>
                <th className="p-2">%</th>
              </tr>
            </thead>
            <tbody>
              {dataBase?.map((item, index) => (
                <tr key={index}>
                  <td className="p-1">{item.code}</td>
                  <td className="p-1">{item.name}</td>
                  {item.balances?.map((balance, balanceIndex) => (
                    <td key={balanceIndex} className="p-1">{formatCurrency(balance.balance.toFixed(2))}</td>
                  ))}
                  <td className="p-1">{formatCurrency(item.variation_amount?.toFixed(2))}</td>
                  <td className="p-1">{getPercentage(item.balances?.[item.balances.length - 1], item.variation_amount)}</td>
                </tr>
              ))}
              <tr key={"total"}>
                <td className="p-1">{ }</td>
                <td className="p-1">{ }</td>
                {columnSums.map((sum, index) => (
                  <td key={index} className="p-1">{formatCurrency(sum.toFixed(2))}</td>
                ))}
                <td className="p-1">{formatCurrency(totalColumnVariation.toFixed(2))}</td>
                <td className="p-1">{totalPercentage.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default TablePrincipalFixedAssetsViewMode;
