import { ElementBase, IElementBase } from "./ElementBase"
import { ElementArgs } from "./ElementArgs"


export class InternalControlComponentsConfigurationElement extends ElementBase implements IElementBase {
  _is_question_finding: boolean
  _is_required_file: boolean
  constructor(args: ElementArgs) {
    super(args)
    this._is_question_finding = args.is_question_finding ?? false
    this._is_required_file = args.is_required_file ?? false
  }

  get is_question_finding(): boolean {
    return this._is_question_finding
  }

  get is_required_file(): boolean {
    return this._is_required_file
  }

  set is_question_finding(is_question_finding: boolean) {
    this._is_question_finding = is_question_finding
    this.args.is_question_finding = is_question_finding
  }

  set is_required_file(is_required_file: boolean) {
    this._is_required_file = is_required_file
    this.args.is_required_file = is_required_file
  }

  render(): void {
    console.log("rendering internal control components configuration element")
  }
}