import React, { useState, useRef, useEffect } from 'react';
import { T } from "../../utils/i18n-config"
import { FindingsElement } from '../../models/special-document/FindingsElement';
import EditModeBase from "../special-document/EditModeBase";
import EditModeProps from '../special-document/EditModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import ModalBase from '../modals/ModalBase';
import Select2AccountConfiguration from '../commons/Select2AccountConfiguration';
import { RequiredAccountConfigurationBankConciliationsRepository } from '../../repository/substantive-tests/RequiredAccountConfigurationBankConciliationsRepository';
import { RequiredAccountConfigurationBankConciliations } from '../../models/special-document/ElementArgs';

const RequiredAccountConfigurationBankConciliationsEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<FindingsElement>(iElement as FindingsElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [accountOptions, setAccountOptions] = useState<RequiredAccountConfigurationBankConciliations[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<number>();
  const [items, setItems] = useState<RequiredAccountConfigurationBankConciliations[]>([]);
  const [numberAccountDigits, setNumberAccountDigits] = useState<number | "">("");
  const [refresh, setRefresh] = useState(false);

  const getRegisters = async () => {
    const requiredAccountConfigurationRepository = new RequiredAccountConfigurationBankConciliationsRepository();
    const response_accounts_configuration = await requiredAccountConfigurationRepository.getAccountsConfiguration(element.args.engagement_id, element.args.document_id, element.reference, "edition");
    if (response_accounts_configuration.data.length > 0) {
      setSelectedAccount(response_accounts_configuration.data[0].id);
    }
    setItems(response_accounts_configuration.data);
    setNumberAccountDigits(response_accounts_configuration.number_account_digits);
    const response_ledger_accounts = await requiredAccountConfigurationRepository.getLedgerAccounts();
    const filteredSavedOptions = response_ledger_accounts.filter(savedOption => {
      
      return !response_accounts_configuration.data.some(responseOption => responseOption.ledger_account_id === savedOption.id);
    });
    
    setAccountOptions(filteredSavedOptions);
  }


  useEffect(() => {
    getRegisters();
  }, [refresh]);

  const handleConfguration = () => {
    setShowConfigurationsModal(true);
  }

  const handleConfigurationsModalAccept = () => {
    setShowConfigurationsModal(false);
  }

  const handleCancel = () => {
    setShowConfigurationsModal(false)
    //setShowAdvancedMenu(false)
  };

  const addAccountConfiguration = async (ledger_account_id: number | null) => {
    const requiredAccountConfigurationRepository = new RequiredAccountConfigurationBankConciliationsRepository();
    const response_accounts_configuration = await requiredAccountConfigurationRepository.addAccountConfiguration(element.args.engagement_id, element.args.document_id, ledger_account_id, element.reference);
    setRefresh(!refresh);
  }

  const handleSelect = (register_id: number) => {
    addAccountConfiguration(register_id);
    setRefresh(!refresh);
  };

  const updateAccountConfiguration = async (accountLength: number) => {
    const requiredAccountConfigurationRepository = new RequiredAccountConfigurationBankConciliationsRepository();
    const response_accounts_configuration = await requiredAccountConfigurationRepository.updateNumberAccountDigits(element.args.engagement_id, element.args.document_id, element.reference, accountLength);
    setRefresh(!refresh);
  }

  const handleSelectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = parseInt(event.target.value);
    updateAccountConfiguration(newValue);
  };

  const deleteAccountConfiguration = async (ledger_account_id: number | undefined) => {
    const requiredAccountConfigurationRepository = new RequiredAccountConfigurationBankConciliationsRepository();
    const response_accounts_configuration = await requiredAccountConfigurationRepository.deleteAccountConfiguration(element.args.engagement_id, ledger_account_id, element.args.document_id, element.reference);
  }

  const handleDelete = (register_id: number| undefined) => {
    if (!items) {
      return;
    }
    deleteAccountConfiguration(register_id);
    setRefresh(!refresh);
  };

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfguration}
        permissions={element.permissions as Permissions}>
        <div className="card h-100">
          <div className="card-body p-3 text-center">
          <h5>{T("Configuration")}</h5>
          <h6>{T("Minimum number of account digits")}</h6>
          <p>{numberAccountDigits}</p>
            <h6>{T("Required Accounts Settings")}</h6>
            <p>{T("Below, you can see the default configuration of the accounting accounts that will be analyzed in this substantive test.")}</p>
            {items.map((item) => (
              <div key={item.id}>
                <p>{item.code}</p>
              </div>
            ))}
          </div>
        </div>
      </EditModeBase>
      <ModalBase
        showModal={showConfigurationsModal}
        title={T("Required Accounts Settings")}
        size="xl">
        <div className="modal-body text-center">
          <div>
          <div>
          <h5>{T("Configuration")}</h5>

              <h6>{T("Select minimum number of account digits")}</h6>
              <div className="d-flex justify-content-center">

              <select style={{ width: '200px' }} id="evenNumbers" className="form-control" value={numberAccountDigits} onChange={handleSelectionChange}>
                    {[4, 6, 8, 10, 12].map(number => (
                      <option key={number} value={number}>{number}</option>
                    ))}
                </select>
              </div>
            </div>
            <h6>{T("Required Accounts Settings")}</h6>
            <p>{T("Below, you can see the default configuration of the accounting accounts that will be analyzed in this substantive test.")}</p>
            {items.map((item) => (
              <div key={item.id} className="d-flex gap-3 justify-content-center">
                {item.code}
                <a onClick={() => handleDelete(item.id)} style={{ cursor: 'pointer' }}><i className="fas fa-trash text-primary"></i></a>
              </div>
            ))}
            <div className="d-flex justify-content-center mt-2">
              <Select2AccountConfiguration
                multiple={false}
                options={accountOptions}
                placeholder={T("Select Accounts")}
                onChange={handleSelect}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn bg-gradient-secondary" onClick={handleCancel}>{T("Cancel")}</button>
          <button type="button" className="btn bg-gradient-primary" onClick={handleConfigurationsModalAccept}>{T("Accept")}</button>
        </div>
      </ModalBase>
    </div>
  )
}
export default RequiredAccountConfigurationBankConciliationsEditMode;
