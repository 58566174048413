import React, { useState, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { decryptUniqueFilename } from "../../utils/utilities"
import { Permissions } from '../../models/special-document/ElementBase'
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { QuestionTextFileWithYesNoElement } from '../../models/special-document/QuestionTextFileWithYesNoElement'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import ViewModeProps from '../special-document/ViewModeProps'
import ViewModeBase from '../special-document/ViewModeBase'
import Summernote from '../utils/Summernote'
import Dropzone from '@/components/commons/dropzone/dropzone-component'

const QuestionTextFileViewWithYesNoViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<QuestionTextFileWithYesNoElement>(iElement as QuestionTextFileWithYesNoElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [answerYesNo, setAnswerYesNo] = useState<string>(element.answer_yes_no ?? "" )
  const [answerYesNoBase, setAnswerYesNoBase] = useState<string>(element.answer_yes_no ?? "" )
  const [answerText, setAnswerText] = useState<string>(element.answer)
  const [attachmentName, setAttachmentName] = useState<string>(element.attachment)
  const question = element.question

  function handleEdit() {
    setShowConfigurationsModal(true)
  }

  async function handleEditModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastAnswer = element.answer
    const lastAttachment = element.attachment
    const lastAnswerYesNo = element.answer_yes_no
    element.answer = answerText
    element.attachment = attachmentName
    element.answer_yes_no = answerYesNo
    console.log("elements", element);
    
    let success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      element.answer = lastAnswer
      element.attachment = lastAttachment
      setAnswerText(lastAnswer)
      setAttachmentName(lastAttachment)
      setAnswerYesNo(lastAnswerYesNo)
      window.htmlHelpers?.swalError()
    }else {
      setAnswerYesNoBase(answerYesNo)
    }
  }

  function handleEditModalCancel() {
    setAnswerText("")
    setAttachmentName("")
    setAnswerYesNo(answerYesNoBase)
    setShowConfigurationsModal(false)
  }

  const options = [
    { value: "Yes" },
    { value: "No" },
  ];

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setAnswerYesNo(event.target.value);
  }

  return (
    <>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        <div className="card card-body flex flex-column gap-3 p-3 bg-light">
          <div>
            <pre className="m-0 text-dark fs-6 fw-bold" style={{ fontFamily: "sans-serif", textWrap: "wrap" }}>
              {question ? question : T("Instruction not defined")}
            </pre>
          </div>
          {answerYesNoBase && (
            <div>
              <h6 className="small mb-1 lh-1">{T("Answer")}</h6>
              <p className="m-0">{answerYesNoBase}</p>
            </div>
          )}
          {answerText && (
            <div>
              <h6 className="small mb-1 lh-1">{T("Justification")}</h6>
              <div className="m-0" dangerouslySetInnerHTML={{ __html: answerText }} />
            </div>
          )}
          {attachmentName && (
            <div>
              <h6 className="small mb-1 lh-1">{T("Attachment")}</h6>
              <p className="m-0">{T("Attachment")}: {decryptUniqueFilename(attachmentName)}</p>
            </div>
          )}
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}
      >
        <div className="card-body">
          <div className="form-group">
          <pre className="m-0 text-dark fs-6 fw-bold" style={{ fontFamily: "sans-serif", textWrap: "wrap" }}>
              {question ? question : T("Instruction not defined")}
            </pre>
          <label htmlFor="optionsSelect">{T("Select Option:")}</label>
          <select
          id="optionsSelect"
          value={answerYesNo}
          onChange={handleSelectChange}
          className="form-select"
        >
          <option value="" disabled>
            {T("Select an option")}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {T(option.value)}
            </option>
          ))}
        </select>
            <label htmlFor="answerText">{T("Justification")}</label>
            <Summernote value={answerText} onChange={setAnswerText} />
          </div>
          <div className="form-group">
            <label htmlFor="attachment">{T("Attachment")}</label>
            <Dropzone
              elementId={element.id}
              attachmentName={attachmentName}
              onUpload={setAttachmentName}
            />
          </div>
        </div>    
      </ModalWithButtons>
    </>
  );
}

export default QuestionTextFileViewWithYesNoViewMode;