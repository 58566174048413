import React, { useState, useEffect, useRef, useMemo } from 'react'
import { T } from "../../utils/i18n-config"
import { arrayToOptions } from "../../utils/utilities"
import { Permissions } from '../../models/special-document/ElementBase'
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { QuestionJustifiedElement } from '../../models/special-document/QuestionJustifiedElement'
import type { ItemJustifedOption } from '../../models/special-document/ElementArgs'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeProps from './EditModeProps'
import EditModeBase from './EditModeBase'
import Select from "../commons/Select"

const ITEM_TYPE_OPTIONS = ["explanation", "justification"] as const
type ItemType = typeof ITEM_TYPE_OPTIONS[number]

const QuestionJustifiedEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<QuestionJustifiedElement>(iElement as QuestionJustifiedElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [question, setQuestion] = useState(element.question)
  const [itemsOnYes, setItemsOnYes] = useState(element.itemsOnYes)
  const [itemsOnNo, setItemsOnNo] = useState(element.itemsOnNo)
  
  const [itemTypeYes, setItemTypeYes] = useState<ItemType>(ITEM_TYPE_OPTIONS[0].toLowerCase() as ItemType)
  const [itemTypeNo, setItemTypeNo] = useState<ItemType>(ITEM_TYPE_OPTIONS[0].toLowerCase() as ItemType)
  const [itemTextYes, setItemTextYes] = useState("")
  const [itemTextNo, setItemTextNo] = useState("")
  const [itemTitleYes, setItemTitleYes] = useState("")
  const [itemTitleNo, setItemTitleNo] = useState("")

  function handleConfiguration() {
    setQuestion(element.question)
    setItemsOnYes(element.itemsOnYes)
    setItemsOnNo(element.itemsOnNo)
    setShowConfigurationsModal(true)
  }

  async function handleConfigurationsModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastQuestion = element.question
    const lastItemsOnYes = element.itemsOnYes
    const lastItemsOnNo = element.itemsOnNo
    element.question = question
    element.itemsOnYes = itemsOnYes
    element.itemsOnNo = itemsOnNo

    let success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      element.question = lastQuestion
      element.itemsOnYes = lastItemsOnYes
      element.itemsOnNo = lastItemsOnNo
      setQuestion(lastQuestion)
      setItemsOnYes(lastItemsOnYes)
      setItemsOnNo(lastItemsOnNo)
      window.htmlHelpers?.swalError()
    }
  }

  function handleConfigurationsModalCancel() {
    setQuestion(element.question)
    setItemsOnYes(element.itemsOnYes)
    setItemsOnNo(element.itemsOnNo)
    setShowConfigurationsModal(false)
  }

  function handleSavingItemOption() {
    if (itemTextYes && itemTypeYes) {
      setItemsOnYes([...itemsOnYes, { type: itemTypeYes, text: itemTextYes, title: itemTitleYes }])
    }

    if (itemTextNo && itemTypeNo) {
      setItemsOnNo([...itemsOnNo, { type: itemTypeNo, text: itemTextNo, title: itemTitleNo }])
    }
  }

  function handleEditItem(index: number, item: ItemJustifedOption, type: string) {
    if (type === "yes") {
      setItemTypeYes(item.type)
      setItemTextYes(item.text)
      setItemTitleYes(item.title ?? "")
      setItemsOnYes(itemsOnYes.filter((_, i) => i !== index))
    }
    if (type === "no") {
      setItemTypeNo(item.type)
      setItemTextNo(item.text)
      setItemTitleNo(item.title ?? "")
      setItemsOnNo(itemsOnNo.filter((_, i) => i !== index))
    }

  }

  function handleDeleteItem(index: number, type: string) {
    if (type === "yes") setItemsOnYes(prevItems => prevItems.filter((_, i) => i !== index))
    if (type === "no") setItemsOnNo(prevItems => prevItems.filter((_, i) => i !== index))
  }

  useEffect(() => {
    setItemTextYes("")
    setItemTitleYes("")
  }, [itemsOnYes])

  useEffect(() => {
    setItemTextNo("")
    setItemTitleNo("")
  }, [itemsOnNo])

  return (
    <>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}
      >
        <ItemsPreview question={question} items={[itemsOnYes, itemsOnNo]} />
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}
      >
        <div>
          <div className="d-flex flex-column gap-4">
            <div className="form-group w-100">
              <label htmlFor="item-text" className="form-label">{T("Question")}</label>
              <input
                type="text"
                id="item-text"
                value={question}
                className="form-control fs-6"
                placeholder={T("Insert the text")}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </div>
            <div className="d-flex align-items-start gap-4">
              <div className="form-group w-100">
                <label htmlFor="item-text" className="form-label">{T("Option")} '{T("Yes")}'</label>
                <Select
                  hasSearch={false}
                  defaultValue={arrayToOptions([itemTypeYes], T)}
                  options={arrayToOptions(ITEM_TYPE_OPTIONS, T)}
                  placeholder={T("Select an option")}
                  onChange={(values) => setItemTypeYes(values[0].id as ItemType)}
                />
                {itemTypeYes === "justification" && (
                  <div className="form-group mt-2 w-100">
                    <label htmlFor="item-text" className="form-label">{T("Title")}</label>
                    <input
                      type="text"
                      id="item-text"
                      value={itemTitleYes}
                      className="form-control fs-6"
                      onChange={(e) => setItemTitleYes(e.target.value)}
                    />
                  </div>
                )}
                <input
                  type="text"
                  id="item-text"
                  value={itemTextYes}
                  className="form-control fs-6 mt-2"
                  placeholder={T("Insert the text")}
                  onChange={(e) => setItemTextYes(e.target.value)}
                />
              </div>
              <div className="form-group w-100">
                <label htmlFor="item-text" className="form-label">{T("Option")} '{T("No")}'</label>
                <Select
                  hasSearch={false}
                  defaultValue={arrayToOptions([itemTypeYes], T)}
                  options={arrayToOptions(ITEM_TYPE_OPTIONS, T)}
                  placeholder={T("Select an option")}
                  onChange={(values) => setItemTypeNo(values[0].id as ItemType)}
                />
                {itemTypeNo === "justification" && (
                  <div className="form-group mt-2 w-100">
                    <label htmlFor="item-text" className="form-label">{T("Title")}</label>
                    <input
                      type="text"
                      id="item-text"
                      value={itemTitleNo}
                      className="form-control fs-6"
                      onChange={(e) => setItemTitleNo(e.target.value)}
                    />
                  </div>
                )}
                <input
                  type="text"
                  id="item-text"
                  value={itemTextNo}
                  className="form-control fs-6 mt-2"
                  placeholder={T("Insert the text")}
                  onChange={(e) => setItemTextNo(e.target.value)}
                />
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSavingItemOption}
          >{T("Add")}</button>
        </div>
        <ItemsList
          items={[itemsOnYes, itemsOnNo]}
          onEditItem={handleEditItem}
          onDeleteItem={handleDeleteItem}
        />
      </ModalWithButtons>
    </>
  );
}

interface ItemsPreviewProps {
  question: string
  items: ItemJustifedOption[][]
}

function ItemsPreview({ question, items }: ItemsPreviewProps) {
  return (
    <>
      <b className="d-block mb-3 lh-sm">{question}</b>
      <div className="d-flex flex-column gap-4">
        <div>
          <h6 className="mb-1 text-white">{T("Options")} '{T("Yes")}'</h6>
          {items[0].map((item, index) => (
            <div key={index} className="d-flex flex-column gap-2">
              {item.title && <b className="m-0 fs-6">{item.title}</b>}
              <p className="m-0">{item.text}</p>
            </div>
          ))}
        </div>
        <div>
          <h6 className="mb-1 text-white">{T("Options")} '{T("No")}'</h6>
          {items[1].map((item, index) => (
            <div key={index} className="d-flex flex-column gap-2">
              {item.title && <b className="m-0 fs-6">{item.title}</b>}
              <p className="m-0">{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}


interface ItemsOnEditProps {
  items: ItemJustifedOption[][]
  onEditItem: (index: number, item: ItemJustifedOption, type: string) => void
  onDeleteItem: (index: number, type: string) => void
}

function ItemsList({ items, onEditItem, onDeleteItem }: ItemsOnEditProps) {
  return (
    <div className="mt-2">
      <h6>{T("Answers")}</h6>
      <div className="d-flex gap-2 w-100">
        <ul className="list-group overflow-auto w-100" style={{ maxHeight: "40vh" }}>
          {
            items[0].map((item, index) => (
              <li key={index} className="list-group-item d-flex justify-content-between align-items-center gap-1">
                <div className="d-flex flex-column gap-2">
                  {item.title && <b className="m-0 fs-6">{item.title}</b>}
                  <p className="m-0 text-wrap">{item.text}</p>
                </div>
                <div className="d-flex gap-2">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => onEditItem(index, item, "yes")}
                  >
                    <i className="fa-solid fa-pen-to-square"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => onDeleteItem(index, "yes")}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                </div>
              </li>
            ))
          }
        </ul>
        <ul className="list-group overflow-auto w-100" style={{ maxHeight: "40vh" }}>
          {
            items[1].map((item, index) => (
              <li key={index} className="list-group-item d-flex justify-content-between align-items-center gap-1">
                <div className="d-flex flex-column gap-2">
                  {item.title && <b className="m-0 fs-6">{item.title}</b>}
                  <p className="m-0 text-wrap">{item.text}</p>
                </div>
                <div className="d-flex gap-2">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => onEditItem(index, item, "no")}
                  >
                    <i className="fa-solid fa-pen-to-square"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => onDeleteItem(index, "no")}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                </div>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  )
}

export default QuestionJustifiedEditMode;