import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { ElementParagraph } from "../../models/special-document/ElementParagraph"
import ViewModeBase from "./ViewModeBase";
import ModalWithButtons from '../modals/AceptCancelModalBase';
import Summernote from '../utils/Summernote';
import ViewModeProps from './ViewModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import { Constants } from '../../utils/Constants';
import DOMPurify from 'isomorphic-dompurify';


const ParagraphViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<ElementParagraph>(iElement as ElementParagraph).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [editorContent, setEditorContent] = useState('')
  const [editorContentTemp, setEditorContentTemp] = useState('')
  const [isEditable, setIsEditable] = useState(false)
  
  useEffect(() => {
    setEditorContent(element.text_default)
    setEditorContentTemp(element.text_default)
    setIsEditable(element.editable)

    if(element.state === "closed"){
      setIsEditable(false)
    }
  },[]);

  const handleEdit = () => {
    setShowConfigurationsModal(true);
  };

  const handleEditorChange = (content: string) => {
    setEditorContentTemp(content);
  };

  const handleEditModalAccept = async () => {
    setShowConfigurationsModal(false);
    const elementRepository = new ElementRepository()
    element.text_default = editorContentTemp
    const lastEditable = element.editable
    element.editable = isEditable
    let success = await  elementRepository.saveElement("view", element.args)
    if (success) {
      setEditorContent(editorContentTemp);
    } else {
      element.text_default = editorContent
      element.editable = lastEditable
      window.htmlHelpers?.swalError()
    }
  };

  const handleEditModalCancel = () => {
    setShowConfigurationsModal(false)
    setEditorContentTemp(editorContent)
  };

  return (
    <div>
      <ViewModeBase
        isEditable={isEditable}
        handleEdit={handleEdit}
        currentElement={element}
        permissions={element.permissions as Permissions}>
        <div className={`${Constants.CONTENT_CLASS}`}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(editorContent) }} />
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Paragraph editing")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <Summernote value={editorContent} onChange={handleEditorChange} showModal={showConfigurationsModal} />
      </ModalWithButtons>
    </div>
  );
};

export default ParagraphViewMode;
