import React, { useState, useRef, useMemo } from 'react'
import { T } from '../../utils/i18n-config'
import { Permissions } from '../../models/special-document/ElementBase'
import type { AttachmentOption } from '../../models/special-document/ElementArgs'
import { MemoReportsAttachmentsElement } from '../../models/special-document/MemoReportsAttachmentsElement'
import { ElementRepository } from '../../repository/special-document/ElementRepository'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeProps from '../special-document/EditModeProps'
import EditModeBase from '../special-document/EditModeBase'

type GroupedOptions = [string, AttachmentOption[]][]
const JournalEntriesAnswersEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<MemoReportsAttachmentsElement>(iElement as MemoReportsAttachmentsElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [attachmentOptions, setAttachmentOptions] = useState(element.attachmentOptions)
  const [title, setTitle] = useState("")
  const [subtitle, setSubtitle] = useState("")
  const groupedOptions = useMemo(() => Object.entries(Object.groupBy(attachmentOptions, (option) => option.title)) as GroupedOptions, [attachmentOptions])

  function handleConfiguration() {
    setShowConfigurationsModal(true)
  }

  async function handleOnAcceptModal() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastAttachmentOptions = element.attachmentOptions
    element.attachmentOptions = attachmentOptions

    const success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      element.attachmentOptions = lastAttachmentOptions
      setAttachmentOptions(lastAttachmentOptions)
      window.htmlHelpers?.swalError()
    }
  }

  function handleOnCancelModal() {
    setShowConfigurationsModal(false)
    setAttachmentOptions(element.attachmentOptions)
  }

  function handleAddAttachmentItem() {
    if (!title) return
    const newOption = { title, subtitle, filename: "" }
    setAttachmentOptions([...attachmentOptions, newOption])
    setTitle("")
    setSubtitle("")
  }

  return (
    <>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}
      >
        {groupedOptions.map(([title, content], outerIndex) => (
          <article key={outerIndex} className="d-flex flex-column gap-2 mb-1">
            <strong className="m-0">{outerIndex + 1}. {title}</strong>
            {content.map(({ subtitle, filename }, innerIndex) => (
              <div className="d-flex flex-column gap-1">
                {subtitle && <strong className="m-0">{outerIndex + 1}.{innerIndex + 1}. {subtitle}</strong>}
                {filename && <p>{filename}</p>}
              </div>
            ))}
          </article>
        ))}
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleOnAcceptModal}
        onCancel={handleOnCancelModal}
      >
        <div className="d-flex flex-column gap-4 align-items-end">
          <div className="container">
            <div className="form-group w-100">
              <label htmlFor="item-title" className="form-label">{T("Title")}</label>
              <input
                type="text"
                id="item-title"
                value={title}
                className="form-control fs-6"
                placeholder={T("Insert the text")}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="form-group w-100">
              <label htmlFor="item-subtitle" className="form-label">{T("Subtitle")}</label>
              <input
                type="text"
                id="item-subtitle"
                value={subtitle}
                className="form-control fs-6"
                placeholder={T("Insert the text")}
                onChange={(e) => setSubtitle(e.target.value)}
              />
            </div>
          </div>
          <button className="btn btn-primary me-4" onClick={handleAddAttachmentItem}>
            {T("Add")}
          </button>
        </div>
        <div className="d-flex flex-column gap-2">
          <h5>Items</h5>
          <ul className="list-group">
            {attachmentOptions.map(({ title, subtitle }, index) => (
              <li key={index} className="list-group-item">
                <span>{title}</span>
                {subtitle && <span> - {subtitle}</span>}
              </li>
            ))}
          </ul>
        </div>
      </ModalWithButtons>
    </>
  );
}

export default JournalEntriesAnswersEditMode;