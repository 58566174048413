import React, { useEffect, useState } from 'react'
import DOMPurify from 'isomorphic-dompurify';
import { T } from "../../utils/i18n-config"
import { decryptUniqueFilename, parseObject } from '../../utils/utilities';
import { Constants } from '../../utils/Constants';
import { ConditionalParagraphElementWithSelector } from "../../models/special-document/ConditionalParagraphElementWithSelector"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { ElementAttachmentRepository } from '../../repository/special-document/ElementAttachmentRepository';
import { ConditionalParagraphOption } from "../../models/special-document/ElementArgs"
import { Permissions } from '../../models/special-document/ElementBase'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import ViewModeProps from './ViewModeProps';
import ViewModeBase from "./ViewModeBase";
import Summernote from '../utils/Summernote';
import Dropzone from '@/components/commons/dropzone/dropzone-component';


const ConditionalParagraphWithSelectorViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<ConditionalParagraphElementWithSelector>(iElement as ConditionalParagraphElementWithSelector)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [optionIdSelected, setOptionIdSelected] = useState(element.optionIdSelected)
  const [optionSelected, setOptionSelected] = useState<ConditionalParagraphOption | undefined>(undefined)
  const attachmentName = element.getOptionSelected()?.attachment_name ?? "";

  useEffect(() => {
    setOptionIdSelected(element.optionIdSelected)
    if(element.optionIdSelected != null)
      setOptionSelected(JSON.parse(JSON.stringify(element.getOptionSelected())));
  },[]);

  const handleEdit = () => {
    setOptionIdSelected(element.optionIdSelected)
    if(element.optionIdSelected != null)
      setOptionSelected(parseObject(element.getOptionSelected()));
    setShowConfigurationsModal(true)
  };

  const handleEditModalAccept = async () => {
    setShowConfigurationsModal(false);    
    const elementRepository = new ElementRepository()
    const lastOptionIdSelected = element.optionIdSelected
    const lastOptions = parseObject(element.options)
    element.optionIdSelected = optionIdSelected
    element.options.forEach((option) => {
      if(option.id === optionIdSelected) {
        option.message = optionSelected?.message ?? ""
        option.value = optionSelected?.value ?? ""
        option.editable = optionSelected?.editable ?? false
        option.has_attachment = optionSelected?.has_attachment ?? false
        option.attachment_name = optionSelected?.attachment_name ?? ""
      }
    })

    let success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      element.optionIdSelected = lastOptionIdSelected
      element.options = lastOptions
      window.htmlHelpers?.swalError()
    }
  };

  const handleEditModalCancel = () => {
    setShowConfigurationsModal(false)
    setOptionSelected(undefined)
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === "") {
      setOptionIdSelected(null);
      setOptionSelected(undefined);
      return;
    }
    const value = parseInt(event.target.value, 10);
    setOptionIdSelected(value);
    setOptionSelected(parseObject(element.getOptionById(value)));
  };

  const handleEditorChange = (content: string) => {
    if (optionSelected != undefined){
      optionSelected.message = content;
    }
    setOptionSelected(parseObject(optionSelected));
  };

  function handleSetAttachment(attachmentName: string, originalFilename: string) {
    if (optionSelected) {
      optionSelected.attachment_name = attachmentName;
      optionSelected.message = originalFilename;
    }
    setOptionSelected(parseObject(optionSelected));
  }

  async function handleDownloadAttachment(attachmentName: string) {
    const elementAttachmentRepository = new ElementAttachmentRepository();
    const attachment = await elementAttachmentRepository.getAttachment("view", element.id, attachmentName);
    if (!(attachment instanceof Blob)) return window.htmlHelpers?.swalError();
    const url = window.URL.createObjectURL(attachment);
    const a = document.createElement('a');
    a.href = url;
    a.download = decryptUniqueFilename(attachmentName);
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  return (
    <div>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}>
        {element.options.length === 0 && 
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{T("Without configuration")}</h6>
              <div className="chart pt-3">
                <p>{T("Configure this element from the template.")}</p>
              </div>
            </div>
          </div>
        }
        {element.options.length > 0 && element.optionIdSelected === null &&
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{element.question}</h6>
              <div className="chart pt-3">
                <p>{T("Select an answer.")}</p>
              </div>
            </div>
          </div>
        }
        {element.options.length > 0 && element.optionIdSelected != null && (
          <>
            {
              !element.getOptionSelected()?.has_attachment ? (
                <div
                  className={`${Constants.CONTENT_CLASS}`}
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(element.getMessage())}}
                />
              ) : (
                <>
                  <h5 className="fw-bold">{T("Attachment")}: {element.question}</h5>
                  <div className="d-flex align-items-center gap-2 py-1 px-2 border border-2 border-secondary rounded"
                    style={{ width: 'fit-content'}}
                    title={attachmentName !== "" ? decryptUniqueFilename(attachmentName) : T("No attachment yet")}
                  >
                    <span className="d-block text-sm fw-bold text-truncate text-uppercase" style={{ maxWidth: '400px' }}>
                      {attachmentName !== "" ? decryptUniqueFilename(attachmentName) : T("No attachment yet")}
                    </span>
                    {attachmentName !== "" && (
                      <button
                        type="button"
                        style={{ cursor: 'pointer' }}
                        className="p-1 bg-transparent border-0 text-secondary"
                        onClick={() => handleDownloadAttachment(attachmentName)}
                      >
                        <i className="fa-solid fa-download"></i>
                        <span className="visually-hidden">{T("Remove file")}</span>
                      </button>
                    )}
                  </div>
                </>
              )
            }
          </>
        )
        }
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={element.question}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <select value={optionIdSelected ?? ""} onChange={handleSelectChange} className="form-select">
          <option value="">{T("Select an answer.")}</option>
          {element.options.map((option) => (
            <option key={option.id} value={option.id} selected={option.id === element.optionIdSelected}>
              {T(option.value)}
            </option>
          ))}
        </select>
        {
          element.options.length > 0 &&
          optionIdSelected != null && 
          optionSelected != undefined &&
          optionSelected.editable === false &&
          optionSelected.has_attachment != null &&
          optionSelected.has_attachment === false && (
            <div className="mt-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(optionSelected.message)}} />
          )
        }
        {
          element.options.length > 0 &&
          optionIdSelected != null &&
          optionSelected != undefined &&
          optionSelected?.has_attachment &&
          optionSelected.attachment_name != null && (
            <AttachmentSection
              optionId={element.id}
              defaultAttachmentName={optionSelected.attachment_name}
              handleSetAttachment={handleSetAttachment}
            />
          )
        }
        {
          element.options.length > 0 &&
          optionIdSelected != null &&
          optionSelected != undefined &&
          optionSelected.editable === true &&
          optionSelected.has_attachment == null && (
            <Summernote value={optionSelected.message} onChange={handleEditorChange} showModal={showConfigurationsModal} />
          )
        }
      </ModalWithButtons>
    </div>
  );
};

interface AttachmentSectionProps {
  optionId: number;
  defaultAttachmentName: string;
  handleSetAttachment: (attachmentName: string, originalFilename: string) => void;
}

const AttachmentSection = ({ optionId, defaultAttachmentName, handleSetAttachment }: AttachmentSectionProps) => {
  const [attachmentName, setAttachmentName] = useState(defaultAttachmentName);

  function handleRemoveAttachment() {
    setAttachmentName('');
    handleSetAttachment('', '');
  }

  function handleUploadAttachment(attachmentName: string, originalFilename: string) {
    setAttachmentName(originalFilename);
    handleSetAttachment(attachmentName, originalFilename);
  }

  return (
    <div className="my-3">
      {
        attachmentName !== "" && (
          <div className="d-flex align-items-center gap-2 py-1 px-2 border border-2 border-secondary rounded"
            style={{ width: 'fit-content'}}
            title={decryptUniqueFilename(attachmentName)}
          >
            <span className="d-block text-sm fw-bold text-truncate text-uppercase" style={{ maxWidth: '400px' }}>
              {decryptUniqueFilename(attachmentName)}
            </span>
            <button
              type="button"
              style={{ cursor: 'pointer' }}
              className="p-1 bg-transparent border-0 text-secondary"
              onClick={handleRemoveAttachment}
            >
              <i className="fa-regular fa-trash-can"></i>
              <span className="visually-hidden">{T("Remove file")}</span>
            </button>
          </div>
        )
      }
      {
        attachmentName === "" && (
          <Dropzone elementId={optionId} onUpload={handleUploadAttachment} />
        )
      }
    </div>
  );
}

export default ConditionalParagraphWithSelectorViewMode;
