import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from '../special-document/ViewModeProps';
import { ConditionalParagraphOption } from '../../models/special-document/ElementArgs';
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import ViewModeBase from "../special-document/ViewModeBase";
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { formatCurrency } from '../../utils/utilities';
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';
import { PaeMovementFixedAssetsRepository, RegistersPaeMovemement } from '../../repository/substantive-tests/PaeMovementFixedAssetsRepository'


export interface AccountData {
  id: number;
  consignment_number: number;
  type_account: string;
  account: string;
  name: string;
  amount: number;
  amount_in_other_accounts: number;
  amount_per_statement: number;
  difference_amount: number;
}


const PaeMovementFixedAssetsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isRefresh, setIsRefresh] = useState(false);
  const [isRefreshLocal, setIsRefreshLocal] = useState(false);
  const [changes, setChanges] = useState<{ [key: number]: Partial<RegistersPaeMovemement> }>({});
  const [totalColumnJustificationDifferences, setTotalColumnJustificationDifferences] = useState<number | undefined>(0);
  const [totalColumnAdditions, setTotalColumnAdditions] = useState<number | undefined>(0);
  const [totalColumnWithdrawals, setTotalColumnWithdrawals] = useState<number | undefined>(0);
  const [totalColumnReclassifications, setTotalColumnReclassifications] = useState<number | undefined>(0);
  const [totalColumnDepreciation, setTotalColumnDepreciation] = useState<number | undefined>(0);
  const [totalColumnImpairment, setTotalColumnImpairment] = useState<number | undefined>(0);
  const [totalColumnValuation, setTotalColumnValuation] = useState<number | undefined>(0);
  const [data, setData] = useState<RegistersPaeMovemement[]>();
  const [dataBase, setDataBase] = useState<RegistersPaeMovemement[]>();

  const handleInputChange = (id: number | undefined, field: keyof RegistersPaeMovemement, value: number | string | undefined | null, code: number | undefined) => {
    if (id === undefined) return;
    setChanges(prev => ({ ...prev, [id]: { ...prev[id], [field]: value, "code": code} }));
  };

  useEffect(() => {
    const handleEvent = () => {
      setIsRefresh(prev => !prev);
    };
    emitter.on("refreshSubstantiveBalanceData", handleEvent);
    return () => {
      emitter.off("refreshSubstantiveBalanceData", handleEvent);
    };
  }, []);

  const calculateCurrentTotal = (item: RegistersPaeMovemement) => {
    const fieldsToSum: (keyof RegistersPaeMovemement)[] = [
      "additions",
      "withdrawals",
      "reclassifications",
      "depreciation",
      "impairment",
      "valuation",
    ];
  
    const total = fieldsToSum.reduce((total, field) => {
      const value = Number(item[field]) || 0;
      const roundedValue = parseFloat(value.toFixed(2));
      return total + roundedValue;
    }, 0);

    const result = Math.round((total) * 100) / 100; 
    return result;
  }


  const getRegistersOfTrialBalanceFile = async () => {
    const paeMovementFixedAssetsRepository = new PaeMovementFixedAssetsRepository()
    const result = await paeMovementFixedAssetsRepository.getPaeMovementFixedAssets(element.args.engagement_id, element.args.document_id, element.reference)

    setData(result.data)
    setDataBase(result.data)
    setTotalColumnJustificationDifferences(result.data.reduce((acc, item) => acc + (Number(item.justification_differences) || 0), 0))
    setTotalColumnAdditions(result.data.reduce((acc, item) => acc + (Number(item.additions) || 0), 0))
    setTotalColumnWithdrawals(result.data.reduce((acc, item) => acc + (Number(item.withdrawals) || 0), 0))
    setTotalColumnReclassifications(result.data.reduce((acc, item) => acc + (Number(item.reclassifications) || 0), 0))
    setTotalColumnDepreciation(result.data.reduce((acc, item) => acc + (Number(item.depreciation) || 0), 0))
    setTotalColumnImpairment(result.data.reduce((acc, item) => acc + (Number(item.impairment) || 0), 0))
    setTotalColumnValuation(result.data.reduce((acc, item) => acc + (Number(item.valuation) || 0), 0))
  }

  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  useEffect(() => {
    setChanges({})
    getRegistersOfTrialBalanceFile()
  }, [isRefresh, isRefreshLocal]);


  const handleEditModalAccept = async () => {
    const paeMovementFixedAssetsRepository = new PaeMovementFixedAssetsRepository()
    const result = await paeMovementFixedAssetsRepository.updatePaeMovementFixedAssets(element.args.engagement_id, element.args.document_id, element.reference, changes)
    setChanges({})
    if (result.success) {
      setIsRefreshLocal(prev => !prev);
      emitter.emit("refreshSubstantiveBalanceData");
      setShowConfigurationsModal(false);
    }
  };

  const handleEditModalCancel = () => {
    setShowConfigurationsModal(false)
  };

  const handleNumericInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedValue = event.target.value.replace(/[^0-9.-]/g, '');
    event.target.value = cleanedValue;
  };

  function handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
    const value = parseFloat(e.target.value.replace(/[^0-9.-]/g, ''));
    e.target.value = formatCurrency(value);
  }

  function handleFocus(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.value = e.target.value.replace(/[^0-9.-]/g, '');
  }

  return (
    <div>
      <ViewModeBase
        handleEdit={handleEdit}
        isEditable={true}
        permissions={element.permissions as Permissions}
      >
        <div className="d-flex w-full flex-column mb-3">
          <div className="d-flex justify-content-between mb-3">
            <h6>{T("PpyE movement")}</h6>
          </div>
          <table className="table-bordered" style={{ fontSize: "0.775rem;" }}>
            <thead>
              <tr>
                <th className="p-2">{T("Code")}</th>
                <th className="p-2">{T("Name")}</th>
                <th className="p-2">{T("Justification of differences")}</th>
                <th className="p-2">{T("Additions")}</th>
                <th className="p-2">{T("Withdrawals")}</th>
                <th className="p-2">{T("Reclassifications")}</th>
                <th className="p-2">{T("Depreciation")}</th>
                <th className="p-2">{T("Impairment")}</th>
                <th className="p-2">{T("Valuation")}</th>
              </tr>
            </thead>
            <tbody>
              {dataBase?.map((item, index) => (
                <tr key={index}>
                  <td className="p-1">{item.code}</td>
                  <td className="p-1">{item.description}</td>
                  <td className="p-1">
                    <span style={{ color: Number(calculateCurrentTotal(item)) == Number(item.justification_differences)? 'green': 'red'}}>
                    {formatCurrency(item.justification_differences)}
                    </span>
                    </td>
                  <td className="p-1">{formatCurrency(item.additions)}</td>
                  <td className="p-1">{formatCurrency(item.withdrawals)}</td>
                  <td className="p-1">{formatCurrency(item.reclassifications)}</td>
                  <td className="p-1">{formatCurrency(item.depreciation)}</td>
                  <td className="p-1">{formatCurrency(item.impairment)}</td>
                  <td className="p-1">{formatCurrency(item.valuation)}</td>
                </tr>
              ))}
              <tr>
                <td className="p-1"></td>
                <td className="p-1">{T("Total")}</td>
                <td className="p-1">{totalColumnJustificationDifferences?.toFixed(2)}</td>
                <td className="p-1">{totalColumnAdditions?.toFixed(2)}</td>
                <td className="p-1">{totalColumnWithdrawals?.toFixed(2)}</td>
                <td className="p-1">{totalColumnReclassifications?.toFixed(2)}</td>
                <td className="p-1">{totalColumnDepreciation?.toFixed(2)}</td>
                <td className="p-1">{totalColumnImpairment?.toFixed(2)}</td>
                <td className="p-1">{totalColumnValuation?.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Bank Reconciliation Review Worksheet")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <div>
          <table className="table-bordered">
            <thead>
              <tr>
                <th className="p-2">{T("Code")}</th>
                <th className="p-2">{T("Name")}</th>
                <th className="p-2">{T("Justification of differences")}</th>
                <th className="p-2">{T("Additions")}</th>
                <th className="p-2">{T("Withdrawals")}</th>
                <th className="p-2">{T("Reclassifications")}</th>
                <th className="p-2">{T("Depreciation")}</th>
                <th className="p-2">{T("Impairment")}</th>
                <th className="p-2">{T("Valuation")}</th>
              </tr>
            </thead>
            <tbody>
              {dataBase?.map((item, index) => (
                <tr key={index}>
                  <td className="p-1">{item.code}</td>
                  <td className="p-1">{item.description}</td>
                  <td className="p-1">{formatCurrency(item.justification_differences)}</td>
                  <td className="p-1"><input
                    className="form-control"
                    defaultValue={formatCurrency(item.additions)}
                    type="text"
                    inputMode="numeric"
                    onInput={handleNumericInput}
                    onChange={e => handleInputChange(item.id, 'additions', Number(e.target.value), item.code)}
                    onFocus={handleFocus}
                    onBlur={handleBlur} />
                  </td>
                  <td className="p-1"><input
                    className="form-control"
                    defaultValue={formatCurrency(item.withdrawals)}
                    type="text"
                    inputMode="numeric"
                    onInput={handleNumericInput}
                    onChange={e => handleInputChange(item.id, 'withdrawals', Number(e.target.value), item.code)}
                    onFocus={handleFocus}
                    onBlur={handleBlur} />
                  </td>
                  <td className="p-1"><input
                    className="form-control"
                    defaultValue={formatCurrency(item.reclassifications)}
                    type="text"
                    inputMode="numeric"
                    onInput={handleNumericInput}
                    onChange={e => handleInputChange(item.id, 'reclassifications', Number(e.target.value), item.code)}
                    onFocus={handleFocus}
                    onBlur={handleBlur} />
                  </td>
                  <td className="p-1"><input
                    className="form-control"
                    defaultValue={formatCurrency(item.depreciation)}
                    type="text"
                    inputMode="numeric"
                    onInput={handleNumericInput}
                    onChange={e => handleInputChange(item.id, 'depreciation', Number(e.target.value), item.code)}
                    onFocus={handleFocus}
                    onBlur={handleBlur} />
                  </td>
                  <td className="p-1"><input
                    className="form-control"
                    defaultValue={formatCurrency(item.impairment)}
                    type="text"
                    inputMode="numeric"
                    onInput={handleNumericInput}
                    onChange={e => handleInputChange(item.id, 'impairment', Number(e.target.value), item.code)}
                    onFocus={handleFocus}
                    onBlur={handleBlur} />
                  </td>
                  <td className="p-1"><input
                    className="form-control"
                    defaultValue={formatCurrency(item.valuation)}
                    type="text"
                    inputMode="numeric"
                    onInput={handleNumericInput}
                    onChange={e => handleInputChange(item.id, 'valuation', Number(e.target.value), item.code)}
                    onFocus={handleFocus}
                    onBlur={handleBlur} />
                  </td>
                </tr>
              ))}
              <tr>
                <td className="p-1"></td>
                <td className="p-1">{T("Total")}</td>
                <td className="p-1">{totalColumnJustificationDifferences?.toFixed(2)}</td>
                <td className="p-1">{totalColumnAdditions?.toFixed(2)}</td>
                <td className="p-1">{totalColumnWithdrawals?.toFixed(2)}</td>
                <td className="p-1">{totalColumnReclassifications?.toFixed(2)}</td>
                <td className="p-1">{totalColumnDepreciation?.toFixed(2)}</td>
                <td className="p-1">{totalColumnImpairment?.toFixed(2)}</td>
                <td className="p-1">{totalColumnValuation?.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default PaeMovementFixedAssetsViewMode;
