import { QuestionTextFileArgs } from './ElementArgs'
import { ElementBase, IElementBase } from './ElementBase'

export class QuestionTextFileElement extends ElementBase implements IElementBase {
  private _question: string
  private _answer: string
  private _attachment: string

  constructor(args: QuestionTextFileArgs) {
    super(args)
    this._question = args.question || ""
    this._answer = args.answer || ""
    this._attachment = args.attachment || ""
  }

  get question(): string {
    return this._question
  }

  get answer(): string {
    return this._answer
  }

  get attachment(): string {
    return this._attachment
  }

  get args(): QuestionTextFileArgs {
    return {
      ...super.args,
      question: this._question,
      answer: this._answer,
      attachment: this._attachment
    }
  }

  set question(question: string) {
    this._question = question
  }

  set answer(answer: string) {
    this._answer = answer
  }

  set attachment(attachment: string) {
    this._attachment = attachment
  }

  render(): void {}
}