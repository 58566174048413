import React, { useState, useEffect, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { type Permissions } from "../../models/special-document/ElementBase"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { DeterminationUseExpertWorkElement } from "../../models/special-document/DeterminationUseExpertWorkElement"
import ViewModeProps from './ViewModeProps'
import ViewModeBase from "./ViewModeBase"
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { DeterminationUseExpertWorkItem } from '../../models/special-document/ElementArgs'
import { emitter } from '../utils/EventEmitter';


export interface LedgerAccountType {
  id: number;
  code: number;
  name: string;
  value?: number;
}


/**
* React component for handling the determination use expert work view mode.
* @param {ViewModeProps} props - The props for the view mode.
*/
const DeterminationUseExpertWorkViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<DeterminationUseExpertWorkElement>(iElement as DeterminationUseExpertWorkElement).current
  const [registersBase, setRegistersBase] = useState<DeterminationUseExpertWorkItem[]>(element.options)
  const [registers, setRegisters] = useState<DeterminationUseExpertWorkItem[]>(element.options)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [materiality, setMateriality] = useState<number | null>(null)
  const [accounts, setAccounts] = useState<LedgerAccountType[]>([])

  const answersYesNo = [
    { value: 1, text: "Yes" },
    { value: 2, text: "No" },
  ]

  /**
  * Retrieves account data for the current engagement and document.
  * @returns {Promise<void>} Updates state with fetched accounts.
  */
  const getAccounts = async () => {
    const elementRepository = new ElementRepository()
    const result = await elementRepository.getRegistersTrialBalance(element.args.engagement_id, element.documentId, element.id)
    if (result.success) {
      setAccounts(result.data)
    } else {
      setAccounts([])
    }
  }

  /**
   * Gets the materiality for the engagement.
   */
  const getMateriality = async () => {
    const elementRepository = new ElementRepository()
    const resultMateriality = await elementRepository.getEngagementMaterialityReport(element.args.engagement_id)
    if (resultMateriality.success) {
      setMateriality(resultMateriality.materiality)
    }
  }

  useEffect(() => {
    getAccounts()
    getMateriality()
  }, [])

  /**
   * Opens the configurations modal.
   */
  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  /**
   * Handles the accept action of the configurations modal.
   */
  async function handleEditModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastOptions = JSON.parse(JSON.stringify(element.options))
    element.options = JSON.parse(JSON.stringify(registers))

    let success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      element.options = lastOptions
      setRegisters(JSON.parse(JSON.stringify(lastOptions)))
      window.htmlHelpers?.swalError()
    } else {
      emitter.emit("refreshUseExpertWork");
    }
  }

  /**
   * Handles the cancel action of the configurations modal.
   */
  function handleEditModalCancel() {
    setShowConfigurationsModal(false)
  }

  /**
   * Handles the selection of the first answer.
   * @param index - The index of the register.
   * @param value - The value of the answer.
   */
  const handleFirstAnswerSelected = (index: number, value: number) => {
    const newOptions = [...registers]
    newOptions[index].first_answer_yes_no = value
    if (value === 2) {
      newOptions[index].ledger_account = null
      newOptions[index].ledger_account_name = null
      newOptions[index].ledger_account_value = null
      newOptions[index].is_materiality = null
      newOptions[index].materiality_answer_yes_no = null
      newOptions[index].is_yes_materiality_answer = null
    }
    setRegisters(newOptions)
  }

  /**
   * Handles the selection of the ledger account.
   * @param index - The index of the register.
   * @param selectedCode - The code of the selected account.
   */
  const handleAccountChange = (index: number, selectedCode: number) => {
    const selectedAccount = accounts.find((acc) => Number(acc.code) === Number(selectedCode));

    if (!selectedAccount) return;

    const newOptions = [...registers];
    newOptions[index].ledger_account = selectedAccount.code;
    newOptions[index].ledger_account_name = selectedAccount.name;
    newOptions[index].ledger_account_value = selectedAccount.value ?? 0;

    if (materiality && selectedAccount.value && selectedAccount.value >= materiality) {
      newOptions[index].is_materiality = true;
    } else if (materiality && selectedAccount.value && selectedAccount.value < materiality) {
      newOptions[index].is_materiality = false;
    }

    setRegisters(newOptions)
  }

  /**
   * Handles the selection of the materiality answer.
   * @param index - The index of the register.
   * @param value - The value of the answer.
   */
  const handleMaterialityAnswerSelected = (index: number, value: number) => {
    const newOptions = [...registers]
    newOptions[index].materiality_answer_yes_no = value
    if (value === 2) {
      newOptions[index].is_yes_materiality_answer = null
    }
    setRegisters(newOptions)
  }

  /**
   * Handles the selection of the yes materiality answer.
   * @param index - The index of the register.
   * @param value - The value of the answer.
   */
  const handleYesMaterialityAnswer = (index: number, value: string) => {
    const newOptions = [...registers]
    newOptions[index].is_yes_materiality_answer = value
    setRegisters(newOptions)
  }

  return (
    <>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        <div className="container">
          {registersBase.map((item, index) => (
            <div key={index} className="table-responsive mb-4">
              <table className="table-bordered table-sm w-100">
                <thead>
                  <tr>
                    <th colSpan={2} className="text-center">
                      {item.title}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "70%", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                      {item.first_question}
                    </td>
                    <td style={{ width: "30%", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                      {item.first_answer_yes_no === 1 ? T("Yes") : item.first_answer_yes_no === 2 ? T("No") : T("No response")}
                    </td>
                  </tr>
                  {item.first_answer_yes_no === 1 && (
                    <>
                      <tr>
                        <td style={{ width: "70%", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                          {item.second_question}
                        </td>
                        <td style={{ width: "30%", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                          {item.ledger_account || T("No response")}
                        </td>
                      </tr>
                      {item.is_materiality === true ? (
                        <>
                          <tr>
                            <td colSpan={2} style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                              {item.conclusion}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: "70%", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                              {item.materiality_question}
                            </td>
                            <td style={{ width: "30%", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                              {item.materiality_answer_yes_no === 1 ? T("Yes") : item.materiality_answer_yes_no === 2 ? T("No") : T("No response")}
                            </td>
                          </tr>
                          {item.materiality_answer_yes_no && item.materiality_answer_yes_no === 1 && (
                            <tr>
                              <td style={{ width: "70%", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                                {item.is_yes_materiality_question}
                              </td>
                              <td style={{ width: "30%", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                                {item.is_yes_materiality_answer || T("No response")}
                              </td>
                            </tr>
                          )}
                        </>
                      ) : item.is_materiality === false ? (
                        <tr>
                          <td colSpan={2} style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                            {item.not_materiality_conclusion}
                          </td>
                        </tr>
                      ) : null}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Configuration Determination Use of Expert Work")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <div className="container">
          {registers.map((item, index) => (
            <div key={index} className="table-responsive mb-4">
              <table className="table-bordered table-sm w-100">
                <thead>
                  <tr>
                    <th colSpan={2} className="text-center">
                      {item.title}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "70%", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                      {item.first_question}
                    </td>
                    <td style={{ width: "30%", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                      <label>{T("Select Answer")}</label>
                      <select className="form-select" value={item.first_answer_yes_no || ""} onChange={(e) => handleFirstAnswerSelected(index, Number(e.target.value))}>
                        <option value="" disabled>{T("Select an option")}</option>
                        {answersYesNo.map((answer) => {
                          return (
                            <option key={answer.value} value={answer.value}>{T(answer.text)}</option>
                          )
                        })}
                      </select>
                    </td>
                  </tr>
                  {item.first_answer_yes_no === 1 && (
                    <>
                      <tr>
                        {materiality ? (
                          <>
                            <td style={{ width: "70%", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                              {item.second_question}
                            </td>
                            <td style={{ width: "30%", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                              <select
                                id="dropdown"
                                className="form-select"
                                value={item.ledger_account || ""}
                                onChange={(e) => {
                                  const selectedCode = Number(e.target.value);
                                  handleAccountChange(index, selectedCode);
                                }}
                              >
                                <option value="" disabled>
                                  {T("Select an option")}
                                </option>
                                {accounts &&
                                  accounts.map((itemAccounts) => (
                                    <option key={`account-${itemAccounts.code}`} value={itemAccounts.code}>
                                      {itemAccounts.code} - {itemAccounts.name}
                                    </option>
                                  ))}
                              </select>
                            </td>
                          </>
                        ) : (
                          <td colSpan={2} style={{ padding: "10px 0", textAlign: "center" }}>
                            <span style={{ wordWrap: "break-word", whiteSpace: "pre-wrap", color: "red" }}>
                              {T("Materiality information is missing. Please enter it and try again.")}
                            </span>
                          </td>
                        )}
                      </tr>
                      {item.is_materiality === true ? (
                        <>
                          <tr>
                            <td colSpan={2} style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                              {item.conclusion}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: "70%", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                              {item.materiality_question}
                            </td>
                            <td style={{ width: "30%", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                              <label>{T("Select Answer")}</label>
                              <select className="form-select" value={item.materiality_answer_yes_no || ""} onChange={(e) => handleMaterialityAnswerSelected(index, Number(e.target.value))}>
                                <option value="" disabled>{T("Select an option")}</option>
                                {answersYesNo.map((answer) => {
                                  return (
                                    <option key={answer.value} value={answer.value}>{T(answer.text)}</option>
                                  )
                                })}
                              </select>
                            </td>
                          </tr>
                          {item.materiality_answer_yes_no && item.materiality_answer_yes_no === 1 && (
                            <tr>
                              <td style={{ width: "70%", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                                {item.is_yes_materiality_question}
                              </td>
                              <td style={{ width: "30%", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                                <textarea
                                  className="form-control"
                                  defaultValue={item.is_yes_materiality_answer || ""}
                                  onChange={(e) => handleYesMaterialityAnswer(index, e.target.value)}
                                  required
                                />
                              </td>
                            </tr>
                          )}
                        </>
                      ) : item.is_materiality === false ? (
                        <tr>
                          <td colSpan={2} style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                            {item.not_materiality_conclusion}
                          </td>
                        </tr>
                      ) : null}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </ModalWithButtons>
    </>
  );
};

export default DeterminationUseExpertWorkViewMode;
