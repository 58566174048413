import React, { useEffect, useState } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from '../special-document/ViewModeProps';
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import ViewModeBase from "../special-document/ViewModeBase";
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { formatCurrency } from '../../utils/utilities';
import { CSSProperties } from "react";
import { CustomerWithdrawalInformationFixedAssetsRepository, RegistersWithdrawalCustomerInformationFixedAssets } from '../../repository/substantive-tests/CustomerWithdrawalInformationAssetsRepository';
import DatePicker from '../commons/Flatpickr';
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';


export interface AccountData {
  id: number;
  consignment_number: number;
  type_account: string;
  account: string;
  name: string;
  amount: number;
  amount_in_other_accounts: number;
  amount_per_statement: number;
  difference_amount: number;
}


/**
 * Renders the view mode for the Customer Withdrawal Information Assets element.
 * @param {ViewModeProps} { iElement } - The destructured properties, including iElement.
 */
const CustomerWithdrawalInformationAssetsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isRefresh, setIsRefresh] = useState(false);
  const [isRefreshLocal, setIsRefreshLocal] = useState(false);
  const [addNewRegisters, setAddNewRegisters] = useState<Record<number, Array<RegistersWithdrawalCustomerInformationFixedAssets>>>({});
  const [newRegisters, setNewRegisters] = useState<Record<number, Array<RegistersWithdrawalCustomerInformationFixedAssets>>>({});
  const [registersBase, setRegistersBase] = useState<RegistersWithdrawalCustomerInformationFixedAssets[]>();
  const [registers, setRegisters] = useState<RegistersWithdrawalCustomerInformationFixedAssets[]>([]);
  const [changedRegisters, setChangedRegisters] = useState<Record<number, Partial<RegistersWithdrawalCustomerInformationFixedAssets>>>({});
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState<Date | undefined>(undefined);

  useEffect(() => {
    const handleEvent = () => {
      setIsRefresh(prev => !prev);
    };
    emitter.on("refreshSubstantiveBalanceData", handleEvent);
    return () => {
      emitter.off("refreshSubstantiveBalanceData", handleEvent);
    };
  }, []);

  /**
  * Retrieves the registers for the trial balance file and updates the component state accordingly.
  */
  const getRegistersOfTrialBalanceFile = async () => {
    const customerWithdrawalInformationFixedAssetsRepository = new CustomerWithdrawalInformationFixedAssetsRepository()
    const result = await customerWithdrawalInformationFixedAssetsRepository.getWithdrawalCustomerInformationFixedAsset(element.args.engagement_id, element.args.document_id, element.reference)
    if (result.success) {
      setRegisters(result.data)
      setRegistersBase(result.data)
    }
  }

  /**
   * Updates the registers of the trial balance file with the new values.
   * @param itemId - The id of the register to update.
   */
  const handleRemoveOldRegister = async (itemId: number | undefined) => {
    const customerWithdrawalInformationFixedAssetsRepository = new CustomerWithdrawalInformationFixedAssetsRepository()
    const result = await customerWithdrawalInformationFixedAssetsRepository.deleteWithdrawalCustomerInformationFixedAsset(element.args.engagement_id, element.args.document_id, element.reference, itemId)
    if (result.success) {
      setIsRefreshLocal(prev => !prev);
      emitter.emit("refreshDifferenceReconcilingItems");
    }
  }

  /**
   * Adds a new register to the trial balance file.
   */
  const addRegister = () => {
    const newId = Date.now();
    const newRegister = {
      id: newId,
      withdrawal_description: "",
      activation_date: undefined,
      deactivation_date: undefined,
      useful_life: 0,
      withdrawal_cost: 0,
      depreciation: 0,
      net_balance: 0,
      act_date_sale: undefined,
      description: "",
      profit_loss_according_accounting_record: 0,
      asset_sale_value_according_invoice: 0,
    };
    setNewRegisters(prev => ({
      ...prev,
      [newId]: [...(prev[newId] || []), newRegister]
    }));

  };

  /**
   * Removes a new register from the trial balance file.
   * @param itemId - The id of the register to remove.
   */
  const handleRemoveNewRegister = (itemId: number | undefined) => {
    setNewRegisters(prev => {
      const updatedRegisters = { ...prev };
      if (updatedRegisters[Number(itemId)]) {
        updatedRegisters[Number(itemId)] = updatedRegisters[Number(itemId)].filter(item => item.id !== itemId);
        if (updatedRegisters[Number(itemId)].length === 0) {
          delete updatedRegisters[Number(itemId)];
        }
      }
      return updatedRegisters;
    });
    setAddNewRegisters(prev => {
      const updated = { ...prev };
      delete updated[Number(itemId)];
      return updated;
    });
  };

  /**
   * Handles input changes for registers withdrawal customer information fixed assets.
   * @param itemId - Unique identifier for the item.
   * @param field - The field of the register to be updated.
   * @param value - The new value to assign to the field.
   */
  const handleNewInputChange = (itemId: number , field: keyof RegistersWithdrawalCustomerInformationFixedAssets, value: number | string | undefined | null) => {
    setNewRegisters((prev: Record<number, Array<RegistersWithdrawalCustomerInformationFixedAssets>>) => {
      const updatedItems = [...(prev[itemId] || [])];
      const itemIndex = updatedItems.findIndex(item => item.id === itemId);
      if (itemIndex !== -1) {
        updatedItems[itemIndex] = {
          ...updatedItems[itemIndex],
          [field]: value
        };
      } else {
        updatedItems.push({ id: itemId, [field]: value } as RegistersWithdrawalCustomerInformationFixedAssets);
      }

      return {
        ...prev,
        [itemId]: updatedItems
      };
    });

    setAddNewRegisters((prev: Record<string, Array<RegistersWithdrawalCustomerInformationFixedAssets>>) => ({
      ...prev,
      [itemId]: {
        ...prev[itemId],
        [field]: value
      }
    }));
  };

  /**
   * Handles the edit action for the trial balance file.
   */
  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  useEffect(() => {
    getRegistersOfTrialBalanceFile()
  }, [isRefresh, isRefreshLocal]);


  /**
   * Handles the accept action for the edit modal.
   */
  const handleEditModalAccept = async () => {
    if (loading) return;
    setLoading(true);
    const customerInformationAssetsRepository = new CustomerWithdrawalInformationFixedAssetsRepository()
    const result = await customerInformationAssetsRepository.updateRegistersWithdrawalCustomerInformation(element.args.engagement_id, element.args.document_id, element.reference, addNewRegisters, changedRegisters)
    if (result.success) {
      setShowConfigurationsModal(false);
      setIsRefreshLocal(prev => !prev);
      setChangedRegisters({});
      setAddNewRegisters({});
      setNewRegisters({});
      setRegisters([]);
    }
    setLoading(false);
  };

  /**
   * Handles the cancel action for the edit modal.
   */
  const handleEditModalCancel = () => {
    setShowConfigurationsModal(false)
    setChangedRegisters({});
    setAddNewRegisters({});
    setNewRegisters({});
    setRegisters(registersBase || []);
  };

  /**
   * Handles numeric input changes.
   * @param event - The change event.
   */
  const handleNumericInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let cleanedValue = event.target.value.replace(/[^0-9.-]/g, '');
    const parts = cleanedValue.split(".");
    if (parts.length > 1 && parts[1].length > 2) {
      parts[1] = parts[1].substring(0, 2);
      cleanedValue = parts.join(".");
    }

    event.target.value = cleanedValue;
  };

  /**
   * Handles input changes for registers.
   * @param itemId - Unique identifier for the item.
   * @param field - The field of the register to be updated.
   * @param value - The new value to assign to the field.
   */
  const handleInputChange = (itemId: string | number | symbol | any, field: keyof RegistersWithdrawalCustomerInformationFixedAssets, value: number | string | Date | undefined | null) => {
    setRegisters(prev =>
      prev.map(item =>
        item.id === itemId
          ? { ...item, [field]: value }
          : item
      )
    );

    setChangedRegisters(prev => ({
      ...prev,
      [itemId]: {
        ...prev[itemId],
        [field]: value,
      },
    }));
  };

  /**
   * Formats the currency value.
   * @param value - The value to format.
   */
  function handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
    const value = parseFloat(e.target.value.replace(/[^0-9.-]/g, ''));
    e.target.value = formatCurrency(value);
  }

  /**
   * Handles the focus event for input fields.
   * @param e - The focus event.
   */
  function handleFocus(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.value = e.target.value.replace(/[^0-9.-]/g, '');
  }

  /**
   * Handles date changes for registers.
   * @param itemId - Unique identifier for the item.
   * @param valueItem - The field of the register to be updated.
   * @param selectedDates - The selected dates.
   */
  const handleDateChange = (itemId: number | undefined, valueItem: string, selectedDates: Date[]) => {
    if (itemId === undefined) return;
    if (selectedDates.length > 0) {
      const formattedDate = selectedDates[0].toISOString().split("T")[0];
      setChangedRegisters(prev => ({
        ...prev,
        [itemId]: {
          ...prev[itemId],
          [valueItem]: formattedDate,
        },
      }));
    } else {
      setDate(undefined);
    }
  };

  /**
   * Handles new date changes for registers.
   * @param itemId - Unique identifier for the item.
   * @param valueItem - The field of the register to be updated.
   * @param selectedDates - The selected dates.
   */
  const handleNewDateChange = (itemId: string | number | symbol | any, valueItem: string, selectedDates: Date[]) => {
    if (selectedDates.length > 0) {
      const formattedDate = selectedDates[0].toISOString().split("T")[0];
      setNewRegisters((prev: Record<number, Array<RegistersWithdrawalCustomerInformationFixedAssets>>) => {
        const updatedItems = [...(prev[itemId] || [])];

        const itemIndex = updatedItems.findIndex(item => item.id === itemId);

        if (itemIndex !== -1) {
          updatedItems[itemIndex] = {
            ...updatedItems[itemIndex],
            [valueItem]: formattedDate
          };
        } else {
          updatedItems.push({ id: itemId, [valueItem]: formattedDate } as RegistersWithdrawalCustomerInformationFixedAssets);
        }

        return {
          ...prev,
          [itemId]: updatedItems
        };
      });

      setAddNewRegisters((prev: Record<string, Array<RegistersWithdrawalCustomerInformationFixedAssets>>) => ({
        ...prev,
        [itemId]: {
          ...prev[itemId],
          [valueItem]: formattedDate
        }
      }));
    } else {
      setDate(undefined);
    }
  };

  /**
   * Calculates the first difference.
   * @param profit_loss_according_accounting_record - Profit or loss according to accounting record.
   * @param net_balance - Net balance.
   */
  const calculateFirstDifference = (profit_loss_according_accounting_record: number | undefined, net_balance: number | undefined) => {
    if (profit_loss_according_accounting_record !== undefined && net_balance !== undefined) {
      return parseFloat((profit_loss_according_accounting_record - net_balance).toFixed(2));
    }
    return 0;
  }

  /**
   * Calculates the profit or loss difference.
   * @param asset_sale_value_according_invoice - Asset sale value according to invoice.
   * @param net_balance - Net balance.
   */
  const calculateProfitLossDifference = (asset_sale_value_according_invoice: number | undefined, net_balance: number | undefined) => {
    if (asset_sale_value_according_invoice !== undefined && net_balance !== undefined) {
      return parseFloat((asset_sale_value_according_invoice - net_balance).toFixed(2));
    }
    return 0;
  }

  /**
   * Calculates the second difference.
   * @param asset_sale_value_according_invoice - Asset sale value according to invoice.
   * @param net_balance - Net balance.
   * @param profit_loss_according_accounting_record - Profit or loss according to accounting record.
   */
  const calculateSecondDifference = (asset_sale_value_according_invoice: number | undefined, net_balance: number | undefined, profit_loss_according_accounting_record: number | undefined) => {
    if (asset_sale_value_according_invoice != undefined && net_balance != undefined && profit_loss_according_accounting_record != undefined) {
      return parseFloat((profit_loss_according_accounting_record - (asset_sale_value_according_invoice - net_balance)).toFixed(2));
    }
    return 0;
  }

  const styleHeader: CSSProperties = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <div>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        <div className="d-flex w-full flex-column mb-3 card">
          <div className="card-body">
            <h6>{T("Withdrawal")}</h6>
            <div style={{ overflowX: "auto" }}>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  minWidth: "2000px",
                  tableLayout: "fixed"
                }}
                className="table table-bordered table-hover"
              >
                <thead>
                  <tr>
                    <th style={{ width: "14%", ...styleHeader }} className="p-2">{T("Fixed Assets")}</th>
                    <th style={{ width: "14%", ...styleHeader }} className="p-2">{T("Description")}</th>
                    <th style={{ width: "11%", ...styleHeader }} className="p-2">{T("Date of acquisition")}</th>
                    <th style={{ width: "11%", ...styleHeader }} className="p-2">{T("Date terminated")}</th>
                    <th style={{ width: "10%", ...styleHeader }} className="p-2">{T("useful life months")}</th>
                    <th style={{ width: "15%", ...styleHeader }} className="p-2">{T("Total cost NIIF")}</th>
                    <th style={{ width: "15%", ...styleHeader }} className="p-2">{T("Accumulated Depreciation")}</th>
                    <th style={{ width: "15%", ...styleHeader }} className="p-2">{T("Net cost cut-off")}</th>
                    <th style={{ width: "14%", ...styleHeader }} className="p-2">{T("Invoice number")}</th>
                    <th style={{ width: "14%", ...styleHeader }} className="p-2">{T("Date")}</th>
                    <th style={{ width: "15%", ...styleHeader }} className="p-2">{T("Description")}</th>
                    <th style={{ width: "15%", ...styleHeader }} className="p-2">{T("Cost")}</th>
                    <th style={{ width: "14%", ...styleHeader }} className="p-2">{T("VAT")}</th>
                    <th style={{ width: "15%", ...styleHeader }} className="p-2">{T("Total cost")}</th>
                    <th style={{ width: "15%", ...styleHeader }} className="p-2">{T("Difference")}</th>
                  </tr>
                </thead>
                <tbody>
                  {registers.map((item) => (
                    <>
                      <tr key={item.id}>
                        <td className="p-1" style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxHeight: "42.6px",
                        }}
                          title={item.withdrawal_reference}>{item.withdrawal_reference}</td>
                        <td className="p-1" style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxHeight: "42.6px",
                        }}
                          title={item.withdrawal_description}>{item.withdrawal_description}</td>
                        <td className="p-1" style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxHeight: "42.6px",
                        }}
                          title={item.activation_date ? new Date(item.activation_date).toLocaleDateString() : ""}>{item.activation_date ? new Date(item.activation_date).toLocaleDateString() : ""}</td>
                        <td className="p-1" style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxHeight: "42.6px",
                        }}
                          title={item.deactivation_date ? new Date(item.deactivation_date).toLocaleDateString() : ""}>{item.deactivation_date ? new Date(item.deactivation_date).toLocaleDateString() : ""}</td>
                        <td className="p-1" style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxHeight: "42.6px",
                        }}
                          title={item.useful_life?.toString()}>{item.useful_life}</td>
                        <td className="p-1" style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxHeight: "42.6px",
                        }}
                          title={item.withdrawal_cost?.toString()}>{item.withdrawal_cost}</td>
                        <td className="p-1" style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxHeight: "42.6px",
                        }}
                          title={item.depreciation?.toString()}>{item.depreciation}</td>
                        <td className="p-1" style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxHeight: "42.6px",
                        }}
                          title={item.net_balance?.toString()}>{item.net_balance}</td>
                        <td className="p-1" style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxHeight: "42.6px",
                        }}
                          title={item.act_date_sale ? new Date(item.act_date_sale).toLocaleDateString() : ""}>{item.act_date_sale ? new Date(item.act_date_sale).toLocaleDateString() : ""}</td>
                        <td className="p-1" style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxHeight: "42.6px",
                        }}
                          title={item.description?.toString()}>{item.description}</td>
                        <td className="p-1" style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxHeight: "42.6px",
                        }}
                          title={item.profit_loss_according_accounting_record?.toString()}>{item.profit_loss_according_accounting_record}</td>
                        <td className="p-1" style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxHeight: "42.6px",
                        }}
                          title={item.difference_net_balance?.toString()}>{calculateFirstDifference(item.profit_loss_according_accounting_record, item.net_balance)}</td>
                        <td className="p-1" style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxHeight: "42.6px",
                        }}
                          title={item.asset_sale_value_according_invoice?.toString()}>{item.asset_sale_value_according_invoice}</td>
                        <td className="p-1" style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxHeight: "42.6px",
                        }}
                          title={item.profit_loss?.toString()}>{calculateProfitLossDifference(item.asset_sale_value_according_invoice, item.net_balance)}</td>
                        <td className="p-1" style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxHeight: "42.6px",
                        }}
                          title={item.difference_profit_loss?.toString()}>{calculateSecondDifference(item.asset_sale_value_according_invoice, item.net_balance, item.profit_loss_according_accounting_record)}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Configuration Withdrawals")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <div className="d-flex w-full flex-column mb-3 card">
          <div className="card-body">
            <h6>{T("Withdrawal")}</h6>
            <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  minWidth: "2000px",
                  tableLayout: "fixed"
                }}
                className="table table-bordered table-hover"
              >
                <thead>
                  <tr>
                    <th style={{ width: "14%", ...styleHeader }} title={T("Fixed Assets")} className="p-2">{T("Fixed Assets")}</th>
                    <th style={{ width: "14%", ...styleHeader }} title={T("Description")} className="p-2">{T("Description")}</th>
                    <th style={{ width: "9%", ...styleHeader }} title={T("Date of acquisition")} className="p-2">{T("Date of acquisition")}</th>
                    <th style={{ width: "11%", ...styleHeader }} title={T("Date terminated")}>{T("Date terminated")}</th>
                    <th style={{ width: "9%", ...styleHeader }} title={T("Useful life months")} className="p-2">{T("Useful life months")}</th>
                    <th style={{ width: "15%", ...styleHeader }} title={T("Total cost NIIF")} className="p-2">{T("Total cost NIIF")}</th>
                    <th style={{ width: "15%", ...styleHeader }} title={T("Accumulated Depreciation")} className="p-2">{T("Accumulated Depreciation")}</th>
                    <th style={{ width: "16%", ...styleHeader }} title={T("Net cost cut-off")} className="p-2">{T("Net cost cut-off")}</th>
                    <th style={{ width: "14%", ...styleHeader }} title={T("Invoice number")} className="p-2">{T("Invoice number")}</th>
                    <th style={{ width: "14%", ...styleHeader }} title={T("Date")} className="p-2">{T("Date")}</th>
                    <th style={{ width: "16%", ...styleHeader }} title={T("Description")} className="p-2">{T("Description")}</th>
                    <th style={{ width: "16%", ...styleHeader }} title={T("Cost")} className="p-2">{T("Cost")}</th>
                    <th style={{ width: "14%", ...styleHeader }} title={T("VAT")} className="p-2">{T("VAT")}</th>
                    <th style={{ width: "15%", ...styleHeader }} title={T("Total cost")} className="p-2">{T("Total cost")}</th>
                    <th style={{ width: "15%", ...styleHeader }} title={T("Difference")} className="p-2">{T("Difference")}</th>
                  </tr>
                </thead>
                <tbody>

                  {Object.values(registers).map(item => (
                    <tr key={item.id}>
                      <td className="p-1" style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxHeight: "42.6px",
                      }}
                        title={item.withdrawal_reference}>
                        <textarea
                          className="form-control"
                          defaultValue={item.withdrawal_reference || ""}
                          onChange={(e) => handleInputChange(item.id, "withdrawal_reference", e.target.value)}
                          required
                        /></td>
                      <td className="p-1" style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxHeight: "42.6px",
                      }}
                        title={item.withdrawal_description}>
                        <textarea
                          className="form-control"
                          defaultValue={item.withdrawal_description || ""}
                          onChange={(e) => handleInputChange(item.id, "withdrawal_description", e.target.value)}
                          required
                        /></td>
                      <td className="p-1" style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxHeight: "42.6px",
                      }}
                        title={item.activation_date ? new Date(item.activation_date).toLocaleDateString() : ""}>
                        <DatePicker
                          id="date-picker"
                          value={item.activation_date ? new Date(item.activation_date) : ""}
                          onChange={(selectedDates) => handleDateChange(item.id, "activation_date", selectedDates)}
                          dateFormat="Y-m-d"
                          className="form-control"
                        /></td>
                      <td className="p-1" style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxHeight: "42.6px",
                      }}
                        title={item.deactivation_date ? new Date(item.deactivation_date).toLocaleDateString() : ""}>
                        <DatePicker
                          id="date-picker"
                          value={item.deactivation_date ? new Date(item.deactivation_date) : ""}
                          onChange={(selectedDates) => handleDateChange(item.id, "deactivation_date", selectedDates)}
                          dateFormat="Y-m-d"
                          className="form-control"
                        />
                      </td>
                      <td className="p-1" style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxHeight: "42.6px",
                      }}
                        title={item.useful_life?.toString()}>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue={item.useful_life}
                          onInput={handleNumericInput}
                          onChange={e => handleInputChange(item.id, 'useful_life', e.target.value)}
                        />
                      </td>
                      <td className="p-1" style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxHeight: "42.6px",
                      }}
                        title={item.withdrawal_cost?.toString()}><input
                          className="form-control"
                          type="text"
                          defaultValue={formatCurrency(item.withdrawal_cost)}
                          onInput={handleNumericInput}
                          onChange={e => handleInputChange(item.id, 'withdrawal_cost', e.target.value)}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        /></td>
                      <td className="p-1" style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxHeight: "42.6px",
                      }}
                        title={item.depreciation?.toString()}><input
                          className="form-control"
                          type="text"
                          defaultValue={formatCurrency(item.depreciation)}
                          onInput={handleNumericInput}
                          onChange={e => handleInputChange(item.id, 'depreciation', e.target.value)}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        /></td>
                      <td className="p-1" style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxHeight: "42.6px",
                      }}
                        title={item.net_balance?.toString()}>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue={formatCurrency(item.net_balance)}
                          onInput={handleNumericInput}
                          onChange={e => handleInputChange(item.id, 'net_balance', e.target.value)}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        />
                      </td>
                      <td className="p-1" style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxHeight: "42.6px",
                      }}
                        title={item.act_date_sale ? new Date(item.act_date_sale).toLocaleDateString() : ""}>
                        <DatePicker
                          id="date-picker"
                          value={item.act_date_sale ? new Date(item.act_date_sale) : ""}
                          onChange={(selectedDates) => handleDateChange(item.id, "act_date_sale", selectedDates)}
                          dateFormat="Y-m-d"
                          className="form-control"
                        /></td>
                      <td className="p-1" style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxHeight: "42.6px",
                      }}
                        title={item.description?.toString()}>
                        <textarea
                          className="form-control"
                          defaultValue={item.description || ""}
                          onChange={(e) => handleInputChange(item.id, "description", e.target.value)}
                          required
                        />
                      </td>
                      <td className="p-1" style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxHeight: "42.6px",
                      }}
                        title={item.profit_loss_according_accounting_record?.toString()}><input
                          className="form-control"
                          type="text"
                          defaultValue={formatCurrency(item.profit_loss_according_accounting_record)}
                          onInput={handleNumericInput}
                          onChange={e => handleInputChange(item.id, 'profit_loss_according_accounting_record', e.target.value)}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        /></td>
                      <td className="p-1" style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxHeight: "42.6px",
                      }}
                        title={item.difference_net_balance?.toString()}>{calculateFirstDifference(item.profit_loss_according_accounting_record, item.net_balance)}</td>
                      <td className="p-1" style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxHeight: "42.6px",
                      }}
                        title={item.asset_sale_value_according_invoice?.toString()}>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue={formatCurrency(item.asset_sale_value_according_invoice)}
                          onInput={handleNumericInput}
                          onChange={e => handleInputChange(item.id, 'asset_sale_value_according_invoice', e.target.value)}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        />
                      </td>
                      <td className="p-1" style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxHeight: "42.6px",
                      }}
                        title={item.profit_loss?.toString()}>{calculateProfitLossDifference(item.asset_sale_value_according_invoice, item.net_balance)}</td>
                      <td className="p-1" style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxHeight: "42.6px",
                      }}
                        title={item.difference_profit_loss?.toString()}>{calculateSecondDifference(item.asset_sale_value_according_invoice, item.net_balance, item.profit_loss_according_accounting_record)}</td>
                      <td className="p-1">
                        <button className="btn btn-icon btn-primary element-card-buttons" onClick={() => handleRemoveOldRegister(item.id)}>
                          <span className="btn-inner--icon"><i className="fa fa-trash py-2" aria-hidden="true"></i></span></button>
                      </td>
                    </tr>
                  ))}
                  {Object.entries(newRegisters).map(([account, items]) => (
                    <React.Fragment key={account}>
                      {items.map((item) => (
                        <tr key={item.id}>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.withdrawal_reference}>
                            <textarea
                              className="form-control"
                              defaultValue={item.withdrawal_reference || ""}
                              onChange={(e) => handleInputChange(item.id, "withdrawal_reference", e.target.value)}
                              required
                            /></td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.withdrawal_description}>
                            <textarea
                              className="form-control"
                              defaultValue={item.withdrawal_description || ""}
                              onChange={(e) => handleNewInputChange(Number(item.id), "withdrawal_description", e.target.value)}
                              required
                            /></td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.activation_date ? new Date(item.activation_date).toLocaleDateString() : ""}>
                            <DatePicker
                              id="date-picker"
                              value={item.activation_date ? new Date(item.activation_date) : ""}
                              onChange={(selectedDates) => handleNewDateChange(item.id, "activation_date", selectedDates)}
                              dateFormat="Y-m-d"
                              className="form-control"
                            />
                          </td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.deactivation_date ? new Date(item.deactivation_date).toLocaleDateString() : ""}>
                            <DatePicker
                              id="date-picker"
                              value={item.deactivation_date ? new Date(item.deactivation_date) : ""}
                              onChange={(selectedDates) => handleNewDateChange(item.id, "deactivation_date", selectedDates)}
                              dateFormat="Y-m-d"
                              className="form-control"
                            />
                          </td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.useful_life?.toString()}>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue={item.useful_life}
                              onInput={handleNumericInput}
                              onChange={e => handleNewInputChange(Number(item.id), 'useful_life', e.target.value)}
                            />
                          </td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.withdrawal_cost?.toString()}><input
                              className="form-control"
                              type="text"
                              defaultValue={formatCurrency(item.withdrawal_cost)}
                              onInput={handleNumericInput}
                              onChange={e => handleNewInputChange(Number(item.id), 'withdrawal_cost', e.target.value)}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                            /></td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.depreciation?.toString()}><input
                              className="form-control"
                              type="text"
                              defaultValue={formatCurrency(item.depreciation)}
                              onInput={handleNumericInput}
                              onChange={e => handleNewInputChange(Number(item.id), 'depreciation', e.target.value)}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                            /></td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.net_balance?.toString()}>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue={formatCurrency(item.net_balance)}
                              onInput={handleNumericInput}
                              onChange={e => handleNewInputChange(Number(item.id), 'net_balance', e.target.value)}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                            />
                          </td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.act_date_sale ? new Date(item.act_date_sale).toLocaleDateString() : ""}>
                            <DatePicker
                              id="date-picker"
                              value={item.act_date_sale ? new Date(item.act_date_sale) : ""}
                              onChange={(selectedDates) => handleNewDateChange(item.id, "act_date_sale", selectedDates)}
                              dateFormat="Y-m-d"
                              className="form-control"
                            />
                          </td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.description?.toString()}>
                            <textarea
                              className="form-control"
                              defaultValue={item.description || ""}
                              onChange={(e) => handleNewInputChange(Number(item.id), "description", e.target.value)}
                              required
                            />
                          </td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.profit_loss_according_accounting_record?.toString()}><input
                              className="form-control"
                              type="text"
                              defaultValue={formatCurrency(item.profit_loss_according_accounting_record)}
                              onInput={handleNumericInput}
                              onChange={e => handleNewInputChange(Number(item.id), 'profit_loss_according_accounting_record', e.target.value)}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                            /></td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.difference_net_balance?.toString()}>{calculateFirstDifference(item.profit_loss_according_accounting_record, item.net_balance)}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.asset_sale_value_according_invoice?.toString()}>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue={formatCurrency(item.asset_sale_value_according_invoice)}
                              onInput={handleNumericInput}
                              onChange={e => handleNewInputChange(Number(item.id), 'asset_sale_value_according_invoice', e.target.value)}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                            />
                          </td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.profit_loss?.toString()}>{calculateProfitLossDifference(item.asset_sale_value_according_invoice, item.net_balance)}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.difference_profit_loss?.toString()}>{calculateSecondDifference(item.asset_sale_value_according_invoice, item.net_balance, item.profit_loss_according_accounting_record)}</td>
                          <td className="p-1">
                            <button className="btn btn-icon btn-primary element-card-buttons" onClick={() => handleRemoveNewRegister(item.id)}>
                              <span className="btn-inner--icon"><i className="fa fa-trash py-2" aria-hidden="true"></i></span></button>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-end w-100 gap-2 mt-2">
              <button className="btn btn-secondary" onClick={() => addRegister()}>{T("Add New")}</button>

            </div>
          </div>
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default CustomerWithdrawalInformationAssetsViewMode;
