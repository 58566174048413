import React, { useState, useRef, useEffect } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from '../special-document/ViewModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import { AuditPlanVarianceAnalysis, SelectAuditPlanFixedAssetsRepository } from '../../repository/substantive-tests/SelectAuditPlanFixedAssetsRepository';
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import ViewModeBase from "../special-document/ViewModeBase";
import ModalWithButtons from '../modals/AceptCancelModalBase';


const SelectAuditPlanFixedAssetsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [registersBase, setRegistersBase] = useState<AuditPlanVarianceAnalysis[]>([]);
  const [selectOptionBase, setSelectOptionBase] = useState<number | undefined >(undefined);
  const [nameSelectBase, setNameSelectBase] = useState<string | undefined>(undefined);
  const [selectOption, setSelectOption] = useState<number | undefined>();
  const [refresh, setRefresh] = useState(false);
  const [key, setKey] = useState(0);

  /**
  * Fetches audit plan registers using the SelectAuditPlanFixedAssetsRepository and updates state accordingly.
  */
  const getRegisters = async () => {
    const selectAuditPlanFixedAssetsRepository = new SelectAuditPlanFixedAssetsRepository();
    const response_accounts_configuration = await selectAuditPlanFixedAssetsRepository.getAuditPlanFilesFixedAssets(element.args.engagement_id, element.args.document_id);
    if (response_accounts_configuration.success) {
      setRegistersBase(response_accounts_configuration.data);
    }
    const response_select_audit_plan = await selectAuditPlanFixedAssetsRepository.getSelectedAuditPlanFilesFixedAssets(element.args.engagement_id, element.args.document_id, element.reference);
   
    if (response_select_audit_plan.success) {
    setSelectOptionBase(response_select_audit_plan.audit_plan_id);
    const selectedRegister = response_accounts_configuration.data.find(register => register.id === response_select_audit_plan.audit_plan_id);
    setNameSelectBase(selectedRegister?.name);
    } else {
      setSelectOptionBase(undefined);
    }
    setKey(prevKey => prevKey + 1);
  }

  useEffect(() => {
    getRegisters();
  }, [refresh]);

  const handleSelectChange = (event:React.ChangeEvent<HTMLSelectElement>) => {
    if (!event.target.value) {
      return;
    }
    setSelectOption(Number(event.target.value));
  }

  const handleEditModalAccept = () => {
    updateAuditPlanFiles();
    setShowConfigurationsModal(false);
  }

  const handleEditModalCancel = () => {
    setSelectOption(selectOptionBase || undefined);    
    setKey(prevKey => prevKey + 1);
    setShowConfigurationsModal(false)
  };

  const handleEdit = () => {
    setShowConfigurationsModal(true)
  }

  const updateAuditPlanFiles = async () => {
    const selectAuditPlanFixedAssetsRepository = new SelectAuditPlanFixedAssetsRepository();
    const response_select_audit_plan = await selectAuditPlanFixedAssetsRepository.updateAuditPlanVarianceAnalysis(element.args.engagement_id, element.args.document_id, { "reference" : element.reference, "audit_plan_variance_analysis_id": selectOption });
    setRefresh(!refresh);
  }

  return (
    <div>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        <div className="card h-100" key={key}>
          <div className="card-body p-3 text-center">
          {selectOptionBase ? (
            <>
              <h5>{T("The following audit plan was selected.")}</h5>
              <p>{nameSelectBase}</p>
            </>
          ) : (
            <>
              <h5>{T("Select the test balances to be analyzed")}</h5>
              <p>{T("You must select the test balances to be analyzed.")}</p>
            </>
          )}
          </div>
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Required Account Settings")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <div className="modal-body text-center">
          <div>
          <div>
          <h5>{T("Configuration")}</h5>
            </div>
            <h6>{T("Required Account Settings")}</h6>
            <div className="d-flex justify-content-center mt-2">
            <p key={key} className="mb-0 text-dark">
              <select className="form-select" value={selectOption || ""} onChange={handleSelectChange} required>
                <option value="" disabled>{T("Select an option")}</option>
                  {registersBase.map((register) => (
                    <option key={register.id} value={register.id}>{register.name}</option>
                  ))}
              </select>
            </p>
            </div>
          </div>
        </div>
        </ModalWithButtons>
        </div>
  );
};

export default SelectAuditPlanFixedAssetsViewMode;
