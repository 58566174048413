import React, { useState, useEffect, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { type Permissions } from "../../models/special-document/ElementBase"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { ScopeAndObjectivesUseExpertWorkElement } from "../../models/special-document/ScopeAndObjectivesUseExpertWorkElement"
import { decryptUniqueFilename } from "../../utils/utilities"
import ViewModeProps from './ViewModeProps'
import ViewModeBase from "./ViewModeBase"
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { ScopeAndObjectivesUseExpertWork } from '../../models/special-document/ElementArgs'
import DOMPurify from 'isomorphic-dompurify';
import Summernote from '../utils/Summernote';
import DatePicker from '../commons/Flatpickr';
import DropzoneComponentScopeAndObjectivesView from '../commons/DropzoneComponentScopeAndObjectivesView'
import { emitter } from '../utils/EventEmitter';


export interface AuditPlanStages {
  id: number;
  name: string;
}


/*
* Renders the view mode for the Scope and Objectives of Use of Expert Work element.
* @param {ViewModeProps} props - Component properties.
*/
const ScopeAndObjectivesUseExpertWorkViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<ScopeAndObjectivesUseExpertWorkElement>(iElement as ScopeAndObjectivesUseExpertWorkElement).current
  const [registersBase, setRegistersBase] = useState<ScopeAndObjectivesUseExpertWork[]>(element.options)
  const [registers, setRegisters] = useState<ScopeAndObjectivesUseExpertWork[]>([])
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [auditPlanStages, setAuditPlanStages] = useState<AuditPlanStages[]>([])
  const [fileNamesCompetence, setFileNamesCompetence] = useState<Record<number, { id: number| string, name: string }[]>>({});
  const [fileNamesIndependence, setFileNamesIndependence] = useState<Record<number | string, { id: number, name: string }[]>>({});
  const [isRefresh, setIsRefresh] = useState(false);
  const [key, setKey] = useState(0);

  const typeReport = [
    { value: 1, text: "Written report" },
    { value: 2, text: "Oral Report" }
  ]

  useEffect(() => {
    const handleEvent = () => {
      setIsRefresh(prev => !prev);
    };
    emitter.on("refreshUseExpertWork", handleEvent);
    return () => {
      emitter.off("refreshUseExpertWork", handleEvent);
    };
  }, []);

  /**
   * Validates the scope and objectives of the use of expert work.
   */
  const validateDeterminationUseExpertWork = async () => {
    const elementRepository = new ElementRepository()
    const result = await elementRepository.validateScopeAndObjetivesUseExpertWork(element.args.engagement_id, element.id)

    if (result.success) {
      const registersElement = element.options.filter((item) => result.data.includes(item.reference))
      const initialFileNamesCompetence = registersElement.reduce<Record<number, { id: number | string, name: string }[]>>((acc, item) => {
        if (item.experts_items && Array.isArray(item.experts_items)) {
          acc[item.id] = item.experts_items
            .map((expert, expertIndex) => ({
              id: expert.id,
              name: expert.competence || "",
            }))
            .filter(file => file.name !== "");
        } else {
          acc[item.id] = [];
        }
        return acc;
      }, {});
  
  
      setFileNamesCompetence(initialFileNamesCompetence);
  
      const initialFileNamesIndependence = registersElement.reduce<Record<number, { id: number, name: string }[]>>((acc, item) => {
        if (item.experts_items && Array.isArray(item.experts_items)) {
          acc[item.id] = item.experts_items
            .map((expert, expertIndex) => ({
              id: expertIndex,
              name: expert.independence || "",
            }))
            .filter(file => file.name !== "");
        }
        return acc;
      }, {});
  
      setFileNamesIndependence(initialFileNamesIndependence);
      setRegisters(registersElement)
      setRegistersBase(registersElement)
      setKey(prevKey => prevKey + 1);
    } 
  }

  useEffect(() => {
    validateDeterminationUseExpertWork()
  }, [isRefresh])

  /**
   * Fetches the audit plan stages from the API.
   */
  const getAuditPlanStages = async () => {
    const elementRepository = new ElementRepository()
    const result = await elementRepository.getAuditPlanStagesByEngagementId(element.args.engagement_id, element.id)
    if (result.success) {
      setAuditPlanStages(result.data)
    }
  }

  useEffect(() => {
    getAuditPlanStages()
  }, [])

  /**
   * Handles the edit action.
   */
  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };


  /**
   * Handles the acceptance of the edit modal.
   */
  async function handleEditModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()

    const lastOptions = JSON.parse(JSON.stringify(element.options))
    element.options = JSON.parse(JSON.stringify(registers))

    let success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      element.options = lastOptions
      setRegisters(JSON.parse(JSON.stringify(lastOptions)))
      window.htmlHelpers?.swalError()
    } else {
      setRegistersBase(registers)
      setIsRefresh(prev => !prev);
      setKey((prevKey) => prevKey + 1);
    }
  }

  /**
   * Handles the cancellation of the edit modal.
   */
  function handleEditModalCancel() {
    setShowConfigurationsModal(false)
    setRegisters(element.options)
    setKey((prevKey) => prevKey + 1);
  }

  /**
   * Handles the change of the register.
   * @param {number} index - The index of the register.
   * @param {string} value - The new value.
   */
  const handleAddExpert = (index: number) => {
    setRegisters((prevRegisters) => {
      const newRegisters = [...prevRegisters];
      newRegisters[index].experts_items.push({
        id: crypto.randomUUID(),
        role: "",
        name: "",
        competence: "",
        independence: ""
      });

      return newRegisters;
    });
  };

  /**
   * Handles the change of the expert.
   * @param {number} index - The index of the register.
   * @param {number} expertIndex - The index of the expert.
   * @param {string} field - The field to change.
   * @param {string} value - The new value.
   */
  const handleExpertChange = (index: number, expertIndex: number, field: string, value: string) => {
    setRegisters((prevRegisters) => {
      const newRegisters = [...prevRegisters];

      newRegisters[index].experts_items[expertIndex] = {
        ...newRegisters[index].experts_items[expertIndex],
        [field]: value,
      };
      return newRegisters;
    }
    );
  };

  /**
   * Handles the change of the competence.
   * @param {number} index - The index of the register.
   * @param {number} expertIndex - The index of the expert.
   * @param {string} attachmentName - The new value.
   */
  const handleSetAttachmentCompetence = (index: number, expertIndex: number, attachmentName: string) => {
    const newRegisters = [...registers];

    newRegisters[index].experts_items[expertIndex] = {
      ...newRegisters[index].experts_items[expertIndex],
      ["competence"]: attachmentName,
    };
    setRegisters(newRegisters);
  }

  /**
   * Handles the change of the independence.
   * @param {number} index - The index of the register.
   * @param {number} expertIndex - The index of the expert.
   * @param {string} attachmentName - The new value.
   */
  const handleSetAttachmentIndependence = (index: number, expertIndex: number, attachmentName: string) => {
    setRegisters((prevRegisters) => {
      const newRegisters = [...prevRegisters];

      newRegisters[index].experts_items[expertIndex] = {
        ...newRegisters[index].experts_items[expertIndex],
        ["independence"]: attachmentName,
      };

      return newRegisters;
    });
  }

  /**
   * Handles the change of the selected stage.
   * @param {number} index - The index of the register.
   * @param {number} value - The new value.
   * @returns {void}
   */
  const handleAnswerSelectedStage = (index: number, value: number | undefined) => {
    const newOptions = [...registers]
    if (value) {
      newOptions[index].answer_select_stage = value
      newOptions[index].responsible_stage = ""
      newOptions[index].start_date_work_stage = ""
      newOptions[index].end_date_work_stage = ""
      setRegisters(newOptions)
    }
  }

  /**
   * Handles the change of the purpose.
   * @param {number} index - The index of the register.
   * @param {string} value - The new value.
   */
  const handlePurposeChange = (index: number, value: string) => {
    const newRegisters = [...registers];
    newRegisters[index].purpose = value;
    setRegisters(newRegisters);
  }

  /**
   * Deletes an expert from the list.
   * @param {number} index - The index of the register.
   * @param {number} expertIndex - The index of the expert.
   */
  const deleteExpert = (index: number, expertIndex: number) => {
    setRegisters((prevRegisters) => {
      if (index < 0 || index >= prevRegisters.length) return prevRegisters;

      const newRegisters = [...prevRegisters];

      if (!newRegisters[index].experts_items || newRegisters[index].experts_items.length <= 1) {
        return prevRegisters;
      }

      newRegisters[index].experts_items = newRegisters[index].experts_items.filter((_, i) => i !== expertIndex);
      return newRegisters;
    });
  };

  /**
   * Gets the stage name by ID.
   * @param {number} id - The ID of the stage.
   * @returns {string} - The name of the stage.
   */
  const getStageNameById = (id: number) => {
    const stage = auditPlanStages.find((stage) => stage.id === id);
    return stage ? stage.name : "";
  }


  /**
   * Handles the change of the responsible stage.
   * @param {number} index - The index of the register.
   * @param {string} value - The new value.
   */
  const handleResponsibleStageChange = (index: number, value: string) => {
    const newOptions = [...registers]
    newOptions[index].responsible_stage = value
    setRegisters(newOptions)
  }

  /**
   * Handles the change of the start date.
   * @param {number } index - The index of the register.
   * @param {Date[]} selectedDates - The selected dates.
   */
  const handleStartDateChange = (index: number, selectedDates: Date[]) => {
    if (selectedDates.length > 0) {
      const formattedDate = selectedDates[0].toISOString().split("T")[0];
      const newOptions = [...registers];
      newOptions[index].start_date_work_stage = formattedDate
      setRegisters(newOptions)

    }
  };

  /**
   * Handles the change of the end date.
   * @param {number } index - The index of the register.
   * @param {Date[]} selectedDates - The selected dates.
   */
  const handleEndDateChange = (index: number , selectedDates: Date[]) => {
    if (selectedDates.length > 0) {
      const formattedDate = selectedDates[0].toISOString().split("T")[0];
      const newOptions = [...registers];
      newOptions[index].end_date_work_stage = formattedDate
      setRegisters(newOptions)

    }
  };

  /**
   * Handles the change of the roles and responsibilities.
   * @param {number} index - The index of the register.
   * @param {string} value - The new value.
   */
  const handleRolesResponsibilitiesChange = (index: number, value: string) => {
    setRegisters((prevRegisters) =>
      prevRegisters.map((register, i) =>
        i === index
          ? { ...register, roles_responsibilities_text: value }
          : register
      )
    );
  };

  /**
   * Handles the change of the communication reporting.
   * @param {number} index - The index of the register.
   * @param {string} value - The new value.
   */
  const handleCommunicationReportingChange = (index: number, value: string) => {
    setRegisters((prevRegisters) =>
      prevRegisters.map((register, i) =>
        i === index
          ? { ...register, communication_reporting_text: value }
          : register
      )
    );
  };


  /**
   * Handles the change of the communication reporting.
   * @param {number} index - The index of the register.
   * @param {string} value - The new value.
   */
  const handleCommunicationReportingSelect = (index: number, value: number) => {
    setRegisters((prevRegisters) =>
      prevRegisters.map((register, i) =>
        i === index
          ? { ...register, communication_reporting_answer_select: value }
          : register
      )
    );
  };

  /**
   * Handles the change of the communication reporting date.
   * @param {number} index - The index of the register.
   * @param {Date[]} selectedDates - The selected dates.
   */
  const handleCommunicationDateChange = (index: number , selectedDates: Date[]) => {
    if (selectedDates.length > 0) {
      const formattedDate = selectedDates[0].toISOString().split("T")[0];
      const newOptions = [...registers];
      newOptions[index].communication_reporting_date = formattedDate
      setRegisters(newOptions)

    }
  };

  return (
    <>
      {registers.length > 0 ? (
        <>
          <ViewModeBase
            isEditable={true}
            handleEdit={handleEdit}
            permissions={element.permissions as Permissions}
          >
            <div key={key}>
              {registersBase
                .map((item, index) => (
                  <div key={index} className="table-responsive mb-4">
                    <h3>{item.title}</h3>
                    <h6>{T("Purpose")}</h6>
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.purpose) }} />
                    <h6>{T("Components of the expert use planning memorandum")}</h6>
                    <h6>{T("Competence, capability and independence of the expert")}</h6>
                    <p>{T("The expert team shall consist of the following persons:")}</p>
                    <table className="table-bordered table-sm w-100">
                      <thead>
                        <tr>
                          <th className="text-center">
                            {T("Role")}
                          </th>
                          <th className="text-center">
                            {T("Name")}
                          </th>
                          <th className="text-center">
                            {T("Competence")}
                          </th>
                          <th className="text-center">
                            {T("Independence")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.experts_items && item.experts_items.map((expert, expertIndex) => (
                          <tr>
                            <td>{expert.role}</td>
                            <td>{expert.name}</td>
                            <td><em>{decryptUniqueFilename(expert.competence || "")}</em></td>
                            <td><em>{decryptUniqueFilename(expert.independence || "")}</em></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <h6>{T("Schedule of the expert's work")}</h6>
                    {item.answer_select_stage ? (
                      <table className="table-bordered mt-2 mb-2">
                        <thead>
                          <tr>
                            <th>{T("Stage")}</th>
                            <th>{T("Responsible")}</th>
                            <th>{T("Start date of the work")}</th>
                            <th>{T("Final date of the work")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr key={item.id}>
                            <td>{getStageNameById(item.answer_select_stage)}</td>
                            <td>{item.responsible_stage}</td>
                            <td>{item.start_date_work_stage}</td>
                            <td>{item.end_date_work_stage}</td>
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      <p>{T("No stage selected")}</p>
                    )}
                    <h6>{T("Roles and responsibilities of the expert")}</h6>
                    <p>{T("In accordance with the understanding of the entity's complex transactions, the following roles and responsibilities of the appraiser have been defined within the framework of the audit procedures:")}</p>
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.roles_responsibilities_text || "") }} />
                    <h6>{T("Expert's communications and reports")}</h6>
                    <p>{T("In accordance with the contractual conditions defined between the firm and the expert, determine the following relevant aspects related to the deliverables of the work performed")}</p>
                    <table className="table-bordered table-sm w-100">
                      <thead>
                        <tr>
                          <th className="w-50">{T("Communication and report")}</th>
                          <th className="w-50">{T("Answer")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {T("Document how the request and follow-up of the delivery of information for the development of the expert's work should be carried out:")}
                          </td>
                          <td>
                            <p>{item.communication_reporting_text}</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {T("Determine how the deliverable should be performed by the expert.")}
                          </td>
                          <td>
                            <p className="m-0">
                              {T(typeReport.find((answer) => answer.value === item.communication_reporting_answer_select)?.text || "")}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {T("Establish the maximum date for delivery of the specialist's report.")}
                          </td>
                          <td>
                            {item.communication_reporting_date}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))}
            </div>
          </ViewModeBase>
          <ModalWithButtons
            showModal={showConfigurationsModal}
            title={T("Configuration Determination Use of Expert Work")}
            size="xl"
            onAccept={handleEditModalAccept}
            onCancel={handleEditModalCancel}>
            <div key={key}>
              {registers.map((item, index) => (
                <div key={index} className="table-responsive mb-4">
                  <h3>{item.title}</h3>
                  <h6>{T("Purpose")}</h6>
                  <Summernote
                    value={item.purpose || ""}
                    onChange={(value) => handlePurposeChange(index, value)}
                  />
                  <h6>{T("Components of the expert use planning memorandum")}</h6>
                  <h6>{T("Competence, capability and independence of the expert")}</h6>
                  <p>{T("The expert team shall consist of the following persons:")}</p>
                  <table className="table-bordered table-sm w-100">
                    <thead>
                      <tr>
                        <th className="text-center">{T("Role")}</th>
                        <th className="text-center">{T("Name")}</th>
                        <th className="text-center">{T("Competence")}</th>
                        <th className="text-center">{T("Independence")}</th>
                        <th className="text-center">{T("Actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.experts_items &&
                        item.experts_items.map((expert, expertIndex) => (
                          <tr key={expertIndex}>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={expert.role || ""}
                                onChange={(e) => handleExpertChange(index, expertIndex, "role", e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={expert.name || ""}
                                onChange={(e) => handleExpertChange(index, expertIndex, "name", e.target.value)}
                              />
                            </td>
                            <td>
                              <DropzoneComponentScopeAndObjectivesView
                                elementId={element.id}
                                attachmentName={
                                  (() => {
                                    const fileData = fileNamesCompetence?.[item.id]?.[expertIndex]?.name;
                                    if (!fileData) return undefined;
                                    if (Array.isArray(fileData)) return fileData;
                                    return [{ id: expertIndex, name: fileData }];
                                  })()
                                }
                                onUpload={(newFileNames) =>
                                  handleSetAttachmentCompetence(index, expertIndex, newFileNames[0])
                                }
                                onRemove={() => { }}
                              />
                            </td>
                            <td>
                              <DropzoneComponentScopeAndObjectivesView
                                elementId={element.id}
                                attachmentName={
                                  (() => {
                                    const fileData = fileNamesIndependence[item.id]?.[expertIndex]?.name;
                                    if (Array.isArray(fileData)) return fileData;
                                    if (typeof fileData === "string") return [{ id: expertIndex, name: fileData }];
                                    return undefined;
                                  })()
                                }
                                onUpload={(newFileNames) =>
                                  handleSetAttachmentIndependence(index, expertIndex, newFileNames[0])
                                }
                                onRemove={() => { }}
                              />
                            </td>
                            <td className="text-center">
                              {item.experts_items.length > 1 && (
                                <button
                                  type="button"
                                  className="bg-transparent border-0 text-danger"
                                  onClick={() => deleteExpert(index, expertIndex)}
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <button className="btn btn-primary mt-2" onClick={() => handleAddExpert(index)}>
                    {T("Add Expert")}
                  </button>
                  <h6>{T("Schedule of the expert's work")}</h6>
                  <h6>{T("Expert's schedule")}</h6>
                  <p>{T("The planned audit fieldwork is scheduled for the following audit stage(s):")}</p>
                  <label>{T("Select Answer")}</label>
                  <select className="form-select" value={Number(item.answer_select_stage) || ""} onChange={(e) => handleAnswerSelectedStage(index, Number(e.target.value))}>
                    <option value="" disabled>{T("Select an option")}</option>
                    {auditPlanStages.map((answer) => {
                      return (
                        <option key={answer.id} value={answer.id}>{T(answer.name)}</option>
                      )
                    })}
                  </select>
                  {item.answer_select_stage && (
                    <table className="table-bordered mt-2 mb-2">
                      <thead>
                        <tr>
                          <th className="p-2">{T("Stage")}</th>
                          <th className="p-2">{T("Responsible")}</th>
                          <th className="p-2">{T("Start date of the work")}</th>
                          <th className="p-2">{T("Final date of the work")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={item.id}>
                          <td className="p-2">{getStageNameById(item.answer_select_stage)}</td>
                          <td className="p-2"><input
                            type="text"
                            className="form-control"
                            value={item.responsible_stage || ""}
                            onChange={(e) => handleResponsibleStageChange(index, e.target.value)}
                          /></td>
                          <td className="p-2"><DatePicker
                            id="date-picker"
                            value={item.start_date_work_stage ? new Date(item.start_date_work_stage) : ""}
                            onChange={(selectedDates) => handleStartDateChange(index, selectedDates)}
                            dateFormat="Y-m-d"
                            className="form-control"
                          /></td>
                          <td className="p-2"><DatePicker
                            id="date-picker"
                            value={item.end_date_work_stage ? new Date(item.end_date_work_stage) : ""}
                            onChange={(selectedDates) => handleEndDateChange(index, selectedDates)}
                            dateFormat="Y-m-d"
                            className="form-control"
                          /></td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  <h6>{T("Roles and responsibilities of the expert")}</h6>
                  <p>{T("In accordance with the understanding of the entity's complex transactions, the following roles and responsibilities of the appraiser have been defined within the framework of the audit procedures:")}</p>
                  <Summernote
                    value={item.roles_responsibilities_text || ""}
                    onChange={(value) => handleRolesResponsibilitiesChange(index, value)}
                  />
                  <h6>{T("Expert's communications and reports")}</h6>
                  <p>{T("In accordance with the contractual conditions defined between the firm and the expert, determine the following relevant aspects related to the deliverables of the work performed")}</p>
                  <table className="table-bordered table-sm w-100">
                    <thead>
                      <tr>
                        <th className="w-50">{T("Communication and report")}</th>
                        <th className="w-50">{T("Answer")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {T("Document how the request and follow-up of the delivery of information for the development of the expert's work should be carried out:")}
                        </td>
                        <td>
                          <textarea
                            className="form-control"
                            value={item.communication_reporting_text}
                            onChange={e => handleCommunicationReportingChange(index, e.target.value)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {T("Determine how the deliverable should be performed by the expert.")}
                        </td>
                        <td>
                          <select className="form-select" value={item.communication_reporting_answer_select || ""} onChange={(e) => handleCommunicationReportingSelect(index, Number(e.target.value))}>
                            <option value="" disabled>{T("Select an option")}</option>
                            {typeReport.map((answer) => (
                              <option key={answer.value} value={answer.value}>
                                {T(answer.text)}
                              </option>
                            ))}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {T("Establish the maximum date for delivery of the specialist's report.")}
                        </td>
                        <td>
                          <DatePicker
                            id="date-picker"
                            value={item.communication_reporting_date ? new Date(item.communication_reporting_date) : ""}
                            onChange={(selectedDates) => handleCommunicationDateChange(index, selectedDates)}
                            dateFormat="Y-m-d"
                            className="form-control"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              ))}
            </div>
          </ModalWithButtons>
        </>
      ) : (
        <div className="card h-100 bg-light">
          <div className="card-body p-3">
            <h6>{T("This element shows the scope and objectives of the use of the expert.")}</h6>
          </div>
        </div>
      )}
    </>
  );
};

export default ScopeAndObjectivesUseExpertWorkViewMode;
