import React, { useEffect, useState } from 'react';
import 'select2';
import { T } from "../../utils/i18n-config";
import { RegistersSubsequentEvents, SubsequentEventsRepository } from '../../repository/SubsequentEventsRepository';
import ModalBase from '../modals/ModalBase';


export interface NotesAndFindingsArgs {
    id: number
    message_description: string
    status?: string
    message_id: number
    reference: string
    transaction_flow_control_id: number
    reference_transaction_flow_control: number
    created_at: string
    created_by: number
    created_by_full_name: string
    completed_by?: number
    approved_by?: number
    refused_by?: number
}

export interface SubsequentEventsArgsProps {
    engagementId: number;
    readPermission: boolean;
    updatePermission: boolean;
}

export interface DropdownMenuPrincipalProps {
    iElement: number;
    isCurrentUser: boolean;
    status: string | undefined;
}

export interface DropdownMenuReplyProps {
    iElement: number;
    IElementFather: number;
    isPermission: boolean;
    isLast?: boolean;
    status: string | undefined;
}

export interface TeamUser {
    engagement_note_id: number | undefined;
    engagement_id: number;
    users_id: string | number | string[] | undefined;
}

export interface User {
    user_id: number;
    user_name: string;
    background_color?: string;
    initials_name?: string;
    font_color?: string;
    full_name?: string;
}


/**
 * Component to show the subsequent events
 * @param engagementId - Engagement id to show the subsequent events
 * @param readPermission - Permission to read the subsequent events
 * @param updatePermission - Permission to update the subsequent events
 */
const SubsequentEvents = ({ engagementId, readPermission, updatePermission }: SubsequentEventsArgsProps) => {
    const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
    const [isRefreshLocal, setIsRefreshLocal] = useState(false);
    const [registers, setRegisters] = useState<RegistersSubsequentEvents[]>([]);
    const [changes, setChanges] = useState<Record<number, Partial<RegistersSubsequentEvents>>>({});
    const [allBaseRegisters, setAllBaseRegisters] = useState<RegistersSubsequentEvents[]>([]);
    const [requireError, setRequireError] = useState(false);
    const [requireRevelation, setRequireRevelation] = useState(false);
    const [key, setKey] = useState(0);

    const listTypeSections = [
        { id: 1, name: "Yes" },
        { id: 2, name: "No" },
    ];

    /**
     * Function to get the registers of the subsequent events
     * @returns - Registers of the subsequent events
     */
    const getRegistersQuestionsSubsequentEvents = async (): Promise<void> => {
        if (!readPermission) return;
        const subsequentEventsRepository = new SubsequentEventsRepository()
        const result = await subsequentEventsRepository.getRegistersSubsequentEvents(engagementId)
        if (result.success) {
            const AllRegisters = result.data.filter((item) => item.is_error !== true && item.is_revelation !== true);
            setRegisters(AllRegisters);
            setAllBaseRegisters(AllRegisters);

            const requireError = result.data.some((item) => item.adjustment_value_yes_no === 1);
            setRequireError(requireError);
            const requireRevelation = result.data.some((item) => item.revelation_value_yes_no === 1);
            setRequireRevelation(requireRevelation);
        } else {
            setRegisters([]);
            setAllBaseRegisters([]);
        }
    }

    useEffect(() => {
        getRegistersQuestionsSubsequentEvents()
    }, [isRefreshLocal]);

    /**
     * Function to show the modal to edit the subsequent events
     */
    const handleEdit = () => {
        setShowConfigurationsModal(true)
    };

    /**
     * Function to accept the changes of the subsequent events
     * @param event - Event to prevent the default behavior of the form
     */
    const handleEditModalAccept = async (event: React.FormEvent) => {
        event.preventDefault();
        const subsequentEventsRepository = new SubsequentEventsRepository()
        const result = await subsequentEventsRepository.updateRegisterQuestionSubsequentEvents(engagementId, changes)
        setIsRefreshLocal(prev => !prev);
        setRegisters([]);
        setChanges({});
        if (result.success) {
            setShowConfigurationsModal(false);
        }
    };

    /**
     * Function to set the value of the principal value yes no
     * @param itemId - Item id to set the value
     * @param value - Value to set
     */
    const setHasPrincipalValueYesNo = (itemId: number, value: number) => {
        setChanges(prev => ({
            ...prev,
            [itemId]: {
                ...prev[itemId],
                principal_value_yes_no: value,
                ...(Number(value) === 2 ? { revelation_value_yes_no: 0, adjustment_value_yes_no: 0, revelation_value_text: "", adjustment_value_text: ""} : {})
            }
        }));
    }

    /**
    * Function to cancel the editing modal, resetting changes and reverting registers
    */
    const handleEditModalCancel = () => {
        setIsRefreshLocal(prev => !prev);
        setRegisters(allBaseRegisters);
        setShowConfigurationsModal(false)
        setChanges({});
        setKey(prevKey => prevKey + 1);
    };

    /**
     * Function to redirect to the error sheet
     */
    const redirectErrorSheet = () => {
        const url = `/audix/engagement_error_sheet/index.load/${engagementId}`;
        window.menuService?.breadcrumbAddLevel(url, T('Error Sheet'))
    }

    /**
     * Function to redirect to the disclosure sheet
     */
    const redirectDisclosureSheet = () => {
        const url = `/audix/engagement_disclosure_sheet/index.load/${engagementId}`;
        window.menuService?.breadcrumbAddLevel(url, T('Disclosure Sheet'))
    }

    /**
     * Function to handle the value yes no
     * @param id - Id to set the value
     * @param field - Field to set the value
     * @param value - Value to set
     */
    const handleChangeValueYesNo = (id: number, field: keyof RegistersSubsequentEvents, value: number) => {
        setChanges((prev) => ({
            ...prev,
            [id]: { ...prev[id], [field]: value }
        }));
    };

    /**
     * Function to handle the text change
     * @param id - Id to set the value
     * @param field - Field to set the value
     * @param value - Value to set
     */
    const handleChangeText = (id: number, field: keyof RegistersSubsequentEvents, value: string) => {
        setChanges((prev) => ({
            ...prev,
            [id]: { ...prev[id], [field]: value }
        }));
    };

    return (
        <React.Fragment>
            <div className="card card-body p-3">
                <div className="row align-items-center">
                    <div id="1_3" className="col-11">
                        <div key={key} className="d-flex w-full flex-column mb-3">
                            <div className="w-100">
                                <h4>{T("General computer controls:")}</h4>
                                <p>{T("Determine whether events occurring up to the date of the audit report that may require adjustments or disclosures in the financial statements have been identified. For this purpose, verify, among others:")}</p>
                                <div className="w-100">
                                    <ol type="a" style={{ fontWeight: "bold", color: "black" }}>
                                        {registers.map((item, index) => (
                                            <li key={index}>
                                                <div className="form-group w-100">
                                                    <label>{T(item.question_name)}</label>
                                                    <p><strong>{T(listTypeSections.find(type => type.id === item.principal_value_yes_no)?.name || "No selection")}</strong></p>
                                                </div>
                                                {Number(item.principal_value_yes_no) === 1 && (
                                                    <React.Fragment>
                                                        <div className="form-group w-100">
                                                            <label>{T("Requires adjustment")}</label>
                                                            <p><strong>{T(listTypeSections.find(type => type.id === item.revelation_value_yes_no)?.name || "No selection")}</strong></p>
                                                        </div>

                                                        {Number(item.revelation_value_yes_no) === 1 && (
                                                            <div className="form-group w-100">
                                                                <label>{T("Revelation Details")}</label>
                                                                <p>{item.revelation_value_text || T("No details provided")}</p>
                                                            </div>
                                                        )}

                                                        <div className="form-group w-100">
                                                            <label>{T("Requires disclosure")}</label>
                                                            <p><strong>{T(listTypeSections.find(type => type.id === item.adjustment_value_yes_no)?.name || "No selection")}</strong></p>
                                                        </div>

                                                        {Number(item.adjustment_value_yes_no) === 1 && (
                                                            <div className="form-group w-100">
                                                                <label>{T("Adjustment Details")}</label>
                                                                <p>{item.adjustment_value_text || T("No details provided")}</p>
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </li>
                                        ))}

                                    </ol>
                                </div>
                            </div>
                        </div>
                        {(requireError === true || requireRevelation === true) && (
                            <div>
                                <h6>{T("Redirect")}</h6>
                                {(requireError === true) && (
                                    <button className="btn btn-primary me-2" onClick={redirectErrorSheet}>{T("Error Sheet")}</button>
                                )}
                                {(requireRevelation === true) && (
                                    <button className="btn btn-primary" onClick={redirectDisclosureSheet}>{T("Disclosure Sheet")}</button>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="col-1 text-end d-print-none">
                        {updatePermission &&
                            (
                                <button className="btn btn-icon btn-primary element-card-buttons" onClick={handleEdit}>
                                    <span className="btn-inner--icon"><i className="fa fa-pen py-2" aria-hidden="true"></i></span>
                                </button>
                            )}
                    </div>
                </div>
            </div>
            <ModalBase showModal={showConfigurationsModal} title={T("Subsequent Events")} size="xl">
                <form onSubmit={handleEditModalAccept}>
                    <div className="modal-body">
                        <div style={{ width: "100%" }}>
                            <div className="mb-3">
                                <h6>{T("Determine whether events occurred up to the date of the audit report that may require adjustments or disclosures in the financial statements were identified. To do so, verify, among others:")}</h6>
                                <p>{T("Answer yes or no, when there is a yes then you must conclude yes:")}</p>
                                <p>{T("It is a fact that requires adjustment or it is a fact that requires disclosure.")}</p>
                                <ol type="a" className="w-100" style={{ fontWeight: "bold", color: "black" }}>
                                    {registers.map((item, index) => (
                                        <li key={index}>
                                            <div className="form-group w-100">
                                                <label>{T(item.question_name)}</label>
                                                <select
                                                    className="form-select"
                                                    value={changes[item.id]?.principal_value_yes_no ?? item.principal_value_yes_no ?? ""}
                                                    onChange={(e) => setHasPrincipalValueYesNo(item.id, Number(e.target.value))}
                                                    required
                                                >
                                                    <option value="">{T("Select an option")}</option>
                                                    {listTypeSections.map((type) => (
                                                        <option key={type.id} value={type.id}>
                                                            {T(type.name)}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            {Number(changes[item.id]?.principal_value_yes_no ?? item.principal_value_yes_no) === 1 && (
                                                <React.Fragment>
                                                    <div className="form-group w-100">
                                                        <label>{T("Requires adjustment")}</label>
                                                        <select
                                                            className="form-select"
                                                            value={changes[item.id]?.revelation_value_yes_no ?? item.revelation_value_yes_no ?? ""}
                                                            onChange={(e) => handleChangeValueYesNo(item.id, "revelation_value_yes_no", Number(e.target.value))}
                                                            required
                                                        >
                                                            <option value="">{T("Select an option")}</option>
                                                            {listTypeSections.map((type) => (
                                                                <option key={type.id} value={type.id}>
                                                                    {T(type.name)}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    {Number(changes[item.id]?.revelation_value_yes_no ?? item.revelation_value_yes_no) === 1 && (
                                                        <div className="form-group w-100">
                                                            <label>{T("Revelation Details")}</label>
                                                            <textarea
                                                                className="form-control"
                                                                value={changes[item.id]?.revelation_value_text ?? item.revelation_value_text ?? ""}
                                                                onChange={(e) => handleChangeText(item.id, "revelation_value_text", e.target.value)}
                                                                placeholder={T("Enter details for revelation")}
                                                                required
                                                            />
                                                        </div>
                                                    )}

                                                    <div className="form-group w-100">
                                                        <label>{T("Requires disclosure")}</label>
                                                        <select
                                                            className="form-select"
                                                            value={changes[item.id]?.adjustment_value_yes_no ?? item.adjustment_value_yes_no ?? ""}
                                                            onChange={(e) => handleChangeValueYesNo(item.id, "adjustment_value_yes_no", Number(e.target.value))}
                                                            required
                                                        >
                                                            <option value="">{T("Select an option")}</option>
                                                            {listTypeSections.map((type) => (
                                                                <option key={type.id} value={type.id}>
                                                                    {T(type.name)}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    {Number(changes[item.id]?.adjustment_value_yes_no ?? item.adjustment_value_yes_no) === 1 && (
                                                        <div className="form-group w-100">
                                                            <label>{T("Adjustment Details")}</label>
                                                            <textarea
                                                                className="form-control"
                                                                value={changes[item.id]?.adjustment_value_text ?? item.adjustment_value_text ?? ""}
                                                                onChange={(e) => handleChangeText(item.id, "adjustment_value_text", e.target.value)}
                                                                placeholder={T("Enter details for adjustment")}
                                                                required
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </li>
                                    ))}
                                </ol>
                            </div>
                        </div>
                        <div>
                            <hr />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn bg-gradient-secondary" onClick={handleEditModalCancel}>{T("Cancel")}</button>
                        <button type="submit" className="btn bg-gradient-primary">{T("Accept")}</button>
                    </div>
                </form>
            </ModalBase>
        </React.Fragment>
    );
}

export default SubsequentEvents;