import { ContractsTableArgs, Contract, SelectedContract } from './ElementArgs'
import { ElementBase, IElementBase } from './ElementBase'

/**
 * ContractsTableElement is responsible for managing the contracts table element and its operations.
 * @param {ContractsTableArgs} args - Initialization arguments for the contracts table element.
 */
export class ContractsTableElement extends ElementBase implements IElementBase {
  private _contracts: Contract[]
  private _selected_contracts: SelectedContract[]
  private _contracts_template_name: string
  private _contracts_attachment_name: string
  private _conclusion: string

  /**
   * Initializes the ContractsTableElement with the provided arguments.
   * @param {ContractsTableArgs} args - Initialization arguments including contracts,
   * selected contracts, template name, attachment name, and conclusion.
   */
  constructor(args: ContractsTableArgs) {
    super(args)
    this._contracts = args.contracts ?? []
    this._selected_contracts = args.selected_contracts ?? []
    this._contracts_template_name = args.contracts_template_name ?? ''
    this._contracts_attachment_name = args.contracts_attachment_name ?? ''
    this._conclusion = args.conclusion ?? ''
  }

  get contracts(): Contract[] {
    return this._contracts
  }

  get selectedContracts(): SelectedContract[] {
    return this._selected_contracts
  }

  get contractsTemplateName(): string {
    return this._contracts_template_name
  }

  get contractsAttachmentName(): string {
    return this._contracts_attachment_name
  }

  get conclusion(): string {
    return this._conclusion
  }

  get args(): ContractsTableArgs {
    return {
      ...super.args,
      contracts: this._contracts,
      selected_contracts: this._selected_contracts,
      contracts_template_name: this._contracts_template_name,
      contracts_attachment_name: this._contracts_attachment_name,
      conclusion: this._conclusion,
    }
  }

  set contracts(contracts: Contract[]) {
    this._contracts = contracts
  }

  set selectedContracts(selected_contracts: SelectedContract[]) {
    this._selected_contracts = selected_contracts
  }

  set contractsTemplateName(contracts_template_name: string) {
    this._contracts_template_name = contracts_template_name
  }

  set contractsAttachmentName(contracts_attachment_name: string) {
    this._contracts_attachment_name = contracts_attachment_name
  }

  set conclusion(conclusion: string) {
    this._conclusion = conclusion
  }

  render(): void {}
}