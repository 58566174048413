import { useState } from 'react'
import { T } from '../../utils/i18n-config'
import { Permissions } from '../../models/special-document/ElementBase'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeProps from '../special-document/EditModeProps'
import EditModeBase from '../special-document/EditModeBase'

/**
 * A React component to render and manage the edit mode for journal entry answers.
 */
const JournalEntriesAnswersEditMode = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)

  /**
   * Opens the configurations modal.
   */
  function handleEdit() {
    setShowConfigurationsModal(true)
  }

  /**
   * Closes the configurations modal.
   */
  function handleAccept() {
    setShowConfigurationsModal(false)
  }

  return (
    <>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleEdit}
        permissions={iElement.permissions as Permissions}
      >
        <div className="card card-body p-3 bg-light">
          <h6>{T("Review Period")}</h6>
          <ul className="mb-0">
            <li>{T("Records for the whole year")}</li>
            <li>{T("Records for the last month")}</li>
          </ul>
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleAccept}
        onCancel={handleAccept}
      >
        <p>{T("There are no configurations for this element")}</p>
      </ModalWithButtons>
    </>
  );
}

export default JournalEntriesAnswersEditMode;