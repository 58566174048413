import { DisallowanceIncomesArgs } from './ElementArgs'
import { ElementBase, IElementBase } from './ElementBase'

/**
 * DisallowanceIncomesElement handles disallowance incomes data and includes write operations via its setter methods.
 */
export class DisallowanceIncomesElement extends ElementBase implements IElementBase {
  private _delivery_type: number
  private _conclusion_type: number
  private _locals_template_name: string
  private _locals_attachment_name: string
  private _exports_template_name: string
  private _exports_attachment_name: string
  private _returns_template_name: string
  private _returns_attachment_name: string
  private _locals_labels: string[]
  private _exports_labels: string[]

  /**
   * Constructs a new DisallowanceIncomesElement using the provided arguments.
   * @param {DisallowanceIncomesArgs} args - The parameters required to initialize the element.
   */
  constructor(args: DisallowanceIncomesArgs) {
    super(args)
    this._delivery_type = args.delivery_type || 0
    this._conclusion_type = args.conclusion_type || 0
    
    this._locals_template_name = args.locals_template_name || ''
    this._locals_attachment_name = args.locals_attachment_name || ''

    this._exports_template_name = args.exports_template_name || ''
    this._exports_attachment_name = args.exports_attachment_name || ''
    
    this._returns_template_name = args.returns_template_name || ''
    this._returns_attachment_name = args.returns_attachment_name || ''

    this._locals_labels = args.locals_labels
    this._exports_labels = args.exports_labels
  }

  get deliveryType(): number {
    return this._delivery_type
  }

  get conclusionType(): number {
    return this._conclusion_type
  }

  get localsTemplateName(): string {
    return this._locals_template_name
  }

  get localsAttachmentName(): string {
    return this._locals_attachment_name
  }

  get exportsTemplateName(): string {
    return this._exports_template_name
  }

  get exportsAttachmentName(): string {
    return this._exports_attachment_name
  }

  get returnsTemplateName(): string {
    return this._returns_template_name
  }

  get returnsAttachmentName(): string {
    return this._returns_attachment_name
  }

  get localsLabels(): string[] {
    return this._locals_labels
  }

  get exportsLabels(): string[] {
    return this._exports_labels
  }

  get args(): DisallowanceIncomesArgs {
    return {
      ...super.args,
      delivery_type: this._delivery_type,
      conclusion_type: this._conclusion_type,
      locals_template_name: this._locals_template_name,
      locals_attachment_name: this._locals_attachment_name,
      exports_template_name: this._exports_template_name,
      exports_attachment_name: this._exports_attachment_name,
      returns_template_name: this._returns_template_name,
      returns_attachment_name: this._returns_attachment_name,
      locals_labels: this._locals_labels,
      exports_labels: this._exports_labels
    }
  }

  set deliveryType(value: number) {
    this._delivery_type = value
  }

  set conclusionType(value: number) {
    this._conclusion_type = value
  }

  set localsTemplateName(value: string) {
    this._locals_template_name = value
  }

  set localsAttachmentName(value: string) {
    this._locals_attachment_name = value
  }

  set exportsTemplateName(value: string) {
    this._exports_template_name = value
  }

  set exportsAttachmentName(value: string) {
    this._exports_attachment_name = value
  }

  set returnsTemplateName(value: string) {
    this._returns_template_name = value
  }

  set returnsAttachmentName(value: string) {
    this._returns_attachment_name = value
  }

  set localsLabels(value: string[]) {
    this._locals_labels = value
  }

  set exportsLabels(value: string[]) {
    this._exports_labels = value
  }

  render(): void {}
}