import React, { useEffect, useRef, useState } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { createId, decryptUniqueFilename } from "../../utils/utilities";
import Summernote from '../utils/Summernote';
import { T } from "../../utils/i18n-config";
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { InternalControlComponentsQuestionsElement } from "../../models/special-document/InternalControlComponentsQuestionsElement"
import { InternalControlComponentsQuestions } from "../../models/special-document/ElementArgs"
import { Permissions } from '../../models/special-document/ElementBase';
import InternalControlComponentsQuestionsOptionEdit from './InternalControlComponentsQuestionsOptionEdit'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeBase from "./EditModeBase"
import EditModeProps from './EditModeProps'

export interface InternalControlComponentsConclusionData {
  id: number;
  name: string;
  min_scale: number;
  max_scale: number;
  error?: string;
}

export interface InternalControlComponentsConclusion {
  success: boolean,
  data_conclusion: InternalControlComponentsConclusionData[],
}

const InternalControlComponentsConclusionEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<InternalControlComponentsQuestionsElement>(iElement as InternalControlComponentsQuestionsElement).current;
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [ranges, setRanges] = useState<InternalControlComponentsConclusionData[]>([]);
  const [rangesBase, setRangesBase] = useState<InternalControlComponentsConclusionData[]>([]);
  const [initialConclusion, setInitialConclusion] = useState<string | null>('')
  const [basicConclusion, setBasicConclusion] = useState<string | null>('')
  const [intermediateConclusion, setIntermediateConclusion] = useState<string | null>('')
  const [optimalConclusion, setOptimalConclusion] = useState<string | null>('')

  const getResultOfAnswers = async () => {
    const elementRepository = new ElementRepository()
    const result = await elementRepository.getConclusionScaleInternalControlComponents(element.engagement_id, element.documentId, element.id)
    if (result.success) {
      setRanges(result.data_conclusion)
      setRangesBase(result.data_conclusion)
    } else {
      setRanges([])
      setRangesBase([])
    }
  }

  const handleConfiguration = () => {
    setShowConfigurationsModal(true);
  };

  useEffect(() => {
    getResultOfAnswers()
    setInitialConclusion(element.initial_conclusion)
    setBasicConclusion(element.basic_conclusion)
    setIntermediateConclusion(element.intermediate_conclusion)
    setOptimalConclusion(element.optimal_conclusion)
  }, []);

  const validateRanges = (updatedRanges: InternalControlComponentsConclusionData[]) => {
    const validatedRanges = updatedRanges.map((range, index) => {
      let error = "";

      if (range.max_scale <= range.min_scale) {
        error = T("The upper limit {{max_scale}} must be greater than the lower limit {{min_scale}}.", { max_scale: range.max_scale, min_scale: range.min_scale });
      }

      if (
        index < updatedRanges.length - 1 &&
        range.max_scale >= updatedRanges[index + 1].min_scale
      ) {
        error = T("The upper limit of {{name}} {{max_scale}} overlaps with the lower limit of {{updatedRanges}} ({{min_scale}}).", { name: range.name, max_scale: range.max_scale, updatedRanges: T(updatedRanges[index + 1].name), min_scale: updatedRanges[index + 1].min_scale });
      }

      return { ...range, error };
    });

    setRanges(validatedRanges);
  };

  const handleRangeChange = (
    index: number,
    field: "min_scale" | "max_scale",
    value: number
  ) => {
    const updatedRanges = [...ranges];
    updatedRanges[index] = { ...updatedRanges[index], [field]: value };
    setRanges(updatedRanges);
    validateRanges(updatedRanges);
  };


  const handleConfigurationsModalAccept = async () => {
    const errors = ranges
      .filter((range) => range.error)
      .map((range) => `${range.name}: ${range.error}`);
    if (errors.length == 0) {
      setShowConfigurationsModal(false);
      const elementRepository = new ElementRepository()
      const lastInitialConclusion = element.initial_conclusion
      const lastBasicConclusion = element.basic_conclusion
      const lastIntermediateConclusion = element.intermediate_conclusion
      const lastOptimalConclusion = element.optimal_conclusion
      element.initial_conclusion = initialConclusion
      element.basic_conclusion = basicConclusion
      element.intermediate_conclusion = intermediateConclusion
      element.optimal_conclusion = optimalConclusion
      
      let success = await elementRepository.saveElement("view", element.args)
      if (!success) {
        element.initial_conclusion = lastInitialConclusion
        element.basic_conclusion = lastBasicConclusion
        element.intermediate_conclusion = lastIntermediateConclusion
        element.optimal_conclusion = lastOptimalConclusion
        setBasicConclusion(lastBasicConclusion)
        setInitialConclusion(lastInitialConclusion)
        setIntermediateConclusion(lastIntermediateConclusion)
        setOptimalConclusion(lastOptimalConclusion)
        window.htmlHelpers?.swalError()
      }
      const result = await elementRepository.updateConclusionScaleControlComponents(element.engagement_id, element.documentId, element.id, ranges)
    }
  };

  const handleConfigurationsModalCancel = () => {
    setShowConfigurationsModal(false)
    setRanges(rangesBase)
  };

  const handleEditInitialConclusion = (initialConclusion: string) => {
    setInitialConclusion(initialConclusion)
  }

  const handleEditBasicConclusion = (basicConclusion: string) => {
    setBasicConclusion(basicConclusion)
  }

  const handleEditIntermediateConclusion = (intermediateConclusion: string) => {
    setIntermediateConclusion(intermediateConclusion)
  }

  const handleEditOptimalConclusion = (optimalConclusion: string) => {
    setOptimalConclusion(optimalConclusion)
  }

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <div className="card h-100 bg-light">
          <div className="card-body p-3">
            <h6>{T("Element for displaying the internal control components conclusion")}</h6>
          </div>
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conclusion parameters")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}>
        <div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {ranges.map((range, index) => (
              <div key={index} className="mb-0 w-25">
                <h4>{range.name}</h4>
                <label>
                  {T("Lower Limit")}:
                  <input
                    type="number"
                    value={range.min_scale}
                    className="form-control"
                    onChange={(e) =>
                      handleRangeChange(index, "min_scale", parseFloat(e.target.value))
                    }
                  />
                </label>
                <label>
                  {T("Upper Limit")}:
                  <input
                    type="number"
                    value={range.max_scale}
                    className="form-control"
                    onChange={(e) =>
                      handleRangeChange(index, "max_scale", parseFloat(e.target.value))
                    }
                  />
                </label>
                <p
                  style={{
                    color: "red",
                    marginTop: "0.5rem",
                    minHeight: "1.2rem",
                    marginBottom: "0",
                    maxWidth: '100%',
                    visibility: range.error ? 'visible' : 'hidden',
                    wordBreak: 'break-word',
                    lineHeight: 'normal',
                    paddingRight: '10px'
                  }}
                >
                  {range.error || " "}
                </p>
              </div>
            ))}
          </div>
          <h5 className="mb-0">{T("Initial Conclusion")}</h5>
          <Summernote
            value={initialConclusion || ""}
            onChange={(e) => handleEditInitialConclusion(e)}
            showModal={showConfigurationsModal}
          />
          <h5 className="mb-0">{T("Basic Conclusion")}</h5>
          <Summernote
            value={basicConclusion || ""}
            onChange={(e) => handleEditBasicConclusion(e)}
            showModal={showConfigurationsModal}
          />

          <h5 className="mb-0">{T("Intermediate Conclusion")}</h5>
          <Summernote
            value={intermediateConclusion || ""}
            onChange={(e) => handleEditIntermediateConclusion(e)}
            showModal={showConfigurationsModal}
          />
          <h5 className="mb-0">{T("Optimal Conclusion")}</h5>
          <Summernote
            value={optimalConclusion || ""}
            onChange={(e) => handleEditOptimalConclusion(e)}
            showModal={showConfigurationsModal}
          />
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default InternalControlComponentsConclusionEditMode;
