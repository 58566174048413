import React from 'react';
import { ElementType } from "../models/special-document/ElementType";
import { ElementParagraph } from "../models/special-document/ElementParagraph";
import { ConditionalParagraphElementWithSelector } from "../models/special-document/ConditionalParagraphElementWithSelector";
import { ConditionalParagraphElementWithGlobalVariable } from "../models/special-document/ConditionalParagraphElementWithGlobalVariable";
import { ThreeStatesTableElement } from "../models/special-document/ThreeStatesTableElement";
import { CustomerRiskClassificationElement } from "../models/special-document/CustomerRiskClassificationElement";
import { ManagementIntegrityElement } from "../models/special-document/ManagementIntegrityElement";
import { IndependenceFormatElement } from "../models/special-document/IndependenceFormatElement";
import { OfacLinkElement } from "../models/special-document/OfacLinkElement";
import { ConditionalParagraphOfacElement } from "../models/special-document/ConditionalParagraphOfacElement";
import { MathFormulaDisplayValueElement } from "../models/special-document/MathFormulaDisplayValueElement";
import { ConditionalMathFormulaElementMessage } from "../models/special-document/ConditionalMathFormulaElementMessage";
import { VarianceAnalysisNotesElement } from "../models/special-document/VarianceAnalysisNotesElement";
import { MaterialityElement } from "../models/special-document/MaterialityElement";
import { AttachDocumentElement } from "../models/special-document/AttachDocumentElement";
import { FindingsElement } from "../models/special-document/FindingsElement";
import { FindingsEvaluationElement } from "../models/special-document/FindingsEvaluationElement";
import { ElementOfacResults } from '../models/special-document/ElementOfacResults';
import { ProbabilityAnalysisElement } from '../models/special-document/ProbabilityAnalysisElement';
import { RedirectionButtonElement } from '../models/special-document/RedirectionButtonElement';
import { QuestionsTableElement } from '../models/special-document/QuestionsTableElement';
import { InformationExtractorElement } from '../models/special-document/InformationExtractorElement';
import { ItemOpeningBalancesElement } from '../models/special-document/ItemOpeningBalancesElement';
import { TrialBalanceOpeningBalanceElement } from '../models/special-document/TrialBalanceOpeningBalanceElement';
import { DeterminationUseExpertWorkElement } from '../models/special-document/DeterminationUseExpertWorkElement';
import { ScopeAndObjectivesUseExpertWorkElement } from '../models/special-document/ScopeAndObjectivesUseExpertWorkElement';
import { QuestionTextFileElement } from '../models/special-document/QuestionTextFileElement';
import { RedirectionMemorandumButtonElement } from '../models/special-document/RedirectionMemorandumButtonElement';
import { InternalControlComponentsQuestionsElement } from '../models/special-document/InternalControlComponentsQuestionsElement';
import { InternalControlComponentsConfigurationElement } from '../models/special-document/InternalControlComponentsConfigurationElement';
import { QuestionTextFileWithYesNoElement } from '../models/special-document/QuestionTextFileWithYesNoElement';
import { QuestionJustifiedElement } from '../models/special-document/QuestionJustifiedElement';
import { UseServiceOrganizationsElement } from '../models/special-document/UseServiceOrganizationsElement';
import { DisallowanceIncomesElement } from '../models/special-document/DisallowanceIncomesElement';
import { ImportantQuestionsMeetingsWithKeyMembersClientElement } from '../models/special-document/ImportantQuestionsMeetingsMembersClientElement';
import { MemorandumOtherRisksElement } from '../models/special-document/MemorandumOtherRisksElement';
import { MemorandumComplexityTechnologyElement } from '../models/special-document/MemorandumComplexityTechnologyControlsElement';
import { MemoReportsAttachmentsElement } from '../models/special-document/MemoReportsAttachmentsElement';
import { MinutesTableElement } from '../models/special-document/MinutesTableElement';
import { ContractsTableElement } from '../models/special-document/ContractsTableElement';
import { BasisParagraphOpinionElement } from '../models/special-document/BasisParagraphOpinionElement';

// Substantive Tests
import {SubstantiveTestBankConciliationsElement} from '../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import { BankConciliationsQuestionElement } from '../models/substantive-tests/BankConciliationsQuestionElement';
import { JournalEntriesElement } from '../models/substantive-tests/JournalEntriesElement';

import { IElementBase } from '../models/special-document/ElementBase';
import ParagraphViewMode from '../components/special-document/ParagraphViewMode';
import ParagraphEditMode from '../components/special-document/ParagraphEditMode';
import ShowOfacResultsEditMode from '../components/special-document/ShowOfacResultsEditMode';
import ShowOfacResultsViewMode from '../components/special-document/ShowOfacResultsViewMode';
import ConditionalParagraphWithSelectorEditMode from '../components/special-document/ConditionalParagraphWithSelectorEditMode';
import ConditionalParagraphWithSelectorViewMode from '../components/special-document/ConditionalParagraphWithSelectorViewMode';
import ConditionalParagraphWithVariableEditMode from '../components/special-document/ConditionalParagraphWithVariableEditMode';
import ConditionalParagraphWithVariableViewMode from '../components/special-document/ConditionalParagraphWithVariableViewMode';
import ThreeStatesTableEditMode from '../components/special-document/ThreeStatesTableEditMode';
import ThreeStatesTableViewMode from '../components/special-document/ThreeStatesTableViewMode';
import IndependenceFormatEditMode from '../components/special-document/IndependenceFormatEditMode';
import IndependenceFormatViewMode from '../components/special-document/IndependenceFormatViewMode';
import ConditionalParagraphOfacViewMode from '../components/special-document/ConditionalParagraphOfacViewMode';
import ConditionalParagraphOfacEditMode from '../components/special-document/ConditionalParagraphOfacEditMode';
import VarianceAnalysisNotesEditMode from '../components/special-document/VarianceAnalysisNotesEditMode';
import VarianceAnalysisNotesViewMode from '../components/special-document/VarianceAnalysisNotesViewMode';
import AttachDocumentEditMode from '../components/special-document/AttachDocumentEditMode';
import AttachDocumentViewMode from '../components/special-document/AttachDocumentViewMode';
import FindingsContainerEditMode from '../components/special-document/FindingsContainerEditMode';
import FindingsContainerViewMode from '../components/special-document/FindingsContainerViewMode';
import FindingsEvaluationEditMode from '../components/special-document/FindingsEvaluationEditMode';
import FindingsEvaluationViewMode from '../components/special-document/FindingsEvaluationViewMode';
import ProbabilityAnalysisEditMode from '../components/special-document/ProbabilityAnalysisEditMode';
import ProbabilityAnalysisViewMode from '../components/special-document/ProbabilityAnalysisViewMode';
import RedirectionButtonViewMode from '../components/special-document/RedirectionButtonViewMode';
import RedirectionButtonEditMode from '../components/special-document/RedirectionButtonEditMode';
import InformationExtractorEditMode from '../components/special-document/InformationExtractorEditMode';
import InformationExtractorViewMode from '../components/special-document/InformationExtractorViewMode';

// Substantive Tests
import RequiredAccountConfigurationBankConciliationsViewMode from '../components/substantive-tests/RequiredAccountConfigurationBankConciliationsViewMode';
import RequiredAccountConfigurationBankConciliationsEditMode from '../components/substantive-tests/RequiredAccountConfigurationBankConciliationsEditMode';
import QuestionBankConciliationsEditMode from '../components/substantive-tests/QuestionBankConciliationsEditMode';
import QuestionBankConciliationsViewMode from '../components/substantive-tests/QuestionBankConciliationsViewMode';
import TrialBalanceFileBankConciliationsViewMode from '../components/substantive-tests/TrialBalanceFileBankConciliationsViewMode';
import TrialBalanceFileBankConciliationsEditMode from '../components/substantive-tests/TrialBalanceFileBankConciliationsEditMode';
import PrincipalTableBankConciliationsViewMode from '../components/substantive-tests/TablePrincipalBankConciliationsViewMode';
import PrincipalTableBankConciliationsEditMode from '../components/substantive-tests/TablePrincipalBankConciliationsEditMode';
import SamplesBankConciliationsViewMode from '../components/substantive-tests/SamplesBankConciliationsViewMode';
import SamplesBankConciliationsEditMode from '../components/substantive-tests/SamplesBankConciliationsEditMode';
import DifferenceReconcilingItemsViewMode from '../components/substantive-tests/DifferenceReconcilingItemsViewMode';
import DifferenceReconcilingItemsEditMode from '../components/substantive-tests/DifferenceReconcilingItemsEditMode';
import DocumentSampleViewMode from '../components/substantive-tests/DocumentSampleViewMode';
import DocumentSampleEditMode from '../components/substantive-tests/DocumentSampleEditMode';
import TrialBalanceOpeningBalanceViewMode from '../components/special-document/TrialBalanceOpeningBalanceViewMode';
import TrialBalanceOpeningBalanceEditMode from '../components/special-document/TrialBalanceOpeningBalanceEditMode';
import ItemOpeningBalancesEditMode from '../components/special-document/ItemOpeningBalancesEditMode';
import ItemOpeningBalancesViewMode from '../components/special-document/ItemOpeningBalancesViewMode';
import JournalEntriesRegistersEditMode from '../components/substantive-tests/JournalEntriesRegistersEditMode';
import JournalEntriesRegistersViewMode from '../components/substantive-tests/JournalEntriesRegistersViewMode';
import JournalEntriesDateRangeEditMode from '../components/substantive-tests/JournalEntriesDateRangeEditMode';
import JournalEntriesDateRangeViewMode from '../components/substantive-tests/JournalEntriesDateRangeViewMode';
import JournalEntriesAttachmentEditMode from '../components/substantive-tests/JournalEntriesAttachmentEditMode';
import JournalEntriesAttachmentViewMode from '../components/substantive-tests/JournalEntriesAttachmentViewMode';
import JournalEntriesAnswersEditMode from '../components/substantive-tests/JournalEntriesAnswersEditMode';
import JournalEntriesAnswersViewMode from '../components/substantive-tests/JournalEntriesAnswersViewMode';
import QuestionTextFileEditMode from '../components/special-document/QuestionTextFileEditMode';
import QuestionTextFileViewMode from '../components/special-document/QuestionTextFileViewMode';
import RedirectionMemorandumButtonEditMode from '../components/special-document/RedirectionMemorandumButtonEditMode';
import RedirectionMemorandumButtonViewMode from '../components/special-document/RedirectionMemorandumButtonViewMode';
import QuestionsTableEditMode from '../components/special-document/QuestionsTableEditMode';
import QuestionsTableViewMode from '../components/special-document/QuestionsTableViewMode';
import RequiredAccountConfigurationFixedAssetsViewMode from '../components/substantive-tests/RequiredAccountConfigurationFixedAssetsViewMode';
import RequiredAccountConfigurationFixedAssetsEditMode from '../components/substantive-tests/RequiredAccountConfigurationFixedAssetsEditMode';
import SelectAuditPlanFixedAssetsViewMode from '../components/substantive-tests/SelectAuditPlanFixedAssetsViewMode';
import SelectAuditPlanFixedAssetsEditMode from '../components/substantive-tests/SelectAuditPlanFixedAssetsEditMode';
import TablePrincipalFixedAssetsViewMode from '../components/substantive-tests/TablePrincipalFixedAssetsViewMode';
import TablePrincipalFixedAssetsEditMode from '../components/substantive-tests/TablePrincipalFixedAssetsEditMode';
import PaeMovementFixedAssetsViewMode from '../components/substantive-tests/PaeMovementFixedAssetsViewMode';
import PaeMovementFixedAssetsEditMode from '../components/substantive-tests/PaeMovementFixedAssetsEditMode';
import SubstantiveBalanceDataViewMode from '../components/substantive-tests/SubstantiveBalanceDataViewMode';
import SubstantiveBalanceDataEditMode from '../components/substantive-tests/SubstantiveBalanceDataEditMode';
import CustomerInformationAssetsViewMode from '../components/substantive-tests/CustomerInformationFixedAssetsViewMode';
import CustomerInformationAssetsEditMode from '../components/substantive-tests/CustomerInformationFixedAssetsEditMode';
import RecalculationCustomerFixedAssetsViewMode from '../components/substantive-tests/RecalculationCustomerFixedAssetsViewMode';
import RecalculationCustomerFixedAssetsEditMode from '../components/substantive-tests/RecalculationCustomerFixedAssetsEditMode';
import SummaryFixedAssetsCostViewMode from '../components/substantive-tests/SummaryFixedAssetsCostViewMode';
import SummaryFixedAssetsCostEditMode from '../components/substantive-tests/SummaryFixedAssetsCostEditMode';
import SummaryDepreciationExpenseFixedAssetsEditMode from '../components/substantive-tests/SummaryDepreciationExpenseFixedAssetsEditMode';
import SummaryDepreciationExpenseFixedAssetsViewMode from '../components/substantive-tests/SummaryDepreciationExpenseFixedAssetsViewMode';
import AccumulatedDepreciationFixedAssetsViewMode from '../components/substantive-tests/AccumulatedDepreciationFixedAssetsViewMode';
import AccumulatedDepreciationFixedAssetsEditMode from '../components/substantive-tests/AccumulatedDepreciationFixedAssetsEditMode';
import PaeMovementReconcilingItemsViewMode from '../components/substantive-tests/PaeMovementReconcilingItemsViewMode';
import PaeMovementReconcilingItemsEditMode from '../components/substantive-tests/PaeMovementReconcilingItemsEditMode';
import PaeMovementDocumentSampleViewMode from '../components/substantive-tests/PaeMovementDocumentSampleViewMode';
import PaeMovementDocumentSampleEditMode from '../components/substantive-tests/PaeMovementDocumentSampleEditMode';
import CustomerAdditionsInformationAssetsViewMode from '../components/substantive-tests/CustomerAdditionsInformationAssetsViewMode';
import CustomerAdditionsInformationAssetsEditMode from '../components/substantive-tests/CustomerAdditionsInformationAssetsEditMode';
import CustomerWithdrawalInformationAssetsViewMode from '../components/substantive-tests/CustomerWithdrawalInformationAssetsViewMode';
import CustomerWithdrawalInformationAssetsEditMode from '../components/substantive-tests/CustomerWithdrawalInformationAssetsEditMode';
import DeterminationUseExpertWorkViewMode from '../components/special-document/DeterminationUseExpertWorkViewMode';
import DeterminationUseExpertWorkEditMode from '../components/special-document/DeterminationUseExpertWorkEditMode';
import ScopeAndObjectivesUseExpertWorkViewMode from '../components/special-document/ScopeAndObjectivesUseExpertWorkViewMode';
import ScopeAndObjectivesUseExpertWorkEditMode from '../components/special-document/ScopeAndObjectivesUseExpertWorkEditMode';
import InternalControlComponentsQuestionsEditMode from '../components/special-document/InternalControlComponentsQuestionsEditMode';
import InternalControlComponentsQuestionsViewMode from '../components/special-document/InternalControlComponentsQuestionsViewMode';
import InternalControlComponentsConfigurationEditMode from '../components/special-document/InternalControlComponentsConfigurationEditMode';
import InternalControlComponentsConfigurationViewMode from '../components/special-document/InternalControlComponentsConfigurationViewMode';
import InternalControlComponentsConclusionViewMode from '../components/special-document/InternalControlComponentsConclusionViewMode';
import InternalControlComponentsConclusionEditMode from '../components/special-document/InternalControlComponentsConclusionEditMode';
import QuestionTextFileViewWithYesNoViewMode from '../components/special-document/QuestionTextFileViewWithYesNoViewMode';
import QuestionTextFileViewWithYesNoEditMode from '../components/special-document/QuestionTextFileViewWithYesNoEditMode';
import QuestionJustifiedEditMode from '../components/special-document/QuestionJustifiedEditMode';
import DisallowanceIncomesEditMode from '../components/special-document/DisallowanceIncomesEditMode';
import DisallowanceIncomesViewMode from '../components/special-document/DisallowanceIncomesViewMode';
import MemorandumOtherRisksViewMode from '../components/special-document/MemorandumOtherRisksViewMode';
import MemorandumOtherRisksEditMode from '../components/special-document/MemorandumOtherRisksEditMode';
import MemorandumComplexityTechnologyControlsViewMode from '../components/special-document/MemorandumComplexityTechnologyControlsViewMode';
import MemorandumComplexityTechnologyControlsEditMode from '../components/special-document/MemorandumComplexityTechnologyControlsEditMode';
import MemorandumComplexityTechnologyModificationViewMode from '../components/special-document/MemorandumComplexityTechnologyModificationViewMode';
import MemorandumComplexityTechnologyModificationEditMode from '../components/special-document/MemorandumComplexityTechnologyModificationEditMode';
import MemorandumComplexityTechnologyGeneralControlsViewMode from '../components/special-document/MemorandumComplexityTechnologyGeneralControlsViewMode';
import MemorandumComplexityTechnologyGeneralControlsEditMode from '../components/special-document/MemorandumComplexityTechnologyGeneralControlsEditMode';
import MemorandumComplexityTechnologyApplicationViewMode from '../components/special-document/MemorandumComplexityTechnologyApplicationViewMode';
import TransactionFlowsUseServiceOrganizationsViewMode from '../components/special-document/TransactionFlowsUseServiceOrganizationsViewMode';
import TypesUseServiceOrganizationsViewMode from '../components/special-document/TypesUseServiceOrganizationsViewMode';
import TransactionFlowsUseServiceOrganizationsEditMode from '../components/special-document/TransactionFlowsUseServiceOrganizationsEditMode';
import TypesUseServiceOrganizationsEditMode from '../components/special-document/TypesUseServiceOrganizationsEditMode';
import MemorandumComplexityTechnologyApplicationEditMode from '../components/special-document/MemorandumComplexityTechnologyApplicationEditMode';
import MemorandumComplexityTechnologyConclusionViewMode from '../components/special-document/MemorandumComplexityTechnologyConclusionViewMode';
import MemorandumComplexityTechnologyConclusionEditMode from '../components/special-document/MemorandumComplexityTechnologyConclusionEditMode';
import QuestionJustifiedViewMode from '../components/special-document/QuestionJustifiedViewMode';
import ImportantQuestionsMeetingsWithKeyMembersClientViewMode from '../components/special-document/ImportantQuestionsMeetingsMembersClientViewMode';
import ImportantQuestionsMeetingsWithKeyMembersClientEditMode from '../components/special-document/ImportantQuestionsMeetingsMembersClientEditMode';
import MemoReportsAttachmentsViewMode from '../components/special-document/MemoReportsAttachmentsViewMode';
import MemoReportsAttachmentsEditMode from '../components/special-document/MemoReportsAttachmentsEditMode';
import MinutesTableEditMode from '../components/special-document/MinutesTableEditMode';
import MinutesTableViewMode from '../components/special-document/MinutesTableViewMode';
import ContractsTableEditMode from '../components/special-document/ContractsTableEditMode';
import ContractsTableViewMode from '../components/special-document/ContractsTableViewMode';
import BasisParagraphViewMode from '../components/special-document/BasisParagraphOpinionViewMode';
import BasisParagraphEditMode from '../components/special-document/BasisParagraphOpinionEditMode';
import KamParagraphOpinionEditMode from '../components/special-document/KamParagraphOpinionEditMode';
import KamParagraphOpinionViewMode from '../components/special-document/KamParagraphOpinionViewMode';
import { Constants } from '../utils/Constants';


export const template = [
  new ElementType(Constants.PARAGRAPH_ELEMENT_TYPE, "Paragraph", ElementParagraph),
  new ElementType(2, "Conditional Paragraph With Selector", ConditionalParagraphElementWithSelector),
  new ElementType(3, "Conditional Paragraph With Global Variable", ConditionalParagraphElementWithGlobalVariable),
  new ElementType(4, "Three States Table", ThreeStatesTableElement),
  new ElementType(5, "Customer Risk Classification", CustomerRiskClassificationElement),
  new ElementType(6, "Management Integrity", ManagementIntegrityElement),
  new ElementType(7, "Independence Format", IndependenceFormatElement),
  new ElementType(8, "OFAC Link", OfacLinkElement),
  new ElementType(9, "Conditional Paragraph OFAC", ConditionalParagraphOfacElement),
  new ElementType(10, "Math Formula Display Value", MathFormulaDisplayValueElement),
  new ElementType(11, "Conditional Math Formula Message", ConditionalMathFormulaElementMessage),
  new ElementType(Constants.VARIANCE_ANALYSIS_NOTES_TYPE, "Variance Analysis Notes", VarianceAnalysisNotesElement),
  new ElementType(13, "Materiality", MaterialityElement),
  new ElementType(14, "Attach Document", AttachDocumentElement),
  new ElementType(Constants.FINDING_ELEMENT_TYPE, "Recommendations for Management", FindingsElement),
  new ElementType(Constants.FINDING_EVALUATION_ELEMENT_TYPE, "Evaluationspecial-document/ Description", FindingsEvaluationElement),
  new ElementType(17, "OFAC results", ElementOfacResults),
  new ElementType(Constants.PROBABILITY_ANALYSIS_TYPE, "Probablity Analysis", ProbabilityAnalysisElement),
  new ElementType(19, "Redirection Button", RedirectionButtonElement),
  new ElementType(Constants.SUBSTANTIVE_TEST_BANK_CONCILIATIONS_ACCOUNT_CONFIGURATION_TYPE, "Bank Reconciliations Account Configuration", SubstantiveTestBankConciliationsElement),
  new ElementType(21, "Question Bank Conciliations", BankConciliationsQuestionElement),
  new ElementType(22, "Trial Balance File Reconciliations", SubstantiveTestBankConciliationsElement),
  new ElementType(23, "Principal Table Bank Reconciliations", SubstantiveTestBankConciliationsElement),
  new ElementType(24, "Samples Bank Reconciliations", SubstantiveTestBankConciliationsElement),
  new ElementType(25, "Difference Reconciling Items", SubstantiveTestBankConciliationsElement),
  new ElementType(26, "Document Samples Reconciling Items", SubstantiveTestBankConciliationsElement),
  new ElementType(27, "Questions Table", QuestionsTableElement),
  new ElementType(28, "Information Extractor", InformationExtractorElement),
  new ElementType(29, "Required Account Configuration Fixed Assets", SubstantiveTestBankConciliationsElement),
  new ElementType(30, "Select Audit Plan Fixed Assets", SubstantiveTestBankConciliationsElement),
  new ElementType(31, "Principal Table Fixed Assets", SubstantiveTestBankConciliationsElement),
  new ElementType(32, "PAE Movement Fixed Assets", SubstantiveTestBankConciliationsElement),
  new ElementType(33, "Fixed Assets File", SubstantiveTestBankConciliationsElement),
  new ElementType(34, "Customer Information Fixed Assets", SubstantiveTestBankConciliationsElement),
  new ElementType(35, "Recalculation Customer Fixed Assets", SubstantiveTestBankConciliationsElement),
  new ElementType(36, "Summary Fixed Assets Cost", SubstantiveTestBankConciliationsElement),
  new ElementType(37, "Summary Depreciation Expense Fixed Assets", SubstantiveTestBankConciliationsElement),
  new ElementType(38, "Accumulated Depreciation Fixed Assets", SubstantiveTestBankConciliationsElement),
  new ElementType(41, "Pae Movement Reconciliation items", SubstantiveTestBankConciliationsElement),
  new ElementType(42, "Pae Movement Document Samples", SubstantiveTestBankConciliationsElement),
  new ElementType(54, "Customer Additions Information Assets", SubstantiveTestBankConciliationsElement),
  new ElementType(55, "Customer Withdrawal Information Assets", SubstantiveTestBankConciliationsElement),
  new ElementType(40, "Question with Text and File", QuestionTextFileElement),
  new ElementType(61, "Transaction Flow - Use Service Organizations", UseServiceOrganizationsElement),
  new ElementType(62, "Types - Use Service Organizations", UseServiceOrganizationsElement),
  new ElementType(43, "Question with Text and File with Yes/No", QuestionTextFileWithYesNoElement),
  new ElementType(44, "Question Justified", QuestionJustifiedElement),
  new ElementType(45, "Redirection Memorandum Button", RedirectionMemorandumButtonElement),
  new ElementType(46, "Internal Control Components Configuration", InternalControlComponentsConfigurationElement),
  new ElementType(47, "Internal Control Components Questions", InternalControlComponentsQuestionsElement),
  new ElementType(48, "Internal Control Components Conclusion", InternalControlComponentsQuestionsElement),
  new ElementType(49, "Memorandum Complexity Technology Controls", MemorandumComplexityTechnologyElement),
  new ElementType(50, "Memorandum Complexity Technology Modification", MemorandumComplexityTechnologyElement),
  new ElementType(51, "Memorandum Complexity Technology General Controls", MemorandumComplexityTechnologyElement),
  new ElementType(52, "Memorandum Complexity Technology Application", MemorandumComplexityTechnologyElement),
  new ElementType(53, "Memorandum Complexity Technology Conclusion", MemorandumComplexityTechnologyElement),
  new ElementType(Constants.MEMORANDUM_OTHER_RISKS_TYPE, "Memorandum Other Risks", MemorandumOtherRisksElement),
  new ElementType(57, "Memorandum Reports Attachments", MemoReportsAttachmentsElement),
  new ElementType(59, "Important Questions - Meetings with Key Members Client", ImportantQuestionsMeetingsWithKeyMembersClientElement),
  new ElementType(63, "Determination Use Expert Work", DeterminationUseExpertWorkElement),
  new ElementType(64, "Scope and Objectives Use Expert Work", ScopeAndObjectivesUseExpertWorkElement),
  new ElementType(65, "Minutes Table", MinutesTableElement),
  new ElementType(66, "Contracts Table", ContractsTableElement),
  new ElementType(58, "Disallowance Incomes", DisallowanceIncomesElement),
  new ElementType(59, "Important Questions - Meetings with Key Members Client", ImportantQuestionsMeetingsWithKeyMembersClientElement),
  new ElementType(63, "Determination Use Expert Work", DeterminationUseExpertWorkElement),
  new ElementType(64, "Scope and Objectives Use Expert Work", ScopeAndObjectivesUseExpertWorkElement),
  new ElementType(67, "Journal Entries Registers", JournalEntriesElement),
  new ElementType(68, "Journal Entries Date Range", JournalEntriesElement),
  new ElementType(69, "Journal Entries Answers", JournalEntriesElement),
  new ElementType(70, "Journal Entries Attachment", JournalEntriesElement),
  new ElementType(71, "Trial Balance Opening Balance", TrialBalanceOpeningBalanceElement),
  new ElementType(72, "Application of auditing procedures to opening balances", ItemOpeningBalancesElement),
  new ElementType(73, "Basis Paragraph Opinion", BasisParagraphOpinionElement),
  new ElementType(74, "KAM Paragraph Opinion", BasisParagraphOpinionElement)
];


export interface CreateEditModeComponentProps {
  elementType: number;
  iElement: IElementBase;
  handleDeleteElement: (iElement: IElementBase) => Promise<void>;
  handleUpElement: (iElement: IElementBase) => Promise<void>;
  handleDownElement: (iElement: IElementBase) => Promise<void>;
}

export interface CreateViewModeComponentProps {
  elementType: number;
  iElement: IElementBase;
}

type ElementComponentViewMode = React.ComponentType<{ iElement: IElementBase }>;
type ElementComponentEditionMode = React.ComponentType<{ iElement: IElementBase, handleDeleteElement: (iElement: IElementBase) => Promise<void>,
  handleUpElement: (iElement: IElementBase) => Promise<void>, handleDownElement: (iElement: IElementBase) => Promise<void> }>;

export const elementComponentsViewMode: { [key: number]: ElementComponentViewMode } = {
  1: ParagraphViewMode,
  2: ConditionalParagraphWithSelectorViewMode,
  3: ConditionalParagraphWithVariableViewMode,
  4: ThreeStatesTableViewMode,
  // 5: CustomerRiskClassificationViewMode,
  // 6: ManagementIntegrityViewMode,
  7: IndependenceFormatViewMode,
  // 8: OfacLinkViewMode,
  9: ConditionalParagraphOfacViewMode,
  // 10: MathFormulaDisplayValueViewMode,
  // 11: ConditionalMathFormulaMessageViewMode,
  12: VarianceAnalysisNotesViewMode,
  // 13: MaterialityViewMode,
  14: AttachDocumentViewMode,
  15: FindingsContainerViewMode,
  16: FindingsEvaluationViewMode,
  17: ShowOfacResultsViewMode,
  18: ProbabilityAnalysisViewMode,
  19: RedirectionButtonViewMode,
  20: RequiredAccountConfigurationBankConciliationsViewMode,
  21: QuestionBankConciliationsViewMode,
  22: TrialBalanceFileBankConciliationsViewMode,
  23: PrincipalTableBankConciliationsViewMode,
  24: SamplesBankConciliationsViewMode,
  25: DifferenceReconcilingItemsViewMode,
  26: DocumentSampleViewMode,
  27: QuestionsTableViewMode,
  28: InformationExtractorViewMode,
  29: RequiredAccountConfigurationFixedAssetsViewMode,
  30: SelectAuditPlanFixedAssetsViewMode,
  31: TablePrincipalFixedAssetsViewMode,
  32: PaeMovementFixedAssetsViewMode,
  41: PaeMovementReconcilingItemsViewMode,
  42: PaeMovementDocumentSampleViewMode,
  33: SubstantiveBalanceDataViewMode,
  34: CustomerInformationAssetsViewMode,
  35: RecalculationCustomerFixedAssetsViewMode,
  36: SummaryFixedAssetsCostViewMode,
  37: SummaryDepreciationExpenseFixedAssetsViewMode,
  38: AccumulatedDepreciationFixedAssetsViewMode,
  54: CustomerAdditionsInformationAssetsViewMode,
  55: CustomerWithdrawalInformationAssetsViewMode,
  49: MemorandumComplexityTechnologyControlsViewMode,
  50: MemorandumComplexityTechnologyModificationViewMode,
  51: MemorandumComplexityTechnologyGeneralControlsViewMode,
  52: MemorandumComplexityTechnologyApplicationViewMode,
  53: MemorandumComplexityTechnologyConclusionViewMode,
  61: TransactionFlowsUseServiceOrganizationsViewMode,
  62: TypesUseServiceOrganizationsViewMode,
  45: RedirectionMemorandumButtonViewMode,
  43: QuestionTextFileViewWithYesNoViewMode,
  40: QuestionTextFileViewMode,
  44: QuestionJustifiedViewMode,
  46: InternalControlComponentsConfigurationViewMode,
  47: InternalControlComponentsQuestionsViewMode,
  48: InternalControlComponentsConclusionViewMode,
  57: MemoReportsAttachmentsViewMode,
  65: MinutesTableViewMode,
  66: ContractsTableViewMode,
  58: DisallowanceIncomesViewMode,
  63: DeterminationUseExpertWorkViewMode,
  64: ScopeAndObjectivesUseExpertWorkViewMode,
  59: ImportantQuestionsMeetingsWithKeyMembersClientViewMode,
  56: MemorandumOtherRisksViewMode,
  67: JournalEntriesRegistersViewMode,
  68: JournalEntriesDateRangeViewMode,
  69: JournalEntriesAttachmentViewMode,
  70: JournalEntriesAnswersViewMode,
  71: TrialBalanceOpeningBalanceViewMode,
  72: ItemOpeningBalancesViewMode,
  73: BasisParagraphViewMode,
  74: KamParagraphOpinionViewMode
};

export const elementComponentsEditionMode: { [key: number]: ElementComponentEditionMode } = {
  1: ParagraphEditMode,
  2: ConditionalParagraphWithSelectorEditMode,
  3: ConditionalParagraphWithVariableEditMode,
  4: ThreeStatesTableEditMode,
  // 5: CustomerRiskClassificationEditMode,
  // 6: ManagementIntegrityEditMode,
  7: IndependenceFormatEditMode,
  // 8: OfacLinkEditMode,
  9: ConditionalParagraphOfacEditMode,
  // 10: MathFormulaDisplayValueEditMode,
  // 11: ConditionalMathFormulaMessageEditMode,
  12: VarianceAnalysisNotesEditMode,
  // 13: MaterialityEditMode,
  14: AttachDocumentEditMode,
  15: FindingsContainerEditMode,
  16: FindingsEvaluationEditMode,
  17: ShowOfacResultsEditMode,
  18: ProbabilityAnalysisEditMode,
  19: RedirectionButtonEditMode,
  20: RequiredAccountConfigurationBankConciliationsEditMode,
  21: QuestionBankConciliationsEditMode,
  22: TrialBalanceFileBankConciliationsEditMode,
  23: PrincipalTableBankConciliationsEditMode,
  24: SamplesBankConciliationsEditMode,
  25: DifferenceReconcilingItemsEditMode,
  26: DocumentSampleEditMode,
  27: QuestionsTableEditMode,
  28: InformationExtractorEditMode,
  29: RequiredAccountConfigurationFixedAssetsEditMode,
  30: SelectAuditPlanFixedAssetsEditMode,
  31: TablePrincipalFixedAssetsEditMode,
  32: PaeMovementFixedAssetsEditMode,
  33: SubstantiveBalanceDataEditMode,
  34: CustomerInformationAssetsEditMode,
  35: RecalculationCustomerFixedAssetsEditMode,
  36: SummaryFixedAssetsCostEditMode,
  37: SummaryDepreciationExpenseFixedAssetsEditMode,
  38: AccumulatedDepreciationFixedAssetsEditMode,
  41: PaeMovementReconcilingItemsEditMode,
  42: PaeMovementDocumentSampleEditMode,
  54: CustomerAdditionsInformationAssetsEditMode,
  55: CustomerWithdrawalInformationAssetsEditMode,  
  49: MemorandumComplexityTechnologyControlsEditMode,
  50: MemorandumComplexityTechnologyModificationEditMode,
  51: MemorandumComplexityTechnologyGeneralControlsEditMode,
  52: MemorandumComplexityTechnologyApplicationEditMode,
  53: MemorandumComplexityTechnologyConclusionEditMode,
  61: TransactionFlowsUseServiceOrganizationsEditMode,
  62: TypesUseServiceOrganizationsEditMode,
  45: RedirectionMemorandumButtonEditMode,
  43: QuestionTextFileViewWithYesNoEditMode,
  40: QuestionTextFileEditMode,
  44: QuestionJustifiedEditMode,
  46: InternalControlComponentsConfigurationEditMode,
  47: InternalControlComponentsQuestionsEditMode,
  48: InternalControlComponentsConclusionEditMode, 
  57: MemoReportsAttachmentsEditMode,
  65: MinutesTableEditMode,
  66: ContractsTableEditMode,
  58: DisallowanceIncomesEditMode,
  63: DeterminationUseExpertWorkEditMode,
  64: ScopeAndObjectivesUseExpertWorkEditMode,
  59: ImportantQuestionsMeetingsWithKeyMembersClientEditMode,
  56: MemorandumOtherRisksEditMode,
  67: JournalEntriesRegistersEditMode,
  68: JournalEntriesDateRangeEditMode,
  69: JournalEntriesAttachmentEditMode,
  70: JournalEntriesAnswersEditMode,
  71: TrialBalanceOpeningBalanceEditMode,
  72: ItemOpeningBalancesEditMode,
  73: BasisParagraphEditMode,
  74: KamParagraphOpinionEditMode
};