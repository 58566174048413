import { useRef, useCallback } from 'react';

/**
 * Debounce a callback function
 * @param callback - The callback function to debounce.
 * @param delay - The delay in milliseconds.
 * @returns The debounced callback function.
 */
function useDebounceCallback<T extends (...args: any[]) => void>(callback: T, delay: number) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedCallback = useCallback((...args: Parameters<T>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, [callback, delay]);

  return debouncedCallback;
}

export default useDebounceCallback;

