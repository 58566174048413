import React, { useEffect, useState } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from './ViewModeProps';
import ViewModeBase from "./ViewModeBase";
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';
import { RegistersMemorandumComplexityTechnologyFirstStageArgs, MemorandumComplexityTechnologyControlsRepository } from '../../repository/special-document/MemorandumComplexityTechnologyControlsRepository';


export interface RegisterType {
  id: string;
  reference_id: number;
  name_reconciling_items: string;
  extracted_value: string;
}

/**
 * Component for displaying the memorandum complexity technology modification view mode.
 * @param {ViewModeProps} props - The properties for the component, including element data.
 */
const MemorandumComplexityTechnologyModificationViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isRefreshLocal, setIsRefreshLocal] = useState(false);
  const [changes, setChanges] = useState<Record<number, Partial<RegistersMemorandumComplexityTechnologyFirstStageArgs>>>({});
  const [baseRegisters, setBaseRegisters] = useState<RegistersMemorandumComplexityTechnologyFirstStageArgs[]>([]);
  const [referenceValidation, setReferenceValidation] = useState<string | undefined>(undefined);
  const [isFinished, setIsFinished] = useState<boolean>(true);
  const [key, setKey] = useState(0);

  const listTypeSections = [
    { id: 1, name: "Yes" },
    { id: 2, name: "No" },
  ];

  useEffect(() => {
    const handleEvent = () => {
      setIsRefreshLocal(prev => !prev);
      setKey(prevKey => prevKey + 1);
    };
    emitter.on("refreshComplexityTechnologyControls", handleEvent);
    return () => {
      emitter.off("refreshComplexityTechnologyControls", handleEvent);
    };
  }, []);

  /**
   * Fetches the registers for complexity technology environment.
   */
  const getRegistersComplexityTechnologyEnvironment = async () => {
    const memorandumComplexityTechnologyControlsRepository = new MemorandumComplexityTechnologyControlsRepository()
    const result = await memorandumComplexityTechnologyControlsRepository.getMemorandumComplexityTechnologyEnvironmentByStage(element.args.engagement_id, element.id, 2)
    if (result.success) {
      const resultValidation = await memorandumComplexityTechnologyControlsRepository.getValidationControlsComplexityTechnology(element.args.engagement_id, element.id)
      if (resultValidation.success) {
        setReferenceValidation(resultValidation.reference);
        setIsFinished(resultValidation.is_finished);
      } else {
        setReferenceValidation(undefined);
        setIsFinished(false);
      }

      setBaseRegisters(result.data);
    } else {
      setBaseRegisters([]);
    }
  }

  useEffect(() => {
    getRegistersComplexityTechnologyEnvironment()
  }, [isRefreshLocal]);

  /**
   * Handles the event of opening the configuration modal.
   */
  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  /**
   * Handles the acceptance of the edit modal, updating the memorandum complexity technology environment.
   */
  const handleEditModalAccept = async () => {
    const memorandumComplexityTechnologyControlsRepository = new MemorandumComplexityTechnologyControlsRepository()
    const result = await memorandumComplexityTechnologyControlsRepository.updateMemorandumComplexityTechnologyEnvironmentByStage(element.args.engagement_id, element.args.document_id, element.id, changes)
    setIsRefreshLocal(prev => !prev);
    setChanges({});
    if (result.success) {
      setShowConfigurationsModal(false);
    }
  };

  /**
   * Sets the value for a specific item in the changes state.
   * @param {number} itemId - The ID of the item to update.
   * @param {number} value - The new value to set.
   */
  const setHasValueYesNo = (itemId: number, value: number) => {
    setChanges(prev => ({
      ...prev,
      [itemId]: { ...prev[itemId], value_yes_no: value }
    }));
  }

  /**
   * Handles the cancellation of the edit modal, resetting the state.
   */
  const handleEditModalCancel = () => {
    setIsRefreshLocal(prev => !prev);
    setShowConfigurationsModal(false)
    setChanges({});
    setKey(prevKey => prevKey + 1);
  };

  /**
   * Handles input changes for the registers.
   * @param {number} itemId - The ID of the register item.
   * @param {keyof RegistersMemorandumComplexityTechnologyFirstStageArgs} field - The field to update.
   * @param {number | string | undefined | null} value - The new value for the field.
   */
  const handleInputChange = (itemId: number, field: keyof RegistersMemorandumComplexityTechnologyFirstStageArgs, value: number | string | undefined | null) => {
    setChanges(prev => ({
      ...prev,
      [itemId]: { ...prev[itemId], [field]: value }
    }));
  };

  return (
    <div key={key}>
      {(isFinished === false && referenceValidation === "second_table") && (
        <>
          <ViewModeBase
            isEditable={true}
            handleEdit={handleEdit}
            permissions={element.permissions as Permissions}
          >
            <div key={key} className="d-flex w-full flex-column mb-3">
              <div className="mb-3 w-100">
                <h4>{T("General computer controls:")}</h4>
                <div className="w-100">
                  {baseRegisters.map((item, index) => (
                    <React.Fragment key={item.id}>
                      <h4>{item.title_name}</h4>
                      <div className="form-group">
                        <label>{item.question}</label>
                        {index === 0 ? (
                          <p>{item.value_description}</p>
                        ) : (
                          <p>
                            {item.value_yes_no === 1
                              ? T("Yes")
                              : item.value_yes_no === 2
                                ? T("No")
                                : ""}
                          </p>)}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </ViewModeBase>
          <ModalWithButtons
            showModal={showConfigurationsModal}
            title={T("General computer controls:")}
            size="xl"
            onAccept={handleEditModalAccept}
            onCancel={handleEditModalCancel}>
            <div>
              <div style={{ width: "100%" }}>
                <div className="mb-3">
                  <div className="d-flex flex-column align-items-center w-100">
                    {baseRegisters.map((item, index) => (
                      <React.Fragment key={item.id}>
                        <div>
                          <h6>{item.title_name}</h6>
                          <div className="form-group">
                            <label>{item.question}</label>
                            {index === 0 ? (
                              <textarea
                                className="form-control"
                                defaultValue={item.value_description || ""}
                                onChange={(e) => handleInputChange(item.id, "value_description", e.target.value)}
                              />
                            ) : (
                              <div className="form-group">
                                <label>{T("Select")}</label>
                                <select
                                  className="form-select"
                                  value={changes[item.id]?.value_yes_no ?? item.value_yes_no ?? ""}
                                  onChange={(e) => setHasValueYesNo(item.id, Number(e.target.value))}
                                >
                                  <option value="">{T("Select an option")}</option>
                                  {listTypeSections.map((type) => (
                                    <option key={type.id} value={type.id}>
                                      {T(type.name)}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </div>
                        </div>
                      </ React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <hr />
              </div>
            </div>
          </ModalWithButtons>
        </>
      )}
    </div>
  );
};

export default MemorandumComplexityTechnologyModificationViewMode;
