import type { Question, QuestionAnswer } from './GoingConcernTypes';
import React, { useState, useEffect, useMemo } from 'react';
import { GoingConcernRepository } from '../../repository/GoingConcernRepository';
import useHistoryState from '../utils/useHistoryState';
import useLocalStorage from '../utils/useLocalStorage';
import { groupBy, stripHtml } from '../../utils/utilities';

function useGoingConcernMemo(engagementId: number) {
  const [localTableIndex, setLocalTable] = useLocalStorage<number>('iara-going-concern-table-index', 0, engagementId);
  const [localTableHistory, setLocalTableHistory] = useLocalStorage<number[]>('iara-going-concern-table-history', [localTableIndex], engagementId);
  const [localAnswers, setLocalAnswers] = useLocalStorage<QuestionAnswer[]>('iara-going-concern-question-answers', [], engagementId);
  const [currentTable, setCurrentTable] = useState<number>(localTableIndex);
  const [questions, setQuestions] = useState<Question[]>([]);
  const groupedQuestions = useMemo(() => Object.entries(groupBy(questions, ({ tableId }) => tableId)), [questions]);
  const [typeOpinion, setTypeOpinion] = useState<string>('');
  const {
    state: tableHistory,
    setState: setTableHistory,
    undo: undoTable
  } = useHistoryState<number>(localTableHistory, localTableHistory.length - 1);

  /**
   * Updates the justification asnwer of the question.
   * @param questionId The id of the question to update.
   */
  async function updateAnswers(answers: QuestionAnswer[]) {
    try {
      const repository = new GoingConcernRepository();
      await repository.updateRegisters(engagementId, answers);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Groups the question answers by the table.
   * @param formData The form data to group.
   * @returns The grouped question answers.
   * @returns The grouped answer options.
   */
  function groupQuestionAnswers(formData: FormData) {
    const answerOptions: number[] = []
    const questionAnswers = Array
      .from(formData.entries())
      .map(([key, value]) => {
        if (typeof value !== "string") return;

        if (key.startsWith('answer_Yes')) {
          answerOptions[0] = Number(value);
          return;
        }
        if (key.startsWith('answer_No')) {
          answerOptions[1] = Number(value);
          return;
        }

        const [_, id] = key.split('_');
        const content = key.startsWith('content_') ? value : '';
        return { id: Number(id), answer: value, content: stripHtml(content) };
      })
      .filter((item): item is { id: number, answer: string, content: string } => Boolean(item))
      .reduce((acc, { id, answer, content }) => {
        const itemIndex = acc.findIndex((item) => item.id === id);
        if (itemIndex === -1) {
          acc.push({ id, answer: answer === "Yes", explanation: content, tableId: currentTable });
        } else {
          acc[itemIndex] = { id, answer: acc[itemIndex].answer, explanation: content, tableId: currentTable };
        }
        return acc;
      }, [] as QuestionAnswer[]);
    return [questionAnswers, answerOptions] as const;
  }

  /**
   * Handles the form submission of the table.
   * @param event The form event.
   */
  function handleTableChange(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);
    const [questionAnswers, answerOptions] = groupQuestionAnswers(formData);

    const hasYesAnswers = questionAnswers.some(({ id, answer }) => answer);
    if (hasYesAnswers) setTableIndex(answerOptions[0]);

    const allNoAnswers = questionAnswers.every(({ id, answer }) => !answer);
    if (allNoAnswers) setTableIndex(answerOptions[1]);

    setCorrectLocalAnswers(questionAnswers);
    updateAnswers(questionAnswers);
  }

  /**
   * Handles the previous table button.
   */
  function handlePreviousTable() {
    undoTable();
    setLocalTableHistory((prev) => prev.slice(0, prev.length - 1));
    const repository = new GoingConcernRepository();
    repository.deleteRegisters(engagementId, localTableHistory[localTableHistory.length - 1]);
  }

  /**
   * Sets the correct local answers for the questions.
   * @param questionAnswers The answers to set on local storage.
   */
  function setCorrectLocalAnswers(questionAnswers: QuestionAnswer[]) {
    const newAnswers = localAnswers.filter(({ id }) => !questionAnswers.some(({ id: newId }) => newId === id));
    setLocalAnswers([...newAnswers, ...questionAnswers]);
  }

  /**
   * Sets the table index and updates the local storage.
   * @param index The index of the table to set.
   */
  function setTableIndex(index: number) {
    setCurrentTable(index);
    setTableHistory(index);
    setLocalTable(index);
    setLocalTableHistory((prevHistory) => {
      const itemIndex = prevHistory.indexOf(index);
      if (itemIndex === -1) return [...prevHistory, index];
      return prevHistory
    });
  }

  /**
   * Gets the checked status of the question.
   * @param questionId The id of the question to check.
   * @param answer The answer to check.
   * @returns `true` if the answer is checked, `false` otherwise.
   */
  function getCheckedStatus(questionId: number, answer: string) {
    const answerIndex = localAnswers.findIndex(({ id }) => id === questionId);
    if (answerIndex === -1) return false;
    const isYes = answer === "Yes";
    return localAnswers[answerIndex].answer === isYes;
  }

  /**
   * Gets the input content of the question.
   * @param questionId The id of the question to set as input content.
   * @returns The explanation of the question to display.
   */
  function getInputContent(questionId: number) {
    const answerIndex = localAnswers.findIndex(({ id }) => id === questionId);
    if (answerIndex === -1) return '';
    return localAnswers[answerIndex].explanation;
  }

  /**
   * Resets the last question of the table.
   * @param answers The answers
   */
  function resetLastQuestion(answers: QuestionAnswer[]) {
    const tableIds = answers.map(({ tableId }) => tableId);
    const lastTableId = tableIds[tableIds.length - 1];
    const history = Array.from(new Set(tableIds));
    setLocalAnswers(answers);
    setLocalTable(lastTableId);
    setLocalTableHistory([...history]);
  }

  /**
   * Gets the type of opinion for the engagement.
   * @returns The type of opinion.
   */
  const getTypeOpinion = async () => {
    const goingConcernRepository = new GoingConcernRepository();
    const result = await goingConcernRepository.getTypeOpinionRegister(engagementId);

    if (result.success) {
      if (result.typeOpinion === "going_concern_unfavorable_opinion") {
        setTypeOpinion("adverse_opinion");
      } else if (result.typeOpinion === "going_concern_qualified_opinion") {
        setTypeOpinion("qualified_opinion");
      }
    } else {
      setTypeOpinion('');
    }
  }

  useEffect(() => {
    setCurrentTable(tableHistory);
    setLocalTable(tableHistory);
    getTypeOpinion();
  }, [tableHistory]);

  useEffect(() => {
    const getQuestions = async () => {
      try {
        const repository = new GoingConcernRepository();
        const data = await repository.getFormatQuestions<Question>(engagementId);
        setQuestions(data);
      } catch (error) {
        console.error(error);
      }
    };
    getTypeOpinion();
    getQuestions();
  }, []);

  return {
    handleTableChange,
    handlePreviousTable,
    getCheckedStatus,
    getInputContent,
    groupQuestionAnswers,
    resetLastQuestion,
    currentTable,
    groupedQuestions,
    typeOpinion
  };
}

export default useGoingConcernMemo;