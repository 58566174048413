import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import { ParagraphsTypesOpinionsRepository } from "../../repository/special-document/ParagraphsTypesOpinionsRepository"
import { ElementParagraph } from "../../models/special-document/ElementParagraph"
import EditModeBase from "./EditModeBase";
import ModalWithButtons from '../modals/AceptCancelModalBase';
import Summernote from '../utils/Summernote';
import EditModeProps from './EditModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import DOMPurify from 'isomorphic-dompurify';


/**
 * Component to display the BasisParagraphOpinion in edit mode
 * @param iElement - interface with the element to display
 * @param handleDeleteElement - function to handle the delete element
 * @param handleUpElement - function to handle the up element
 * @param handleDownElement - function to handle the down element
 */
const BasisParagraphOpinionEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<ElementParagraph>(iElement as ElementParagraph).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [editorContent, setEditorContent] = useState('')
  const [editorContentTemp, setEditorContentTemp] = useState('')
  const [titleOpinion, setTitleOpinion] = useState('')
  const [refresh, setRefresh] = useState(false)

  /**
   * Function to get the data from the repository
   */
  const getTextContent = async () => {
    const paragraphsTypesOpinionsRepository = new ParagraphsTypesOpinionsRepository()
    const textContent = await paragraphsTypesOpinionsRepository.getOpinionByReferenceAndTypeOpinion(element.args.engagement_id, element.args.document_id, element.id, element.args.reference_type_opinion, "basis_paragraph", "edition")
    if (textContent.success) {
      setEditorContent(textContent.data.text_content)
      setEditorContentTemp(textContent.data.text_content)
      setTitleOpinion(textContent.data.title)
    }
  }
  
  useEffect(() => {
    getTextContent()
  },[refresh]);

  /**
   * Function to handle the configuration button
   */
  const handleConfiguration = () => {
    setShowConfigurationsModal(true);
  };

  /**
   * Function to handle the editor change
   * @param content - content of the editor
   */
  const handleEditorChange = (content: string) => {
    setEditorContentTemp(content);
  };

  /**
   * Function to handle the configurations modal accept
   */
  const handleConfigurationsModalAccept = async () => {
    setShowConfigurationsModal(false);    
    const paragraphsTypesOpinionsRepository = new ParagraphsTypesOpinionsRepository()
    const paragraph = await paragraphsTypesOpinionsRepository.UpdateTypeOpinion(element.args.engagement_id, element.args.document_id , "edition",{content: editorContentTemp, reference: element.args.reference_type_opinion})
    setRefresh(!refresh)
  };

  /**
   * Function to handle the configurations modal cancel
   */
  const handleConfigurationsModalCancel = () => {
    setShowConfigurationsModal(false)
    setEditorContentTemp(editorContent)
  };

  return (
    <div>
      {element.args.reference_type_opinion != undefined &&(
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <h5>{titleOpinion}</h5>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(editorContent) }} />
      </EditModeBase>
          )}
      {element.args.reference_type_opinion != undefined ?(
        <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Paragraph settings")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}>
        <Summernote value={editorContent} onChange={handleEditorChange} showModal={showConfigurationsModal} />
      </ModalWithButtons>
      ):(
        <div className="card h-100 bg-light col-11 mb-3">
          <div className="card-body p-3">
            <h6>{T("Element for displaying the foundation paragraph. If you need to edit this document, go to Opinion Types in the Formats section.")}</h6>
          </div>
        </div>
      )}
    </div>
  );
};

export default BasisParagraphOpinionEditMode;
