import ReactDOM from 'react-dom';
import Board from '../components/special-document/Board';
//import DraggableDivDemo from '../components/examples/DraggableDivDemo';


interface SpecialDocumentBoardArgs {
  containerId: string;
  reference: string;
  engagementReference: string | undefined;
  renderingMode: "edition" | "view";
  engagementID: number | null;
  read: boolean;
  create: boolean;
  update: boolean;
  delete: boolean;
  name?: string;
}

/**
* Renders the Board component for special documents.
* @param args - SpecialDocumentBoardArgs containing properties for rendering the board.
*/
export function specialDocumentBoardRenderer(args: SpecialDocumentBoardArgs) {  
  ReactDOM.render(
    <Board 
    name={args.name}
    reference={args.reference}
    engagementReference={args.engagementReference}
    renderingMode={args.renderingMode}
    engagementID={args.engagementID}
    permissions={{
      read: args.read,
      create: args.create,
      update: args.update,
      delete: args.delete
    }}
    />,
    document.getElementById(args.containerId)
  );
  window.menuService?.breadcrumbSetReactContainerId(args.containerId);
}
