import React, { useEffect, useState, CSSProperties } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from '../special-document/ViewModeProps';
import { ConditionalParagraphOption } from '../../models/special-document/ElementArgs';
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import ViewModeBase from "../special-document/ViewModeBase";
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { formatCurrency } from '../../utils/utilities';
import { CustomerInformationFixedAssetsRepository, RegistersCustomerInformationFixedAssets } from '../../repository/substantive-tests/CustomerInformationAssetsRepository';
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';


export interface AccountData {
  id: number;
  consignment_number: number;
  type_account: string;
  account: string;
  name: string;
  amount: number;
  amount_in_other_accounts: number;
  amount_per_statement: number;
  difference_amount: number;
}


const CustomerInformationAssetsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isRefresh, setIsRefresh] = useState(false);
  const [columnTotalCostNiif, setColumnTotalCostNiif] = useState<number>(0);
  const [columnAccumulatedDepreciation, setColumnAccumulatedDepreciation] = useState<number>(0);
  const [columnExpenseInTheYear, setColumnExpenseInTheYear] = useState<number>(0);
  const [columnNetCostCut, setColumnNetCostCut] = useState<number>(0);
  const [data, setData] = useState<RegistersCustomerInformationFixedAssets[]>();
  const [periods, setPeriods] = useState<string[] | undefined>(undefined);
  const [groupedData, setGroupedData] = useState<Record<string, RegistersCustomerInformationFixedAssets[]>>({});
  
  useEffect(() => {
    const handleEvent = () => {
      setIsRefresh(prev => !prev);
    };
    emitter.on("refreshSubstantiveBalanceData", handleEvent);
    return () => {
      emitter.off("refreshSubstantiveBalanceData", handleEvent);
    };
  }, []);

  /**
  * Retrieves the trial balance file registers and sets the grouped data and totals.
  */
  const getRegistersOfTrialBalanceFile = async () => {
    const customerInformationAssetsRepository = new CustomerInformationFixedAssetsRepository()
    const result = await customerInformationAssetsRepository.getCustomerInformationFixedAsset(element.args.engagement_id, element.args.document_id, element.reference)
    try {
    if (result.success) {
      
    const groupedData = result.data.reduce((acc: Record<string, any>, item) => {
      if (item.account) {
        acc[item.account] = acc[item.account] || [];
        acc[item.account].push(item);
      }
      return acc;
    }, {});
    setGroupedData(groupedData);

    setColumnTotalCostNiif(
      result.data.reduce(
        (acc: number, item) =>
          acc + (item.total_cost_niif ? item.total_cost_niif : 0),
        0
      )
    )

    setColumnAccumulatedDepreciation(
      result.data.reduce(
        (acc: number, item) =>
          acc + (item.accumulated_depreciation ? item.accumulated_depreciation : 0),
        0
      )
    )

    setColumnExpenseInTheYear(
      result.data.reduce(
        (acc: number, item) =>
          acc + (item.expense_in_the_year ? item.expense_in_the_year : 0),
        0
      )
    )

    setColumnNetCostCut(
      result.data.reduce(
        (acc: number, item) =>
          acc + (item.net_cost_cut ? item.net_cost_cut : 0),
        0
      )
    )

    setData(result.data)
    }} catch (error) {
      console.log("error: ", error)
    }
  }

  useEffect(() => {
    getRegistersOfTrialBalanceFile()
  }, [isRefresh]);

  const handleEditModalAccept = async () => {
    setShowConfigurationsModal(false);
  };

  const handleEditModalCancel = () => {
    setShowConfigurationsModal(false)
  };


  const [expandedAccounts, setExpandedAccounts] = useState<Record<string, boolean>>({});

  /**
  * Toggles the expanded state for a given account key.
  * @param account - The account identifier to be toggled.
  */
  const toggleAccount = (account: string) => {
    setExpandedAccounts((prev) => ({
      ...prev,
      [account]: !prev[account],
    }));
  };

  const styles = {
    accordionItem: {
      border: "1px solid #ccc",
      marginBottom: "8px",
    },
    accordionHeader: {
      padding: "8px",
      backgroundColor: "#f7f7f7",
      cursor: "pointer",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse" as "collapse",
    },
    tableCell: {
      border: "1px solid #ddd",
      padding: "8px",
    }
  }

  const styleHeader: CSSProperties = {
    width: "8.3%",
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
    whiteSpace: "normal"
  };

  return (
    <div>
      <ViewModeBase
        isEditable={false}
        permissions={element.permissions as Permissions}
      >
        <div className="d-flex w-full flex-column mb-3 card">
          <div className="card-body">
            <h6>{T("Customer Information Fixed Assets")}</h6>
            <table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }} className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th style={ styleHeader } className="p-2">{T("Fixed Assets")}</th>
                  <th style={ styleHeader } className="p-2">{T("NIIF Statement")}</th>
                  <th style={ styleHeader } className="p-2">{T("Account")}</th>
                  <th style={ styleHeader } className="p-2">{T("Description")}</th>
                  <th style={ styleHeader } className="p-2">{T("Date of acquisition")}</th>
                  <th style={ styleHeader } className="p-2">{T("Total cost NIIF")}</th>
                  <th style={ styleHeader } className="p-2">{T("Residual value")}</th>
                  <th style={ styleHeader } className="p-2">{T("Accumulated Depreciation")}</th>
                  <th style={ styleHeader } className="p-2">{T("Expense in the year")}</th>
                  <th style={ styleHeader } className="p-2">{T("Net cost cut-off")}</th>
                  <th style={ styleHeader } className="p-2">{T("useful life months")}</th>
                  <th style={ styleHeader } className="p-2">{T("Times depreciated S. Accounting")}</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(groupedData).map(([account, items]) => (
                  <React.Fragment key={account}>
                    <tr
                      style={{
                        backgroundColor: "#f7f7f7",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                      onClick={() => toggleAccount(account)}
                    >
                      <td
                        colSpan={12}
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                      {`${T("Account")}: ${account} (${items.length} ${T("registers")})`}
                    </td>
                    </tr>
                    {expandedAccounts[account] &&
                      items.map((item) => (
                        <tr key={item.id}>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.fixed_assets}>{item.fixed_assets}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.niif_state}>{item.niif_state}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.account}>{item.account}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.description}>{item.description}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.acquisition_date ? new Date(item.acquisition_date).toLocaleDateString() : ""}>{item.acquisition_date ? new Date(item.acquisition_date).toLocaleDateString() : ""}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.total_cost_niif?.toString()}>{item.total_cost_niif}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.residual_value?.toString()}>{item.residual_value}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.accumulated_depreciation?.toString()}>{item.accumulated_depreciation}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.expense_in_the_year?.toString()}>{item.expense_in_the_year}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.net_cost_cut?.toString()}>{item.net_cost_cut}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.useful_life_months?.toString()}>{item.useful_life_months}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.depreciated_accounting_times?.toString()}>{item.depreciated_accounting_times}</td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
                <tr>
                <td className="p-1" style={{
                    width: "20%",
                    border: "1px solid #ddd",
                    padding: "8px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxHeight: "42.6px",
                  }}>{ }</td>
                <td className="p-1" style={{
                    width: "20%",
                    border: "1px solid #ddd",
                    padding: "8px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxHeight: "42.6px",
                  }}>{ }</td>
                    <td className="p-1" style={{
                    width: "20%",
                    border: "1px solid #ddd",
                    padding: "8px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxHeight: "42.6px",
                  }}>{ }</td>
                    <td className="p-1" style={{
                    width: "20%",
                    border: "1px solid #ddd",
                    padding: "8px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxHeight: "42.6px",
                  }}>{ }</td>
                    <td className="p-1" style={{
                    width: "20%",
                    border: "1px solid #ddd",
                    padding: "8px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxHeight: "42.6px",
                  }}>{ }</td>
                  <td className="p-1" style={{
                    width: "20%",
                    border: "1px solid #ddd",
                    padding: "8px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxHeight: "42.6px",
                  }}>{formatCurrency(columnTotalCostNiif.toFixed(2))}</td>
                  <td className="p-1" style={{
                    width: "20%",
                    border: "1px solid #ddd",
                    padding: "8px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxHeight: "42.6px",
                  }}>{ }</td>
                  <td className="p-1" style={{
                    width: "20%",
                    border: "1px solid #ddd",
                    padding: "8px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxHeight: "42.6px",
                  }}>{formatCurrency(columnAccumulatedDepreciation.toFixed(2))}</td>
                     <td className="p-1" style={{
                    width: "20%",
                    border: "1px solid #ddd",
                    padding: "8px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxHeight: "42.6px",
                  }}>{formatCurrency(columnNetCostCut.toFixed(2))}</td>
                   <td className="p-1" style={{
                    width: "20%",
                    border: "1px solid #ddd",
                    padding: "8px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxHeight: "42.6px",
                  }}>{formatCurrency(columnExpenseInTheYear.toFixed(2))}</td>
                    <td className="p-1" style={{
                    width: "20%",
                    border: "1px solid #ddd",
                    padding: "8px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxHeight: "42.6px",
                  }}>{}</td>
                    <td className="p-1" style={{
                    width: "20%",
                    border: "1px solid #ddd",
                    padding: "8px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxHeight: "42.6px",
                  }}>{}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Bank Reconciliation Review Worksheet")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="p-2">{T("ID Variance")}</th>
                <th className="p-2">{T("Name")}</th>
                <th className="p-2">{T("Code")}</th>
                <th className="p-2">{T("Rec. Adjustments Cr")}</th>
                <th className="p-2">{T("Rec. Adjustments Db")}</th>
                <th className="p-2">{T("Balance At")}</th>
                <th className="p-2">{T("Variation Amount")}</th>
                {periods?.map((period, index) => (
                  <th key={index} className="p-2">{period}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={index}>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default CustomerInformationAssetsViewMode;
