import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'
import { AccountData } from '../../components/substantive-tests/TablePrincipalBankConciliationsViewMode'


export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface RegistersAccumulatedDepreciationFixedAssets {
  id: number
  name: string
  code: string
  sub_code: string
  balance_at: number
  accumulated_depreciation_customer: number
  accumulated_depreciation_recalculate: number
} 


/**
* Repository for managing accumulated depreciation on fixed assets.
* Provides methods to retrieve and update related data via API calls.
*/
export class AccumulatedDepreciationFixedAssetsRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}


  /**
  * Retrieves the accumulated depreciation expense using the provided engagement id, document id, and reference.
  * @param engagement_id - The engagement identifier.
  * @param documentId - The document identifier.
  * @param reference - An optional reference string.
  * @returns A promise that resolves with the success status and data array.
  */
  async getAccumulatedDepreciationExpense(engagement_id: number|null | undefined, documentId: number, reference: string | undefined): Promise<{success: boolean, data: RegistersAccumulatedDepreciationFixedAssets[]}> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/get_accumulated_depreciation_expense/' + engagement_id + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {success: boolean, data: RegistersAccumulatedDepreciationFixedAssets[]} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
  * Updates the bank conciliation table with the provided trial balance data.
  * @param engagementId - The engagement identifier.
  * @param documentId - The document identifier.
  * @param reference - An optional reference string.
  * @param data_trial_balance - An object containing trial balance data.
  * @returns A promise that resolves to a BasicResponse.
  */
  async updateTableBankConciliation(engagementId: number|null | undefined, documentId: number, reference: string | undefined, data_trial_balance: { [key: number]: Partial<AccountData> }): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_api/update_table_bank_conciliation/' + engagementId + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(data_trial_balance)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }  
}
