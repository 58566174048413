import React, { useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import EditModeBase from "./EditModeBase";
import EditModeProps from './EditModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import ModalWithButtons from '../modals/AcceptModalBase';
import { ConditionalParagraphElementWithSelector } from "../../models/special-document/ConditionalParagraphElementWithSelector"


/**
* React component for handling the determination use expert work edit mode.
* @param {EditModeProps} props - The props for the edit mode.
*/
const DeterminationUseExpertWorkEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)

  /**
  * Opens the configurations modal.
  */
  const handleConfiguration = () => {
    setShowConfigurationsModal(true);
  };

  /**
  * Handles the accept action of the configurations modal.
  */
  const handleConfigurationsModalAccept = async () => {
    setShowConfigurationsModal(false);    
  };

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{T("Element for displaying the determination use expert work")}</h6>
            </div>
          </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Element for displaying the determination use expert work")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}>
        <div style={{display: "flex", justifyContent: "flex-end"}}></div>
        <div className="card-body p-3">
          <h6>{T("Element for displaying the determination use expert work")}</h6>
        </div>
      </ModalWithButtons>
    </div>
  )
}
export default DeterminationUseExpertWorkEditMode;
