import React, { useState, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { type Permissions } from "../../models/special-document/ElementBase"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { MemorandumOtherRisksElement } from "../../models/special-document/MemorandumOtherRisksElement"
import { decryptUniqueFilename } from "../../utils/utilities"
import ModalWithButtons from '../modals/AceptCancelModalBase'
import ViewModeProps from './ViewModeProps';
import ViewModeBase from "./ViewModeBase";
import Dropzone from '@/components/commons/dropzone/dropzone-component';
import { MemorandumOtherRisksOption } from '../../models/special-document/ElementArgs'


/**
 * Component to display the Memorandum of other risks in view mode
 * @param iElement - Element to display
 */
const MemorandumOtherRisksViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<MemorandumOtherRisksElement>(iElement as MemorandumOtherRisksElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [sectionRegisters, setSectionRegisters] = useState<MemorandumOtherRisksOption[]>(element.options)
  const [conclusion, setConclusion] = useState(element.conclusion)
  const [key, setKey] = useState(0)

  const answerYesNo = [
    { value: 1, label: T("Yes") },
    { value: 2, label: T("No") }
  ]

  /**
   * Function to handle the configuration of the element
   * @param index - Index of the element
   * @param attachmentName - Name of the attachment
   */
  const handleAttachment = (index: number, attachmentName: string) => {
    const newOptions = [...sectionRegisters]
    newOptions[index].attachment_name = attachmentName
    setSectionRegisters(newOptions)
  }

  /**
   * Function to get the Memorandum of other risks
   */
  const getMemorandumOtherRisks = async () => {
    const elementRepository = new ElementRepository()
    const data = await elementRepository.getElementsByDocumentSpecialId(element.engagement_id, element.documentId, "view")

    if (data) {
      setSectionRegisters(JSON.parse(JSON.stringify(data[0].options)))
      setConclusion(data[0].conclusion)
      setKey(prev => prev + 1)
    }
  }

  /**
   * Function to handle the configuration of the element
   */
  const handleEdit = () => {
    setShowConfigurationsModal(true);
  };

  /**
   * Function to handle the accept of the configurations
   * and close the modal
   */
  const handleConfigurationsModalAccept = async () => {
    setShowConfigurationsModal(false);
    const elementRepository = new ElementRepository()
    const lastConclusion = element.conclusion
    const lastOptions = JSON.parse(JSON.stringify(element.options))
    element.options = JSON.parse(JSON.stringify(sectionRegisters))
    element.conclusion = conclusion

    let success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      element.options = lastOptions
      element.conclusion = lastConclusion
      setSectionRegisters(JSON.parse(JSON.stringify(lastOptions)))
      window.htmlHelpers?.swalError()
    }
  };

  /**
   * Function to handle the cancel of the configurations
   * and close the modal
   */
  const handleConfigurationsModalCancel = () => {
    setShowConfigurationsModal(false)
    getMemorandumOtherRisks()
    setConclusion(element.conclusion)
  };

  /**
   * Function to handle the change of the select
   * @param itemId - Index of the element
   * @param event - Event of the select
   */
  const handleSelectChange = (itemId: number, event: React.ChangeEvent<HTMLSelectElement> ) => {
    const newOptions = [...sectionRegisters]
    newOptions[itemId].value_yes_no = Number(event.target.value)

    if (Number(event.target.value) === 2 && newOptions.length > 2) {
      newOptions[2].value_message = ""
    }
    setSectionRegisters(newOptions)
  }

  /**
   * Function to handle the change of the values
   * @param itemId - Index of the element
   * @param value - Value of the element
   */
  const handleValuesChange = (itemId: number, value: string) => {
    const newOptions = [...sectionRegisters]
    newOptions[itemId].value_message = value
    setSectionRegisters(newOptions)
  };

  /**
   * Function to handle the change of the conclusion
   * @param value - Value of the conclusion
   */
  const handleConclusionChange = (value: string) => {
    setConclusion(value)
  };

  return (
    <div>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}>
        {(element.options != undefined && element.options.length === 0) &&
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{T("Without configuration")}</h6>
              <div className="chart pt-3">
                <p>{T("Configure this element from the template.")}</p>
              </div>
            </div>
          </div>
        }
        {(element.options != undefined && element.options.length > 0) &&
          <div className="card h-100">
            <div className="card-body p-3">
              {element.options != undefined && element.options.length > 0 && (
                <>
                  {element.options.map((option: MemorandumOtherRisksOption, index: number) => {
                    if (
                      index === 0 ||
                      index === 1 ||
                      (index === 2 &&
                        Number(sectionRegisters[0]?.value_yes_no) === 2 &&
                        Number(sectionRegisters[1]?.value_yes_no) === 2)
                    ) {
                      return (
                        <React.Fragment key={index}>
                          <div className="form-group">
                            <h5>{T(option.title)}</h5>
                          </div>
                          <div className="form-group">
                            <p>{T(option.paragraph)}</p>
                          </div>
                          {(index === 0 || index === 1) ? (
                            sectionRegisters[index]?.value_yes_no === 1 ? (
                              <p>{T("Yes")}</p>
                            ) : sectionRegisters[index]?.value_yes_no === 2 ? (
                              <p>{T("No")}</p>
                            ) : (
                              <p>{T("No answer provided")}</p>
                            )
                          ) : null}
                          {(index === 0 || index === 1) && Number(sectionRegisters[index]?.value_yes_no) == 1 && (
                            <div className="form-group">
                              <p><strong>{T("Attachment")}:</strong> <em>{decryptUniqueFilename(sectionRegisters[index]?.attachment_name || "") || T("No attachment provided")}</em></p>
                            </div>
                          )}
                          {index == 2 &&
                            Number(sectionRegisters[0]?.value_yes_no) === 2 &&
                            Number(sectionRegisters[1]?.value_yes_no) === 2 && (
                              <div className="form-group">
                                <p><strong>{T("Control procedures")}:</strong> {option.value_message || T("No message provided")}</p>
                              </div>
                            )}
                        </React.Fragment>
                      );
                    }
                    return null;
                  })}

                  {(Number(sectionRegisters[0]?.value_yes_no) == 1 || Number(sectionRegisters[1]?.value_yes_no) == 1 ||
                    sectionRegisters[2]?.value_message != undefined &&
                    sectionRegisters[2]?.value_message !== "") && (
                      <div className="form-group">
                        <h5>{T("Conclusion")}</h5>
                        <p>{T("If neither control nor substantive procedures are implemented, document the additional audit procedures that will be performed to address the other identified risks.")}</p>
                        <p>{element.conclusion || T("No conclusion provided")}</p>
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
        }
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Memorandum of other risks")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}>
        <hr className="horizontal dark my-3"></hr>
        <div key={key}>
          <>
            {element.options != undefined && element.options.length > 0 && (
              <>
                {sectionRegisters.map((option: MemorandumOtherRisksOption, index: number) => {
                  if (
                    index === 0 ||
                    index === 1 ||
                    (index === 2 &&
                      Number(sectionRegisters[0]?.value_yes_no) === 2 &&
                      Number(sectionRegisters[1]?.value_yes_no) === 2)
                  ) {
                    return (
                      <React.Fragment key={index}>
                        <div className="form-group">
                          <h5>{T(option.title)}</h5>
                        </div>
                        <div className="form-group">
                          <p>{T(option.paragraph)}</p>
                        </div>
                        {(index === 0 || index === 1) && (
                          <div className="form-group">
                            <label htmlFor="optionsSelect">{T("Select Option:")}</label>
                            <select
                              id="optionsSelect"
                              value={sectionRegisters[index]?.value_yes_no || ""}
                              onChange={(e) => handleSelectChange(index, e)}
                              className="form-select"
                            >
                              <option value="" disabled>
                                {T("Select")}
                              </option>
                              {answerYesNo.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {T(option.label)}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        {(index === 0 || index === 1) && Number(sectionRegisters[index]?.value_yes_no) == 1 && (
                          <div className="form-group">

                            <Dropzone
                              elementId={element.id}
                              attachmentName={sectionRegisters[index]?.attachment_name}
                              onUpload={(attachmentName: string) =>
                                handleAttachment(index, attachmentName)
                              }
                            />
                          </div>
                        )}
                        {index == 2 &&
                          Number(sectionRegisters[0]?.value_yes_no) === 2 &&
                          Number(sectionRegisters[1]?.value_yes_no) === 2 && (
                            <div className="form-group">
                              <label htmlFor="attachment">{T("Controls Procedures")}</label>
                              <textarea
                                className="form-control"
                                defaultValue={option.value_message}
                                onChange={(e) =>
                                  handleValuesChange(index, e.target.value)
                                }
                              />
                            </div>
                          )}
                      </React.Fragment>
                    );
                  }
                  return null;
                })}

                {(Number(sectionRegisters[0]?.value_yes_no) == 1 || Number(sectionRegisters[1]?.value_yes_no) == 1 ||
                  sectionRegisters[2]?.value_message != undefined && sectionRegisters[2]?.value_message != "") && (
                    <div className="form-group">
                      <h5>{T("Conclusion")}</h5>
                      <p>{T("If neither control nor substantive procedures are implemented, document the additional audit procedures that will be performed to address the other identified risks.")}</p>
                      <textarea
                        className="form-control"
                        defaultValue={conclusion}
                        onChange={(e) =>
                          handleConclusionChange(e.target.value)
                        }
                      />
                    </div>
                  )}
              </>
            )}
          </>
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default MemorandumOtherRisksViewMode;
