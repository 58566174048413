import React, { useEffect, useRef, useState } from 'react'
import { T } from "../../utils/i18n-config"
import { InternalControlComponentsQuestionsElement } from "../../models/special-document/InternalControlComponentsQuestionsElement"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { Permissions } from '../../models/special-document/ElementBase'
import ViewModeProps from './ViewModeProps';
import ViewModeBase from "./ViewModeBase";
import { emitter} from '../../components/utils/EventEmitter';


const InternalControlComponentsConclusionViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<InternalControlComponentsQuestionsElement>(iElement as InternalControlComponentsQuestionsElement).current;
  const [valoration, setValoration] = useState<string | undefined| null>(undefined)
  const [conclusionValoration, setConclusionValoration] = useState<string | undefined | null>(undefined)
  const [isRefresh, setIsRefresh] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const handleEvent = () => {
      setIsRefresh(prev => !prev);
      setKey(prevKey => prevKey + 1);
    };
    emitter.on("refreshInternalControlComponent", handleEvent);
    return () => {
      emitter.off("refreshInternalControlComponent", handleEvent);
    };
  }, []);

  const getResultOfAnswers = async () => {
    const elementRepository = new ElementRepository()
    const result = await elementRepository.getResultOfAnswersInternalControlComponents(element.engagement_id, element.documentId, element.id)
   
    if (result.success) {
      if (result.valoration == 1) {
        setConclusionValoration(element.initial_conclusion || T("No conclusion"))
        setValoration(T("Initial"))
      } else if (result.valoration == 2) {
        setConclusionValoration(element.basic_conclusion || T("No conclusion"))
        setValoration(T("Basic"))
      } else if (result.valoration == 3) {
        setConclusionValoration(element.intermediate_conclusion || T("No conclusion"))
        setValoration(T("Intermediate"))
      } else if (result.valoration == 4) {
        setConclusionValoration(element.optimal_conclusion || T("No conclusion"))
        setValoration(T("Optimal"))
      } else {
        setValoration(T("Out of range"))
      }
    } else {
      setValoration(undefined)
    }
  }

  useEffect(() => {
    getResultOfAnswers()
  }, [isRefresh]); 

  return (
    <div key={key}>
      <ViewModeBase
        isEditable={false}
        permissions={element.permissions as Permissions}>
        {valoration !== undefined && valoration !== null && (
          <div className="card h-100 d-flex flex-column justify-content-center align-items-center p-2">
              <h6>{T("Internal control systems")}</h6>
              <div className="chart pt-3 text-center">
                <p>{T(valoration || "")}</p>
                <h6>{T(conclusionValoration || "")}</h6>
              </div>
          </div>
          )
        }
      </ViewModeBase>
    </div>
  );
};

export default InternalControlComponentsConclusionViewMode;
