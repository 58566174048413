import { ElementArgs, ScopeAndObjectivesUseExpertWork } from './ElementArgs'
import { ElementBase, IElementBase } from './ElementBase'

export class ScopeAndObjectivesUseExpertWorkElement extends ElementBase implements IElementBase {
  private _options: ScopeAndObjectivesUseExpertWork[]

  constructor(args: ElementArgs) {
    super(args)
    this._options = args.options as ScopeAndObjectivesUseExpertWork[]
  }

  get options(): ScopeAndObjectivesUseExpertWork[] {
    return this._options
  }

  get args(): ElementArgs {
    return {
      ...super.args,
      options: this._options
    }
  }

  set options(options: ScopeAndObjectivesUseExpertWork[]) {
    this._options = options;
    this.args.options = options;
  }


  render(): void {}
}