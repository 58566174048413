import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'
import { RegisterType } from '../../components/substantive-tests/DifferenceReconcilingItemsViewMode'

export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface RegistersDifferenceReconcilingItems {
  id: number
  account?: number
  difference_value?: number
  name_reconciling_items?: string
  extracted_value?: number
  is_total_difference?: boolean
  is_error?: boolean
  bank_conciliation_account_difference_id?: number
  reference_id?: number
}


export class DifferenceReconcilingItemsRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  async getRegistersDifferenceReconcilingItems(engagement_id: number|null | undefined, documentId: number, reference: string | undefined): Promise<RegistersDifferenceReconcilingItems[]> {
    const apiUrl = getUrl('substantive_test_api/get_substantive_test_difference_reconciling_items/' + engagement_id + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: RegistersDifferenceReconcilingItems[] = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async updateDifferenceReconcilingItems(engagementId: number|null | undefined, documentId: number,reference: string | undefined, data_new_changes: Record<string, Array<RegisterType>>, data_changes: { [key: number]: Partial<RegistersDifferenceReconcilingItems> }): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_api/update_substantive_test_difference_reconciling_items/' + engagementId + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({"new_changes":data_new_changes, "changes":data_changes})
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async validateRecordsCreation(engagement_id: number|null | undefined, documentId: number, reference: string | undefined): Promise<{can_create: boolean, data: RegistersDifferenceReconcilingItems[]}> {
    const apiUrl = getUrl('substantive_test_api/validate_records_to_create_difference_reconciling_items/' + engagement_id + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {can_create: boolean, data: RegistersDifferenceReconcilingItems[]} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async deleteDifferenceReconcilingItems(engagement_id: number|null | undefined, documentId: number, reference: string | undefined, registerId: number): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_api/delete_substantive_test_difference_reconciling_items/' + engagement_id + '/' + documentId + '/' + reference + '/' + registerId)
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async markErrorDifferenceReconcilingItems(engagementId: number|null | undefined, documentId: number,register_id: number): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_api/mark_error_difference_reconciling_items/' + engagementId + '/' + documentId + '/' + register_id)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

}
