import React, { useEffect, useRef, useState } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { createId, decryptUniqueFilename } from "../../utils/utilities";
import Summernote from '../utils/Summernote';
import { T } from "../../utils/i18n-config";
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { InternalControlComponentsQuestionsElement } from "../../models/special-document/InternalControlComponentsQuestionsElement"
import { InternalControlComponentsQuestions } from "../../models/special-document/ElementArgs"
import { Permissions } from '../../models/special-document/ElementBase';
import InternalControlComponentsQuestionsOptionEdit from './InternalControlComponentsQuestionsOptionEdit'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeBase from "./EditModeBase"
import EditModeProps from './EditModeProps'

export interface InternalControlComponentsData {
  id: number;
  name: string;
  configuration_value?: number;
  is_question_finding?: boolean;
  is_required_file?: boolean;
}

export interface InternalControlComponentsConfigurations {
  success: boolean,
  data_dropdown: InternalControlComponentsData[],
  data_yes_no: InternalControlComponentsData[],
  data_yes_no_inverse: InternalControlComponentsData[]
}

const InternalControlComponentsQuestionsEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<InternalControlComponentsQuestionsElement>(iElement as InternalControlComponentsQuestionsElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [controlType, setControlType] = useState<number | undefined>(element.type_section_id)
  const [title, setTitle] = useState('')
  const [paragraph, setParagraph] = useState('')
  const [options, setOptions] = useState<InternalControlComponentsQuestions[]>([])
  const [initialConclusion, setInitialConclusion] = useState<string | null>('')
  const [basicConclusion, setBasicConclusion] = useState<string | null>('')
  const [intermediateConclusion, setIntermediateConclusion] = useState<string | null>('')
  const [optimalConclusion, setOptimalConclusion] = useState<string | null>('')
  const [dataDropdown, setDataDropdown] = useState<InternalControlComponentsData[]>([])
  const [dataYesNo, setDataYesNo] = useState<InternalControlComponentsData[]>([])
  const [dataYesNoInverse, setDataYesNoInverse] = useState<InternalControlComponentsData[]>([])
  const [listTypeSections, setListTypeSections] = useState<InternalControlComponentsData[]>([])

  const getListTypeSections = async () => {
    const elementRepository = new ElementRepository()
    const data = await elementRepository.getTypeSectionInternalControlComponents(
      element.engagement_id, element.documentId, element.id
    )
    if (data.success) {
      setListTypeSections(data.type_sections)
    }
  }

  const getInternalControlComponentsConfigurations = async () => {
    const elementRepository = new ElementRepository()
    const data = await elementRepository.getInternalControlComponentsConfiguration(
      element.engagement_id, element.documentId, element.id, "edition"
    )
    if (data.success) {
      setDataDropdown(data.data_dropdown)
      setDataYesNo(data.data_yes_no)
      setDataYesNoInverse(data.data_yes_no_inverse)
    } else {
      setDataDropdown([])
      setDataYesNo([])
      setDataYesNoInverse([])
    }
  }

  useEffect(() => {
    getListTypeSections()
    setTitle(element.title_section)
    setInitialConclusion(element.initial_conclusion ?? "")
    setBasicConclusion(element.basic_conclusion ?? "")
    setIntermediateConclusion(element.intermediate_conclusion ?? "")
    setOptimalConclusion(element.optimal_conclusion ?? "")
    setOptions(JSON.parse(JSON.stringify(element.questions_internal_control)))
    getInternalControlComponentsConfigurations()
  }, []);

  const handleConfiguration = () => {
    setTitle(element.title_section)
    setOptions(JSON.parse(JSON.stringify(element.questions_internal_control)));
    setShowConfigurationsModal(true);
  };

  const handleConfigurationsModalAccept = async () => {
    setShowConfigurationsModal(false);
    const elementRepository = new ElementRepository()
    const lastQuestion = element.title_section
    const lastParagraph = element.paragraph_section
    const lastControlType = element.type_section_id
    const lastInitialConclusion = element.initial_conclusion
    const lastBasicConclusion = element.basic_conclusion
    const lastIntermediateConclusion = element.intermediate_conclusion
    const lastOptimalConclusion = element.optimal_conclusion
    const lastOptions = JSON.parse(JSON.stringify(element.questions_internal_control))
    element.type_section_id = controlType
    element.title_section = title
    element.paragraph_section = paragraph
    element.initial_conclusion = initialConclusion
    element.basic_conclusion = basicConclusion
    element.intermediate_conclusion = intermediateConclusion
    element.optimal_conclusion = optimalConclusion
    element.questions_internal_control = JSON.parse(JSON.stringify(options))

    let success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      element.type_section_id = lastControlType
      element.title_section = lastQuestion
      element.questions_internal_control = lastOptions
      element.paragraph_section = lastParagraph
      element.initial_conclusion = lastInitialConclusion
      element.basic_conclusion = lastBasicConclusion
      element.intermediate_conclusion = lastIntermediateConclusion
      element.optimal_conclusion = lastOptimalConclusion
      setBasicConclusion(lastBasicConclusion)
      setIntermediateConclusion(lastIntermediateConclusion)
      setOptimalConclusion(lastOptimalConclusion)
      setInitialConclusion(lastInitialConclusion)
      setTitle(lastQuestion)
      setOptions(JSON.parse(JSON.stringify(lastOptions)))
      setParagraph(lastParagraph)
      window.htmlHelpers?.swalError()
    }
  };

  const handleConfigurationsModalCancel = () => {
    setShowConfigurationsModal(false)
  };

  const handleAddValueMessage = () => {
    const InternalControlComponentsQuestions: InternalControlComponentsQuestions = {
      id: createId(),
      question: "",
      answer_selected: null,
      description: "",
      attachment_name: "",
      is_father: false,
      is_yes_no: false,
      is_yes_no_reverse: false,
    }
    const newOptions = [...options, InternalControlComponentsQuestions]
    setOptions(newOptions)
  }

  const handleRemoveLastValueMessage = () => {
    const newOptions = [...options]
    newOptions.pop()
    setOptions(newOptions)
  }

  const handleEditQuestion = (index: number, question: string) => {
    const newOptions = [...options]
    newOptions[index].question = question
    setOptions(newOptions)
  }

  const handleSetTypeQuestion = (index: number, isFather: boolean) => {
    const newOptions = [...options]
    newOptions[index].is_father = isFather
    setOptions(newOptions)
  }

  const handleSetHasDropdown = (index: number, hasDropdown: boolean) => {
    const newOptions = [...options]
    newOptions[index].is_yes_no = hasDropdown
    setOptions(newOptions)
  }

  const handleSetHasYesNoInverse = (index: number, hasYesNoInverse: boolean) => {
    const newOptions = [...options]
    newOptions[index].is_yes_no_reverse = hasYesNoInverse
    setOptions(newOptions)
  }

  const handleEditJustification = (index: number, justification: string) => {
    const newOptions = [...options]
    newOptions[index].description = justification
    setOptions(newOptions)
  }

  const handleEditAttachmentName = (index: number, attachment_name: string) => {
    const newOptions = [...options]
    newOptions[index].attachment_name = attachment_name
    setOptions(newOptions)
  }

  const handleEditInitialConclusion = (initialConclusion: string) => {
    setInitialConclusion(initialConclusion)
  }

  const handleEditBasicConclusion = (basicConclusion: string) => {
    setBasicConclusion(basicConclusion)
  }

  const handleEditIntermediateConclusion = (intermediateConclusion: string) => {
    setIntermediateConclusion(intermediateConclusion)
  }

  const handleEditOptimalConclusion = (optimalConclusion: string) => {
    setOptimalConclusion(optimalConclusion)
  }

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <div>
          {element.questions_internal_control.length == 0 &&
            <div className="card h-100 bg-light">
              <div className="card-body p-3">
                <h6>{T("Complete the required data")}</h6>
              </div>
            </div>
          }
          {element.questions_internal_control.length > 0 &&
            <div className="card h-100">
              <div className="card-body p-3">
                <h6>{element.title_section}</h6>
                <div className="chart pt-3">
                  <ul>
                    {element.questions_internal_control.map((option) => {
                      return (
                        <li key={option.id}>
                            <div className="text-sm font-weight-normal" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(option.question ?? "")}} />
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          }
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            className="btn btn-primary btn-sm me-2"
            type="button"
            style={{ padding: "8px 8px", fontSize: "12px" }}
            onClick={handleAddValueMessage}>
            {T("Add Option/Message")}
          </button>
          <button
            className="btn btn-outline-danger"
            type="button"
            style={{ padding: "8px 8px", fontSize: "12px" }}
            onClick={handleRemoveLastValueMessage}>
            {T("Remove last Option/Message")}
          </button>
        </div>
          <div className="form-group">
          <label>{T("Control Type")}</label>
          <select className="form-select" value={controlType} onChange={(e) => setControlType(Number(e.target.value))}>
            <option value="">{T("Select an option")}</option>
            {listTypeSections.map((type: InternalControlComponentsData) => {
              return (
                <option key={type.id} value={type.id}>{type.name}</option>
              )
            })}
          </select>
        </div>
        <div className="form-group">
          <label>{T("Title")}</label>
          <input type="text" className="form-control" placeholder={T('Enter title')} value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className="form-group">
          <label>{T("Paragraph")}</label>
          <Summernote value={element._paragraph_section} onChange={(e) => setParagraph(e)} showModal={showConfigurationsModal} />
        </div>

        <hr className="horizontal dark my-3"></hr>
        <div>
          {options.map((option, index) => {
            return (
              <InternalControlComponentsQuestionsOptionEdit
                key={`${option.id}-${options.length}`}
                index={index}
                elementId={element.id}
                question={option.question}
                isParentQuestion={option.is_father}
                hasDropdown={option.is_yes_no}
                hasYesNoInverse={option.is_yes_no_reverse}
                attachmentName={option.attachment_name ?? ""}
                showModal={showConfigurationsModal}
                handleEditQuestion={handleEditQuestion}
                handleSetTypeQuestion={handleSetTypeQuestion}
                handleSetHasDropdown={handleSetHasDropdown}
                handleSetHasYesNoInverse={handleSetHasYesNoInverse}
                handleEditAttachmentName={handleEditAttachmentName}
              />
            )
          })}
        </div>
        <hr className="horizontal dark my-3"></hr>
        <h5 className="mb-0">{T("Initial Conclusion")}</h5>
        <Summernote
              value={initialConclusion || ""}
              onChange={(e) => handleEditInitialConclusion(e)}
              showModal={showConfigurationsModal}
            />
        <h5 className="mb-0">{T("Basic Conclusion")}</h5>
        <Summernote
              value={basicConclusion || ""}
              onChange={(e) => handleEditBasicConclusion(e)}
              showModal={showConfigurationsModal}
            />

        <h5 className="mb-0">{T("Intermediate Conclusion")}</h5>
        <Summernote
              value={intermediateConclusion || ""}
              onChange={(e) => handleEditIntermediateConclusion(e)}
              showModal={showConfigurationsModal}
            />
        <h5 className="mb-0">{T("Optimal Conclusion")}</h5>
        <Summernote
              value={optimalConclusion || ""}
              onChange={(e) => handleEditOptimalConclusion(e)}
              showModal={showConfigurationsModal}
            />
      </ModalWithButtons>
    </div>
  );
};

export default InternalControlComponentsQuestionsEditMode;
