import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'

export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface RegistersDocumentSample {
  id: number
  name_reconciling_items?: string
  substantive_test_difference_reconciling_items_id?: number
  reference_substantive_test?: number
  description?: string
  attachment?: {id: number, name: string}[]
  attachment_name?: string[]
}


export class DocumentSampleRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  async updateDocumentSamples(engagementId: number|null | undefined, documentId: number,data_changes:Record<number, RegistersDocumentSample[]>): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_api/update_documents_samples/' + engagementId + '/' + documentId )
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({"changes":data_changes})
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async getDocumentSamples(engagement_id: number|null | undefined, documentId: number, reference: string | undefined): Promise<{can_create: boolean, type_conclusion: string, data: RegistersDocumentSample[] }> {
    const apiUrl = getUrl('substantive_test_api/get_documents_samples/' + engagement_id + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {can_create: boolean, type_conclusion: string, data: RegistersDocumentSample[] } = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async setValidateRecordsCreation(engagement_id: number|null | undefined, documentId: number, reference: string | undefined): Promise<{can_create: boolean, data: RegistersDocumentSample[]}> {
    const apiUrl = getUrl('substantive_test_api/set_validate_records_to_create_document_sample/' + engagement_id + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {can_create: boolean, data: RegistersDocumentSample[]} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async deleteAttachmentDocumentSample(engagement_id: number|null| undefined, documentId: number, attachmentId: number): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_api/delete_attachment_document_sample/' + engagement_id + '/' + documentId + '/' + attachmentId)
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}
