import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from '../special-document/ViewModeProps';
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import ViewModeBase from "../special-document/ViewModeBase";
import { formatCurrency } from '../../utils/utilities';
import { AccumulatedDepreciationFixedAssetsRepository, RegistersAccumulatedDepreciationFixedAssets } from '../../repository/special-document/AccumulatedDepreciationFixedAssetsRepository';
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';


export interface AccountData {
  id: number;
  consignment_number: number;
  type_account: string;
  account: string;
  name: string;
  amount: number;
  amount_in_other_accounts: number;
  amount_per_statement: number;
  difference_amount: number;
}


const AccumulatedDepreciationFixedAssetsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement)
  const [isRefresh, setIsRefresh] = useState(false);
  const [isRefreshLocal, setIsRefreshLocal] = useState(false);
  const [registersOfTable, setRegistersOfTable] = useState<RegistersAccumulatedDepreciationFixedAssets[]>([]);
  const [totalColumnBalanceAt, setTotalColumnBalanceAt] = useState<number>(0);
  const [totalColumnAccumulatedDepreciationCustomer, setTotalColumnAccumulatedDepreciationCustomer] = useState<number>(0);
  const [totalColumnAccumulatedDepreciationRecalculate, setTotalColumnAccumulatedDepreciationRecalculate] = useState<number>(0);
  const [totalVariationBalanceAt, setTotalVariationBalanceAt] = useState<number>(0);
  const [totalVariationRecalculate, setTotalVariationRecalculate] = useState<number>(0);
  
  useEffect(() => {
    const handleEvent = () => {
      setIsRefresh(prev => !prev);
    };
    emitter.on("refreshSubstantiveBalanceData", handleEvent);
    return () => {
      emitter.off("refreshSubstantiveBalanceData", handleEvent);
    };
  }, []);

   /**
   * Calculates the variation between two numeric values and returns it as a fixed 2-decimal string.
   * @param first_value - The first numeric value (defaulting to 0 if undefined).
   * @param second_value - The second numeric value (defaulting to 0 if undefined).
   */
  const calculateVariation = (first_value: number |  undefined, second_value: number | undefined) => {
    first_value = first_value || 0
    second_value = second_value || 0
    const total = first_value - second_value
    return total.toFixed(2)
  }

  /**
  * Retrieves accumulated depreciation data for fixed assets and updates component state with computed totals.
  * This function uses the AccumulatedDepreciationFixedAssetsRepository to fetch data based on engagement, document, and reference.
  */
  const getRegistersAcumulatedDepreciation = async () => {
    const accumulatedDepreciationFixedAssetsRepository = new AccumulatedDepreciationFixedAssetsRepository()
    const result = await accumulatedDepreciationFixedAssetsRepository.getAccumulatedDepreciationExpense(element.args.engagement_id, element.args.document_id, element.reference)
    
    if (result.success) {
      setTotalColumnBalanceAt(result.data.reduce((acc, item) => acc + (Number(item.balance_at) || 0), 0));
      setTotalColumnAccumulatedDepreciationCustomer(result.data.reduce((acc, item) => acc + (Number(item.accumulated_depreciation_customer) || 0), 0));
      setTotalColumnAccumulatedDepreciationRecalculate(result.data.reduce((acc, item) => acc + (Number(item.accumulated_depreciation_recalculate) || 0), 0));
      const totalDifferenceBalanceAt = result.data.reduce((acc, item) => {
        const balanceAt = Number(item.balance_at) || 0;
        const acumulatedDepreciationCustomer = Number(item.accumulated_depreciation_customer) || 0;
        return acc + (balanceAt + acumulatedDepreciationCustomer);
      }, 0);
      setTotalVariationBalanceAt(totalDifferenceBalanceAt);
      const totalDifferenceRecalculate = result.data.reduce((acc, item) => {
        const accumulatedDepreciationRecalculate = Number(item.accumulated_depreciation_recalculate) || 0;
        const accumulatedDepreciationCustomer = Number(item.accumulated_depreciation_customer) || 0;
        return acc + (accumulatedDepreciationRecalculate - accumulatedDepreciationCustomer);
      }, 0);
      setTotalVariationRecalculate(totalDifferenceRecalculate);
      setRegistersOfTable(result.data);
    }
  }

  useEffect(() => {
    getRegistersAcumulatedDepreciation()
  }, [isRefresh, isRefreshLocal]);

  return (
    <div>
      <ViewModeBase
        isEditable={false}
        permissions={element.permissions as Permissions}
      >
        <div className="d-flex w-full flex-column mb-3 card">
          <div className="card-body">
            <h4>{T("Accumulated Depreciation Fixed Assets Russell Vs Modulo Calculation")}</h4>
            <table className="table-bordered">
              <thead>
                <tr>
                  <th className="p-2">{T("Account")}</th>
                  <th className="p-2">{T("Accumulated depreciation account")}</th>
                  <th className="p-2">{T("Description")}</th>
                  <th className="p-2">{T("Balance of the cut-off module")}</th>
                  <th className="p-2">{T("Accounting record cut")}</th>
                  <th className="p-2">{T("Variation in the accounting module")}</th>
                  <th className="p-2">{T("Balance according to Ruseell")}</th>
                  <th className="p-2">{T("Modulus variation vs recalculation")}</th>
                </tr>
              </thead>
              <tbody>
                {registersOfTable.map((item) => (
                  <tr key={item.code}>
                    <td className="p-1">{item.code}</td>
                    <td className="p-1">{item.sub_code}</td>
                    <td className="p-1">{item.name}</td>
                    <td className="p-1">{item.balance_at}</td>
                    <td className="p-1">{item.accumulated_depreciation_customer}</td>             
                    <td className="p-1">{formatCurrency(calculateVariation(item.balance_at, item.accumulated_depreciation_customer))}</td>
                    <td className="p-1">{item.accumulated_depreciation_recalculate}</td> 
                    <td className="p-1">{formatCurrency(calculateVariation(item.accumulated_depreciation_customer, item.accumulated_depreciation_recalculate))}</td>
                  </tr>
                ))}
                <tr key={"total-banks"}>
                  <td className="p-1">{ }</td>
                  <td className="p-1">{ }</td>
                  <td className="p-1">{T("Total Banks")}</td>
                  <td className="p-1">{ formatCurrency(Number(totalColumnBalanceAt).toFixed(2))}</td>
                  <td className="p-1">{ formatCurrency(Number(totalColumnAccumulatedDepreciationCustomer).toFixed(2))}</td>
                  <td className="p-1">{ formatCurrency(Number(totalVariationBalanceAt).toFixed(2))}</td>
                  <td className="p-1">{ formatCurrency(Number(totalColumnAccumulatedDepreciationRecalculate).toFixed(2))}</td>
                  <td className="p-1">{ formatCurrency(Number(totalVariationRecalculate).toFixed(2))}</td>
                </tr>
              </tbody>
            </table>
        </div>
        </div>
      </ViewModeBase>
    </div>
  );
};

export default AccumulatedDepreciationFixedAssetsViewMode;
