import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'


export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface Balances {
  value: number;
  percentage: number;
  period?: Date;
}

export interface RegistersWithdrawalCustomerInformationFixedAssets {
  id?: number
  withdrawal_reference?: string
  withdrawal_description?: string
  activation_date?: Date
  deactivation_date?: Date
  useful_life?: number
  withdrawal_cost?: number
  depreciation?: number
  net_balance?: number
  act_date_sale?: Date
  description?: string
  profit_loss_according_accounting_record?: number
  difference_net_balance?: number
  asset_sale_value_according_invoice?: number
  profit_loss?: number
  difference_profit_loss?: number
} 


/**
* Repository class for managing customer withdrawal information of fixed assets.
*
* Provides methods to retrieve, update, and delete withdrawal data via API calls.
*/
export class CustomerWithdrawalInformationFixedAssetsRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}
  
  /**
  * Retrieves withdrawal customer information for fixed assets.
  * @param engagement_id - Identifier of engagement
  * @param documentId - Document identifier
  * @param reference - Optional reference parameter
  * @returns Promise resolving with success flag and retrieved data
  */
  async getWithdrawalCustomerInformationFixedAsset(engagement_id: number|null | undefined, documentId: number, reference: string | undefined): Promise<{success: boolean, data: RegistersWithdrawalCustomerInformationFixedAssets[]}> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/get_withdrawal_customer_information_fixed_asset/' + engagement_id + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {success: boolean, data: RegistersWithdrawalCustomerInformationFixedAssets[]} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
  * Updates the registers for customer withdrawal information of fixed assets.
  * @param engagementId - Identifier of engagement
  * @param documentId - Document identifier
  * @param reference - Optional reference parameter
  * @param data_new_changes - New data changes as a record
  * @param data_changes - Partial data changes
  * @returns Promise resolving with success flag and record id if updated
  */
  async updateRegistersWithdrawalCustomerInformation(engagementId: number| undefined| null,documentId: number, reference: string | undefined, data_new_changes: Record<number, Partial<RegistersWithdrawalCustomerInformationFixedAssets[]>>, data_changes: { [key: number]: Partial<RegistersWithdrawalCustomerInformationFixedAssets> }): Promise<{ success: boolean, id?: number }> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/update_customer_withdrawal_fixed_asset/' + engagementId + '/' + documentId + '/' + reference)

    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({"data_new_changes":data_new_changes, "data_changes": data_changes})
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return { success: false };
      }

      const data: {success: boolean, id: number} = await response.json()
      return { success: true, id: data.id };
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false }
    }
  }

  /**
  * Deletes customer withdrawal information of a fixed asset.
  * @param engagement_id - Identifier of engagement
  * @param documentId - Document identifier
  * @param reference - Optional reference parameter
  * @param registerId - Identifier of the register to delete
  * @returns Promise resolving with a basic response
  */
  async deleteWithdrawalCustomerInformationFixedAsset(engagement_id: number|null | undefined, documentId: number, reference: string | undefined, registerId: number | undefined): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/delete_withdrawal_customer_information_fixed_asset/' + engagement_id + '/' + documentId + '/' + reference + '/' + registerId)
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}
