import { ItemsOpeningBalances, ElementArgs } from './ElementArgs'
import { ElementBase, IElementBase } from './ElementBase'

/**
 * Represents an element for managing item opening balances.
 */
export class ItemOpeningBalancesElement extends ElementBase implements IElementBase {
  private _max_account_code: number;
  private _type_conclusion: number | undefined;
  private _items_opening_balances: ItemsOpeningBalances[];

  /**
   * Initializes an instance of ItemOpeningBalancesElement.
   * @param args - The element arguments containing initial values.
   */
  constructor(args: ElementArgs) {
    super(args);
    this._max_account_code = args.max_account_code ?? 0;
    this._items_opening_balances = (args.items_opening_balances as ItemsOpeningBalances[]) ?? [];
    this.type_conclusion = args.type_conclusion;
  }

  /**
   * Gets the maximum account code.
   * @returns The maximum account code as a number.
   */
  get max_account_code(): number {
    return this._max_account_code;
  }

  /**
   * Gets the list of item opening balances.
   * @returns An array of ItemsOpeningBalances.
   */
  get items_opening_balances(): ItemsOpeningBalances[] {
    return this._items_opening_balances;
  }

  /**
   * Gets the type of conclusion.
   * @returns The type of conclusion as a number or undefined.
   */
  get type_conclusion(): number | undefined {
    return this._type_conclusion;
  }

  /**
   * Sets the maximum account code.
   * @param max_account_code - The new maximum account code.
   */
  set max_account_code(max_account_code: number) {
    this._max_account_code = max_account_code;
    this.args.max_account_code = max_account_code;
  }

  /**
   * Sets the list of item opening balances.
   * @param items_opening_balances - The new array of item opening balances.
   */
  set items_opening_balances(items_opening_balances: ItemsOpeningBalances[]) {
    this._items_opening_balances = items_opening_balances;
    this.args.items_opening_balances = items_opening_balances;
  }

  /**
   * Sets the type of conclusion.
   * @param type_conclusion - The new type of conclusion.
   */
  set type_conclusion(type_conclusion: number | undefined) {
    this._type_conclusion = type_conclusion;
    this.args.type_conclusion = type_conclusion;
  }

  /**
   * Renders the element. (Implementation pending)
   */
  render(): void {}
}
