import React, { useState, useRef, useEffect } from 'react';
import { T } from "../../utils/i18n-config"
import EditModeBase from "../special-document/EditModeBase";
import EditModeProps from '../special-document/EditModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import ModalWithButtons from '../modals/AcceptModalBase';


const PaeMovementFixedAssetsEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isEditable , setIsEditable] = useState<boolean>(false);

  const handleConfiguration = () => {
    setShowConfigurationsModal(true);
  };

  const handleConfigurationsModalAccept = async () => {
    setShowConfigurationsModal(false);    
  };

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={isEditable}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{T("Asset display element")}</h6>
            </div>
          </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Asset display element")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}>
        <div style={{display: "flex", justifyContent: "flex-end"}}></div>
        <div className="card-body p-3">
          <h6>{T("Asset display element")}</h6>
        </div>
      </ModalWithButtons>
    </div>
  )
}
export default PaeMovementFixedAssetsEditMode;
