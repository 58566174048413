import { Constants } from '@/utils/Constants';

/**
 * Returns the preview image URL for a given file type.
 *
 * @param fileType - The file type or extension.
 * @returns The preview image URL.
 */
export function getPreviewImage(fileType: string): string {
  if (fileType in Constants.FILE_EXTENSIONS) {
    return `${Constants.FILE_IMAGE_PATH}/${Constants.FILE_EXTENSIONS[fileType as keyof typeof Constants.FILE_EXTENSIONS]}`;
  }
  return `${Constants.FILE_IMAGE_PATH}/${Constants.FILE_IMAGES[fileType as keyof typeof Constants.FILE_IMAGES]}`;
}

/**
 * Builds the accepted files configuration based on allowed file extensions.
 *
 * @param fileExtensions - The list of allowed file extensions.
 * @returns The accepted files configuration.
 */
export function getAcceptedFiles(fileExtensions: string[]): Record<string, string[]> {
  const fileImagesKeys = Object.keys(Constants.FILE_IMAGES);
  const fileExtensionsKeys = Object.keys(Constants.FILE_EXTENSIONS);

  if (fileImagesKeys.length !== fileExtensionsKeys.length) {
    throw new Error('Mismatch in FILE_IMAGES and FILE_EXTENSIONS lengths');
  }

  const fileEntries = fileImagesKeys.map((key, index) => [key, [fileExtensionsKeys[index]]]) as [string, string[]][];
  const defaultAccepted = Object.fromEntries(fileEntries);
  
  if (fileExtensions.length === 0) return defaultAccepted;
  const accepted = fileEntries.filter(([_, value]) =>
    value.some(ext => fileExtensions.includes(ext))
  );
  return Object.fromEntries(accepted);
}
