import { useState, useEffect } from "react"
import { T } from '../../utils/i18n-config'
import { sanitizeHTML } from '../../utils/utilities'
import { ElementRepository } from '../../repository/special-document/ElementRepository'
import Summernote from '../utils/Summernote'

interface ConclusionProps {
  element: { conclusion: string, args: any }
  subtitle?: string
}

/**
 * Renders the Conclusion component with editing functionality
 * @param element The element to display
 * @param subtitle The subtitle to display
 */
export default function Conclusion({ element, subtitle }: ConclusionProps) {
  const [isEditing, setIsEditing] = useState(false)
  const [conclusion, setConclusion] = useState(element.conclusion)

  /**
   * Updates the conclusion field and reverts changes if saving fails.
   */
  function updateConclusion() {
    const elementRepository = new ElementRepository()
    const lastConclusion = element.conclusion
    element.conclusion = conclusion

    const success = elementRepository.saveElement("view", element.args)
    if (!success) {
      window.htmlHelpers?.swalError()
      element.conclusion = lastConclusion
      setConclusion(lastConclusion)
    }
  }

  useEffect(() => {
    if (!isEditing && conclusion !== element.conclusion) updateConclusion()
  }, [isEditing, conclusion, element.conclusion])

  return (
    <div className="mt-5">
      <h6>{T("Conclusion")}</h6>
      {subtitle && <p className="text-secondary">{subtitle}</p>}
      <div className="d-flex align-items-center gap-4 mb-2">
        <b>{T("Justify your answer")}</b>
        <button className="p-2 bg-secondary border-0 rounded text-white text-sm lh-1" onClick={() => setIsEditing(!isEditing)}>
          <i className={isEditing ? "fa-solid fa-check" : "fa-solid fa-pencil"} />
        </button>
      </div>
      {isEditing ? (
        <Summernote value={conclusion} onChange={setConclusion} />
      ) : (
        conclusion && <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(conclusion) }} />
      )}
    </div>
  )
}