import { useState, useEffect, useRef } from 'react'
import { T } from '../../utils/i18n-config'
import { Constants } from '../../utils/Constants'
import { Permissions } from '../../models/special-document/ElementBase'
import { JournalEntriesElement } from '../../models/substantive-tests/JournalEntriesElement'
import { JournalEntriesRepository } from '../../repository/substantive-tests/JournalEntriesRepository'
import ViewModeProps from '../special-document/ViewModeProps'
import ViewModeBase from '../special-document/ViewModeBase'

/**
 * A React component to render and manage the view mode for journal entry date range.
 */
const JournalEntriesViewMode = ({ iElement }: ViewModeProps) => {
  const element = useRef<JournalEntriesElement>(iElement as JournalEntriesElement).current
  const [dateRange, setDateRange] = useState<string>("")

  /**
   * Saves the date range in the element.
   * @param {string} dateRange - The date range.
   */
  async function saveDateRange(dateRange: string) {
    const repository = new JournalEntriesRepository()
    let { success } = await repository.setDateRange(element.engagement_id, element.documentId, element.id, dateRange)
    if (!success) return window.htmlHelpers?.swalError()
  }

  /**
   * Handles the change of the date range.
   * @param {string} value - The new date range value.
   */
  function handleDateChange(value: string) {
    setDateRange(value)
    saveDateRange(value)
  }

  useEffect(() => {
    /**
     * Retrieves the date range from the repository and updates the state with the received data.
     */
    async function fetchData() {
      const repository = new JournalEntriesRepository()
      const { success, data } = await repository.getDateRange(element.engagement_id, element.documentId, element.id)
      if (!success) return window.htmlHelpers?.swalError()
      setDateRange(data)
    }

    fetchData()
  }, [])

  return (
    <ViewModeBase
      isEditable={false}
      permissions={element.permissions as Permissions}
    >
      <div>
        <div className="form-check">
          <input
            type="radio"
            id="all_year"
            name="journal_date_range"
            className="form-check-input"
            checked={dateRange === Constants.JOURNAL_DATE_RANGE_ALL_YEAR}
            onChange={() => handleDateChange(Constants.JOURNAL_DATE_RANGE_ALL_YEAR)}
          />
          <label className="form-check-label" htmlFor="all_year">
            {T("Records for the whole year")}
          </label>
        </div>
        <div className="form-check">
          <input
            type="radio"
            id="last_month"
            name="journal_date_range"
            className="form-check-input"
            checked={dateRange === Constants.JOURNAL_DATE_RANGE_LAST_MONTH}
            onChange={() => handleDateChange(Constants.JOURNAL_DATE_RANGE_LAST_MONTH)}
          />
          <label className="form-check-label" htmlFor="last_month">
            {T("Records for the last month")}
          </label>
        </div>
      </div>
    </ViewModeBase>
  );
}

export default JournalEntriesViewMode;