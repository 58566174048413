import React, { useEffect, useState } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from '../special-document/ViewModeProps';
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import ViewModeBase from "../special-document/ViewModeBase";
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { formatCurrency } from '../../utils/utilities';
import { TrialBalanceBankConciliationsRepository } from '../../repository/special-document/TrialBalanceBankConciliationsRepository';
import { GeneralDataBankConciliationsRepository, RegistersOfTrialBalanceFile } from '../../repository/special-document/GeneralDataBankConciliationsRepository';
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';


export interface AccountData {
  id: number;
  consignment_number: number;
  type_account: string;
  account: string;
  name: string;
  amount: number;
  amount_in_other_accounts: number;
  amount_per_statement: number;
  difference_amount: number;
}


/**
 * Component for the view mode of the PrincipalTableBankConciliations element
 * @param props - The props for the component
 * @returns JSX.Element
 */
const PrincipalTableBankConciliationsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isRefresh, setIsRefresh] = useState(false);
  const [isRefreshLocal, setIsRefreshLocal] = useState(false);
  const [changes, setChanges] = useState<{ [key: number]: Partial<AccountData> }>({});
  const [showBalanceOtherAccounts, setShowBalanceOtherAccounts] = useState<boolean | undefined>(false);
  const [materialityReport, setMaterialityReport] = useState<number | undefined>(0);
  const [sampleQuantity, setSampleQuantity] = useState<number | undefined>(0);
  const [registersOfTable, setRegistersOfTable] = useState<RegistersOfTrialBalanceFile[]>([]);
  const [totalColumnAmount, setTotalColumnAmount] = useState<number>(0);
  const [totalColumnAmountInOther, setTotalColumnAmountInOther] = useState<number>(0);
  const [totalColumnAmountPerStatement, setTotalColumnAmountPerStatement] = useState<number>(0);
  const [totalColumnDifference, setTotalColumnDifference] = useState<number>(0);
  const [isAllPositive, setIsAllPositive] = useState<boolean>(true);
  const [contentConclusion, setContentConclusion] = useState<string | undefined>(undefined);

  /**
   * Function to handle the event of changing the input value
   * @param id - The id of the item being edited
   * @param field - The field being edited
   * @param value - The new value
   * @returns void
   */
  const handleInputChange = (id: number | undefined, field: keyof AccountData, value: number | string | undefined | null) => {
    const newData = registersOfTable.map(item => item.id === id ? { ...item, [field]: value } : item);
    if (id === undefined) return;
    setChanges(prev => ({ ...prev, [id]: { ...prev[id], [field]: value } }));
  };

  useEffect(() => {
    const handleEvent = () => {
      setIsRefresh(prev => !prev);
    };
    emitter.on("refreshSubstantiveBalanceData", handleEvent);
    return () => {
      emitter.off("refreshSubstantiveBalanceData", handleEvent);
    };
  }, []);

  /**
   * Function to get the registers of the trial balance file
   */
  const getRegistersOfTrialBalanceFile = async () => {
    const trialBalanceBankConciliationsRepository = new TrialBalanceBankConciliationsRepository()
    const result = await trialBalanceBankConciliationsRepository.getRegistersOfTrialBalanceFile(element.args.engagement_id, element.args.document_id, element.reference)
    
    if (Array.isArray(result)) {
      setRegistersOfTable(result);
      setTotalColumnAmount(result.reduce((acc, item) => acc + parseFloat(item.amount || 0), 0));
      setTotalColumnAmountInOther(result.reduce((acc, item) => acc + parseFloat(item.amount_in_other_accounts || 0), 0));
      setTotalColumnAmountPerStatement(result.reduce((acc, item) => acc + parseFloat(item.amount_per_statement || 0), 0));
      setTotalColumnDifference(result.reduce((acc, item) => acc + parseFloat(item.difference_amount || 0), 0));
      
      const allDifferencesZero = result.every(item => parseFloat(item.difference_amount || 0) === 0);
      if (allDifferencesZero && result.length > 0) {
        setContentConclusion(T("Based on the work performed in the testing of bank reconciliations, we conclude that no material errors have been identified that could compromise the reasonableness of the account."));
      } else {
        setContentConclusion(undefined);
      }

      if (result.length > 0) {
        setIsAllPositive(result.every(item => item.amount >= 0));
      }
    }
  }

  /**
   * Function to get the answer and materiality of the substantive test
   */
  const getAnswerSubstantiveTest = async () => {
    const questionBankConciliationsRepository = new GeneralDataBankConciliationsRepository()
    const result = await questionBankConciliationsRepository.getAnswerAndMaterialitySubstantiveTest(element.args.engagement_id, element.reference, element.args.document_id)
    if (result.answer != undefined) {
      setShowBalanceOtherAccounts(result.answer)
    }
    if (result.materiality != undefined) {
      setMaterialityReport(result.materiality)
      setSampleQuantity(result.sample_quantity)
    }
    return result
  }

  /**
   * Function to update the difference amount in the table
   */
  const updateDifferenceAmount = async () => {
    const trialBalanceBankConciliationsRepository = new TrialBalanceBankConciliationsRepository()
    const result = await trialBalanceBankConciliationsRepository.updateDifferenceAmountTableBankConcilitaion(
      element.args.document_id, element.reference
    )
  }

  /**
   * Function to handle the event of opening the configuration modal
   */
  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  useEffect(() => {
    setChanges({})
    const response = getAnswerSubstantiveTest()
    getRegistersOfTrialBalanceFile()
  }, [isRefresh, isRefreshLocal]);

  /**
   * Function to handle the event of accepting the configuration modal
   */
  const handleEditModalAccept = async () => {
    const trialBalanceBankConciliationsRepository = new TrialBalanceBankConciliationsRepository()
    const result = await trialBalanceBankConciliationsRepository.updateTableBankConcilitaion(element.args.engagement_id, element.args.document_id, element.reference, changes)
    if (result.success) {
      updateDifferenceAmount()
      setIsRefreshLocal(prev => !prev);
      emitter.emit("refreshSubstantiveBalanceData");
      setShowConfigurationsModal(false);
    }
  };

  /**
   * Function to handle the event of canceling the configuration modal
   */
  const handleEditModalCancel = () => {
    setShowConfigurationsModal(false)
  };

  /**
   * Function to handle the event of changing the input value
   * @param itemId - The id of the item
   * @param value - The new value
   */
  const handleNumericInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedValue = event.target.value.replace(/[^0-9.-]/g, '');
    event.target.value = cleanedValue;
  };

  /**
   * Function to handle the event of formatting the input value
   * @param e - The event object
   */
  function handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
    const value = parseFloat(e.target.value.replace(/[^0-9.-]/g, ''));
    e.target.value = formatCurrency(value);
  }
  
  /**
   * Function to handle the event of formatting the input value
   * @param e - The event object
   */
  function handleFocus(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.value = e.target.value.replace(/[^0-9.-]/g, '');
  }

  return (
    <div>
      <ViewModeBase
        handleEdit={handleEdit}
        isEditable={true}
        permissions={element.permissions as Permissions}
      >
        <div className="d-flex w-full flex-column mb-3">
            <div className="d-flex justify-content-between mb-3">
              <h6>{T("Bank Reconciliation Review Spreadsheet")}</h6>
              <table className="table-bordered">
                <thead>
                  <tr>
                    <th className="p-2">{T("Materiality")}</th>
                    <th className="p-2">{T("Samples")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-2">{formatCurrency(materialityReport)}</td>
                    <td className="p-2">{sampleQuantity}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <table className="table-bordered">
              <thead>
                <tr>
                  <th className="p-2">{T("Account number")}</th>
                  <th className="p-2">{T("Type of account")}</th>
                  <th className="p-2">{T("Account")}</th>
                  <th className="p-2">{T("Name")}</th>
                  <th className="p-2"><span className="flex-grow-1 text-nowrap">{T("Balance in books")}</span> {!isAllPositive && (<span className="text-danger" title={T("Bank accounts are overdrawn")}><i className="fa fa-triangle-exclamation py-2" aria-hidden="true"></i></span>)}</th>
                  {showBalanceOtherAccounts && (
                    <th className="p-2">{T("Balances in other statement of financial position accounts")}</th>
                  )}
                  <th className="p-2">{T("Balance according to statements")}</th>
                  <th className="p-2">{T("Differences")}</th>
                </tr>
              </thead>
              <tbody>
                {registersOfTable.map((item) => (
                  <tr key={item.code}>
                    <td className="p-1">{item.consignment_number}</td>
                    <td className="p-1">{item.type_account ? item.type_account : T("Savings account")}</td>
                    <td className="p-1">{item.code}</td>
                    <td className="p-1">{item.name}</td>
                    <td className="p-1"><span style={{ color: (item.amount || 0) < 0 ? 'red' : 'inherit' }}>{formatCurrency(item.amount)}</span></td>
                    {showBalanceOtherAccounts && (
                      <td className="p-1">{formatCurrency(item.amount_in_other_accounts)}</td>
                    )}
                    <td className="p-1">{formatCurrency(item.amount_per_statement)}</td>
                    <td className="p-1">{formatCurrency(item.difference_amount)}</td>
                  </tr>
                ))}
                <tr key={"total-banks"}>
                  <td className="p-1">{T("Total Banks")}</td>
                  <td className="p-1">{ }</td>
                  <td className="p-1">{ }</td>
                  <td className="p-1">{ }</td>
                  <td className="p-1">{formatCurrency(totalColumnAmount.toFixed(2))}</td>
                  {showBalanceOtherAccounts && (
                    <td className="p-1">{formatCurrency(totalColumnAmountInOther.toFixed(2))}</td>
                  )}
                  <td className="p-1">{formatCurrency(totalColumnAmountPerStatement.toFixed(2))}</td>
                  <td className="p-1">{formatCurrency(totalColumnDifference.toFixed(2))}</td>
                </tr>
              </tbody>
            </table>
          <div className="w-100 d-flex align-items-center text-center flex-column mt-2">
            {contentConclusion != undefined && (
              <div className="mt-3">
                <h6>{T("Conclusion")}</h6>
                <p>{contentConclusion}</p>
              </div>
            )}
            </div>
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Bank Reconciliation Review Worksheet")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <div>
          <table className="table-bordered">
            <thead>
              <tr>
                <th className="p-2">{T("Account number")}</th>
                <th className="p-2">{T("Type of account")}</th>
                <th className="p-2">{T("Account")}</th>
                <th className="p-2">{T("Name")}</th>
                <th className="p-2"><span className="flex-grow-1 text-nowrap">{T("Balance in books")}</span> {!isAllPositive && (<span className="text-danger" title={T("Bank accounts are overdrawn")}><i className="fa fa-triangle-exclamation py-2" aria-hidden="true"></i></span>)}</th>
                {showBalanceOtherAccounts && (
                  <th className="p-2" style={showBalanceOtherAccounts ? undefined : { backgroundColor: "#ccc", cursor: "not-allowed" }}>{T("Balances in other statement of financial position accounts")}</th>
                )}
                <th className="p-2">{T("Balance according to statements")}</th>
                <th className="p-2">{T("Differences")}</th>
              </tr>
            </thead>
            <tbody>
              {registersOfTable.map(item => (
                <tr key={item.id}>
                  <td className="p-1">
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={item.consignment_number}
                      onChange={e => handleInputChange(item.id, 'consignment_number', String(e.target.value))}
                    />
                  </td>
                  <td className="p-1">
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={item.type_account ? item.type_account : T("Savings account")} onChange={e => handleInputChange(item.id, 'type_account', String(e.target.value))}
                    />
                  </td>
                  <td className="p-1">{item.code}</td>
                  <td className="p-1">{item.name}</td>
                  <td className="p-1"><span style={{ color: (item.amount || 0) < 0 ? 'red' : 'inherit' }}>{formatCurrency(item.amount)}</span></td>
                  {showBalanceOtherAccounts && (
                  <td className="p-1" style={showBalanceOtherAccounts ? undefined : { backgroundColor: "#ccc", cursor: "not-allowed" }}>
                    <input
                      disabled={!showBalanceOtherAccounts}
                      defaultValue={item.amount_in_other_accounts}
                      style={showBalanceOtherAccounts ? undefined : { color: "#ccc", cursor: "not-allowed" }}
                      className="form-control"
                      type="text"
                      inputMode="numeric"
                      onInput={handleNumericInput}
                      onChange={e => handleInputChange(item.id, 'amount_in_other_accounts', Number(e.target.value))}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    /></td>
                  )}
                  <td className="p-1"><input
                    className="form-control"
                    defaultValue={formatCurrency(item.amount_per_statement)}
                    type="text"
                    inputMode="numeric"
                    onInput={handleNumericInput}
                    onChange={e => handleInputChange(item.id, 'amount_per_statement', Number(e.target.value))} 
                    onFocus={handleFocus}
                    onBlur={handleBlur} /></td>
                  <td className="p-1">{formatCurrency(item.difference_amount)}</td>
                </tr>
              ))}
              <tr key={"total-banks"}>
                <td className="p-1">{T("Total Banks")}</td>
                <td className="p-1">{ }</td>
                <td className="p-1">{ }</td>
                <td className="p-1">{ }</td>
                <td className="p-1">{formatCurrency(totalColumnAmount.toFixed(2))}</td>
                {showBalanceOtherAccounts && (
                  <td className="p-1">{formatCurrency(totalColumnAmountInOther.toFixed(2))}</td>
                )}
                <td className="p-1">{formatCurrency(totalColumnAmountPerStatement.toFixed(2))}</td>
                <td className="p-1">{formatCurrency(totalColumnDifference.toFixed(2))}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default PrincipalTableBankConciliationsViewMode;
