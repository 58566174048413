import { T } from "@/utils/i18n-config"
import TableComponent from "@/components/commons/TableComponent"

interface InvoiceSelectionProps {
  invoices: any[]
  labels: string[]
  inputColumns: number[]
  typeColumns: {
    index: number
    type: "integer" | "float" | "date"
  }[]
  updateInvoice: (invoiceId: number, deliveryDate: Date, adjustment: boolean) => void
}

/**
 * Table to select the invoices to be tested.
 * @param invoices The list of invoices
 * @param labels The labels for the columns
 * @param inputColumns The columns that can be edited
 * @param typeColumns The types of the columns
 * @param updateInvoice The function to update an invoice
 */
export function InvoiceSelection({ invoices, labels, inputColumns, typeColumns, updateInvoice }: InvoiceSelectionProps) {
  
  /**
   * Handles the calculation of delivery date and adjustment flag, then updates the invoice.
   * @param row The row index
   * @param col The column index
   * @param value The new value
   */
  function handleInputChange(row: number, col: number, value: string) {
    const changedRow = invoices[row]
    const estimatedDate = new Date(changedRow[4])
    const deliveryDate = new Date(value)

    // Check if the delivery date is next year after the estimated date
    const adjustment = deliveryDate.getFullYear() >= estimatedDate.getFullYear() + 1
    updateInvoice(changedRow[0], deliveryDate, adjustment)
  }

  return (
    <div className="d-flex flex-column gap-2">
      <h6>{T("Invoice Selection")}</h6>
      <p>{T("Request the corresponding end of period (December) invoice and/or dispatch report. Download the template provided and complete the required information to select the invoices to be tested.")}</p>
      <TableComponent
        labels={labels}
        values={invoices}
        inputColumns={inputColumns}
        onChange={handleInputChange}
        typeColumns={typeColumns}
      />
    </div>
  )
}