import { getUrl } from '../../utils/app-config';
import { fetchAPI } from '../../services/api_services/fetchAPI';

interface UploadedAttachment {
  success: boolean;
  filename: string;
}

interface UploadedAttachments {
  success: boolean;
  filename: string[];
}

type RenderingMode = "edition" | "view"

export class ElementAttachmentRepository {
  private readonly getRegistersApiUrl: string = getUrl("elements_api/get_attachment")
  private readonly updateRegisterApiUrl: string = getUrl("elements_api/upload_attachment")
  private readonly updateMultipleRegisterApiUrl: string = getUrl("elements_api/upload_multiple_attachments")
  private readonly removeRegisterApiUrl: string = getUrl("elements_api/remove_attachment")

  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) { }

  /**
    * Gets the attachment from the element.
    * @param renderingMode The rendering mode of the element.
    * @param elementId The id of the element to get the attachment.
    * @param attachmentName The name of the attachment to get.
    * @returns The attachment blob or a boolean indicating if the attachment does not exist.
   */
  async getAttachment(renderingMode: RenderingMode, elementId: number, attachmentName: string): Promise<{success: boolean} | Blob> {
    const apiUrl = `${this.getRegistersApiUrl}/${elementId}/${renderingMode}?attachment=${attachmentName}`
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const contentType = response.headers.get('Content-Type');
      if (contentType && contentType === 'application/json') {
        const data = await response.json()
        return data
      }

      return await response.blob()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false }
    }
  }

  /**
    * Uploads an attachment to the element.
    * @param renderingMode The rendering mode of the element.
    * @param element_id The id of the element to upload the attachment.
    * @param attachment The attachment to upload.
    * @returns The filename of the uploaded attachment.
   */
  async uploadAttachment(renderingMode: RenderingMode, element_id: number, attachment: FormData): Promise<UploadedAttachment> {
    const apiUrl = `${this.updateRegisterApiUrl}/${element_id}/${renderingMode}`
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          body: attachment,
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, filename: '' }
    }
  }


  /**
    * Uploads multiple attachments to the element.
    * @param renderingMode The rendering mode of the element.
    * @param element_id The id of the element to upload the attachment.
    * @param attachment The attachment to upload.
    * @returns The filename of the uploaded attachment.
   */
  async uploadMultipleAttachments(renderingMode: RenderingMode, element_id: number, attachment: FormData): Promise<UploadedAttachments> {
    const apiUrl = `${this.updateMultipleRegisterApiUrl}/${element_id}/${renderingMode}`
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          body: attachment,
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, filename: [] }
    }
  }


  /**
    * Removes the attachment from the element.
    * @param renderingMode The rendering mode of the element.
    * @param elementId The id of the element to remove the attachment.
    * @param attachmentName The name of the attachment to remove.
    * @returns A boolean indicating if the attachment was removed successfully.
   */
  async removeAttachment(renderingMode: RenderingMode, elementId: number, attachmentName: string): Promise<boolean> {
    const apiUrl = `${this.removeRegisterApiUrl}/${elementId}/${renderingMode}?attachment=${attachmentName}`
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'DELETE',
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }
  }
}
