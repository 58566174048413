import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'


export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface RegistersSummaryFixedAssetsCost {
  id: number
  code?: number
  name?: string
  balances?: {
    balance?: number
    period_end?: string
  }
  balance_of_the_cutoff_module?: number
  observation_fixed_assets_summary?: string
} 

export class SummaryFixedAssetsCostRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  /**
 * Retrieves the fixed cost calculation registers summary.
 * @param engagement_id - Identifier for the engagement.
 * @param documentId - Identifier for the document.
 * @param reference - Optional reference string.
 * @returns A promise resolving to an object containing success status and data.
 */
  async getRegistersSummaryFixedCostCalculation(engagement_id: number|null | undefined, documentId: number, reference: string | undefined): Promise<{success: boolean, data: RegistersSummaryFixedAssetsCost[]}> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/get_summary_fixed_cost_calculation/' + engagement_id + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {success: boolean, data: RegistersSummaryFixedAssetsCost[]} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

   /**
   * Updates the observed fixed assets summary by submitting trial balance data.
   * @param engagementId - Identifier for the engagement.
   * @param documentId - Identifier for the document.
   * @param reference - Optional reference string.
   * @param data_trial_balance - Object containing trial balance key/value pairs.
   * @returns A promise that resolves to a BasicResponse.
   */
  async updateObservationFixedAssetsSummary(engagementId: number|null | undefined, documentId: number, reference: string | undefined, data_trial_balance: { [key: string]: string}): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/update_observation_fixed_assets_summary/' + engagementId + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(data_trial_balance)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}
