import Swal from 'sweetalert2'
import { T } from "./i18n-config"
import ReactDOM from 'react-dom';
export type ToastType = 'success' | 'info' | 'warning' | 'danger';
import { Constants } from '../utils/Constants';
import { Html2DocxRepository } from '../repository/special-document/Html2WordRepository';
import { Html2PdfRepository } from '../repository/special-document/Html2PdfRepository';


export class HtmlHelpers {

  unmountComponentAtNode(containerId: string) {
    const container = document.getElementById(containerId);
    if (!container) return;
    ReactDOM.unmountComponentAtNode(container);
  }

  customSwalConfirm(msg: string, callback: (registerId: number) => void, registerId: number): void {
    Swal.fire({
      title: T('Are you sure?'),
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: T('Yes'),
      cancelButtonText: T('No')
    }).then((result) => {
      if (result.isConfirmed) {
        callback(registerId)
      }
    })
  }

  swalError(): void {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: T('Something went wrong!'),
    })
  }

  customSwalError(message: string): void {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    })
  }

  customSwalInfo(message: string): void {
    Swal.fire({
      icon: 'info',
      title: T('Information'),
      text: message,
    })
  }

  setLogo(logo: string, url: string): void {
    const logoContainer = document.getElementById("lyo-company-logo-container")
    const urlCtr = document.getElementById("lyo-company-url")
    const logoCtr = document.getElementById("lyo-company-logo")

    if (urlCtr) {
      urlCtr.setAttribute("href", url)
    }

    if (logoCtr) {
      logoCtr.setAttribute("src", logo)
    }

    if (logoContainer) {
      logoContainer.removeAttribute("style")
      logoContainer.setAttribute("style", "display: block;")
    }

  }

  getSpinner(): string {
    return `
    <div id="loading-spinner" style="display: flex; justify-content: center; align-items: center; height: 50%;">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>`;
  }

  getSpinnerFull(): string {
    return `
    <div id="loading-spinner" style="display: flex; justify-content: center; align-items: center; height: 100vh; width: 100vw;">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    `;
  }

  setSpinner(elementID: string): void {
    const element = document.getElementById(elementID)
    if (element) {
      element.innerHTML = this.getSpinner()
    }
  }

  setMenuAvatar(elementID: string, initials: string, fullName: string, background_color: string, font_color: string): void {
    const element = document.getElementById(elementID)
    if (element) {
      element.innerHTML = `
        <div class="avatar-circle avatar avatar-xl shadow" style="background-color: ${background_color};color: ${font_color};">${initials}</div>
        <div class="card-body pb-2">
          <h4 class="card-title">${fullName}</h4>
        </div>
      `
    }
  }

  showToast(type: ToastType, message: string, title: string | null = null, ms: number = 5000): void {
    const toastContainer = document.getElementById('toast-container');
    if (!toastContainer) return;
    if (!title) title = T(type.charAt(0).toUpperCase() + type.slice(1));

    const iconClass = {
      'success': 'ni ni-check-bold text-success',
      'info': 'ni ni-bell-55 text-white',
      'warning': 'ni ni-spaceship text-warning',
      'danger': 'ni ni-notification-70 text-danger'
    };

    const backgroundColorClass = {
      'success': 'bg-white',
      'info': 'bg-gradient-info',
      'warning': 'bg-white',
      'danger': 'bg-white'
    };

    const toastId = `toast-${Math.random().toString(36).substring(2, 11)}`;
    const toastHtml = `
      <div class="toast fade hide p-2 ${backgroundColorClass[type]}" role="alert" aria-live="assertive" id="${toastId}" aria-atomic="true">
        <div class="toast-header border-0">
          <i class="${iconClass[type]} me-2"></i>
          <span class="me-auto font-weight-bold">${title}</span>
          <small class="text-body">${T('Just now')}</small>
          <i class="fas fa-times text-md ms-3 cursor-pointer" data-bs-dismiss="toast" aria-label="Close"></i>
        </div>
        <hr class="horizontal dark m-0">
        <div class="toast-body ${type == "info" ? 'text-white' : ''}">
          ${message}
        </div>
      </div>
    `;

    toastContainer.innerHTML += toastHtml;

    const toastElement = document.getElementById(toastId);
    if (toastElement) {
      toastElement.classList.remove('hide');
      toastElement.classList.add('show');

      setTimeout(() => {
        const toastElement = document.getElementById(toastId);
        if (toastElement) {
          toastElement.classList.remove('show');
          toastElement.classList.add('hide');
        }
      }, ms);
    }
  }

  createPopup(idModel: string, titleModal: string, idModelContent: string): string {
    return `
    <div class="modal fade" id="${idModel}" tabindex="-1" role="dialog" aria-labelledby="${idModel}Label"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog modal-xl modal-xl-tall" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="${idModel}Label">${titleModal}</h5>
          <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" id="${idModelContent}">
          
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </div>
    `;
  }

  closeModal(idModel: string = 'ctr-pop-up'): void {
    const modal: HTMLElement | null = document.getElementById(idModel);
    if (modal !== null) {
      modal.innerHTML = '';
    }
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
    document.body.removeAttribute('data-bs-overflow');

    document.querySelectorAll('.modal-backdrop').forEach(element => element.remove());
    document.body.classList.remove('modal-open');
  }

  sortTable(tableId: string, column: number, order: "asc" | "desc"): void {
    const table: HTMLTableElement = document.getElementById(tableId) as HTMLTableElement;
    let rows: HTMLCollectionOf<HTMLTableRowElement>, switching: boolean, shouldSwitch: boolean;
    let i: number;
    let x: string | number, y: string | number;
    let dir: string = order;


    function isNumericValue(value: string): boolean {
      if (value === "") return false;
      const num = parseFloat(value.replace(/,/g, '').replace(/[()]/g, ''));
      return !isNaN(num) && isFinite(num);
    }

    do {
      switching = false;
      rows = table.rows;
      shouldSwitch = false;
      for (i = 1; i < rows.length - 1; i++) {
        const xElement: HTMLElement = rows[i].getElementsByTagName("TD")[column].querySelector("p")!;
        const yElement: HTMLElement = rows[i + 1].getElementsByTagName("TD")[column].querySelector("p")!;

        x = xElement ? xElement.textContent!.toLowerCase().trim() : "";
        y = yElement ? yElement.textContent!.toLowerCase().trim() : "";

        if (x === "") x = dir === "asc" ? "\uffff" : "\u0000";
        if (y === "") y = dir === "asc" ? "\uffff" : "\u0000";

        const isNumeric = isNumericValue(x) && isNumericValue(y);

        if (isNumeric) {
          const negativeX = x.startsWith('(') && x.endsWith(')');
          if (negativeX) {
            x = x.replace(/,/g, '').replace(/[()]/g, '');
            x = -parseFloat(x);
          } else {
            x = parseFloat(x.replace(/,/g, ''));
          }

          const negativeY = y.startsWith('(') && y.endsWith(')');

          if (negativeY) {
            y = y.replace(/,/g, '').replace(/[()]/g, '');
            y = -parseFloat(y);
          } else {
            y = parseFloat(y.replace(/,/g, ''));
          }
        }

        if ((dir === "asc" && x > y) || (dir === "desc" && x < y)) {
          shouldSwitch = true;
          break;
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode!.insertBefore(rows[i + 1], rows[i]);
        switching = true;
      }
    } while (switching);
  }

  exportHtmlToWord(fileName: string): void {
    
    const base64ToBlob = (base64: string, mime: string): Blob => {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mime });
    }

    const downloadBlob = (blob: Blob): void => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    const exportHtmlToWordWithTemplate = async (htmlContent: string): Promise<void> => {
      try {
        const html2DocxRepository = new Html2DocxRepository();
        const header = '<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><title>Document</title></head><body>';
        const footer = '</body></html>';
        const fullHtml = header + htmlContent + footer;
        const data = await html2DocxRepository.convertToDocx(fullHtml);

        if (data.status !== 'success') {
          this.showToast('danger', data.message);
          throw new Error(data.complete_error);
        }

        const base64Content = data.base64_content;
        const mime = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        const blob = base64ToBlob(base64Content, mime);
        downloadBlob(blob);
        this.showToast('success', T('Document exported successfully'));

      } catch (error) {
        console.error(T('Error exporting document'), error);  
      }
    };

    let htmlContent = ''
    const contentByClass = document.getElementsByClassName(Constants.CONTENT_CLASS);
    if (contentByClass.length > 0) {
      for (let i = 0; i < contentByClass.length; i++) {
        htmlContent += contentByClass[i].innerHTML;
      }
    } else {
      return;
    }
    exportHtmlToWordWithTemplate(htmlContent);

  }

  /**
   * Export the HTML content of the page to a PDF file
   * @param {string} fileName - The name of the file to be saved
   */
  exportHtmlToPdf(fileName: string, includeBreakLines = false): void {
    /**
     * Convert a base64 string to a Blob object.
     * @param {string} base64 - The base64 string to convert.
     * @param {string} mime - The MIME type of the Blob.
     * @returns {Blob} - The resulting Blob object.
     */
    const base64ToBlob = (base64: string, mime: string): Blob => {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mime });
    }

    /**
     * Trigger a download of a Blob object.
     * @param {Blob} blob - The Blob object to download.
     */
    const downloadBlob = (blob: Blob): void => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    /**
     * Export HTML content to a PDF file using a template.
     * @param {string} htmlContent - The HTML content to convert to PDF.
     * @returns {Promise<void>} - A promise that resolves when the export is complete.
     */
    const exportHtmlToPdfWithTemplate = async (htmlContent: string): Promise<void> => {
      try {
        const html2PdfRepository = new Html2PdfRepository();
        const header = '<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><title>Document</title></head><body>';
        const footer = '</body></html>';
        const fullHtml = header + htmlContent + footer;
        const data = await html2PdfRepository.convertToPdf(fullHtml);

        if (data.status !== 'success') {
          this.showToast('danger', data.message);
          throw new Error(data.complete_error);
        }

        const base64Content = data.base64_content;
        const mime = "application/pdf";
        const blob = base64ToBlob(base64Content, mime);
        downloadBlob(blob);
        this.showToast('success', T('Document exported successfully'));

      } catch (error) {
        console.error(T('Error exporting document'), error);  
      }
    };

    let htmlContent = ''
    const contentByClass = document.getElementsByClassName(Constants.CONTENT_CLASS);
    if (contentByClass.length > 0) {
      for (let i = 0; i < contentByClass.length; i++) {
        htmlContent += contentByClass[i].innerHTML;
        if (includeBreakLines) htmlContent += '<br><br>';
      }
      exportHtmlToPdfWithTemplate(htmlContent);
    }
  }

  scrollToTop(): void {
    const mainElement = document.querySelector('main#main-container')!
    mainElement.scrollTo({ top: 0 });
  }
}
