import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'

export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface RegistersTransactionFlowGeneral {
  id: number
  name: string
}

export interface RegistersTransactionFlow {
  id: number
  name: string
  transaction_flow_category_id: number
}

export class RedirectionMemorandumButtonRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  /**
  * Retrieves the registers transaction flow category from the API.
  * @returns {Promise<{ success: boolean, data: RegistersTransactionFlowGeneral[]}>} The API response with status and data.
  */
  async getRegistersTransactionFlowCategory(): Promise<{ success: boolean, data: RegistersTransactionFlowGeneral[]}> {
    const apiUrl = getUrl('elements_api/get_transaction_flow_category_redirect_memorandum')
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, data: RegistersTransactionFlowGeneral[]} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Retrieves the registers transaction flow from the API.
   * @returns {Promise<{ success: boolean, data: RegistersTransactionFlow[]}>} The API response with status and data.
   */
  async getRegistersTransactionFlow(): Promise<{ success: boolean, data: RegistersTransactionFlow[]}> {
    const apiUrl = getUrl('elements_api/get_transaction_flow_redirect_memorandum')
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, data: RegistersTransactionFlow[]} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Retrieves the registers transaction flow controls from the API.
   * @param {number} transaction_flow_id - The ID of the transaction flow.
   * @returns {Promise<{ success: boolean, data: RegistersTransactionFlowGeneral[]}>} The API response with status and data.
   */
  async getRegistersTransactionFlowControls(transaction_flow_id: number | null): Promise<{ success: boolean, data: RegistersTransactionFlowGeneral[]}> {
    const apiUrl = getUrl('elements_api/get_transaction_flow_controls_redirect_memorandum/' + transaction_flow_id)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, data: RegistersTransactionFlowGeneral[]} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}
