import { getUrl } from '../utils/app-config';
import { fetchAPI } from '../services/api_services/fetchAPI';
import { QuestionAnswer } from '../components/going-concern/GoingConcernTypes';


interface QuestionRegisters {
  id: number
  answer?: boolean
  explanation: string
}

export class GoingConcernRepository {
  private readonly getFormatQuestionsApiUrl: string = getUrl("engagement_going_concern_memorandum_api/get_going_concern_hypothetical_format_questions")
  private readonly updateRegistersApiUrl: string = getUrl("engagement_going_concern_memorandum_api/update_registers")
  private readonly updateTypeOpinionApiUrl: string = getUrl("engagement_going_concern_memorandum_api/update_type_opinion_going_concern")
  private readonly getTypeOpinionApiUrl: string = getUrl("engagement_going_concern_memorandum_api/get_type_opinion_engagement")
  private readonly getRegistersApiUrl: string = getUrl("engagement_going_concern_memorandum_api/get_registers")
  private readonly deleteRegistersApiUrl: string = getUrl("engagement_going_concern_memorandum_api/delete_latest_register")
  private readonly updateRegistersToFinishedApiUrl: string = getUrl("engagement_going_concern_memorandum_api/update_registers_to_finished")
  private readonly getAllQuestionsFinishedApiUrl: string = getUrl("engagement_going_concern_memorandum_api/get_all_questions_finished")
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }

  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  /**
   * Fetches all the questions for the memorandum.
   * @returns A list of questions for the memorandum.
   */
  async getFormatQuestions<T>(engagementId: number): Promise<T[]> {
    const apiUrl = `${this.getFormatQuestionsApiUrl}/${engagementId}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Updates the registers for the memorandum.
   * @param engagementId The engagement id.
   * @param registers The registers to update.
   * @returns A boolean indicating if the update was successful.
   */
  async updateRegisters(engagementId: number, registers: QuestionRegisters[]): Promise<boolean> {
    const apiUrl = `${this.updateRegistersApiUrl}/${engagementId}`
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({ registers })
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return false
      }

      const data: {success: boolean} = await response.json()
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }
  }

  /**
    * Updates the type opinion register for the memorandum.
    * @param engagementId The engagement id.
    * @param registers The registers to update.
    * @returns A boolean indicating if the update was successful.
   */
  async updateTypeOpinionRegister(engagementId: number, registers:{typeOpinion: string } ): Promise<boolean> {
    const apiUrl = `${this.updateTypeOpinionApiUrl}/${engagementId}`
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(registers)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return false
      }

      const data: {success: boolean} = await response.json()
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }
  }

   /**
   * Fetches the type opinion register for the memorandum.
   */
   async getTypeOpinionRegister(engagementId: number): Promise<{success: boolean, typeOpinion: string | undefined}> {
    const apiUrl = `${this.getTypeOpinionApiUrl}/${engagementId}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {success: boolean, typeOpinion: string | undefined} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Fetches all the questions for the memorandum.
   * @returns A list of questions for the memorandum.
   */
  async getRegisters(engagementId: number): Promise<QuestionAnswer[]> {
    const apiUrl = `${this.getRegistersApiUrl}/${engagementId}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
  * Deletes a register for the memorandum.
  * @param engagementId The engagement id.
  * @param questionId The question id.
  * @returns A boolean indicating if the delete was successful.
  */
  async deleteRegisters(engagementId: number, questionId: number): Promise<boolean> {
    const apiUrl = `${this.deleteRegistersApiUrl}/${engagementId}`

    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({question_id: questionId})  
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return false
      }

      const data: {success: boolean} = await response.json()
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }
  }

  /**
   * Updates the registers for the memorandum to finished.
   * @param engagementId The engagement id.
   * @returns A boolean indicating if the update was successful.
   */
  async updateRegistersToFinished(engagementId: number): Promise<boolean> {
    const apiUrl = `${this.updateRegistersToFinishedApiUrl}`

    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'PUT',
          headers: this._jsonContentType,
          body: JSON.stringify({"engagement_id":engagementId})
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return false
      }

      const data: {success: boolean} = await response.json()
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }
  }

  async getAllQuestionsFinished(engagementId: number): Promise<boolean> {
    const apiUrl = `${this.getAllQuestionsFinishedApiUrl}/${engagementId}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {success: boolean} = await response.json()
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}
