import React, { useState, useEffect, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { Permissions } from '../../models/special-document/ElementBase'
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { RedirectionMemorandumButtonElement } from '../../models/special-document/RedirectionMemorandumButtonElement'
import { RedirectionMemorandumButtonRepository, RegistersTransactionFlow, RegistersTransactionFlowGeneral } from "../../repository/special-document/RedirectionMemorandumButtonRepository"
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeProps from './EditModeProps'
import EditModeBase from './EditModeBase'


/**
 * Renders the edit mode for the Redirection Memorandum Button element.
 * @param {EditModeProps} props - The properties for the component.
 */
const RedirectionMemorandumButtonEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<RedirectionMemorandumButtonElement>(iElement as RedirectionMemorandumButtonElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [defaultTransactionFlowCategory, setDefaultTransactionFlowCategory] = useState(element.transaction_flow_category_id ?? null)
  const [defaultTransactionFlow, setDefaultTransactionFlow] = useState(element.transaction_flow_id ?? null)
  const [defaultTransactionFlowControl, setDefaultTransactionFlowControl] = useState<number | null>(
      element.transaction_flow_control_id && element.transaction_flow_control_id !== 0
        ? element.transaction_flow_control_id
        : null
    );
  const [defaultRedirectTo, setDefaultRedirectTo] = useState<string>(element.redirect_to ?? "");
  const [title, setTitle] = useState<string>(element.title)
  const [transactionFlowCategory, setTransactionFlowCategory] = useState<RegistersTransactionFlowGeneral[]>([])
  const [transactionFlowControls, setTransactionFlowControls] = useState<RegistersTransactionFlowGeneral[]>([]);
  const [filteredTransactions, setFilteredTransactions] = useState<RegistersTransactionFlow[]>([]);

  /**
   * Fetches the transaction flow categories from the API and updates the state.
   */
  const getTransactionFlowCategory = async (): Promise<void> => {
    const redirectionMemorandumButtonRepository = new RedirectionMemorandumButtonRepository()
    const transactionFlowCategory = await redirectionMemorandumButtonRepository.getRegistersTransactionFlowCategory()
    if (transactionFlowCategory.success) {
      setTransactionFlowCategory(transactionFlowCategory.data)
    }
  }

  /**
   * Fetches the transaction flows from the API and updates the state.
   */
  const getTransactionFlow = async () => {
    const redirectionMemorandumButtonRepository = new RedirectionMemorandumButtonRepository()
    const transactionFlowCategory = await redirectionMemorandumButtonRepository.getRegistersTransactionFlow()
    if (transactionFlowCategory.success) {
      const filteredFlows = transactionFlowCategory.data.filter(
        (flow) => Number(flow.transaction_flow_category_id) === Number(defaultTransactionFlowCategory)
      );
      setFilteredTransactions(filteredFlows);
    }
  }

  useEffect(() => {
    getTransactionFlowCategory()
    if (defaultTransactionFlowCategory) {
      getTransactionFlow()
    }
  }, [defaultTransactionFlowCategory])

  /**
   * Fetches the transaction flow controls from the API and updates the state.
   */
  const getTransactionFlowControls = async (): Promise<void> => {
    const redirectionMemorandumButtonRepository = new RedirectionMemorandumButtonRepository()
    const transactionFlowControl = await redirectionMemorandumButtonRepository.getRegistersTransactionFlowControls(defaultTransactionFlow)
    if (transactionFlowControl.success) {
      setTransactionFlowControls(transactionFlowControl.data)
    }
  }

  useEffect(() => {
    if (defaultTransactionFlow) {
      getTransactionFlowControls()
    }
  }, [defaultTransactionFlow])

  /**
   * Toggles the visibility of the configuration modal.
   */
  function handleConfiguration() {
    setShowConfigurationsModal(true)
  }

  /**
   * Handles the acceptance of the configurations modal.
   */
  function handleConfigurationsModalCancel() {
    setShowConfigurationsModal(false)
  }

  /**
   * Handles the acceptance of the configurations modal.
   */
  async function handleConfigurationsModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const response = await elementRepository.saveElement("edition", element.args)
    if (!response) {
      setTitle(element.title)
      window.htmlHelpers?.swalError()
    }
  }

  const options = [
    { value: "dae", label: "Design and implementation" },
    { value: "risk_assessment", label: "Risk assessment" },
  ];

 /**
   * Handles updating the element's category selection.
   */
  const saveCategorySelection = async (id: number): Promise<void> => {
    const elementRepository = new ElementRepository()
    element.transaction_flow_category_id = id
    element.transaction_flow_id = null
    element.redirect_to = undefined
    element.transaction_flow_control_id = null
    setFilteredTransactions([]);
    setTransactionFlowControls([]);
    setDefaultTransactionFlow(null);
    setDefaultRedirectTo("");
    const response = await elementRepository.saveElement("edition", element.args)
  };

  /**
   * Handles updating the element's transaction flow selection
   */
  const saveFlowSelection = async (id: number): Promise<void> => {
    const elementRepository = new ElementRepository()
    element.transaction_flow_id = id
    element.transaction_flow_control_id = null
    element.redirect_to = undefined
    setDefaultRedirectTo("");
    setTransactionFlowControls([]);
    const response = await elementRepository.saveElement("edition", element.args)
  };

  /**
   * Handles updating the element's transaction flow control selection.
   */
  const saveFlowControlSelection = async (id: number):  Promise<void> => {
    const elementRepository = new ElementRepository()
    element.transaction_flow_control_id = id
    element.redirect_to = undefined
    setDefaultRedirectTo("");
    const response = await elementRepository.saveElement("edition", element.args)
  }

  useEffect(() => {
    setDefaultRedirectTo(defaultRedirectTo ?? "");
  }, [defaultRedirectTo]);

  /**
   * Handles the deletion of the element.
   */
  const handleSelectChange = async (e: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    const elementRepository = new ElementRepository()
    element.redirect_to = e.target.value

    const selectedFlowCategory = transactionFlowCategory.find(
      (flow) => flow.id === defaultTransactionFlowCategory
    );

    const selectedFlow = filteredTransactions.find(
      (flow) => flow.id === defaultTransactionFlow
    );

    const selectedFlowControl = transactionFlowControls.find(
      (flow) => flow.id === defaultTransactionFlowControl
    );

    const asseveration = options.find((option) => option.value === e.target.value);
    
    if (selectedFlowCategory?.name && selectedFlow?.name && selectedFlowControl?.name && asseveration?.label) {
      const new_title = `${T(selectedFlowCategory.name)} -> ${T(selectedFlow.name)} -> ${selectedFlowControl.name} -> ${T(asseveration.label)}`;
      element.title = new_title;
      setTitle(new_title);
    } else {
      element.title = ""
      setTitle("")
    }

    setDefaultRedirectTo(e.target.value);
    const response = await elementRepository.saveElement("edition", element.args)
  };


  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}
        >
        <div className="card h-100 bg-light">
          <div className="card-body p-3">
            <span className="text-dark fw-bold mb-0">{T("Control Override - Redirect to")}:</span>
            <p className="m-0">
              {title !== "" ? T(title) : T("No document/formulary selected")}
            </p>
          </div>
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Control Override - Redirection Button")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}
      >
        <div className="form-group w-full d-flex justify-content-center">
          <div className="w-50 d-flex flex-column">
          <label>{T("Select Category")}</label>
          <select
            value={defaultTransactionFlowCategory ?? ""}
            onChange={(e) => {
              const selectedId = Number(e.target.value);
              setDefaultTransactionFlowCategory(selectedId);
              saveCategorySelection(selectedId);
            }}
            className="form-select"
          >
            <option value="" disabled>
              {T("Select a Category")}
            </option>
            {transactionFlowCategory.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          
          <label>{T("Select Transaction Flow")}</label>
          <select
            value={defaultTransactionFlow ?? ""}
            onChange={(e) => {
              const selectedId = Number(e.target.value);
              setDefaultTransactionFlow(selectedId);
              saveFlowSelection(selectedId);
            }}
            disabled={filteredTransactions.length === 0}
            className="form-select"
          >
            <option value="" disabled>
              {filteredTransactions.length > 0
                ? T("Select a Flow")
                : T("No Flows Available")}
            </option>
            {filteredTransactions.map((flow) => (
              <option key={flow.id} value={flow.id}>
                {flow.name}
              </option>
            ))}
          </select>

          <label>{T("Select Transaction Flow Asseverations")}</label>
          <select
            value={defaultTransactionFlowControl ?? ""}
            onChange={(e) => {
              const selectedId = Number(e.target.value);
              setDefaultTransactionFlowControl(selectedId);
              saveFlowControlSelection(selectedId);
            }}
            disabled={transactionFlowControls.length === 0}
            className="form-select"
          >
            <option value="" disabled>
              {transactionFlowControls.length > 0
                ? T("Select a Flow")
                : T("No Flows Available")}
            </option>
            {transactionFlowControls.map((flow) => (
              <option key={flow.id} value={flow.id}>
                {flow.name}
              </option>
            ))}
          </select>

          <label htmlFor="optionsSelect">{T("Select Option:")}</label>
      <select
      id="optionsSelect"
      value={defaultRedirectTo}
      onChange={handleSelectChange}
      className="form-select"
    >
      <option value="" disabled>
        {T("Select an option")}
      </option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {T(option.label)}
        </option>
      ))}
    </select>
        </div>
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default RedirectionMemorandumButtonEditMode;
