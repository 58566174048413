import React, { useState, useEffect, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { decryptUniqueFilename } from "../../utils/utilities"
import { type Permissions } from "../../models/special-document/ElementBase"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { ElementAttachmentRepository } from '../../repository/special-document/ElementAttachmentRepository'
import { SubstantiveBalanceRepository } from "../../repository/SubstantiveBalanceRepository"
import { SubstantiveBalanceDataElement } from "../../models/special-document/SubstantiveBalanceDataElement"
import ViewModeProps from "../special-document/ViewModeProps"
import ViewModeBase from "../special-document/ViewModeBase"
import DropzoneComponent from "@/components/commons/dropzone/dropzone-component"
import Select, { type Option } from "../commons/Select"
import { emitter} from '../../components/utils/EventEmitter';


const SubstantiveBalanceDataViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<SubstantiveBalanceDataElement>(iElement as SubstantiveBalanceDataElement).current
  const [attachmentName, setAttachmentName] = useState(element.attachmentName)
  const [columns, setColumns] = useState(element.columns ?? [])
  const [initialRow, setInitialRow] = useState(element.initialRow ?? 0)
  const [selectedColumns, setSelectedColumns] = useState(element.selectedColumns ?? [])
  const [loading, setLoading] = useState(false)
  const columnsOptions = columns.map((column) => ({ id: column, label: column })) as Option[]
  const defaultOptions = selectedColumns.map((column) => ({ id: column, label: column })) as Option[]

  /**
  * Saves the new attachment name to the element repository and updates the UI accordingly.
  * @param attachmentName - The new attachment name to set.
  */
  async function saveAttachmentName(attachmentName: string): Promise<void> {  
    const elementRepository = new ElementRepository()
    element.attachmentName = attachmentName
    
    const response = await elementRepository.saveElement("view", element.args)
    if (!response) {
      setAttachmentName("")
      window.htmlHelpers?.swalError()
    }
  }

  async function saveSelectedColumns(selectedColumns: string[]) {
    const elementRepository = new ElementRepository()
    element.selectedColumns = selectedColumns
    element.initialRow = initialRow
    element.columns = columns
    const response = await elementRepository.saveElement("view", element.args)
    if (!response) return window.htmlHelpers?.swalError()

    const substantiveBalanceRepository = new SubstantiveBalanceRepository()
    const saveColumnsParams = {
      engagementId: element.engagement_id,
      attachment: attachmentName,
      columns: selectedColumns,
      initialRow: initialRow
    }
    
    const success = await substantiveBalanceRepository.saveBalanceColumns(element.args.document_id ,element.reference, saveColumnsParams)    
    if (success) emitter.emit("refreshSubstantiveBalanceData");
    if (!success) window.htmlHelpers?.swalError()
    setLoading(false)
  }

  async function getBalanceColumns() {
    if (attachmentName === "") return
    const substantiveBalanceRepository = new SubstantiveBalanceRepository()
    const response = await substantiveBalanceRepository.getBalanceColumns(element.id, attachmentName)
    if (response.success) setColumns(response.columns)
    setLoading(false)
  }

  async function removeAttachment() {
    const attachmentRepository = new ElementAttachmentRepository()
    const response = await attachmentRepository.removeAttachment("view", element.id, attachmentName)
    if (!response) window.htmlHelpers?.swalError()
  }

  function handleSavingAttachment(newAttachmentName: string) {
    setAttachmentName(newAttachmentName)
    if (newAttachmentName !== attachmentName) saveAttachmentName(newAttachmentName)
  }

  function handleSelectedColumns() {
    setLoading(true)
    saveSelectedColumns(selectedColumns)
  }

  function handleRemoveAttachment() {
    setAttachmentName("")
    saveAttachmentName("")
    removeAttachment()
  }

  useEffect(() => {   
    if(attachmentName !== "" && columns.length === 0) {
      setLoading(true)
      getBalanceColumns()
    }
  }, [attachmentName])

  return (
    <ViewModeBase
      isEditable={false}
      permissions={element.permissions as Permissions}
    >
      <div className="card">
        <div className="card-body py-3 px-0">
          <h6>{T("Balance Data for Substantial Test")}</h6>
          <div className="mt-2">
            {
              attachmentName ? (
                <div className="d-flex justify-content-between align-items-center w-100">
                  <p className="m-0">{T("Attachment")}: <em>{decryptUniqueFilename(attachmentName)}</em></p>
                  <button
                    type="button"
                    style={{ cursor: 'pointer' }}
                    className="d-flex align-items-center gap-2 p-1 bg-transparent border-0 text-secondary"
                    onClick={() => handleRemoveAttachment()}
                  >
                    <i className="fa-solid fa-trash"></i>
                    <span>{T("Remove file")}</span>
                  </button>
                </div>
              ) : (
                <div className="form-group">
                  <label>{T("Upload file")}</label>
                  <DropzoneComponent
                    elementId={element.id}
                    renderingMode="view"
                    attachmentName={element.attachmentName}
                    onUpload={(attachmentName) => handleSavingAttachment(attachmentName)}
                  />
                </div>
              )
            }
            {
              columns.length > 0 && (
                <>
                  <div className="form-group">
                    <label>{T("Initial Row")}</label>
                    <input
                      type="number"
                      className="form-control"
                      value={initialRow}
                      onChange={(e) => setInitialRow(parseInt(e.target.value))}
                    />
                  </div>
                  <div className="mt-2 form-group">
                    <label>{T("Column values")}</label>
                    <p>{T("For this substantive test, it is necessary to analyze two columns: first select the Assets column and then the Accumulated Depreciation column. Other available columns include IFRS Status , Account , Acquisition Date , Total Cost (IFRS) , Residual Value , Expense in Year , Net Cost (trimming) , Useful Life (months) and Depreciated Accounting Times.")}</p>
                    <Select
                      multiple
                      defaultValue={defaultOptions}
                      options={columnsOptions}
                      placeholder={T("Select columns")}
                      onChange={(value) => setSelectedColumns(value.map(column => column.id))}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary mt-2"
                    onClick={handleSelectedColumns}
                  >
                    {
                      loading ? (
                        <div className="spinner-border text-sm" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <span className="fs-6">{T("Save")}</span>
                      )
                    }
                  </button>
                </>
              )
            }
          </div>
        </div>
      </div>
    </ViewModeBase>
  );
};

export default SubstantiveBalanceDataViewMode;