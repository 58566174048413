import { ElementArgs, MemorandumOtherRisksOption } from './ElementArgs'
import { ElementBase, IElementBase } from './ElementBase'

export class MemorandumOtherRisksElement extends ElementBase implements IElementBase {
  private _conclusion: string | undefined
  private _options: MemorandumOtherRisksOption[]


  constructor(args: ElementArgs) {
    super(args)
    this._conclusion = args.conclusion ?? undefined
    this._options = args.options as MemorandumOtherRisksOption[] ?? []
  }

  get conclusion(): string | undefined {
    return this._conclusion
  }

  get options(): MemorandumOtherRisksOption[] {
    return this._options
  }

  set conclusion(conclusion: string | undefined) {
    this._conclusion = conclusion
    this.args.conclusion = conclusion
  }

  set options(options: MemorandumOtherRisksOption[]) {
    this._options = options
    this.args.options = options
  }

  render(): void {}
}