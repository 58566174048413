import { useState, useRef, useEffect, useMemo } from 'react'
import { T } from '../../utils/i18n-config'
import { Permissions } from '../../models/special-document/ElementBase'
import { JournalEntriesElement } from '../../models/substantive-tests/JournalEntriesElement'
import { JournalEntriesRepository, type JEQuestion } from '../../repository/substantive-tests/JournalEntriesRepository'
import ViewModeProps from '../special-document/ViewModeProps'
import ViewModeBase from '../special-document/ViewModeBase'

/**
 * A React component to render and manage the view mode for journal entry answers.
 */
const JournalEntriesViewMode = ({ iElement }: ViewModeProps) => {
  const element = useRef<JournalEntriesElement>(iElement as JournalEntriesElement).current
  const [items, setItems] = useState<JEQuestion[]>([])
  const isAllChecked = useMemo(() => items.length !== 0 && items.every(item => item.checked), [items])  

  /**
   * Sets the important attribute to the element.
   * @param {HTMLDivElement | null} el - The element to set the attribute.
   * @param {string} property - The property to set.
   * @param {string} value - The value to set.
   */
  function setImportantAttr(el: HTMLDivElement | null, property: string, value: string) {
    return el && el.style.setProperty(property, value, "important")
  }

  /**
   * Marks all items as checked or unchecked.
   */
  function markAllItemsAsChecked() {
    if (isAllChecked) setItems(items.map((item) => ({ ...item, checked: false })))
    else setItems(items.map((item) => ({ ...item, checked: true })))
  }

  /**
   * Marks an item as checked or unchecked.
   * @param {number} index - The item index.
   */
  function markItemAsChecked(index: number) {
    setItems(items.map((item, i) => i === index ? { ...item, checked: !item.checked } : item))
  }

  /**
   * Saves the item selection in the element.
   */
  async function saveItemSelection() {
    const journalRepository = new JournalEntriesRepository()
    let success = await journalRepository.setQuestions(element.engagement_id, element.documentId, element.id, items)
    if (!success) {
      window.htmlHelpers?.swalError()
    }
  }

  useEffect(() => {
    if (items.length !== 0) saveItemSelection()
  }, [items])

  useEffect(() => {
    /**
     * Retrieves questions from the repository and updates the state with the received data.
     */
    async function fetchData() {
      const repository = new JournalEntriesRepository()
      const { success, data } = await repository.getQuestions(element.engagement_id, element.documentId, element.id)
      if (!success) return window.htmlHelpers?.swalError()
      setItems(data)
    }

    fetchData()
  }, [])

  return (
    <ViewModeBase
      isEditable={false}
      permissions={element.permissions as Permissions}
    >
      <table className="table table-responsive table-striped table-hover" style={{ borderCollapse: "collapse" }}>
        <thead className="table-dark border">
          <tr>
            <th scope="col" className="border-end border-bottom border-white" style={{ width: "50px", paddingInline: "15px 0" }}>
              <div className="form-check" ref={(el) => setImportantAttr(el, "padding-left", "0")}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={isAllChecked}
                  onChange={markAllItemsAsChecked}
                />
              </div>
            </th>
            <th scope="col">{T("Question")}</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <th scope="row" className="border-start border-end border-bottom">
                <div className="form-check" ref={(el) => setImportantAttr(el, "padding-left", "6px")}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={item.checked}
                    onChange={() => markItemAsChecked(index)}
                  />
                </div>
              </th>
              <td className="ps-4 text-wrap border-bottom border-end">{item.question}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ViewModeBase>
  );
}

export default JournalEntriesViewMode;