
import React, { useState } from 'react';
import { T } from "../../utils/i18n-config"
import EditModeBase from "../special-document/EditModeBase";
import EditModeProps from '../special-document/EditModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import ModalWithButtons from '../modals/AcceptModalBase';


/**
 * Component to handle edit mode for customer additions information assets.
 * @param iElement - The element data to be processed.
 * @param handleDeleteElement - Callback to delete the element.
 * @param handleUpElement - Callback to move the element up.
 * @param handleDownElement - Callback to move the element down.
 */
const PaeMovementReconcilingItemsEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)

   /**
   * Opens the configuration modal by setting its visibility state to true.
   */
  const handleConfiguration = () => {
    setShowConfigurationsModal(true);
  };

  /**
  * Handles the acceptance action in the configuration modal and closes it.
  */
  const handleConfigurationsModalAccept = async () => {
    setShowConfigurationsModal(false);    
  };

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{T("Element for displaying the customer additions information assets")}</h6>
            </div>
          </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Element for displaying the customer additions information assets")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}>
        <div style={{display: "flex", justifyContent: "flex-end"}}></div>
        <div className="card-body p-3">
          <h6>{T("Element for displaying the customer additions information assets")}</h6>
        </div>
      </ModalWithButtons>
    </div>
  )
}
export default PaeMovementReconcilingItemsEditMode;
