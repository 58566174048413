import React, { useState, useRef, useEffect } from 'react';
import { T } from "../../utils/i18n-config"
import EditModeBase from "./EditModeBase";
import EditModeProps from './EditModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { UseServiceOrganizationsElement } from '../../models/special-document/UseServiceOrganizationsElement';
import { UseServiceOrganizationsArgs, UseServiceOrganizationsRepository } from '../../repository/special-document/UseServiceOrganizationsRepository';


/**
 * Component for the edit mode of the TransactionFlowsUseServiceOrganizations element
 * @param props - The props for the component
 */
const TransactionFlowsUseServiceOrganizationsEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<UseServiceOrganizationsElement>(iElement as UseServiceOrganizationsElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isRefreshLocal, setIsRefreshLocal] = useState(false);
  const [registers, setRegisters] = useState<UseServiceOrganizationsArgs[]>([]);
  const [baseRegisters, setBaseRegisters] = useState<UseServiceOrganizationsArgs[]>([]);
  const [key, setKey] = useState(0);

  const answersYesNo = [
    {
      value: 1, label: T("Yes")
    },
    {
      value: 2, label: T("No")
    }
  ]

  /**
   * Function to handle the event of opening the configuration modal
   */
  const handleConfiguration = () => {
    setShowConfigurationsModal(true);
  };

  /**
   * Function to handle the event of accepting the configuration modal
   */
  const handleConfigurationsModalAccept = async () => {
    const useServiceOrganizationsRepository = new UseServiceOrganizationsRepository()
    const result = await useServiceOrganizationsRepository.updateTransactionFlowsMemorandumUseServiceOrganizations(element.args.engagement_id, element.documentId, element.id, registers)
    setIsRefreshLocal(prev => !prev);
    setRegisters([]);
    if (result.success) {
      setShowConfigurationsModal(false);
    }

  };

  /**
   * Function to handle the event of canceling the configuration modal
   */
  const handleConfigurationsModalCancel = () => {
    setIsRefreshLocal(prev => !prev);
    setRegisters(baseRegisters);
    setShowConfigurationsModal(false)
    setKey(prevKey => prevKey + 1);
  };

  /**
   * Function to get the registers of the complexity technology environment
   */
  const getRegistersComplexityTechnologyEnvironment = async () => {
    const useServiceOrganizationsRepository = new UseServiceOrganizationsRepository()
    const result = await useServiceOrganizationsRepository.getTransactionFlowsMemorandumUseServiceOrganizations(element.args.engagement_id, element.id)
    if (result.success) {
      setRegisters(result.data);
      setBaseRegisters(result.data);
    } else {
      setRegisters([]);
      setBaseRegisters([]);
    }
  }

  useEffect(() => {
    getRegistersComplexityTechnologyEnvironment()
  }, [isRefreshLocal]);

  /**
   * Function to handle the event of changing the input value
   * @param itemId - The id of the item
   * @param field - The field to change
   * @param value - The new value
   */
  const handleAnswerSelect = (itemId: number, answerValue: number) => {
    setRegisters((prev) =>
      prev.map((item) =>
        item.id === itemId
          ? { ...item, required_type_service: answerValue, was_changed: true }
          : item
      )
    );
  }

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={true}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <div className="card h-100 bg-light">
          <div className="card-body p-3">
            <h6>{T("Element for displaying the transaction flow use service organization")}</h6>
          </div>
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Element for displaying the transaction flow use service organization")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}>
        <div style={{ width: "100%" }}>
          <div className="mb-3">
            {registers[0]?.answer_yes_no != null ? (
              <>
                <div className="d-flex flex-column align-items-center w-100">
                  <table className="table-bordered w-80">
                    <thead>
                      <tr>
                        <th className="w-50 p-2">{T("Transaction Flow")}</th>
                        <th className="w-50 p-2">{T("Required type of service?")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {registers.map((item) => (
                        <tr key={`${item.id}-${key}`}>
                          <td className="p-1">{item.title_name}</td>
                          <td className="p-1">
                          {item.is_accounting == false && (
                            <select
                              className="form-select"
                              value={item.required_type_service ?? ""}
                              onChange={(e) => handleAnswerSelect(item.id, Number(e.target.value))}
                            >
                              <option value="" disabled>{T("Select an option")}</option>
                              {answersYesNo.map((answer) => (
                                <option key={answer.value} value={answer.value}>{answer.label}</option>
                              ))}
                            </select>
                          )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div className="card h-100 bg-warning">
                <div className="card-body p-3">
                  <h6>{T("This item will not be displayed until the materiality is entered, in the materiality section.")}</h6>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <hr />
        </div>
      </ModalWithButtons>
    </div>
  )
}
export default TransactionFlowsUseServiceOrganizationsEditMode;
