import React, { useState, useRef, useEffect } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from '../special-document/ViewModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import Select from "../commons/Select"
import { LedgerAccountsFixedAssets, RequiredAccountConfigurationFixedAssetsRepository, Option } from '../../repository/substantive-tests/RequiredAccountConfigurationFixedAssetsRepository';
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import ViewModeBase from "../special-document/ViewModeBase";
import ModalWithButtons from '../modals/AceptCancelModalBase';
import Select2 from '../commons/Select2NotesAndFindings';



const RequiredAccountConfigurationFixedAssetsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [accountOptions, setAccountOptions] = useState<Option[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<number | undefined>();
  const [selectedRegisterBase, setSelectedRegisterBase] = useState<Option | undefined>();
  const [refresh, setRefresh] = useState(false);
  const [key, setKey] = useState(0);

  /**
  * Fetch registers and update the state with account options and register base configuration.
  */
  const getRegisters = async () => {
    const requiredAccountConfigurationRepository = new RequiredAccountConfigurationFixedAssetsRepository();
    const response_accounts_configuration = await requiredAccountConfigurationRepository.getAccountsConfiguration(element.args.engagement_id, element.args.document_id, element.reference, "view");
    if (response_accounts_configuration.success) {
      setSelectedRegisterBase(response_accounts_configuration.data);
      setKey(prevKey => prevKey + 1);
    }
    const response_ledger_accounts = await requiredAccountConfigurationRepository.getLedgerAccountsFixedAssets();
    setAccountOptions(response_ledger_accounts);
  }

  useEffect(() => {
    getRegisters();
  }, [refresh]);

  /**
  * Handles the acceptance action from the modal and triggers account configuration update.
  */
  const handleEditModalAccept = () => {
    addAccountConfiguration();
    setShowConfigurationsModal(false);
  }

  /**
  * Handles the cancellation action from the modal and resets the selected account and register base configuration
  * while hiding the modal.
  * */
  const handleEditModalCancel = () => {
    setSelectedAccount(undefined)
    setKey(prevKey => prevKey + 1);
    setShowConfigurationsModal(false)
  };

  /**
  * Triggers the display of the configuration modal for editing.
  */
  const handleEdit = () => {
    setShowConfigurationsModal(true)
  }

  /**
  * Update the account configuration using the repository and trigger state refresh.
  */
  const addAccountConfiguration = async () => {
    const requiredAccountConfigurationRepository = new RequiredAccountConfigurationFixedAssetsRepository();
    const response_accounts_configuration = await requiredAccountConfigurationRepository.updateAccountConfiguration(element.args.engagement_id, element.args.document_id, { "reference" : element.reference, "ledger_account_id": selectedAccount }, "view");
    setRefresh(!refresh);
  }

  return (
    <div>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        <div className="card h-100">
          <div className="card-body p-3 text-center">
          <h5>{T("Configuration")}</h5>
            <h6>{T("Required Accounts Settings")}</h6>
            <p>{T("Below, you can see the default configuration of the accounting accounts that will be analyzed in this substantive test.")}</p>
            <div>
                <p>{selectedRegisterBase?.label}</p>
              </div>
          </div>
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Required Accounts Settings")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <div className="modal-body text-center">
          <div>
          <div>
          <h5>{T("Configuration")}</h5>
            </div>
            <h6>{T("Required Accounts Settings")}</h6>
            <div className="d-flex justify-content-center mt-2">
              <div key={key}>
                <Select
                  hasSearch={false}
                  options={accountOptions}
                  defaultValue={selectedRegisterBase ? [selectedRegisterBase] : []}
                  placeholder={T("Select an option")}
                  onChange={(values: Option[]) => setSelectedAccount(Number(values[0].id))}
                  hasDelete={false}
                />
              </div>
            </div>
          </div>
        </div>
        </ModalWithButtons>
        </div>
  );
};

export default RequiredAccountConfigurationFixedAssetsViewMode;
