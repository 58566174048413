import React, { useState, useEffect, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { Permissions } from '../../models/special-document/ElementBase'
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { SubstantiveBalanceDataElement } from "../../models/special-document/SubstantiveBalanceDataElement"
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeProps from '../special-document/EditModeProps'
import EditModeBase from '../special-document/EditModeBase'

const SubstantiveBalanceDataEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<SubstantiveBalanceDataElement>(iElement as SubstantiveBalanceDataElement).current
  const [isEditable, setIsEditable] = useState(false)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)

  useEffect(() => {
    if (element.state === "closed") {
      setIsEditable(false)
    }
  }, [])

  function handleConfiguration() {
    setShowConfigurationsModal(true)
  }

  function handleConfigurationsModalCancel() {
    setShowConfigurationsModal(false)
  }

  async function handleConfigurationsModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const response = await elementRepository.saveElement("edition", element.args)
    if (!response) {
      window.htmlHelpers?.swalError()
    }
  }

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={isEditable}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <div className="card h-100 bg-light">
          <div className="card-body p-3">
            <h6>{T("Balance Data for Substantial Test")}</h6>
          </div>
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Balance Data for Substantial Test")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}
      >
        <p>{T("Used for uploading balance file for substantive test to extract data")}</p>
      </ModalWithButtons>
    </div>
  );
};

export default SubstantiveBalanceDataEditMode;