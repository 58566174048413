import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { T } from "../../utils/i18n-config"
import { decryptUniqueFilename } from "../../utils/utilities"
import { Constants } from '../../utils/Constants';
import { ElementAttachmentRepository } from '../../repository/special-document/ElementAttachmentRepository';

type DropFile = { file: File; preview: string };
type AcceptedFiles = keyof typeof Constants.FILE_IMAGES;
type AcceptedExtensions = keyof typeof Constants.FILE_EXTENSIONS;

const fileImagesKeys = Object.keys(Constants.FILE_IMAGES);
const fileExtensionsKeys = Object.keys(Constants.FILE_EXTENSIONS);
const fileEntries = fileImagesKeys.map((key, index) => [key, [fileExtensionsKeys[index]]]) as [AcceptedFiles, AcceptedExtensions[]][];
const ACCEPTED_FILES = Object.fromEntries(fileEntries) as Record<AcceptedFiles, AcceptedExtensions[]>;

interface DropzoneComponentProps {
  elementId: number;
  renderingMode?: "edition" | "view";
  attachmentName?: string;
  acceptedFiles?: AcceptedExtensions[];
  onUpload: (attachmentName: string, originalFilename: string) => void;
}

/**
 * Returns the preview image for the file type.
 * @param fileType - The file type.
 * @returns The preview image.
 */
function getPreviewImage(fileType: string) {
  if (fileType in Constants.FILE_EXTENSIONS) {
    return `${Constants.FILE_IMAGE_PATH}/${Constants.FILE_EXTENSIONS[fileType as AcceptedExtensions]}`;
  }
  return `${Constants.FILE_IMAGE_PATH}/${Constants.FILE_IMAGES[fileType as AcceptedFiles]}`;
}

/**
 * Returns the accepted files based on the extensions provided.
 * @param fileExtensions - The file extensions to be accepted.
 * @returns The accepted files.
 */
export function getAcceptedFiles(fileExtensions: AcceptedExtensions[]): Record<AcceptedFiles, AcceptedExtensions[]> {
  if (fileExtensions.length === 0) return ACCEPTED_FILES;
  const accepted = fileEntries.filter(([_, value]) => value.some(ext => fileExtensions.includes(ext)));
  return Object.fromEntries(accepted) as Record<AcceptedFiles, AcceptedExtensions[]>;
}

/**
 * Dropzone component.
 * @param elementId - The element identifier.
 * @param renderingMode - The rendering mode.
 * @param attachmentName - The attachment name.
 * @param acceptedFiles - The accepted files.
 * @param onUpload - The on upload event handler.
 */
const DropzoneComponent = ({ elementId, renderingMode = "view", attachmentName, acceptedFiles, onUpload }: DropzoneComponentProps) => {
  const [files, setFiles] = useState<DropFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isFileLoaded, setIsFileLoaded] = useState(Boolean(attachmentName));

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const previewFiles = acceptedFiles.map(file => ({
      file,
      preview: getPreviewImage(file.type),
    }));
    setFiles(previewFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: Constants.MAX_FILES,
    multiple: Constants.MAX_FILES > 1,
    accept: acceptedFiles ? getAcceptedFiles(acceptedFiles) : ACCEPTED_FILES,
  });

  useEffect(() => {
    if (attachmentName) {
      const fileName = decryptUniqueFilename(attachmentName);
      const fileExtension = attachmentName.split('.').pop()!;
      const previewFiles = [{
        file: new File([], fileName),
        preview: getPreviewImage(fileExtension),
      }];
      setFiles(previewFiles);
    } else setFiles([]);
  }, [attachmentName]);

  /**
   * Removes a file from the list of files.
   * @param event - The mouse event triggered by clicking the remove button.
   */
  function removeFile(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    event.stopPropagation();
    const fileName = event.currentTarget.getAttribute('data-file');
    const fileIndex = files.findIndex(({ file }) => file.name === fileName)
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== fileIndex));
    if (isFileLoaded) setIsFileLoaded(false);
  }

  /**
   * Uploads the selected file to the server.
   */
  async function uploadFiles() {
    setUploading(true);
    const formData = new FormData();
    const elementsAttachmentRepository = new ElementAttachmentRepository();

    if (files.length === 0 || files.length > Constants.MAX_FILES) {
      setUploading(false);
      setError(T("Please select a file. Maximum files allowed is 1"));
      return;
    }

    formData.append('file', files[0].file);
    const { filename, success } = await elementsAttachmentRepository.uploadAttachment(renderingMode, elementId, formData);
    
    if (!success) {
      setUploading(false);
      setError(T("Error uploading file. Please try again"));
      return;
    }

    onUpload(filename, files[0].file.name);
    setIsFileLoaded(true);
    setUploading(false);
    setError(null);
  }

  return (
    <div className="position-relative">
      {
        error && <div className="alert alert-danger d-flex align-items-center justify-content-between py-2 text-white" role="alert">
          <span>{error}</span>
          <button
            type="button"
            className="px-1 bg-transparent border-0 text-white fs-3"
            style={{ lineHeight: 0, cursor: 'pointer' }}
            aria-label={T("Close")}
            onClick={() => setError(null)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      }
      {uploading && (
        <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center bg-light rounded" style={{ opacity: 0.9, zIndex: 10 }}>
          <strong className="fs-4 text-dark">
            <em>{T("Files are being uploaded. Please do not close this view!")}</em>
          </strong>
        </div>
      )}
      <div
        {...getRootProps()}
        ref={(node) => node && node.style.setProperty('border-style', 'dashed', 'important')}
        style={{ cursor: 'pointer' }}
        className="dropzone border border-2 border-secondary rounded p-4 d-flex flex-wrap align-items-center justify-content-center"
      >
        {
          files.length > 0 ? (
            <>
              {files.map(({ file, preview }) => (
                <div key={file.name} className="position-relative m-2 d-flex flex-column align-items-center" style={{ maxWidth: '200px' }}>
                  <img
                    src={preview}
                    alt={file.name}
                    className="w-100 rounded"
                    style={{ maxWidth: '120px', aspectRatio: '1 / 1', objectFit: 'cover' }}
                  />
                  <p className="m-0 px-2 fw-bold text-dark text-sm text-wrap text-center">{file.name}</p>
                  <span
                    role="button"
                    tabIndex={0}
                    style={{ cursor: 'pointer' }}
                    data-file={file.name}
                    className="text-muted text-sm text-center fw-bold text-decoration-underline"
                    onClick={removeFile}
                  >
                    {T("Remove file")}
                  </span>
                </div>
              ))}
            </>
          ) : (
            <>
              {
                isDragActive ?
                  <p className="m-0">{T("Drop the file here")} ...</p> :
                  <p className="m-0">{T("Drop file or click here to upload")}</p>
              }
            </>
          )
        }
        <input {...getInputProps()} />
      </div>
      {!isFileLoaded ? (
        <button
          type="button"
          className="btn btn-primary position-absolute bottom-0 end-0 m-3"
          onClick={uploadFiles}
        >
          {
            uploading ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : T("Upload file")
          }
        </button>
      ) : (
        <div title="Uploaded" className="position-absolute bottom-0 end-0 p-2 m-3 bg-light border-secondary rounded-2 lh-1">
          <i className="fa-solid fa-check" aria-hidden="true"></i>
        </div>
      )}
    </div>
  );
}

export default DropzoneComponent;