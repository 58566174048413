import React from "react";
import Flatpickr from "react-flatpickr";

interface DatePickerProps {
  id: string;
  value: Date | string | undefined;
  onChange: (selectedDates: Date[]) => void;
  dateFormat?: string;
  className?: string;
}

/**
* DatePicker component
* 
* @param {DatePickerProps} props - Component properties including id, value, onChange, and optional dateFormat & className.
*/
const DatePicker: React.FC<DatePickerProps> = ({
  id,
  value,
  onChange,
  dateFormat = "Y-m-d",
  className = "form-control",
}) => {
  return (
    <Flatpickr
      id={id}
      value={value}
      onChange={onChange}
      options={{
        dateFormat,
      }}
      className={className}
    />
  );
};

export default DatePicker;
