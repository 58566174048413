import { RedirectionMemorandumButtonArgs } from './ElementArgs'
import { ElementBase, IElementBase } from './ElementBase'

export class RedirectionMemorandumButtonElement extends ElementBase implements IElementBase {
  private _title: string
  private _url: string
  private _context: string
  private _transaction_flow_category_id: number | null
  private _transaction_flow_id: number | null
  private _transaction_flow_control_id: number | null
  private _redirect_to: string | undefined

  constructor(args: RedirectionMemorandumButtonArgs) {
    super(args)
    this._title = args.title ? args.title : ""
    this._url = args.url ? args.url : ""
    this._context = args.context ? args.context : ""
    this._transaction_flow_category_id = args.transaction_flow_category_id ? args.transaction_flow_category_id : 0
    this._transaction_flow_id = args.transaction_flow_id ? args.transaction_flow_id : null
    this._transaction_flow_control_id = args.transaction_flow_control_id ? args.transaction_flow_control_id : 0
    this._redirect_to = args.redirect_to ? args.redirect_to : undefined
  }

  get title(): string {
    return this._title
  }

  get url(): string {
    return this._url
  }

  get context(): string {
    return this._context
  }

  get transaction_flow_category_id(): number | null{
    return this._transaction_flow_category_id
  }

  get transaction_flow_id(): number | null {
    return this._transaction_flow_id
  }

  get transaction_flow_control_id(): number | null {
    return this._transaction_flow_control_id
  }

  get redirect_to(): string | undefined {
    return this._redirect_to
  }

  get args(): RedirectionMemorandumButtonArgs {
    return {
      ...super.args,
      title: this._title,
      url: this._url,
      context: this._context,
      transaction_flow_category_id: this._transaction_flow_category_id,
      transaction_flow_id: this._transaction_flow_id,
      transaction_flow_control_id: this._transaction_flow_control_id,
      redirect_to: this._redirect_to
    }
  }

  set title(value: string) {
    this._title = value
  }

  set url(value: string) {
    this._url = value
  }

  set context(value: string) {
    this._context = value
  }

  set transaction_flow_category_id(value: number | null) {
    this._transaction_flow_category_id = value
  }

  set transaction_flow_id(value: number | null) {
    this._transaction_flow_id = value
  }

  set transaction_flow_control_id(value: number | null) {
    this._transaction_flow_control_id = value
  }

  set redirect_to(value: string | undefined) {
    this._redirect_to = value
  }

  render(): void {}
}