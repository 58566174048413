import { T } from '../../utils/i18n-config'
import { decryptUniqueFilename, downloadFile } from '../../utils/utilities'
import { ElementAttachmentRepository } from '../../repository/special-document/ElementAttachmentRepository'

interface TemplateDownloadProps {
  elementId: number
  templateName: string
}

/**
 * Template download component for downloading template file
 * @param elementId Element ID
 * @param templateName Template name
 */
export default function TemplateDownload({ elementId, templateName }: TemplateDownloadProps) {
  /**
   * Handle download template attachment
   */
  async function handleDownloadAttachment() {
    const elementAttachmentRepository = new ElementAttachmentRepository();
    const response = await elementAttachmentRepository.getAttachment("view", elementId, templateName);
    if (!(response instanceof Blob)) return window.htmlHelpers?.swalError();
    downloadFile(response, templateName);
  }

  return (
    <div className="d-flex justify-content-between align-items-center flex-wrap">
      <p className="m-0">{T("Template")}: <em>{decryptUniqueFilename(templateName)}</em></p>
      <button
        type="button"
        style={{ cursor: 'pointer' }}
        className="d-flex align-items-center gap-2 p-1 bg-transparent border-0 text-secondary"
        onClick={handleDownloadAttachment}
      >
        <i className="fa-solid fa-download"></i>
        <span>{T("Download file")}</span>
      </button>
    </div>
  )
}