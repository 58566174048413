import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'


export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface Balances {
  value: number;
  percentage: number;
  period?: Date;
}

export interface RegistersRecalculationCustomerFixedAssets {
  id?: number
  fixed_assets?: string
  account?: string
  months_elapsed?: number
  depreciation_end?: Date
  monthly_depreciation?: number
  accumulated_depreciation?: number
  accumulated_difference?: number
  annual_depreciation_expense?: number
  difference_depreciation?: number
  months_to_depreciate_year?: number
} 


/**
* Repository for handling the interaction with the fixed assets recalculation API.
*/
export class RecalculationCustomerFixedRepository {
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  /**
  * Retrieves recalculation information for customer fixed assets.
  * @param engagement_id - Engagement identifier (can be null or undefined).
  * @param documentId - Document identifier.
  * @param reference - Optional reference string.
  * @returns A Promise resolving to an object with a success flag and an array of fixed asset records.
  */
  async getRecalculationCustomerFixedAsset(engagement_id: number|null | undefined, documentId: number, reference: string | undefined): Promise<{success: boolean, data: RegistersRecalculationCustomerFixedAssets[]}> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/get_recalculation_customer_information/' + engagement_id + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {success: boolean, data: RegistersRecalculationCustomerFixedAssets[]} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}
