import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'

export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface UseServiceOrganizationsArgs {
  id: number
  title_name: string | null
  title_text: string | null
  engagement_id: number
  transaction_flow_id: number
  type_question: string | null
  type_response: string | null
  answer_yes_no: number | null
  answer_text: string | null
  attachment_name: string | null
  service_organization_name: string | null
  materiality: boolean | null
  required_type_service : number | null
  is_accounting: boolean | null
  type_services_select:string | null
}

export class UseServiceOrganizationsRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }

  /**
   * Constructor for UseServiceOrganizationsRepository
   * @param fetcher - fetch function to be used for API calls
   */
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  /**
   * Function to get the registers of the complexity technology environment
   * @param engagementId - The engagement ID
   * @param elementId - The element ID
   * @param stage - The stage number
   * @return A promise that resolves to the response data
   */
  async getTransactionFlowsMemorandumUseServiceOrganizations(engagementId: number|null | undefined, elementId: number): Promise<{ success: boolean, data: UseServiceOrganizationsArgs[]}> {
    const apiUrl = getUrl('elements_api/get_transaction_flows_memorandum_use_service_organizations/' + engagementId + '/' + elementId )
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, data: UseServiceOrganizationsArgs[]} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
  * Function to update an existing register
  * @param engagementId - The engagement ID
  * @param documentId - The document ID
  * @param elementId - The element ID
  * @param data_changes - The changes to update
  * @return A promise that resolves to the response data
  */
  async updateTransactionFlowsMemorandumUseServiceOrganizations(engagementId: number|null | undefined, documentId: number,elementId:number, data_changes: { [key: number]: Partial<UseServiceOrganizationsArgs> }): Promise<BasicResponse> {
    const apiUrl = getUrl('elements_api/update_transaction_flows_memorandum_use_service_organizations/' + engagementId + '/' + documentId+ '/' + elementId)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({"changes":data_changes})
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
  * Function to fetch service organization types
  * @param engagementId - The engagement ID
  * @param elementId - The element ID
  * @return A promise that resolves to the response data
  */
  async getTypesUseServiceOrganizations(engagementId: number|null | undefined, elementId: number): Promise<{ success: boolean, data: UseServiceOrganizationsArgs[]}> {
    const apiUrl = getUrl('elements_api/get_types_use_service_organizations/' + engagementId + '/' + elementId )
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, data: UseServiceOrganizationsArgs[]} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
  * Function to update the memorandum for use service organizations
  * @param engagementId - The engagement ID
  * @param documentId - The document ID
  * @param elementId - The element ID
  * @param data_changes - The changes to update
  * @return A promise that resolves to the response data
  */
  async updateTypeMemorandumUseServiceOrganizations(engagementId: number|null | undefined, documentId: number,elementId:number, data_changes: { [key: number]: Partial<UseServiceOrganizationsArgs> }): Promise<BasicResponse> {
    const apiUrl = getUrl('elements_api/update_types_memorandum_use_service_organizations/' + engagementId + '/' + documentId+ '/' + elementId)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({"changes":data_changes})
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Function to validate the transaction flows memorandum use service organizations
   * @param engagementId - The engagement ID
   * @param elementId - The element ID
   * @return A promise that resolves to the response data
   */
  async validateTransactionFlowsMemorandumUseServiceOrganizations(engagementId: number|null | undefined, elementId: number): Promise<{ success: boolean, is_correct: boolean}> {
    const apiUrl = getUrl('elements_api/validate_transaction_flows_memorandum_use_service_organizations/' + engagementId + '/' + elementId )
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, is_correct: boolean} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}
