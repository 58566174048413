import { getUrl } from '../../utils/app-config';
import { fetchAPI, type ResponseBody } from '../../services/api_services/fetchAPI';

export type JEQuestion = {
  question: string
  reference: string
  checked: boolean
}

export type JEAnswer = {
  question: string
  answers: (string | number)[][]
  reference: string
  hasError: boolean
  justification: string
}

type JEAnswersResponse = {
  items: JEAnswer[]
  date_range: string
}

type JournalEntryAnswer = {
  reference: string
  is_error: boolean
  justification: string
}

type JournalEntryTask = {
  status: string
  result?: string
}

export class JournalEntriesRepository {
  private readonly baseUrl: string = "substantive_test_api"
  private readonly getQuestionsApiUrl: string = getUrl(`${this.baseUrl}/get_journal_entries_questions`)
  private readonly setQuestionsApiUrl: string = getUrl(`${this.baseUrl}/save_journal_entries_questions`)
  private readonly getAnswersApiUrl: string = getUrl(`${this.baseUrl}/get_journal_entries_answers`)
  private readonly setAnswersApiUrl: string = getUrl(`${this.baseUrl}/save_journal_entries_answers`)
  private readonly getDateRangeApiUrl: string = getUrl(`${this.baseUrl}/get_journal_entries_date_range`)
  private readonly setDateRangeApiUrl: string = getUrl(`${this.baseUrl}/save_journal_entries_date_range`)
  private readonly beginProcessApiUrl: string = getUrl(`${this.baseUrl}/begin_process_journal_entries`)
  private readonly getStatusApiUrl: string = getUrl(`${this.baseUrl}/get_journal_entries_status`)

  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) { }

  /**
    * Get the questions for the journal entry substantive test.
    * @param engagementId The id of the engagement.
    * @param documentId The id of the document.
    * @param elementId The id of the element.
  */
  async getQuestions(engagementId: number, documentId: number, elementId: number): ResponseBody<JEQuestion[]> {
    const apiUrl = `${this.getQuestionsApiUrl}/${engagementId}/${documentId}/${elementId}`
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, data: [] }
    }
  }

  /**
    * Get the questions for the journal entry substantive test.
    * @param engagementId The id of the engagement.
    * @param documentId The id of the document.
    * @param elementId The id of the element.
    * @param items The items to save.
  */
  async setQuestions(engagementId: number, documentId: number, elementId: number, items: JEQuestion[]): Promise<{ success: boolean }> {
    const apiUrl = `${this.setQuestionsApiUrl}/${engagementId}/${documentId}/${elementId}`
    try {
      const response = await this.fetcher(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ items }),
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false }
    }
  }

  /**
    * Get the registers for the journal entries.
    * @param engagementId The id of the engagement to get the external content.
    * @param documentId The id of the document to get the external content.
    * @param elementId The id of the element to get the external content.
  */
  async getAnswers(engagementId: number, documentId: number, elementId: number): ResponseBody<JEAnswersResponse> {
    const apiUrl = `${this.getAnswersApiUrl}/${engagementId}/${documentId}/${elementId}`
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, data: { items: [], date_range: '' } }
    }
  }

  /**
    * Save the completed items for the journal entries.
    * @param engagementId The id of the engagement to get the external content.
    * @param documentId The id of the document to get the external content.
    * @param elementId The id of the element to get the external content.
    * @param items The items to save.
  */
  async setAnswers(engagementId: number, documentId: number, elementId: number, items: JournalEntryAnswer[]): Promise<{success: boolean}> {
    const apiUrl = `${this.setAnswersApiUrl}/${engagementId}/${documentId}/${elementId}`
    try {
      const response = await this.fetcher(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ items }),
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Get the date range for the journal entries.
   * @param engagementId The id of the engagement.
   * @param documentId The id of the document.
   * @param elementId The id of the element.
   * @returns The date range for the journal entries.
   */
  async getDateRange(engagementId: number, documentId: number, elementId: number): ResponseBody<string> {
    const apiUrl = `${this.getDateRangeApiUrl}/${engagementId}/${documentId}/${elementId}`
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, data: '' }
    }
  }

  /**
   * Save the date range for the journal entries.
   * @param engagementId The id of the engagement.
   * @param documentId The id of the document.
   * @param elementId The id of the element.
   * @param dateRange The date range to save.
   */
  async setDateRange(engagementId: number, documentId: number, elementId: number, dateRange: string): Promise<{ success: boolean }> {
    const apiUrl = `${this.setDateRangeApiUrl}/${engagementId}/${documentId}/${elementId}`
    try {
      const response = await this.fetcher(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ dateRange }),
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false }
    }
  }

  /**
   * Get the questions for the journal entry substantive test.
   * @param engagementId The id of the engagement.
   * @param documentId The id of the document.
   * @param elementId The id of the element.
   */
  async beginProcess(engagementId: number, documentId: number, elementId: number): Promise<{ success: boolean, taskId: string|null }> {
    const apiUrl = `${this.beginProcessApiUrl}/${engagementId}/${documentId}/${elementId}`
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, taskId: null }
    }
  }

  /**
   * Get the status of the journal entries.
   * @param engagementId The id of the engagement.
   * @param documentId The id of the document.
   * @param elementId The id of the element.
   * @param taskId The id of the task.
   * @param taskStatus The status of the task.
   */
  async getStatus(engagementId: number, documentId: number, elementId: number, taskId: string, taskStatus: string): ResponseBody<JournalEntryTask|null> {
    const apiUrl = `${this.getStatusApiUrl}/${engagementId}/${documentId}/${elementId}/${taskId}/${taskStatus}`
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, data: null }
    }
  }
}
