import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'


export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface Balances {
  balance: number;
  period_end?: Date;
}

export interface RegistersFixedAssetsFile {
  id?: number
  code?: number
  name?: string
  balances?: Balances[]
  variation_amount?: number
} 

export class TrialBalanceFixedAssetsRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}


  /**
  * Retrieves substantive tests for fixed assets.
  * @param documentId - Identifier for the document
  * @param engagementId - Identifier for the engagement
  * @param reference - Optional reference string
  * @returns Promise resolving with an object containing success status and data array
  */
  async getTableFixedAssetsSubstantiveTests( documentId: number, engagementId?: number | null, reference?: string): Promise<{success: boolean, data: RegistersFixedAssetsFile[], data_costs: RegistersFixedAssetsFile[]}> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/get_table_fixed_assets_substantive_tests/' + engagementId + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data = await response.json() as {success: boolean, data: RegistersFixedAssetsFile[], data_costs: RegistersFixedAssetsFile[]};
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}
