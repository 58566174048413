import type { ReportsAttachmentArgs, AttachmentOption } from '../special-document/ElementArgs'
import { ElementBase, IElementBase } from '../special-document/ElementBase'

export class MemoReportsAttachmentsElement extends ElementBase implements IElementBase {
  private _attachment_options: AttachmentOption[]

  constructor(args: ReportsAttachmentArgs) {
    super(args)
    this._attachment_options = args.attachment_options ?? []
  }

  get attachmentOptions(): AttachmentOption[] {
    return this._attachment_options
  }

  get args(): ReportsAttachmentArgs {
    return {
      ...super.args,
      attachment_options: this._attachment_options
    }
  }

  set attachmentOptions(attachmentOptions: AttachmentOption[]) {
    this._attachment_options = attachmentOptions
  }

  render(): void {}
}