import React from "react"
import MultipleDropzoneComponent from "../../components/utils/MultipleDropzoneComponent";

interface Button {
  text: string;
  icon: string;
  onClick: (iElement: number) => void;
}


export interface DropFile {
  file: File;
  preview: string;
}

interface DropzoneComponentProps {
  elementId: number;
  attachmentName?: { id: number, name: string }[];
  onUpload: (attachmentName: string[], originalFilename: DropFile[]) => void;
  onRemove: (attachmentName: { id: number, name: string }) => void;
}


const DropzoneComponentSamplesView: React.FC<DropzoneComponentProps> = ({ elementId, attachmentName, onUpload, onRemove }) => {

  return (
    <div>
      <MultipleDropzoneComponent
        elementId={elementId}
        attachmentName={attachmentName}
        onUpload={onUpload}
        onRemove={onRemove}
        maxFiles={3}
      />
    </div>
  );
}

export default DropzoneComponentSamplesView;
