import { ElementArgs, ItemsOfficials } from './ElementArgs'
import { ElementBase, IElementBase } from './ElementBase'

export class ImportantQuestionsMeetingsWithKeyMembersClientElement extends ElementBase implements IElementBase {
  private _conclusion : string | undefined
  private _items_officials: ItemsOfficials[]

  constructor(args: ElementArgs) {
    super(args)
    this._conclusion = args.conclusion ?? ''
    this._items_officials = args.items_officials ?? []
  }

  get items_officials(): ItemsOfficials[] {
    return this._items_officials
  }

  get conclusion(): string | undefined {
    return this._conclusion
  }

  get args(): ElementArgs {
    return {
      ...super.args,
      items_officials: this._items_officials,
      conclusion: this._conclusion,
    }
  }

  set conclusion(conclusion: string | undefined) {
    this._conclusion = conclusion
    this.args.conclusion = conclusion
  }

  set items_officials(items_officials: ItemsOfficials[]) {
    this._items_officials = items_officials;
    this.args.items_officials = items_officials;
  }

  /**
   * Renders the element and logs a message indicating the rendering process.
   */
  render(): void {}
}