import type { Minute } from '@/models/special-document/ElementArgs'
import { getUrl } from '@/utils/app-config';
import { type ResponseBody, fetchAPI } from '@/services/api_services/fetchAPI';

export class MinutesContractsRepository {
  private _jsonContentType = { 'Content-Type': 'application/json' }

  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) { }

  /**
   * Get the complete URL for the API request
   * @param route The route to be called
   * @param args The arguments to be passed to the route
   * @returns The complete URL
   */
  getCompleteUrl(route: string, args: (string | number)[]): string {
    const url = getUrl(`elements_api/${route}`)
    return `${url}/${args.join('/')}`
  }

  /**
   * Get the minutes information for the given attachment
   * @param engagementId The engagement ID
   * @param elementId The element ID
   * @param attachmentName The attachment name
   * @returns The minutes information for the given attachment
   */
  async getMinutesInformation(engagementId: number, elementId: number, attachmentName: string): ResponseBody<Minute[]> {
    const apiUrl = this.getCompleteUrl("get_minutes_information", [engagementId, elementId])
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({attachmentName})
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return { success: false, data: [] }
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, data: [] }
    }
  }

  /**
   * Get the contracts information for the given attachment
   * @param engagementId The engagement ID
   * @param elementId The element ID
   * @param attachmentName The attachment name
   * @returns The contracts information for the given attachment
   */
  async getContractsInformation(engagementId: number, elementId: number, attachmentName: string): ResponseBody<{ contract: string }[]> {
    const apiUrl = this.getCompleteUrl("get_contracts_information", [engagementId, elementId])
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({attachmentName})
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return { success: false, data: [] }
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, data: [] }
    }
  }
}
