import { useState, useRef } from 'react'
import { T } from '../../utils/i18n-config'
import { Permissions } from '../../models/special-document/ElementBase'
import { JournalEntriesElement } from '../../models/substantive-tests/JournalEntriesElement'
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeProps from '../special-document/EditModeProps'
import EditModeBase from '../special-document/EditModeBase'

/**
 * A React component to render and manage the edit mode for journal entry attachments.
 */
const JournalEntriesAttachmentEditMode = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<JournalEntriesElement>(iElement as JournalEntriesElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [title, setTitle] = useState<string>(element.attachmentTitle)

  /**
   * Opens the modal for editing the attachment
   */
  function handleEdit() {
    setShowConfigurationsModal(true)
  }

  /**
   * Handles the acceptance of the changes made in the modal
   */
  async function handleEditModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastTitle = element.attachmentTitle
    element.attachmentTitle = title

    let success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      element.attachmentTitle = lastTitle
      setTitle(lastTitle)
      window.htmlHelpers?.swalError()
    }    
  }

  /**
   * Handles the cancellation of the changes made in the modal
   */
  function handleEditModalCancel() {
    setTitle(element.attachmentTitle)
    setShowConfigurationsModal(false)
  }

  return (
    <>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleEdit}
        permissions={iElement.permissions as Permissions}
      >
        <div className="card card-body p-3 bg-light">
          <h6>{T("Attachment to Examine")}:</h6>
          <strong className="fs-6">{title}</strong>
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}
      >
        <div className="form-group">
          <label htmlFor="attachment-title">{T("Name of the Attachment")}</label>
          <input
            type="text"
            id="attachment-title"
            className="form-control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
      </ModalWithButtons>
    </>
  );
}

export default JournalEntriesAttachmentEditMode;