import { useEffect, useState } from "react"
import { T } from "@/utils/i18n-config"
import { DisallowanceIncomesElement } from "@/models/special-document/DisallowanceIncomesElement"
import { ElementRepository } from "@/repository/special-document/ElementRepository"
import { DisallowanceIncomesRepository } from "@/repository/special-document/DisallowanceIncomesRepository"

const CONCLUSION_TYPES = {
  "Material Errors": 1,
  "Immaterial Errors": 2,
  "No Errors": 3,
}

interface ConclusionProps {
  element: DisallowanceIncomesElement
}
export default function Conclusion({ element }: ConclusionProps) {
  const [conclusionType, setConclusionType] = useState(element.conclusionType)

  /**
   * Saves the conclusion type for the element by updating the repository.
   * @param conclusionType - Numeric conclusion type to be saved.
   */
  async function saveConclusionType(conclusionType: number) {
    const elementRepository = new ElementRepository()
    element.conclusionType = conclusionType
    await elementRepository.saveElement("view", element.args)
  }

  useEffect(() => {
    /**
     * Retrieves the conclusion type from the repository and updates the state.
     * If retrieval fails, displays an error.
     */
    async function getConclusionType() {
      const disallowanceIncomesRepository = new DisallowanceIncomesRepository()
      const { success, conclusionType } = await disallowanceIncomesRepository.getIncomeConclusion(element.id, "view", element.engagement_id)
      if (!success) return window.htmlHelpers?.swalError()
      const conclusionTypeValue = CONCLUSION_TYPES[conclusionType as keyof typeof CONCLUSION_TYPES]
      setConclusionType(conclusionTypeValue)
      saveConclusionType(conclusionTypeValue)
    }

    getConclusionType()
  }, [element.localsAttachmentName, element.exportsAttachmentName, element.id, element.engagement_id])

  return (
    <div className="d-flex flex-column gap-4">
      <b>{T("Conclusion")}</b>
      {conclusionType === 1 && (
        <div className="d-flex flex-column gap-2">
          <h6>{T("Material errors in the improper recognition of revenues")}</h6>
          <p>{T("During the performance of the audit tests, material misstatements related to inadequate revenue recognition by the client have been identified. As a consequence, these misstatements will be recorded in the misstatement schedule, and the audit opinion will be affected by the inclusion of a qualification for the misstatements detected.")}</p>
        </div>
      )}
      {conclusionType === 2 && (
        <div className="d-flex flex-column gap-2">
          <h6>{T("Immaterial errors in the improper recognition of revenues")}</h6>
          <p>{T("In the course of the audit tests, misstatements have been identified that, although not material, are associated with inadequate revenue recognition by the client. These misstatements will be documented in the misstatement spreadsheet and the necessary adjusting entries will be proposed to correct them prior to the issuance of the audit opinion.")}</p>
        </div>
      )}
      {conclusionType === 3 && (
        <div className="d-flex flex-column gap-2">
          <h6>{T("Absence of errors in the recognition of revenues")}</h6>
          <p>{T("The audit procedures performed have not identified any revenue recognition misstatements, indicating that the client has properly applied revenue recognition policies.")}</p>
        </div>
      )}
    </div>
  )
}