import { getUrl } from '../utils/app-config';
import { fetchAPI } from '../services/api_services/fetchAPI';

type SaveColumnsParams = {
  engagementId: number
  attachment: string
  columns: string[]
  initialRow: number
}

export class SubstantiveBalanceRepository {
  private readonly getRegistersApiUrl: string = getUrl("substantive_test_fixed_assets_api/get_balance_columns")
  private readonly saveRegistersApiUrl: string = getUrl("substantive_test_fixed_assets_api/save_balance_columns")

  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) { }

  /**
    * Gets the columns of the balance attachment.
    * @param elementId The id of the element to get the attachment.
    * @param attachmentName The name of the attachment to get the columns.
    * @returns The columns of the balance attachment.
   */
  async getBalanceColumns(elementId: number, attachmentName: string): Promise<{success: boolean, columns: string[]}> {
    const apiUrl = `${this.getRegistersApiUrl}/${elementId}?attachment=${attachmentName}`
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data = await response.json()
      return data as {success: boolean, columns: string[]}
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Saves the columns of the balance attachment.
   * @param documentId The id of the element to save the columns.
   * @param params The parameters to save the columns.
   * @returns True if the columns were saved successfully, false otherwise.
   */
  async saveBalanceColumns(documentId: number, reference: string | undefined, params: SaveColumnsParams): Promise<boolean> {
    const apiUrl = `${this.saveRegistersApiUrl}/${documentId}/${reference}`
    try {
      const response = await this.fetcher(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      return true
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}
