import { getUrl } from '../utils/app-config'
import { fetchAPI } from '../services/api_services/fetchAPI'


export interface RegistersSubsequentEvents {
  id: number
  engagement_id: number
  question_name: string
  principal_value_yes_no: number
  revelation_value_yes_no: number
  adjustment_value_yes_no: number
  revelation_value_text: string
  adjustment_value_text: string
  is_question_required: boolean
  is_error: boolean
  is_revelation: boolean
  is_question: boolean
}


export class SubsequentEventsRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }

  /**
   * Constructor for SubsequentEventsRepository
   * @param fetcher - fetch function to be used for API calls
   */
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) { }

  /**
  * Fetches and parses JSON from the provided URL using the configured fetcher.
  * @param url The URL to fetch.
  * @param options Optional fetch options.
  * @returns Parsed JSON data.
  */
  private async fetchJSON(url: string, options?: RequestInit): Promise<any> {
    const response = await this.fetcher(url, options);
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.json()
  }

  /**
  * Fetch registers for subsequent events based on the provided engagement identifier.
  * @param engagementId - Engagement identifier, which may be null or undefined.
  * @returns An object containing a success flag and the list of registers.
  */
  async getRegistersSubsequentEvents(engagementId: number | null | undefined): Promise<{ success: boolean, data: RegistersSubsequentEvents[] }> {
    const apiUrl = getUrl('question_subsequent_event_api/get_registers_subsequent_events/' + engagementId)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, data: RegistersSubsequentEvents[] } = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
  * Update an existing register for the subsequent events questions.
  * @param engagementId - Engagement identifier for which the register is updated.
  * @param dataSubsequent - A record containing the partial changes for registers.
  * @returns An object with a success flag and the id of the updated register if successful.
  */
  async updateRegisterQuestionSubsequentEvents(engagementId: number, dataSubsequent: Record<number, Partial<RegistersSubsequentEvents>>): Promise<{ success: boolean, id?: number }> {
    const apiUrl = `${getUrl('question_subsequent_event_api/update_register_question_subsequent_events/')}/${engagementId}`

    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({ "data_changes": dataSubsequent })
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return { success: false };
      }

      const data: { success: boolean, id: number } = await response.json()
      return { success: true, id: data.id };
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false }
    }
  }
}

