import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'


export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface Balances {
  balance: number;
  period_end?: Date;
}

export interface RegistersPaeMovemement {
  id: number
  code?: number
  description?: string
  justification_differences?: number
  additions?: number
  withdrawals?: number
  reclassifications?: number
  depreciation?: number
  impairment?: number
  valuation?: number
} 

export class PaeMovementFixedAssetsRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  /**
  * Retrieves fixed assets movement data.
  *
  * @param engagement_id   The engagement identifier (may be null/undefined).
  * @param documentId      The document identifier.
  * @param reference       An optional reference string.
  * @returns             A promise resolving to an object containing a success flag and an array of registers.
  */
  async getPaeMovementFixedAssets(engagement_id: number|null | undefined, documentId: number, reference: string | undefined): Promise<{success: boolean, data: RegistersPaeMovemement[]}> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/get_pae_movement_fixed_assets/' + engagement_id + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {success: boolean, data: RegistersPaeMovemement[]} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
  * Updates fixed assets movement data.
  *
  * @param engagementId      The engagement identifier (may be null/undefined).
  * @param documentId        The document identifier.
  * @param reference         An optional reference string.
  * @param data_pae_movement An object containing the movement data to update.
  * @returns               A promise resolving to a basic response object.
  */
  async updatePaeMovementFixedAssets(engagementId: number|null | undefined, documentId: number, reference: string | undefined, data_pae_movement: { [key: number]: Partial<RegistersPaeMovemement> }): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/update_pae_movement_fixed_assets/' + engagementId + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(data_pae_movement)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }  


}
