import { useState, useRef, useEffect } from 'react'
import { T } from '@/utils/i18n-config'
import { setImportantAttr } from '@/utils/utilities'
import useDebounceCallback from '@/components/utils/useDebounce'
import type { SelectedContract } from '@/models/special-document/ElementArgs'
import { Permissions } from '@/models/special-document/ElementBase'
import { ElementRepository } from '@/repository/special-document/ElementRepository'
import { MinutesContractsRepository } from '@/repository/special-document/MinutesContractsRepository'
import { ContractsTableElement } from '@/models/special-document/ContractsTableElement'
import ViewModeProps from '../special-document/ViewModeProps'
import ViewModeBase from '../special-document/ViewModeBase'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import TemplateDownload from '../utils/TemplateDownload'
import DropzoneComponent from '../commons/dropzone/dropzone-component'
import Select, { type Option } from '../commons/Select'
import Conclusion from '../commons/Conclusion'

/**
 * Component to show the contracts table in view mode
 * @param iElement The element
 */
const ContractsTableViewMode = ({ iElement }: ViewModeProps) => {
  const element = useRef<ContractsTableElement>(iElement as ContractsTableElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [selectedContracts, setSelectedContracts] = useState(element.selectedContracts)

  /**
   * Handle the configuration of the element
   */
  function handleConfiguration() {
    setShowConfigurationsModal(true)
  }

  /**
   * Handle the accept of the configurations modal
   */
  async function handleConfigurationsModalAccept() {
    setShowConfigurationsModal(false)
  }

  /**
   * Handle the cancel of the configurations modal
   */
  function handleConfigurationsModalCancel() {
    setShowConfigurationsModal(false)
    setSelectedContracts(element.selectedContracts)
  }

  /**
   * Handle the selected contracts
   * @param selectedContracts The selected contracts
   */
  function handleSelectedContracts(selectedContracts: { contract: string }[]) {
    const formattedContracts = selectedContracts.map((contract) => ({
      contractNumber: contract.contract,
      riskType: "",
      riskDescription: "",
      impactFinancialStatements: "",
      accountFinancialStatements: "",
      riskResponse: "",
    }))
    setSelectedContracts(formattedContracts)
    handleSaveContracts(formattedContracts)
  }

  /**
   * Handle the save contracts
   */
  async function handleSaveContracts(selectedContracts: SelectedContract[]) {
    const elementRepository = new ElementRepository()
    const lastContracts = element.selectedContracts
    element.selectedContracts = selectedContracts

    let success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      window.htmlHelpers?.swalError()
      element.selectedContracts = lastContracts
      setSelectedContracts(lastContracts)
    }
  }

  return (
    <>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleConfiguration}
        permissions={iElement.permissions as Permissions}
      >
        <div>
          <p>{T("1. Request relevant contracts with customers and suppliers during the audited year:")}</p>
          <p className="ms-4">{T("a. Attach the list of contracts, if no list is provided, see the physical documents and summarize as follows:")}</p>
          <p>{T("2. Based on the list of contracts and performance materiality, make a selection of the most significant contracts.")}</p>
          <p>{T("3. For selected contracts, request a copy and perform the following steps:")}</p>
          <p className="ms-4">{T("Perform a complete reading of the contract to understand its purpose, scope, and the commitments assumed by the entity.")}</p>
          <p className="ms-4">{T("Identifies key sections of the contract that may involve risks or affect the financial statements, such as:")}</p>
          <ul className="ms-4 ps-4">
            <li>{T("Obligations of the parties (what the entity must comply with and what it receives in return).")}</li>
            <li>{T("Penalty or noncompliance clauses.")}</li>
            <li>{T("Payment terms or financial conditions.")}</li>
            <li>{T("Guarantees offered or required.")}</li>
            <li>{T("Contract terms (term, expiration).")}</li>
            <li>{T("Special clauses (renegotiations, early termination, exclusivity, etc.).")}</li>
          </ul>
        </div>
        <ContractsTable element={element} defaultSelectedContracts={selectedContracts} />
        <Conclusion element={element} subtitle={T("Based on the audit procedures performed, determine whether all relevant contracts that could give rise to risks of material misstatement due to complexity of application have been identified.")} />
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}
      >
        <ContractsForm element={element} onSelectedContracts={handleSelectedContracts} />
      </ModalWithButtons>
    </>
  )
}

interface ContractsFormProps {
  element: ContractsTableElement
  onSelectedContracts: (selectedContracts: { contract: string }[]) => void
}

/**
 * Component to show the contracts form
 * @param element The element
 */
function ContractsForm({ element, onSelectedContracts }: ContractsFormProps) {
  const [contractsAttachment, setContractsAttachment] = useState<string>(element.contractsAttachmentName)
  const [isLoading, setIsLoading] = useState(false)

  /**
   * Get the contracts information from the attachment and save it in the element
   */
  async function executeExtractor() {
    setIsLoading(true)
    const contractsRepository = new MinutesContractsRepository()
    const { success, data, error } = await contractsRepository.getContractsInformation(element.engagement_id, element.id, contractsAttachment)
    if (!success) {
      if (error) window.htmlHelpers?.customSwalError(error)
      else window.htmlHelpers?.swalError()
    } else onSelectedContracts(data)
    setIsLoading(false)
  }

  /**
   * Save the contracts attachment name in the element
   * @param attachmentName The attachment name
   */
  async function saveContractsAttachment(attachmentName: string) {
    const elementRepository = new ElementRepository()
    const lastContractsAttachmentName = element.contractsAttachmentName
    element.contractsAttachmentName = attachmentName

    const success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      window.htmlHelpers?.swalError()
      element.contractsAttachmentName = lastContractsAttachmentName
      setContractsAttachment(lastContractsAttachmentName)
    }
  }

  /**
   * Handle the save attachment event
   * @param attachmentName The attachment name
   */
  async function handleSaveAttachment(attachmentName: string) {
    saveContractsAttachment(attachmentName)
    setContractsAttachment(attachmentName)
  }

  return (
    <div className="d-flex flex-column gap-2 mx-auto" style={{ maxWidth: "50%" }}>
      <h5>{T("Contracts")}</h5>
      <TemplateDownload elementId={element.id} templateName={element.contractsTemplateName} />
      <DropzoneComponent
        elementId={element.id}
        renderingMode="view"
        attachmentName={contractsAttachment}
        onUpload={(attachmentName) => handleSaveAttachment(attachmentName as string)}
      />
      <button
        type="submit"
        disabled={isLoading}
        className="btn btn-primary mx-auto px-4"
        style={{ width: "fit-content" }}
        onClick={executeExtractor}
      >
        {!isLoading ? T("Extract Information") : (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">{T("Loading")}</span>
          </div>
        )}
      </button>
    </div>
  )
}


interface SelectImpactProps {
  currentImpact: string
  unlistedImpact?: string
  onSelected: (selected: Option) => void
  onUnlisted?: (justification: string) => void
}

/**
 * Component to select the type of impact on financial statements
 * @param currentImpact The current impact
 * @param unlistedImpact The unlisted impact
 * @param onSelected The function to call when impact is selected
 * @param onUnlisted The function to call when unlisted impact is selected
 */
function SelectImpactFinancialStatements({ currentImpact, unlistedImpact, onSelected, onUnlisted }: SelectImpactProps) {
  const defaultOptions = [
    { id: 1, label: T("Accounting estimates") },
    { id: 2, label: T("Construction contracts") },
    { id: 3, label: T("Warranty contract") },
    { id: 4, label: T("Sales contract with returns") },
    { id: 5, label: T("Sales contract for options or financial derivatives") },
    { id: 6, label: T("Contract with variable incentives or bonuses") },
    { id: 7, label: T("Fraudulent contracts") },
    { id: 8, label: T("Related party contracts") },
    { id: 9, label: T("Contracts with key corporate governance members") },
    { id: 10, label: T("Renegotiated contracts") },
    { id: 11, label: T("Significant contracts outside the normal course of business") },
    { id: 12, label: T("Others") },
    { id: 13, label: T("Without complex calculations or associated risks") }    
  ].map((option) => ({ id: String(option.id), label: option.label }))
  const [selectedImpact, setSelectedImpact] = useState<Option[]>(() => {
    const impact = defaultOptions.find((option) => option.label === currentImpact)
    return impact ? [impact] : []
  })
  const [unlistedValue, setUnlistedImpact] = useState(unlistedImpact ?? "")

  /**
   * Handle the selected impact
   * @param selectedImpact The selected impact
   */
  function handleSelectedImpact(selectedImpact: Option[]) {
    setSelectedImpact(selectedImpact)
    onSelected(selectedImpact[0])
    if (selectedImpact[0]?.id === "12" && onUnlisted) onUnlisted(unlistedValue)
  }

  return (
    <div className="d-flex flex-column gap-2">
      <Select
        hasSearch={false}
        hasDelete={false}
        options={defaultOptions}
        defaultValue={selectedImpact}
        placeholder={T("Select an option")}
        onChange={(value) => handleSelectedImpact(value)}
      />
      {selectedImpact[0]?.id === "12" && (
        <input
          type="text"
          className="form-control"
          value={unlistedValue}
          placeholder={T("Justify your answer")}
          onChange={(e) => setUnlistedImpact(e.target.value)}
        />
      )}
    </div>
  )
}


interface SelectAccountProps {
  element: ContractsTableElement
  currentAccount: string
  onSelected: (selected: Option) => void
}

/**
 * Component to select the account on financial statements
 * @param element The contract element
 * @param currentAccount The current account
 * @param onSelected The function to call when account is selected
 */
function SelectAccountFinancialStatements({ element, currentAccount, onSelected }: SelectAccountProps) {
  const [accounts, setAccounts] = useState<Option[]>([])
  const [selectedAccount, setSelectedAccount] = useState<Option[]>([])

  /**
   * Handle the selected accounts
   * @param selectedAccount The selected accounts
   */
  function handleSelectedAccounts(selectedAccount: Option[]) {
    setSelectedAccount(selectedAccount)
    onSelected(selectedAccount[0])
  }

  useEffect(() => {
    async function getAccounts() {
      const elementRepository = new ElementRepository()
      const { success, data } = await elementRepository.getRegistersTrialBalance(element.engagement_id, element.documentId, element.id)
      if (!success) return

      const accounts = data.map((account) => ({ id: String(account.code), label: account.name }))
      setAccounts(accounts)

      const activeAccount = accounts.find((account) => account.id === String(currentAccount))
      setSelectedAccount(activeAccount ? [activeAccount] : [])
    }

    getAccounts()
  }, [])

  return (
    <Select
      hasSearch={false}
      hasDelete={false}
      options={accounts}
      defaultValue={selectedAccount}
      placeholder={T("Select an option")}
      onChange={(value) => handleSelectedAccounts(value)}
    />
  )
}


interface ContractsTableProps {
  element: ContractsTableElement
  defaultSelectedContracts: SelectedContract[]
}

/**
 * Component to show the contracts table
 * @param element The element
 * @param defaultSelectedContracts The default selected contracts
 */
function ContractsTable({ element, defaultSelectedContracts }: ContractsTableProps) {
  const [isSaving, setIsSaving] = useState(false)
  const [selectedContracts, setSelectedContracts] = useState(defaultSelectedContracts)
  const selectedContractsRef = useRef(selectedContracts)
  const selectedContractsLabels = [
    T("Contract Number"),
    T("Risk"),
    T("Risk Description"),
    T("Impact on Financial Statements"),
    T("Account on Financial Statements"),
    T("Risk Response")
  ]

  /**
   * Saves the selected contracts in the element
   */
  async function handleSaveContracts() {
    if (isSaving) return
    setIsSaving(true)

    const elementRepository = new ElementRepository()
    const lastContracts = element.selectedContracts
    element.selectedContracts = selectedContractsRef.current

    let success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      window.htmlHelpers?.swalError()
      element.selectedContracts = lastContracts
    }
    setIsSaving(false)
  }

  /**
   * Handle the updated contract
   * @param contract The contract
   * @param index The index
   */
  function handleUpdatedContract(contract: SelectedContract, index: number) {
    const newContracts = [...selectedContracts]
    newContracts[index] = contract
    selectedContractsRef.current = newContracts
    setSelectedContracts(newContracts)
  }

  useEffect(() => {
    const intervalId = setInterval(handleSaveContracts, 5000)
    return () => clearInterval(intervalId)
  }, [isSaving])

  return (
    <>
      <h6>{T("Contracts Table")}</h6>
      <table className="table table-responsive table-hover" style={{ width: "fit-content", borderCollapse: "collapse" }}>
        <thead className="table-dark border">
          <tr>
            {selectedContractsLabels.map((label, index) => (
              <th key={index} scope="col" className="text-center text-wrap align-middle">{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {selectedContracts.map((contract, index) => (
            <ContractsTableRow
              key={index}
              element={element}
              contract={contract}
              onUpdateContract={(contract) => handleUpdatedContract(contract, index)}
            />
          ))}
        </tbody>
      </table>
    </>
  )
}


interface ContractsTableRowProps {
  element: ContractsTableElement
  contract: SelectedContract
  onUpdateContract: (contract: SelectedContract) => void
}

/**
 * Component to show the contracts table row
 * @param element The element
 * @param contract The contract
 * @param onUpdateContract The function to call when the contract is updated
 */
function ContractsTableRow({ element, contract, onUpdateContract }: ContractsTableRowProps) {
  const [riskType, setRiskType] = useState(contract.riskType)
  const [riskDescription, setRiskDescription] = useState(contract.riskDescription)
  const [impactFinancialStatements, setImpactFinancialStatements] = useState(contract.impactFinancialStatements)
  const [accountFinancialStatements, setAccountFinancialStatements] = useState(contract.accountFinancialStatements)
  const [riskResponse, setRiskResponse] = useState(contract.riskResponse)
  const [unlistedImpact, setUnlistedImpact] = useState(contract.unlistedImpact)

  const debouncedUpdate = useDebounceCallback(() => {
    onUpdateContract({
      ...contract,
      riskType,
      riskDescription,
      impactFinancialStatements,
      accountFinancialStatements,
      riskResponse,
      unlistedImpact
    })
  }, 5000)

  useEffect(() => {
    debouncedUpdate()
  }, [riskType, riskDescription, impactFinancialStatements, accountFinancialStatements, riskResponse, unlistedImpact])

  return (
    <tr>
      <td className="border text-center text-wrap align-middle">
        <span className="fs-6">{contract.contractNumber}</span>
      </td>
      <td className="p-0 border align-middle">
        <textarea
          value={riskType}
          className="form-control border-0 fs-6"
          placeholder={T("Risk")}
          ref={(textarea) => setImportantAttr(textarea, "border-radius", "0")}
          onChange={(e) => setRiskType(e.target.value)}
        ></textarea>
      </td>
      <td className="p-0 border align-middle">
        <textarea
          value={riskDescription}
          className="form-control border-0 fs-6"
          placeholder={T("Risk Description")}
          ref={(textarea) => setImportantAttr(textarea, "border-radius", "0")}
          onChange={(e) => setRiskDescription(e.target.value)}
        ></textarea>
      </td>
      <td className="p-0 border align-middle">
        <SelectImpactFinancialStatements
          currentImpact={impactFinancialStatements}
          unlistedImpact={unlistedImpact}
          onSelected={(value) => setImpactFinancialStatements(value.label)}
          onUnlisted={(justification) => setUnlistedImpact(justification)}
        />
      </td>
      <td className="p-0 border align-middle">
        <SelectAccountFinancialStatements
          element={element}
          currentAccount={accountFinancialStatements}
          onSelected={(value) => setAccountFinancialStatements(value.id)}
        />
      </td>
      <td className="p-0 border align-middle">
        <textarea
          value={riskResponse}
          className="form-control border-0 fs-6"
          placeholder={T("Risk Response")}
          ref={(textarea) => setImportantAttr(textarea, "border-radius", "0")}
          onChange={(e) => setRiskResponse(e.target.value)}
        ></textarea>
      </td>
    </tr>
  )
}


export default ContractsTableViewMode