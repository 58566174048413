import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'
import { AddNewRegisters } from '../../components/substantive-tests/PaeMovementReconcilingItemsViewMode'

export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface PaeMovementDifferenceReconcilingItems {
  id: number
  pae_movement_fixed_asset_id: number
  type_movement: string
  name_reconciling_items?: string
  extracted_value?: number
}


export class PaeMovementReconcilingItemsRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  /**
  * Retrieves register differences for reconciling items from the API.
  * @param engagementId - The engagement identifier; can be null or undefined.
  * @param documentId - The document identifier.
  * @param reference - Optional reference string.
  * @returns A promise resolving to an object with success status and data list.
  */
  async getRegistersDifferenceReconcilingItems(engagementId: number|null | undefined, documentId: number, reference: string | undefined): Promise<{ success: boolean, data: PaeMovementDifferenceReconcilingItems[]}> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/get_pae_movement_difference_reconciling_items/' + engagementId + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean, data: PaeMovementDifferenceReconcilingItems[]} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
  * Update the fixed assets difference reconciling items using the provided changes.
  * @param engagementId - The engagement identifier (can be null or undefined).
  * @param documentId - The document identifier.
  * @param reference - Optional reference string.
  * @param data_new_changes - New changes to apply.
  * @param data_changes - Existing changes mapped by register id.
  * @returns A promise that resolves with a BasicResponse.
   */
  async updateFixedAssetsDifferenceReconcilingItems(engagementId: number|null | undefined, documentId: number,reference: string | undefined, data_new_changes: AddNewRegisters, data_changes: { [key: number]: Partial<PaeMovementDifferenceReconcilingItems> }): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/update_pae_movement_difference_reconciling_items/' + engagementId + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({"new_changes":data_new_changes, "changes":data_changes})
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
  * Validates record creation for reconciling items from the API.
  * @param engagementId - The engagement identifier; can be null or undefined.
  * @param documentId - The document identifier.
  * @param reference - Optional reference string.
  * @returns A promise resolving to an object with creation permission and data list.
  */
  async validateRecordsCreation(engagementId: number|null | undefined, documentId: number, reference: string | undefined): Promise<{can_create: boolean, data: PaeMovementDifferenceReconcilingItems[]}> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/validate_records_to_create_difference_reconciling_items/' + engagementId + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {can_create: boolean, data: PaeMovementDifferenceReconcilingItems[]} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
  * Deletes a difference in reconciling items for a given register.
  * @param engagementId - The engagement identifier; can be null or undefined.
  * @param documentId - The document identifier.
  * @param reference - Optional reference string.
  * @param registerId - The register identifier to delete.
  * @returns A promise resolving to a BasicResponse.
  */
  async deleteDifferenceReconcilingItems(engagementId: number|null | undefined, documentId: number, reference: string | undefined, registerId: number): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/delete_substantive_test_difference_reconciling_items/' + engagementId + '/' + documentId + '/' + reference + '/' + registerId)
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
  * Marks an error for a difference reconciling item using the provided register identifier.
  * @param engagementId - The engagement identifier; can be null or undefined.
  * @param documentId - The document identifier.
  * @param register_id - The register identifier to mark as error.
  * @returns A promise resolving to a BasicResponse.
  */
  async markErrorDifferenceReconcilingItems(engagementId: number|null | undefined, documentId: number,register_id: number): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/mark_error_difference_reconciling_items/' + engagementId + '/' + documentId + '/' + register_id)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

}
