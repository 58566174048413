import React, { useEffect, useRef, useState } from 'react'
import DOMPurify from 'isomorphic-dompurify';
import { T } from "../../utils/i18n-config"
import { decryptUniqueFilename } from '../../utils/utilities';
import { InternalControlComponentsQuestionsElement } from "../../models/special-document/InternalControlComponentsQuestionsElement"
import { InternalControlComponentsData } from "../../components/special-document/InternalControlComponentsQuestionsEditMode"
import InternalControlComponentsQuestionsOptionView from "./InternalControlComponentsQuestionsOptionView"
import { InternalControlComponentsQuestions } from "../../models/special-document/ElementArgs"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { Permissions } from '../../models/special-document/ElementBase'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import ViewModeProps from './ViewModeProps';
import ViewModeBase from "./ViewModeBase";
import Dropzone from '@/components/commons/dropzone/dropzone-component';
import { emitter} from '../../components/utils/EventEmitter';


const InternalControlComponentsQuestionsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<InternalControlComponentsQuestionsElement>(iElement as InternalControlComponentsQuestionsElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [title, setTitle] = useState('')
  const [titleBase, setTitleBase] = useState('')
  const [options, setOptions] = useState<InternalControlComponentsQuestions[]>([])
  const [optionsBase, setOptionsBase] = useState<InternalControlComponentsQuestions[]>([])
  const [dataDropdown, setDataDropdown] = useState<InternalControlComponentsData[]>([])
  const [dataYesNo, setDataYesNo] = useState<InternalControlComponentsData[]>([])
  const [dataYesNoInverse, setDataYesNoInverse] = useState<InternalControlComponentsData[]>([])
  const [valoration, setValoration] = useState<string | undefined | null>(undefined)
  const [conclusionValoration, setConclusionValoration] = useState<string | undefined | null>(undefined)
  const [requiredFinding, setRequiredFinding] = useState(false)

  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  const getInternalControlComponentsConfigurations = async () => {
    const elementRepository = new ElementRepository()
    const data = await elementRepository.getInternalControlComponentsConfiguration(
      element.engagement_id, element.documentId, element.id, "view"
    )

    if (data.success) {
      setDataDropdown(data.data_dropdown)
      setDataYesNo(data.data_yes_no)
      setDataYesNoInverse(data.data_yes_no_inverse)
    } else {
      setDataDropdown([])
      setDataYesNo([])
      setDataYesNoInverse([])
    }
  }

  useEffect(() => {
    setTitle(element.title_section)
    setTitleBase(element.title_section)
    setOptions(JSON.parse(JSON.stringify(element.questions_internal_control)))
    setOptionsBase(JSON.parse(JSON.stringify(element.questions_internal_control)))
    const hasRequiredFinding = element.questions_internal_control.some((item) => item.is_required_finding === true);
    
    setRequiredFinding(hasRequiredFinding)

    if (element.question_conclusion == 1) {
      setValoration("Initial")
      setConclusionValoration(element.initial_conclusion || T("No conclusion"))
    } else if (element.question_conclusion == 2) {
      setValoration("Basic")
      setConclusionValoration(element.basic_conclusion || T("No conclusion"))
    } else if (element.question_conclusion == 3) {
      setValoration("Intermediate")
      setConclusionValoration(element.intermediate_conclusion || T("No conclusion"))
    } else if (element.question_conclusion == 4) {
      setValoration("Optimal")
      setConclusionValoration(element.optimal_conclusion || T("No conclusion"))
    } else if (element.question_conclusion == 5) {
      setValoration("Out of range")
    }

    getInternalControlComponentsConfigurations()
  }, []);

  const handleConfigurationsModalAccept = async () => {
    setShowConfigurationsModal(false);
    const elementRepository = new ElementRepository()
    const lastQuestion = element.title_section
    const lastOptions = JSON.parse(JSON.stringify(element.questions_internal_control))
    element.title_section = title
    element.questions_internal_control = JSON.parse(JSON.stringify(options))
    
    let success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      element.title_section = lastQuestion
      element.questions_internal_control = lastOptions
      setTitle(lastQuestion)
      setOptions(JSON.parse(JSON.stringify(lastOptions)))
      window.htmlHelpers?.swalError()
    } else {      
      const hasRequiredFinding = element.questions_internal_control.some((item) => item.is_required_finding === true);
      console.log("hasRequiredFinding", hasRequiredFinding);      
      setRequiredFinding(hasRequiredFinding)
      emitter.emit("refreshInternalControlComponent");
      const result = await elementRepository.validateQuestionsInternalControlComponents(element.engagement_id, element.documentId, element.id)
      if (result.success) {
        if (result.valoration == 1) {
          setConclusionValoration(element.initial_conclusion || T("No conclusion"))
          setValoration("Initial")
        } else if (result.valoration == 2) {
          setConclusionValoration(element.basic_conclusion || T("No conclusion"))
          setValoration("Basic")
        } else if (result.valoration == 3) {
          setConclusionValoration(element.intermediate_conclusion || T("No conclusion"))
          setValoration("Intermediate")
        } else if (result.valoration == 4) {
          setConclusionValoration(element.optimal_conclusion || T("No conclusion"))
          setValoration("Optimal")
        } else if (result.valoration == 5) {
          setValoration("Out of range")
        }
      } else {
        setValoration(undefined)
      }
    }
  };

  const handleConfigurationsModalCancel = () => {
    setShowConfigurationsModal(false)
    setOptions(JSON.parse(JSON.stringify(optionsBase)))
    setTitle(titleBase)
  };

  const handleEditJustification = (index: number, justification: string) => {
    const newOptions = [...options]
    newOptions[index].description = justification
    setOptions(newOptions)
  }

  const handleEditAttachmentName = (index: number, attachment_name: string) => {
    const newOptions = [...options]
    newOptions[index].attachment_name = attachment_name
    setOptions(newOptions)
  }

  const handleAnswerSelected = (index: number, answer_selected: number, is_required_file?: boolean, is_required_finding?:boolean) => {
    const newOptions = [...options]
    newOptions[index].answer_selected = answer_selected
    console.log("is_required_file", is_required_file);
    
    if (is_required_file != undefined) {
      newOptions[index].is_required_file = is_required_file
    }

    if (is_required_finding != undefined) {
      newOptions[index].is_required_finding = is_required_finding
    }
    setOptions(newOptions)
  }

  const redirectFinding = () => {
    window.menuService?.breadcrumbAddLevel(`/audix/engagement_finding/index.load/${element.args.engagement_id}`, T('Recommendations for Management'))

  }
  

  return (
    <div>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}>
        {element.questions_internal_control.length === 0 &&
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{T("Without configuration")}</h6>
              <div className="chart pt-3">
                <p>{T("Configure this element from the template.")}</p>
              </div>
            </div>
          </div>
        }
        {element.questions_internal_control.length > 0 &&
          <div className="card h-100">
            <div className="card-body p-3">
              <div className="d-flex justify-content-between">
                <h6>{element.title_section && element.title_section != "" ? element.title_section: T("No title")}</h6>
                <h6>{T(valoration || "")}</h6>
              </div>
              <div className="chart pt-3">
                <p className="font-weight-normal">
                {element.paragraph_section && element.paragraph_section !== "" ? element.paragraph_section : T("No description")}
                </p>
              </div>
              <div>
                <h6>{T(conclusionValoration || "")}</h6>
              </div>
              {requiredFinding &&(
              <div className="d-flex justify-content-center">
                <button className="btn btn-primary" onClick={redirectFinding}>{T("Recommendations for Management")}</button>
              </div>
              )}
                
            </div>
          </div>
        }
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("ICS")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}>
        <div className="form-group">
          <h4>{element.title_section}</h4>
        </div>
        <div className="form-group">
          <div className="mt-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(element.paragraph_section ?? "") }} />
        </div>

        <hr className="horizontal dark my-3"></hr>
        <div>
          {options.map((option, index) => {
            let dataAnswers: InternalControlComponentsData[] = []
            if (option.is_yes_no == false) {
              dataAnswers = dataDropdown
            } else {
              if (option.is_yes_no_reverse == false) {
                dataAnswers = dataYesNo
              } else {
                dataAnswers = dataYesNoInverse
              }
            }

            return (
              <InternalControlComponentsQuestionsOptionView
                key={`${option.id}-${options.length}`}
                index={index}
                elementId={element.id}
                question={option.question}
                dataAnswers={dataAnswers}
                selectAnswerSelectedId={option.answer_selected ?? undefined}
                isParentQuestion={option.is_father}
                notHasDropdown={option.is_yes_no}
                hasYesNoInverse={option.is_yes_no_reverse}
                justification={option.description}
                requiredFile={option.is_required_file}
                attachmentName={option.attachment_name ?? ""}
                showModal={showConfigurationsModal}
                handleAnswerSelected={handleAnswerSelected}
                handleEditJustification={handleEditJustification}
                handleEditAttachmentName={handleEditAttachmentName}
              />
            )
          })}
        </div>
      </ModalWithButtons>
    </div>
  );
};

interface AttachmentSectionProps {
  optionId: number;
  defaultAttachmentName: string;
  handleSetAttachment: (attachmentName: string, originalFilename: string) => void;
}

const AttachmentSection = ({ optionId, defaultAttachmentName, handleSetAttachment }: AttachmentSectionProps) => {
  const [attachmentName, setAttachmentName] = useState(defaultAttachmentName);

  function handleRemoveAttachment() {
    setAttachmentName('');
    handleSetAttachment('', '');
  }

  function handleUploadAttachment(attachmentName: string, originalFilename: string) {
    setAttachmentName(originalFilename);
    handleSetAttachment(attachmentName, originalFilename);
  }

  return (
    <div className="my-3">
      {
        attachmentName !== "" && (
          <div className="d-flex align-items-center gap-2 py-1 px-2 border border-2 border-secondary rounded"
            style={{ width: 'fit-content' }}
            title={decryptUniqueFilename(attachmentName)}
          >
            <span className="d-block text-sm fw-bold text-truncate text-uppercase" style={{ maxWidth: '400px' }}>
              {decryptUniqueFilename(attachmentName)}
            </span>
            <button
              type="button"
              style={{ cursor: 'pointer' }}
              className="p-1 bg-transparent border-0 text-secondary"
              onClick={handleRemoveAttachment}
            >
              <i className="fa-regular fa-trash-can"></i>
              <span className="visually-hidden">{T("Remove file")}</span>
            </button>
          </div>
        )
      }
      {
        attachmentName === "" && (
          <Dropzone elementId={optionId} onUpload={handleUploadAttachment} />
        )
      }
    </div>
  );
}

export default InternalControlComponentsQuestionsViewMode;
