import React, { useState, useEffect, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { type Permissions } from "../../models/special-document/ElementBase"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { RedirectionMemorandumButtonElement } from "../../models/special-document/RedirectionMemorandumButtonElement"
import ViewModeProps from './ViewModeProps'
import ViewModeBase from "./ViewModeBase"


/**
 * Renders the view mode for the Redirection Memorandum Button element.
 * @param {ViewModeProps} props - The properties for the component.
 * @returns {JSX.Element} The rendered component.
 */
const RedirectionMemorandumButtonViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<RedirectionMemorandumButtonElement>(iElement as RedirectionMemorandumButtonElement).current
  const [url, setUrl] = useState(element.url)
  const [title, setTitle] = useState<string>("")

  useEffect(() => {
    /**
     * Fetch the redirection URL from the API and set it to the state
     */
    async function fetchRedirectionUrl() {
      try {
        const elementRepository = new ElementRepository()
        const redirectUrl = await elementRepository.getRedirectMemorandumUrl(
          element.engagement_id, element.id
        )
        setUrl(redirectUrl)
      } catch (error) {
        console.error('Error fetching redirection URL:', error)
      }
    }

    fetchRedirectionUrl()

    if (element.redirect_to === "dae") {
      setTitle(T("D&I"))
    } else if (element.redirect_to === "risk_assessment") {
      setTitle(T("Risks"))
    }
  }, [])

  /**
   * Redirects the user to the document/formulary
   * If the URL is empty, it shows an error message
   */
  function handleRedirection() {
    if (url !== "") return window.menuService?.breadcrumbAddLevel(url, title)
    return window.htmlHelpers?.customSwalError(T("The document/formulary does not exist yet"))
  }

  return (
    <ViewModeBase
      isEditable={false}
      permissions={element.permissions as Permissions}
    >
      {title ? (
        <button type="button" className="btn btn-primary" onClick={handleRedirection}>
          {T("Go to")}{` `}{T(title)}
        </button>
      ) : (
        <div className="card border-2 border-dark" style={{ width: "fit-content", padding: "15px" }}>
          <h6 className="m-0">{T("There is no redirection selected yet")}</h6>
        </div>
      )}
    </ViewModeBase>
  );
};

export default RedirectionMemorandumButtonViewMode;
