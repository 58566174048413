import { RequiredAccountConfigurationBankConciliations } from '../../models/special-document/ElementArgs'
import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'

export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface LedgerAccountsFixedAssets {
  id: string
  label: string
}

export type Option = { id: string; label: string };

export class RequiredAccountConfigurationFixedAssetsRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  /**
  * Retrieves ledger accounts for fixed assets.
  * @returns {Promise<Option[]>} A list of ledger account options.
  */
  async getLedgerAccountsFixedAssets(): Promise<Option[]> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/get_ledger_accounts_fixed_assets')
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: Option[] = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
  * Retrieves a configurable account based on the engagement, document, reference, and rendering mode.
  * @param {number|null|undefined} engagementId
  * @param {number} documentId
  * @param {string|undefined} reference
  * @param {string} renderingMode
  * @returns {Promise<{success: boolean, data: Option}>}
  */
  async getAccountsConfiguration(engagementId: number|null| undefined, documentId: number, reference: string | undefined, renderingMode: string): Promise<{success: boolean,data: Option}> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/get_configurable_accounts/' + engagementId + '/'+ documentId +'/'+ reference + '/' + renderingMode)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {success: boolean, data: Option} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
  * Updates the account configuration for fixed assets.
  * @param {number|null|undefined} engagementId
  * @param {number} documentId
  * @param {{reference: string|undefined, ledger_account_id: number|undefined}} data_changes
  * @param {string} renderingMode
  * @returns {Promise<BasicResponse>}
  */
  async updateAccountConfiguration(engagementId: number|null | undefined, documentId: number,data_changes: {reference: string | undefined, ledger_account_id: number| undefined}, renderingMode: string): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/update_account_configuration/' + engagementId + '/' + documentId + '/' + renderingMode)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(data_changes)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}