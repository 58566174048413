import { DocumentSpecial } from "../../models/special-document/DocumentSpecial"
import { DocumentSpecialRepository } from "../../repository/special-document/DocumentSpecialRepository"
import { ElementFactory } from "./ElementFactory"
import { ElementRepository } from "../../repository/special-document/ElementRepository"

export class DocumentSpecialFactory{
  private _documentSpecialRepository: DocumentSpecialRepository
  private _elementFactory: ElementFactory

  constructor(documentSpecialRepository: DocumentSpecialRepository = new DocumentSpecialRepository(),
              elementFactory: ElementFactory = new ElementFactory(new ElementRepository())){
    this._documentSpecialRepository = documentSpecialRepository
    this._elementFactory = elementFactory
  }

  async createDocumentSpecial(reference: string, renderingMode: string, engagementID:number|null, name?: string): Promise<DocumentSpecial>{
    const iDocumentSpecial = await this._documentSpecialRepository.getDocumentByReference(reference)
    if (iDocumentSpecial){
      const documentSpecial = new DocumentSpecial(
      iDocumentSpecial.id, 
      iDocumentSpecial.reference,
      iDocumentSpecial.engagement_id,
      iDocumentSpecial.name,
      iDocumentSpecial.state
      )
      documentSpecial.content = await this._elementFactory.loadClassessByDocumentId(iDocumentSpecial.id, renderingMode, engagementID)

      return documentSpecial
    } else{
      const documentSpecial = new DocumentSpecial(1, reference, engagementID, name)
      const registerId = await this._documentSpecialRepository.createDocumentSpecial(documentSpecial)
      if (registerId !== null && registerId !== undefined){
        documentSpecial.id = registerId
      }else {
        window.menuService?.breadcrumbRefreshLevel()
      }
        
      return documentSpecial
    }
  }
}