import React, { useState, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { decryptUniqueFilename } from "../../utils/utilities"
import { Permissions } from '../../models/special-document/ElementBase'
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { QuestionTextFileElement } from '../../models/special-document/QuestionTextFileElement'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import ViewModeProps from '../special-document/ViewModeProps'
import ViewModeBase from '../special-document/ViewModeBase'
import Summernote from '../utils/Summernote'
import Dropzone from '@/components/commons/dropzone/dropzone-component'

const QuestionTextFileViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<QuestionTextFileElement>(iElement as QuestionTextFileElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [answerText, setAnswerText] = useState<string>(element.answer)
  const [attachmentName, setAttachmentName] = useState<string>(element.attachment)
  const question = element.question

  function handleEdit() {
    setShowConfigurationsModal(true)
  }

  async function handleEditModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastAnswer = element.answer
    const lastAttachment = element.attachment
    element.answer = answerText
    element.attachment = attachmentName

    let success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      element.answer = lastAnswer
      element.attachment = lastAttachment
      setAnswerText(lastAnswer)
      setAttachmentName(lastAttachment)
      window.htmlHelpers?.swalError()
    }
  }

  function handleEditModalCancel() {
    setAnswerText("")
    setAttachmentName("")
    setShowConfigurationsModal(false)
  }

  return (
    <>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        <div className="card card-body flex flex-column gap-3 p-3 bg-light">
          <pre
            className="m-0 text-dark fs-6 fw-bold text-wrap"
            style={{ fontFamily: "sans-serif" }}
            dangerouslySetInnerHTML={{ __html: question ? question : T("Question not defined")  }}
          />
          {answerText && (
            <div>
              <h6 className="small mb-1 lh-1">{T("Answer")}</h6>
              <div className="m-0" dangerouslySetInnerHTML={{ __html: answerText }} />
            </div>
          )}
          {attachmentName && (
            <div>
              <h6 className="small mb-1 lh-1">{T("Attachment")}</h6>
              <p className="m-0">{T("Attachment")}: {decryptUniqueFilename(attachmentName)}</p>
            </div>
          )}
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}
      >
        <div className="card-body">
          <pre
            className="text-dark fs-5 fw-bold text-wrap text-center"
            style={{ fontFamily: "sans-serif" }}
            dangerouslySetInnerHTML={{ __html: question ? question : T("Question not defined")  }}
          />
          <div className="form-group">
            <label htmlFor="answerText">{T("Answer")}</label>
            <Summernote value={answerText} onChange={setAnswerText} />
          </div>
          <div className="form-group">
            <label htmlFor="attachment">{T("Attachment")}</label>
            <Dropzone
              elementId={element.id}
              attachmentName={attachmentName}
              onUpload={setAttachmentName}
            />
          </div>
        </div>    
      </ModalWithButtons>
    </>
  );
}

export default QuestionTextFileViewMode;