import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { ElementParagraph } from "../../models/special-document/ElementParagraph"
import EditModeBase from "./EditModeBase";
import ModalWithButtons from '../modals/AceptCancelModalBase';
import Summernote from '../utils/Summernote';
import EditModeProps from './EditModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import DOMPurify from 'isomorphic-dompurify';

const ParagraphEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<ElementParagraph>(iElement as ElementParagraph).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [editorContent, setEditorContent] = useState('')
  const [editorContentTemp, setEditorContentTemp] = useState('')
  const [isEditable, setIsEditable] = useState(false)
  const [name, setName] = useState<string | undefined>(undefined)

  useEffect(() => {
    setEditorContent(element.text_default)
    setEditorContentTemp(element.text_default)
    setIsEditable(element.editable)
    setName(element.name)

    if(element.state === "closed"){
      setIsEditable(false)
    }

  },[]);

  const handleConfiguration = () => {
    setShowConfigurationsModal(true);
  };

  const handleEditorChange = (content: string) => {
    setEditorContentTemp(content);
  };

  const handleConfigurationsModalAccept = async () => {
    setShowConfigurationsModal(false);    
    const elementRepository = new ElementRepository()
    const lastEditable = element.editable
    const lastName = element.name
    const lastGlobalVariable = element.global_variable
    element.text_default = editorContentTemp
    element.editable = isEditable
    element.name = name
    let success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      element.text_default = editorContent
      element.editable = lastEditable
      element.name = lastName
      element.global_variable = lastGlobalVariable
      return window.htmlHelpers?.swalError()
    }
    setEditorContent(editorContentTemp)
  };

  const handleConfigurationsModalCancel = () => {
    setShowConfigurationsModal(false)
    setEditorContentTemp(editorContent)
    setIsEditable(element.editable)
    setName(element.name)
    //setShowAdvancedMenu(false)
  };


  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={isEditable}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(editorContent) }} />
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Paragraph settings")}
        size="xl"
        currentElement={element}
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}
      >
        <Summernote value={editorContent} onChange={handleEditorChange} showModal={showConfigurationsModal} />
        <div className="form-check">
          <input className="form-check-input" type="checkbox" checked={isEditable} onChange={() => setIsEditable(!isEditable)} />
          <label className="custom-control-label">{T("Is Editable")}</label>
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default ParagraphEditMode;
