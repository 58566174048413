import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from '../special-document/ViewModeProps';
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import ViewModeBase from "../special-document/ViewModeBase";
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { formatCurrency } from '../../utils/utilities';
import { CSSProperties } from "react";
import { TrialBalanceBankConciliationsRepository } from '../../repository/special-document/TrialBalanceBankConciliationsRepository';
import { CustomerInformationFixedAssetsRepository, RegistersCustomerInformationFixedAssets } from '../../repository/substantive-tests/CustomerInformationAssetsRepository';
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';
import DatePicker from '../commons/Flatpickr';


export interface AccountData {
  id: number;
  consignment_number: number;
  type_account: string;
  account: string;
  name: string;
  amount: number;
  amount_in_other_accounts: number;
  amount_per_statement: number;
  difference_amount: number;
}


/**
* Retrieves and processes registers of the trial balance file by grouping data and updating state.
*/
const CustomerAdditionsInformationAssetsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isRefresh, setIsRefresh] = useState(false);
  const [isRefreshLocal, setIsRefreshLocal] = useState(false);
  const [columTotalCostNiif, setColumnTotalCostNiif] = useState<number>(0);
  const [columnAccumulatedDepreciation, setColumnAccumulatedDepreciation] = useState<number>(0);
  const [columnExpenseInTheYear, setColumnExpenseInTheYear] = useState<number>(0);
  const [columnNetCostCut, setColumnNetCostCut] = useState<number>(0);
  const [data, setData] = useState<RegistersCustomerInformationFixedAssets[]>();
  const [groupedData, setGroupedData] = useState<Record<string, RegistersCustomerInformationFixedAssets[]>>({});
  const [changes, setChanges] = useState<Record<number, Partial<RegistersCustomerInformationFixedAssets>>>({});
  const [date, setDate] = useState<Date | undefined>(undefined); 

  useEffect(() => {
    const handleEvent = () => {
      setIsRefresh(prev => !prev);
    };
    emitter.on("refreshSubstantiveBalanceData", handleEvent);
    return () => {
      emitter.off("refreshSubstantiveBalanceData", handleEvent);
    };
  }, []);

  /**
  * Retrieves and processes registers of the trial balance file by grouping data and updating state.
  */
  const getRegistersOfTrialBalanceFile = async () => {
    const customerInformationAssetsRepository = new CustomerInformationFixedAssetsRepository()
    const result = await customerInformationAssetsRepository.getCustomerInformationFixedAsset(element.args.engagement_id, element.args.document_id, element.reference)
    if (result.success) {
      
    const groupedData = result.data.reduce((acc: Record<string, any>, item) => {
      if (item.account) {
        acc[item.account] = acc[item.account] || [];
        acc[item.account].push(item);
      }
      return acc;
    }, {});
    setGroupedData(groupedData);

    setColumnTotalCostNiif(
      result.data.reduce(
        (acc: number, item) =>
          acc + (item.total_cost_niif ? item.total_cost_niif : 0),
        0
      )
    )

    setColumnAccumulatedDepreciation(
      result.data.reduce(
        (acc: number, item) =>
          acc + (item.accumulated_depreciation ? item.accumulated_depreciation : 0),
        0
      )
    )

    setColumnExpenseInTheYear(
      result.data.reduce(
        (acc: number, item) =>
          acc + (item.expense_in_the_year ? item.expense_in_the_year : 0),
        0
      )
    )

    setColumnNetCostCut(
      result.data.reduce(
        (acc: number, item) =>
          acc + (item.net_cost_cut ? item.net_cost_cut : 0),
        0
      )
    )

    setData(result.data)
    }
  }

  const updateDifferenceAmount = async () => {
    const trialBalanceBankConciliationsRepository = new TrialBalanceBankConciliationsRepository()
    const updateDifferenceAmount = await trialBalanceBankConciliationsRepository.updateDifferenceAmountTableBankConcilitaion(
      element.args.document_id, element.reference
    )
  }

  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  useEffect(() => {
    getRegistersOfTrialBalanceFile()
  }, [isRefresh, isRefreshLocal]);


  const handleEditModalAccept = async () => {
    const customerInformationAssetsRepository = new CustomerInformationFixedAssetsRepository()
    const result = await customerInformationAssetsRepository.updateRegistersAdditionsCustomerInformation(element.args.engagement_id, element.args.document_id, element.reference, changes)
    setShowConfigurationsModal(false);
    setIsRefreshLocal(prev => !prev);
    setChanges({});
  };

  const handleEditModalCancel = () => {
    setShowConfigurationsModal(false)
    setChanges({});
  };


  const [expandedAccounts, setExpandedAccounts] = useState<Record<string, boolean>>({});

  const toggleAccount = (account: string) => {
    setExpandedAccounts((prev) => ({
      ...prev,
      [account]: !prev[account],
    }));
  };

  const handleNumericInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let cleanedValue = event.target.value.replace(/[^0-9.-]/g, '');
    const parts = cleanedValue.split(".");
    if (parts.length > 1 && parts[1].length > 2) {
      parts[1] = parts[1].substring(0, 2);
      cleanedValue = parts.join(".");
    }

    event.target.value = cleanedValue;
  };

  const handleInputChange = (itemId: number  , field: keyof RegistersCustomerInformationFixedAssets, value: number | string | Date | undefined | null) => {
    setChanges(prev => ({
        ...prev,
        [itemId]: { ...prev[itemId], [field]: value }
    }));
  };

  function handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
    const value = parseFloat(e.target.value.replace(/[^0-9.-]/g, ''));
    e.target.value = formatCurrency(value);
  }

  function handleFocus(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.value = e.target.value.replace(/[^0-9.-]/g, '');
  }

  const handleDateChange = (itemId: string | number | symbol | any, selectedDates: Date[]) => {
    if (selectedDates.length > 0) {
      const formattedDate = selectedDates[0].toISOString().split("T")[0];
      setChanges(prev => ({
        ...prev,
        [itemId]: { ...prev[itemId], additional_date: formattedDate }
      }));
    } else {
      setDate(undefined);
    }
  };

  const calculateDifference = (total_cost_niif: number | undefined, additional_cost: number | undefined, additional_vat: number | undefined ) => {
    if (total_cost_niif !== undefined && additional_cost !== undefined && additional_vat !== undefined) {
      return parseFloat(((additional_cost + additional_vat) - total_cost_niif).toFixed(2));
    }
    return 0;
  }

  const calculateAdditionalTotalCost = (additional_cost: number | undefined, additional_vat: number | undefined) => {
    if (additional_cost && additional_vat) {
      return parseFloat((additional_cost + additional_vat).toFixed(2)); 
    }
    return 0;
  }

  const styleHeader: CSSProperties = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <div>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        <div className="d-flex w-full flex-column mb-3 card">
          <div className="card-body">
            <h6>{T("Additions")}</h6>
            <div style={{ overflowX: "auto"}}> 
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  minWidth: "2000px",
                  tableLayout: "fixed"
                }}
                className="table table-bordered table-hover"
              >
              <thead>
                <tr>
                  <th style={{width:"14%", ...styleHeader }} className="p-2">{T("Fixed Assets")}</th>
                  <th style={{width:"14%", ...styleHeader }} className="p-2">{T("Description")}</th>
                  <th style={{width:"11%", ...styleHeader }} className="p-2">{T("Date of acquisition")}</th>
                  <th style={{width:"10%", ...styleHeader }} className="p-2">{T("useful life months")}</th>
                  <th style={{width:"15%", ...styleHeader }} className="p-2">{T("Total cost NIIF")}</th>
                  <th style={{width:"15%", ...styleHeader }} className="p-2">{T("Accumulated Depreciation")}</th>
                  <th style={{width:"15%", ...styleHeader }} className="p-2">{T("Net cost cut-off")}</th>
                  <th style={{width:"14%", ...styleHeader }} className="p-2">{T("Invoice number")}</th>
                  <th style={{width:"14%", ...styleHeader }} className="p-2">{T("Date")}</th>
                  <th style={{width:"15%", ...styleHeader }} className="p-2">{T("Description")}</th>
                  <th style={{width:"15%", ...styleHeader }} className="p-2">{T("Cost")}</th>
                  <th style={{width:"14%", ...styleHeader }} className="p-2">{T("VAT")}</th>
                  <th style={{width:"15%", ...styleHeader }} className="p-2">{T("Total cost")}</th>
                  <th style={{width:"15%", ...styleHeader }} className="p-2">{T("Difference")}</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(groupedData).map(([account, items]) => (
                  <React.Fragment key={account}>
                    <tr
                      style={{
                        backgroundColor: "#f7f7f7",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                      onClick={() => toggleAccount(account)}
                    >
                      <td
                        colSpan={14}
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                      {`${T("Account")}: ${account} (${items.length} ${T("registers")})`}
                      </td>
                    </tr>
                    {expandedAccounts[account] &&
                      items.map((item) => (
                        <tr key={item.id}>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.fixed_assets}>{item.fixed_assets}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.description}>{item.description}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.acquisition_date ? new Date(item.acquisition_date).getFullYear().toString() : ""}>{item.acquisition_date ? new Date(item.acquisition_date).getFullYear() : ""}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.useful_life_months?.toString()}>{item.useful_life_months}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.total_cost_niif?.toString()}>{item.total_cost_niif}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.accumulated_depreciation?.toString()}>{item.accumulated_depreciation}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.net_cost_cut?.toString()}>{item.net_cost_cut}</td>
                            
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.invoice_number?.toString()}>
                          {item.invoice_number}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.additional_date ? new Date(item.additional_date).toLocaleDateString() : ""}
                            >
                              {item.additional_date ? new Date(item.additional_date).toLocaleDateString() : ""}
                            </td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.additional_description?.toString()}>
                          {item.additional_description}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.additional_cost?.toString()}>
                          {item.additional_cost}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.additional_vat?.toString()}>
                          {item.additional_vat}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.additional_total_cost?.toString()}>
                          {formatCurrency(calculateAdditionalTotalCost(item.additional_cost, item.additional_vat))}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={formatCurrency(calculateDifference(item.total_cost_niif, item.additional_cost, item.additional_vat)?.toString())}>
                          {formatCurrency(calculateDifference(item.total_cost_niif, item.additional_cost, item.additional_vat))}</td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Configuration Additions")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <div className="d-flex w-full flex-column mb-3 card">
          <div className="card-body">
            <h6>{T("Additions")}</h6>
            <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}> 
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  minWidth: "2000px",
                  tableLayout: "fixed"
                }}
                className="table table-bordered table-hover"
              >
              <thead>
                <tr>
                  <th style={{width:"14%",...styleHeader}} title={T("Fixed Assets")} className="p-2">{T("Fixed Assets")}</th>
                  <th style={{width:"14%",...styleHeader}} title={T("Description")} className="p-2">{T("Description")}</th>
                  <th style={{width:"9%",...styleHeader}} title={T("Date of acquisition")} className="p-2">{T("Date of acquisition")}</th>
                  <th style={{width:"9%",...styleHeader}} title={T("Useful life months")} className="p-2">{T("Useful life months")}</th>
                  <th style={{width:"15%",...styleHeader}} title={T("Total cost NIIF")} className="p-2">{T("Total cost NIIF")}</th>
                  <th style={{width:"15%",...styleHeader}} title={T("Accumulated Depreciation")} className="p-2">{T("Accumulated Depreciation")}</th>
                  <th style={{width:"16%",...styleHeader}} title={T("Net cost cut-off")} className="p-2">{T("Net cost cut-off")}</th>
                  <th style={{width:"14%",...styleHeader}} title={T("Invoice number")} className="p-2">{T("Invoice number")}</th>
                  <th style={{width:"14%",...styleHeader}} title={T("Date")} className="p-2">{T("Date")}</th>
                  <th style={{width:"16%",...styleHeader}} title={T("Description")} className="p-2">{T("Description")}</th>
                  <th style={{width:"16%",...styleHeader}} title={T("Cost")} className="p-2">{T("Cost")}</th>
                  <th style={{width:"14%",...styleHeader}} title={T("VAT")} className="p-2">{T("VAT")}</th>
                  <th style={{width:"15%",...styleHeader}} title={T("Total cost")} className="p-2">{T("Total cost")}</th>
                  <th style={{width:"15%",...styleHeader}} title={T("Difference")} className="p-2">{T("Difference")}</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(groupedData).map(([account, items]) => (
                  <React.Fragment key={account}>
                    <tr
                      style={{
                        backgroundColor: "#f7f7f7",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                      onClick={() => toggleAccount(account)}
                    >
                      <td
                        colSpan={14}
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                      {`${T("Account")}: ${account} (${items.length} ${T("registers")})`}
                      </td>
                    </tr>
                    {expandedAccounts[account] &&
                      items.map((item) => (
                        <tr key={item.id}>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.fixed_assets}>{item.fixed_assets}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.description}>{item.description}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.acquisition_date ? new Date(item.acquisition_date).getFullYear().toString() : ""}>{item.acquisition_date ? new Date(item.acquisition_date).getFullYear() : ""}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.useful_life_months?.toString()}>{item.useful_life_months}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.total_cost_niif?.toString()}>{item.total_cost_niif}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.accumulated_depreciation?.toString()}>{item.accumulated_depreciation}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.net_cost_cut?.toString()}>{item.net_cost_cut}</td>
                            
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.invoice_number?.toString()}>
                          <textarea
                            className="form-control"
                            defaultValue={item.invoice_number || ""}
                            onChange={(e) => handleInputChange(Number(item.id), "invoice_number", e.target.value)}
                            required
                          /></td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.additional_date ? new Date(item.additional_date).toLocaleDateString() : ""}
                            >
                              <DatePicker
                                id="date-picker"
                                value={item.additional_date ? new Date(item.additional_date) : ""}
                                onChange={(selectedDates) => handleDateChange(item.id, selectedDates)}
                                dateFormat="Y-m-d"
                                className="form-control"
                              />                            
                          </td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.additional_description?.toString()}>
                          <textarea
                            className="form-control"
                            defaultValue={item.additional_description || ""}
                            onChange={(e) => handleInputChange(Number(item.id), "additional_description", e.target.value)}
                            required
                          /></td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.additional_cost?.toString()}>
                          <input
                            className="form-control"
                            type="text"
                            defaultValue={formatCurrency(item.additional_cost)}
                            onInput={handleNumericInput}
                            onChange={e => handleInputChange(Number(item.id), 'additional_cost', e.target.value)}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          /></td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.additional_vat?.toString()}>
                          <textarea
                            className="form-control"
                            defaultValue={item.additional_vat || ""}
                            onChange={(e) => handleInputChange(Number(item.id), "additional_vat", e.target.value)}
                            required
                          /></td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.additional_total_cost?.toString()}>
                          {formatCurrency(calculateAdditionalTotalCost(item.additional_cost, item.additional_vat))}</td>
                          <td className="p-1" style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.additional_difference?.toString()}>
                          {formatCurrency(calculateDifference(item.total_cost_niif, item.additional_cost, item.additional_vat))}
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            </div>
          </div>
          </div>
      </ModalWithButtons>
    </div>
  );
};

export default CustomerAdditionsInformationAssetsViewMode;
