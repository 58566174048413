import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from '../special-document/ViewModeProps';
import ViewModeBase from "../special-document/ViewModeBase";
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { formatCurrency } from '../../utils/utilities';
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';
import { RegistersDifferenceReconcilingItems, DifferenceReconcilingItemsRepository } from '../../repository/substantive-tests/DifferenceReconcilingItemsRepository';
import { DocumentSampleRepository } from '../../repository/substantive-tests/DocumentSampleRepository';


export interface AccountData {
  id: number;
  consignment_number: number;
  type_account: string;
  account: string;
  name: string;
  amount: number;
  amount_in_other_accounts: number;
  amount_per_statement: number;
  difference_amount: number;
}

export interface RegisterType {
  id: string;
  reference_id: number;
  name_reconciling_items: string;
  extracted_value: string;
}

const DifferenceReconcilingItemsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isRefresh, setIsRefresh] = useState(false);
  const [isRefreshLocal, setIsRefreshLocal] = useState(false);
  const [registersDifferenceReconcilingItems, setRegistersDifferenceReconcilingItems] = useState<RegistersDifferenceReconcilingItems[]>([]);
  const [registers, setRegisters] = useState<RegistersDifferenceReconcilingItems[]>([]);
  const [registersSon, setRegistersSon] = useState<RegistersDifferenceReconcilingItems[]>([]);
  const [registersFather, setRegistersFather] = useState<RegistersDifferenceReconcilingItems[]>([]);
  const [newRegisters, setNewRegisters] = useState<Record<string, Array<RegisterType>>>({});
  const [addNewRegisters, setAddNewRegisters] = useState<Record<string, Array<RegisterType>>>({});
  const [setRergistersToAddTotal , setRergistersToAddTotalState] = useState<RegistersDifferenceReconcilingItems[]>([]);
  const [contentConclusion, setContentConclusion] = useState<string | undefined>(undefined);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const handleEvent = () => {
      setIsRefresh(prev => !prev);
      setKey(prevKey => prevKey + 1);
    };
    emitter.on("refreshSubstantiveBalanceData", handleEvent);
    return () => {
      emitter.off("refreshSubstantiveBalanceData", handleEvent);
    };
  }, []);

  const getRegistersDifferenceReconcilingItems = async () => {
    const differenceReconcilingItemsRepository = new DifferenceReconcilingItemsRepository()
    const result = await differenceReconcilingItemsRepository.getRegistersDifferenceReconcilingItems(element.args.engagement_id, element.args.document_id, element.reference)
    if (Array.isArray(result)) {
      setRegistersSon(result);
      setRegistersDifferenceReconcilingItems(result);
      setRergistersToAddTotalState(result);
    } else {
      setRegistersSon([]);
      setRegistersDifferenceReconcilingItems([]);
      setRergistersToAddTotalState([]);
    }
  }

  useEffect(() => {
    const allFieldsAreError = registersFather.every(item => item.is_error === true);
    if (registersSon.length == 0 && allFieldsAreError == true) {
      setContentConclusion(T("Based on the work performed in the testing of bank reconciliations, we conclude that non-material errors have been identified that may compromise the reasonableness of the account. These errors should be documented in the error log."));
    } else {
      setContentConclusion(undefined);
    }
  }, [registersSon]);
    

  const validateRecordsCreation = async () => {
    const differenceReconcilingItemsRepository = new DifferenceReconcilingItemsRepository()
    const result = await differenceReconcilingItemsRepository.validateRecordsCreation(element.args.engagement_id, element.args.document_id, element.reference)
    if (result.can_create) {
      setRegistersFather(result.data);
      getRegistersDifferenceReconcilingItems()
    } else {
      setRegistersFather([]);
      setRegistersSon([]);
      setRegistersDifferenceReconcilingItems([]);
      setRergistersToAddTotalState([]);
    }
  }

  useEffect(() => {
    validateRecordsCreation()
  }, [isRefresh, isRefreshLocal]);

  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  const redirectErrorSheet = () => {
    const url = `/audix/engagement_error_sheet/index.load/${element.args.engagement_id}/${element.reference}/${element.reference}`;
    window.menuService?.breadcrumbAddLevel(url, T('Error Sheet'))
  }

  const handleEditModalAccept = async () => {
    const differenceReconcilingItemsRepository = new DifferenceReconcilingItemsRepository()
    const result = await differenceReconcilingItemsRepository.updateDifferenceReconcilingItems(element.args.engagement_id, element.args.document_id, element.reference, addNewRegisters, registers)
    setIsRefreshLocal(prev => !prev);
    setNewRegisters({});
    setAddNewRegisters({});
    setRegisters([]);
    
    if (result.success) {
      const documentSampleRepository = new DocumentSampleRepository()
      const resultDocumentSample = await documentSampleRepository.setValidateRecordsCreation(element.args.engagement_id, element.args.document_id, element.reference)
      setIsRefreshLocal(prev => !prev);
      emitter.emit("refreshDifferenceReconcilingItems");
      setShowConfigurationsModal(false);
    }
  };

  const handleEditModalCancel = () => {
    setIsRefreshLocal(prev => !prev);
    setNewRegisters({});
    setAddNewRegisters({});
    setRegisters([]);
    setShowConfigurationsModal(false)
    setKey(prevKey => prevKey + 1);
  };

  const handleNumericInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let cleanedValue = event.target.value.replace(/[^0-9.-]/g, '');
    const parts = cleanedValue.split(".");
    if (parts.length > 1 && parts[1].length > 2) {
      parts[1] = parts[1].substring(0, 2);
      cleanedValue = parts.join(".");
    }

    event.target.value = cleanedValue;
  };

  const addRegister = (testId: number) => {
    const testRegisters = addNewRegisters[testId] || [];
    const anyIncomplete = testRegisters.some(
      item => item.name_reconciling_items.trim() === '' || item.extracted_value.trim() === ''
    );

    if (!anyIncomplete) {
      const newId = Date.now();
      const newRegister = {
        id: newId,
        name_reconciling_items: '',
        extracted_value: ''
      };

      setNewRegisters(prev => ({
        ...prev,
        [testId]: [...(prev[testId] || []), newRegister]
      }));
    }
  };

  const markError = async (testId: number) => {
    const differenceReconcilingItemsRepository = new DifferenceReconcilingItemsRepository()
    const result = await differenceReconcilingItemsRepository.markErrorDifferenceReconcilingItems(element.args.engagement_id, element.args.document_id, testId)
    setIsRefreshLocal(prev => !prev);
    emitter.emit("refreshDifferenceReconcilingItems");
  }

  const handleNewInputChange = (itemId: string | number | symbol | any, field: keyof RegistersDifferenceReconcilingItems, value: number | string | undefined | null, referenceId: number) => {
    setNewRegisters((prev: Record<string, Array<RegisterType>>) => ({
      ...prev,
      [itemId + "_" + referenceId]: {
        ...prev[itemId + "_" + referenceId],
        reference_id: referenceId,
        [field]: value
      }
    }));
    setAddNewRegisters((prev: Record<string, Array<RegisterType>>) => ({
      ...prev,
      [itemId + "_" + referenceId]: {
        ...prev[itemId + "_" + referenceId],
        reference_id: referenceId,
        [field]: value
      }
    }));
  };

  const handleInputChange = (itemId: string | number | symbol | any, field: keyof RegistersDifferenceReconcilingItems, value: number | string | undefined | null, referenceId: number) => {    
    setRegisters(prev => ({
      ...prev,
      [itemId]: { ...prev[itemId],reference_id: referenceId, [field]: value }
    }));
    
    setRergistersToAddTotalState(prev => {
      const updatedRegisters = prev.map(item => {
        if (item.id === itemId) {
          return { ...item, [field]: value }
        }
        return item
      });
      return updatedRegisters
    }
  )
  };

  const handleRemoveNewRegister = (itemId: string | number, referenceId: number) => {
    const keyToRemove = `${itemId}_${referenceId}`;
   
    setNewRegisters(prev => {
      const updatedRegisters = { ...prev };
      if (updatedRegisters[referenceId]) {
        updatedRegisters[referenceId] = updatedRegisters[referenceId].filter(item => item.id !== itemId);
        if (updatedRegisters[referenceId].length === 0) {
          delete updatedRegisters[referenceId];
        }
      }
      return updatedRegisters;
    });
    setAddNewRegisters(prev => {
      const updated = { ...prev };
      delete updated[keyToRemove];
      return updated;
    });
  };

  const handleRemoveOldRegister = async (itemId: number) => {
    const differenceReconcilingItemsRepository = new DifferenceReconcilingItemsRepository()
    const result = await differenceReconcilingItemsRepository.deleteDifferenceReconcilingItems(element.args.engagement_id, element.args.document_id, element.reference, itemId)
    if (result.success) {
      setIsRefreshLocal(prev => !prev);
      emitter.emit("refreshDifferenceReconcilingItems");
    }
  }

  const calculateCurrentTotal = (testId: number, difference: number | undefined = 0) => {
    const filteredItems = setRergistersToAddTotal.filter(item => item.bank_conciliation_account_difference_id === testId);
    const total = filteredItems.reduce((sum, item) => {
      const value = Number(item.extracted_value) || 0;
      const roundedValue = Number(value.toFixed(2));
      return parseFloat((sum + roundedValue).toFixed(2));
    }, 0);
    
    const sumFromNewRegisters = Object.values(addNewRegisters)
      .flat()
      .filter(item => item.reference_id === testId)
      .reduce((acc, item) => {
        const itemValue = Number(item.extracted_value || 0);
        return parseFloat((acc + itemValue).toFixed(2));
      }, 0);    
    
    const result = Math.round((total + sumFromNewRegisters - difference) * 100) / 100; 
    return result;
  }

  function handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
    const value = parseFloat(e.target.value.replace(/[^0-9.-]/g, ''));
    e.target.value = formatCurrency(value);
  }
  
  function handleFocus(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.value = e.target.value.replace(/[^0-9.-]/g, '');
  }

  return (
    <div key={key}>
      {registersFather.length > 0 && (
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        <div key={key} className="d-flex w-full flex-column mb-3">
          {registersFather.map((test) => (
            <div className="mb-3 w-100">
              <h4>{T("Account")}: {test.account} - {T("Difference")}: {formatCurrency(test.difference_value)}</h4>
              <div className="d-flex justify-content-center w-100">
                <table className="table-bordered w-100">
                  <thead>
                    <tr>
                      <th className="w-50 p-2">{T("Reconciling Items")}</th>
                      <th className="w-50 p-2">{T("Extracted Value")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {registersDifferenceReconcilingItems.filter(item => item.bank_conciliation_account_difference_id === test.id).map(item => (
                      <tr key={item.id}>
                        <td className="p-1">{item.name_reconciling_items}</td>
                        <td className="p-1">{formatCurrency(item.extracted_value)}</td>
                      </tr>
                    ))}
                      <tr key={test.id}>
                        <td className="p-1">Total</td>
                        <td className="p-1">
                        <span style={{ color: test.is_error === true ? '#E3553B': Number(calculateCurrentTotal(test.id, test.difference_value)) == 0? 'green': 'red'}}>
                            {formatCurrency(calculateCurrentTotal(test.id, test.difference_value))} <span style={{color: '#E3553B'}}>{test.is_error ? T("Error") : ""}</span>
                          </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))}
          <div className="w-100 d-flex align-items-center text-center flex-column mt-2">
          {contentConclusion != undefined && (
              <div className="mt-3">
                <h6>{T("Conclusion")}</h6>
                <p>{contentConclusion}</p>
                <button className="btn btn-primary" onClick={redirectErrorSheet}>{T("Error Sheet")}</button>
              </div>
            )}
            </div>
        </div>
      </ViewModeBase>
      )}
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Reconciling Items")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <div>
          {registersFather.map((test) => (
            <div style={{ width: "100%" }} key={test.id}>
              <h4>{T("Account")}: {test.account} - {T("Difference")}: {formatCurrency(test.difference_value)}</h4>
              <div className="mb-3">
                <div className="d-flex flex-column align-items-center w-100">
                <table className="table-bordered w-80">
                  <thead>
                    <tr>
                      <th className="w-45 p-2">{T("Reconciling Items")}</th>
                      <th className="w-45 p-2">{T("Extracted Value")}</th>
                      <th className="w-10 p-2">{T("Actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {registersSon.filter(item => item.bank_conciliation_account_difference_id === test.id).map(item => (
                      <tr key={`${item.id}-${key}`}>
                        <td className="p-1">
                          <input
                            className="form-control"
                            type="text"
                            defaultValue={item.name_reconciling_items}
                            onChange={e => handleInputChange(item.id, 'name_reconciling_items', e.target.value, test.id)}
                          />
                        </td>
                        <td className="p-1">
                          <input
                            className="form-control"
                            type="text"
                            defaultValue={formatCurrency(item.extracted_value)}
                            onInput={handleNumericInput}
                            onChange={e => handleInputChange(item.id, 'extracted_value', e.target.value, test.id)}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                        </td>
                        <td className="p-1">
                          <button className="btn btn-icon btn-primary element-card-buttons" onClick={() => handleRemoveOldRegister(item.id)}>
                            <span className="btn-inner--icon"><i className="fa fa-trash py-2" aria-hidden="true"></i></span></button>
                        </td>
                      </tr>
                    ))}
                    {newRegisters[test.id]?.map((item) => (
                      <tr key={item.id}>
                        <td className="p-1 bg-light">
                          <input
                            className="form-control"
                            type="text"
                            defaultValue={item.name_reconciling_items}
                            onChange={e => handleNewInputChange(item.id, 'name_reconciling_items', e.target.value, test.id)}
                          />
                        </td>
                        <td className="p-1 bg-light">
                          <input
                            className="form-control"
                            type="text"
                            defaultValue={formatCurrency(item.extracted_value)}
                            onInput={handleNumericInput}
                            onChange={e => handleNewInputChange(item.id, 'extracted_value', e.target.value, test.id)}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                        </td>
                          <td className="p-1">
                          <button className="btn btn-icon btn-primary element-card-buttons" onClick={() => handleRemoveNewRegister(item.id, test.id)}>
                          <span className="btn-inner--icon"><i className="fa fa-trash py-2" aria-hidden="true"></i></span></button>
                        </td>
                      </tr>
                    ))}
                      <tr key={test.id}>
                        <td className="p-1">Total</td>
                        <td className="p-1">
                          <span style={{ color: test.is_error === true ? '#E3553B': Number(calculateCurrentTotal(test.id,  test.difference_value)) == 0? 'green': 'red'}}>
                            {formatCurrency(calculateCurrentTotal(test.id,  test.difference_value))} <span style={{color: '#E3553B'}}>{test.is_error ? T("Error") : ""}</span>
                          </span>
                          </td>
                        <td className="p-1"></td>
                      </tr>
                  </tbody>
                </table>
                <div className="d-flex justify-content-end w-80 gap-2 mt-2">
                  <button className="btn btn-secondary" onClick={() => addRegister(test.id)}>{T("Add New")}</button>
                  <button className="btn btn-primary" onClick={() => markError(test.id)}>
                    {test.is_error ? T("Unmark error") : T("Mark as error")}
                  </button>
                </div>
              </div>
              </div>
            </div>

          ))}
           <div>
            <hr />
          </div>
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default DifferenceReconcilingItemsViewMode;
