import React, { useState, useEffect, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { type Permissions } from "../../models/special-document/ElementBase"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { decryptUniqueFilename } from "../../utils/utilities"
import { ImportantQuestionsMeetingsWithKeyMembersClientElement } from "../../models/special-document/ImportantQuestionsMeetingsMembersClientElement"
import ViewModeProps from './ViewModeProps'
import ViewModeBase from "./ViewModeBase"
import ModalWithButtons from '../modals/AceptCancelModalBase'
import DOMPurify from 'isomorphic-dompurify';
import { ItemsOfficials } from '../../models/special-document/ElementArgs'
import DropzoneComponentMeetingsMembersView from '../commons/DropzoneComponentMeetingsMembersView';
import Summernote from '../utils/Summernote';


/**
 * Renders the view mode of the Important Questions Meetings Members Client.
 * @param {ViewModeProps} props - The properties for the component.
 */
const ImportantQuestionsMeetingsMembersClientViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<ImportantQuestionsMeetingsWithKeyMembersClientElement>(iElement as ImportantQuestionsMeetingsWithKeyMembersClientElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)

  const [fileNames, setFileNames] = useState<Record<number| string, { id: number, name: string }[]>>({});
  const [baseFileNames, setBaseFileNames] = useState<Record<number, { id: number, name: string }[]>>({});
  const [renderKey, setRenderKey] = useState(0);
  const [visibleSections, setVisibleSections] = useState<Record<string, boolean>>({});

  const [dataBaseAllRegisters, setDataBaseAllRegisters] = useState<ItemsOfficials[]>(element.items_officials)
  const [groupedData, setGroupedData] = useState<Record<string, { officials: ItemsOfficials[]; questions: Record<string, ItemsOfficials[]> }>>({});
  const [validateConclusion, setValidateConclusion] = useState(false)
  const [conclusionBase, setConclusionBase] = useState<string>("");
  const [conclusion, setConclusion] = useState<string>("");
  const [expandedSections, setExpandedSections] = useState<Record<string, boolean>>({});
  const [expandedEditSections, setExpandedEditSections] = useState<Record<string, boolean>>({});

  const answersYesNo = [
    { value: 1, text: "Yes" },
    { value: 2, text: "No" },
    { value: 3, text: "Not Applicable" },
  ]

  /**
   * Toggles the visibility of a section.
   * @param {string} orderItem - The order item identifier.
   */
  const toggleSection = (orderItem: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [orderItem]: !prev[orderItem],
    }));
  };

  /**
   * Toggles the editability of a section.
   * @param {string} orderItem - The order item identifier.
   */
  const toggleEditSection = (orderItem: string) => {
    setExpandedEditSections((prev) => ({
      ...prev,
      [orderItem]: !prev[orderItem],
    }));
  }

  /**
   * Handles the edit button click.
   */
  function handleEdit() {
    setShowConfigurationsModal(true)
  }

  /**
   * Handles the acceptance of the edit modal.
   */
  async function handleEditModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastItemsOfficials = element.items_officials
    element.items_officials = dataBaseAllRegisters
    element.conclusion = conclusion
    let success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      element.items_officials = lastItemsOfficials
      element.conclusion = conclusionBase
      setDataBaseAllRegisters(lastItemsOfficials)
      window.htmlHelpers?.swalError()
    } else {
      const initialFileNames = element.items_officials.reduce<Record<number | string, { id: number, name: string }[]>>(
        (acc, item) => {
          if (item.attachment_name) {
            try {
              const parsedAttachments = JSON.parse(item.attachment_name);

              if (Array.isArray(parsedAttachments)) {
                acc[item.id] = parsedAttachments.map((att) => ({
                  id: att.id ?? Date.now(),
                  name: att.name ?? att,
                }));
              } else {
                acc[item.id] = [];
              }
            } catch (error) {
              acc[item.id] = item.attachment_name.split(',').map((name, index) => ({
                id: Date.now() + index,
                name: name.trim(),
              }));
            }
          } else {
            acc[item.id] = [];
          }

          return acc;
        },
        {}
      );
      setFileNames(initialFileNames);
      setBaseFileNames(initialFileNames);
      setConclusionBase(element.conclusion)
      const groupedByOrderItem = element.items_officials.reduce((acc, item) => {
        if (!acc[item.order_item]) {
          acc[item.order_item] = { officials: [], questions: {} };
        }

        if (!item.type_question) {
          acc[item.order_item].officials.push(item);
        } else {
          if (!acc[item.order_item].questions[item.type_question]) {
            acc[item.order_item].questions[item.type_question] = [];
          }

          const exists = acc[item.order_item].questions[item.type_question].some(q => q.id === item.id);
          if (!exists) {
            acc[item.order_item].questions[item.type_question].push(item);
          }
        }

        return acc;
      }, {} as Record<string, { officials: ItemsOfficials[]; questions: Record<string, ItemsOfficials[]> }>);

      setGroupedData(groupedByOrderItem);
    }
  }

  /**
   * Handles the cancellation of the edit modal.
   */
  function handleEditModalCancel() {
    setFileNames(baseFileNames);
    setShowConfigurationsModal(false)
    const groupedByOrderItem = element.items_officials.reduce((acc, item) => {
      if (!acc[item.order_item]) {
        acc[item.order_item] = { officials: [], questions: {} };
      }

      if (!item.type_question) {
        acc[item.order_item].officials.push(item);
      } else {
        if (!acc[item.order_item].questions[item.type_question]) {
          acc[item.order_item].questions[item.type_question] = [];
        }

        const exists = acc[item.order_item].questions[item.type_question].some(q => q.id === item.id);
        if (!exists) {
          acc[item.order_item].questions[item.type_question].push(item);
        }
      }

      return acc;
    }, {} as Record<string, { officials: ItemsOfficials[]; questions: Record<string, ItemsOfficials[]> }>);
    setDataBaseAllRegisters(element.items_officials)
    setConclusion(element.conclusion || "");
    setGroupedData(groupedByOrderItem);
    setRenderKey((prevKey) => prevKey + 1);
  }

  /**
   * Handles the addition of a new section.
   */
  const handleAddSection = () => {
    setGroupedData((prev) => {
      const existingOrderItems = Object.keys(prev).map(Number);
      if (existingOrderItems.length === 0) return prev;
      const baseOrderItem = Math.max(...existingOrderItems);
      const baseData = prev[baseOrderItem];
      if (!baseData) return prev;

      const newOrderItem = baseOrderItem + 1;
      const newOfficials = baseData.officials.map((official) => ({
        ...official,
        id: crypto.randomUUID(),
        official: "",
        official_position: "",
        order_item: newOrderItem,
        value_text: "",
        attachment_name: "",
      }));

      const newQuestions = Object.entries(baseData.questions).reduce((acc, [typeQuestion, items]) => {
        acc[typeQuestion] = items.map((item) => ({
          ...item,
          id: crypto.randomUUID(),
          order_item: newOrderItem,
          value_yes_no: undefined,
          value_text: "",
          attachment_name: "",
        }));
        return acc;
      }, {} as Record<string, ItemsOfficials[]>);

      setDataBaseAllRegisters((prevRegisters) => [
        ...prevRegisters,
        ...newOfficials,
        ...Object.values(newQuestions).flat(),
      ]);

      return {
        ...prev,
        [newOrderItem]: {
          officials: newOfficials,
          questions: newQuestions,
        },
      };
    }
    );
  };


  useEffect(() => {
    const initialFileNames = element.items_officials.reduce<Record<number | string, { id: number, name: string }[]>>(
      (acc, item) => {
        if (item.attachment_name) {
          try {
            const parsedAttachments = JSON.parse(item.attachment_name);

            if (Array.isArray(parsedAttachments)) {
              acc[item.id] = parsedAttachments.map((att) => ({
                id: att.id ?? Date.now(),
                name: att.name ?? att,
              }));
            } else {
              acc[item.id] = [];
            }
          } catch (error) {
            acc[item.id] = item.attachment_name.split(',').map((name, index) => ({
              id: Date.now() + index,
              name: name.trim(),
            }));
          }
        } else {
          acc[item.id] = [];
        }

        return acc;
      },
      {}
    );
    setFileNames(initialFileNames);
    setBaseFileNames(initialFileNames);
    setConclusion(element.conclusion || "");
    setConclusionBase(element.conclusion || "");

    const groupedByOrderItem = element.items_officials.reduce((acc, item) => {
      if (!acc[item.order_item]) {
        acc[item.order_item] = { officials: [], questions: {} };
      }

      if (!item.type_question) {
        acc[item.order_item].officials.push(item);
      } else {
        if (!acc[item.order_item].questions[item.type_question]) {
          acc[item.order_item].questions[item.type_question] = [];
        }

        const exists = acc[item.order_item].questions[item.type_question].some(q => q.id === item.id);
        if (!exists) {
          acc[item.order_item].questions[item.type_question].push(item);
        }
      }

      return acc;
    }, {} as Record<string, { officials: ItemsOfficials[]; questions: Record<string, ItemsOfficials[]> }>);

    setGroupedData(groupedByOrderItem);

    const questionsData = Object.entries(groupedByOrderItem).reduce((acc, [orderItem, data]) => {
      Object.entries(data.questions).forEach(([typeQuestion, items]) => {
        acc[`${orderItem}-${typeQuestion}`] = items;
      });
      return acc;
    }, {} as Record<string, ItemsOfficials[]>);


    const initialVisibility = Object.entries(questionsData).reduce((acc, [key, items]) => {
      if (Array.isArray(items) && items.length > 0) {
        acc[key] = items[0].value_yes_no === 1;
      } else {
        acc[key] = false;
      }
      return acc;
    }, {} as Record<string, boolean>);

    setVisibleSections(initialVisibility);
  }, []);

  useEffect(() => {
    const questionsData = Object.entries(groupedData).reduce((acc, [orderItem, data]) => {
      Object.entries(data.questions).forEach(([typeQuestion, items]) => {
        acc[`${orderItem}-${typeQuestion}`] = items;
      });
      return acc;
    }, {} as Record<string, ItemsOfficials[]>);

    const allSectionsValid = Object.entries(questionsData).every(([typeQuestion, items]) => {
      const isVisible = visibleSections[typeQuestion] ?? false;
      const firstItem = items[0];

      if (!firstItem || !Object.prototype.hasOwnProperty.call(firstItem, "value_yes_no")) return false;

      if (!isVisible) {
        return firstItem.value_yes_no !== null && firstItem.value_yes_no !== undefined;
      }

      if (firstItem.value_yes_no === 1) {
        return items.every((item) =>
          item.hasOwnProperty("value_yes_no") && item.value_yes_no !== null && item.value_yes_no !== undefined
        );
      }

      return firstItem.value_yes_no !== null && firstItem.value_yes_no !== undefined;
    });

    setValidateConclusion(allSectionsValid);
    setRenderKey((prevKey) => prevKey + 1);
  }, [groupedData]);

  /**
   * Handles the editing of an official's name.
   * @param {number} orderItem - The order item identifier.
   * @param {string} newValue - The new value for the official's name.
   */
  const handleEditOfficial = (orderItem: number, newValue: string) => {
    setDataBaseAllRegisters((prev) =>
      prev.map((item) =>
        item.order_item === orderItem ? { ...item, official: newValue } : item
      )
    );
  };

  /**
   * Handles the editing of an official's position.
   * @param {number} orderItem - The order item identifier.
   * @param {string} newValue - The new value for the official's position.
   */
  const handleEditOfficialPosition = (orderItem: number, newValue: string) => {
    setDataBaseAllRegisters((prev) =>
      prev.map((item) =>
        item.order_item === orderItem ? { ...item, official_position: newValue } : item
      )
    );
  };

  /**
   * Handles the editing of an answer.
   * @param {number| string} itemId - The ID of the item to edit.
   * @param {string} answerValue - The new value for the answer.
   */
  const handleEditAnswer = (itemId: number | string, answerValue: string) => {
    setDataBaseAllRegisters((prev) =>
      prev.map((item) =>
        item.id === itemId ? { ...item, value_text: answerValue } : item
      )
    );
  }

  /**
   * Handles saving an attachment.
   * @param {number | string} itemId - The ID of the item to save the attachment for.
   * @param {string} attachmentName - The name of the attachment to save.
   */
  const handleSavingAttachment = (itemId: number | string, attachmentName: string) => {
    setFileNames((prev) => ({
      ...prev,
      [itemId]: [
        ...(prev[itemId] || []),
        { id: Date.now(), name: attachmentName },
      ],
    }));

    setDataBaseAllRegisters((prev) =>
      prev.map((item) =>
        item.id === itemId ? { ...item, attachment_name: attachmentName } : item
      )
    );
  }

  /**
   * Handles removing an attachment.
   * @param {number| string} itemId - The ID of the item to remove the attachment from.
   * @param {{ id: number; name: string }} fileToRemove - The attachment to remove.
   */
  const handleRemoveAttachment = (itemId: number| string, fileToRemove: { id: number; name: string }) => {
    setFileNames((prev) => ({
      ...prev,
      [itemId]: (prev[itemId] || []).filter((file) => file.id !== fileToRemove.id),
    }));
  };

  /**
   * Handles deleting a section.
   * @param {number} orderItem - The order item identifier.
   */
  const handleDeleteSection = (orderItem: number) => {
    const totalGroups = Object.keys(groupedData).length;

    if (totalGroups <= 1) {
      return;
    }
    setDataBaseAllRegisters((prev) => prev.filter((item) => item.order_item !== orderItem));
    setGroupedData((prev) => {
      const newState = { ...prev };
      delete newState[orderItem];
      return newState;
    });
  };
 
  /**
   * Handles the selection of an answer.
   * @param {number | string} itemId - The ID of the item to update.
   * @param {number} answerValue - The selected answer value.
   * @param {string} typeQuestion - The type of question.
   * @param {number} orderItem - The order item identifier.
   */
  const handleAnswerSelected = (itemId: number| string, answerValue: number, typeQuestion: string, orderItem: number) => {
    setDataBaseAllRegisters((prev) =>
      prev.map((item) => (item.id === itemId ? { ...item, value_yes_no: answerValue } : item))
    );

    const questionsData = Object.entries(groupedData).reduce((acc, [orderItem, data]) => {
      Object.entries(data.questions).forEach(([typeQuestion, items]) => {
        acc[`${orderItem}-${typeQuestion}`] = items;
      });
      return acc;
    }, {} as Record<string, ItemsOfficials[]>);

    const sectionItems = questionsData[`${orderItem}-${typeQuestion}`];

    if (sectionItems && sectionItems.length > 0 && sectionItems[0].id === itemId) {
      setVisibleSections((prev) => ({
        ...prev,
        [`${orderItem}-${typeQuestion}`]: answerValue === 1,
      }));
    }
  }

  useEffect(() => {
    setRenderKey((prevKey) => prevKey + 1);
  }, [visibleSections]);


  /**
   * Handles the editing of an answer for the second part.
   * @param {number} itemId - The ID of the item to edit.
   * @param {string} answerValue - The new value for the answer.
   */
  const handleEditAnswerSecond = (itemId: number| string, answerValue: string) => {
    setDataBaseAllRegisters((prev) =>
      prev.map((item) =>
        item.id === itemId ? { ...item, value_text: answerValue } : item
      )
    );
  }

  const referenceTitles: Record<string, string> = {
    "changes_accounting_policies": "Changes in Accounting Policies",
    "unusual_transactions": "Unusual Transactions",
    "significant_management_events": "Significant Management Events",
    "fraud_risk_indicators": "Fraud Risk Indicators",
    "regulatory_compliance": "Regulatory Compliance",
    "other_disclosures": "Other Relevant Changes"
  };

  /**
   * Handles the conclusion.
   * @param {string} answerValue - The conclusion value.
   */
  const handleEditConclusion = (answerValue: string) => {
    setConclusion(answerValue);
  }

  return (
    <>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        <div key={renderKey}>
          {Object.entries(groupedData).map(([orderItem, { officials, questions }]) => {
            const isExpanded = expandedSections[orderItem] ?? false;

            return (
              <div key={orderItem} style={{ marginBottom: "20px", border: "1px solid #ddd", padding: "10px", borderRadius: "5px" }}>
                <label>{T("Official")}</label>
                <h3 style={{ textAlign: "left", marginBottom: "10px" }}>{officials[0]?.official}</h3>
                <label>{T("Official position")}</label>
                <h3 style={{ textAlign: "left", marginBottom: "10px" }}>{officials[0]?.official_position}</h3>
                <div className="d-flex justify-content-center">
                  <button
                    onClick={() => toggleSection(orderItem)}
                    className="btn btn-primary w-100"
                  >
                    {isExpanded ? T("Hide Information") : T("Show Information")}
                  </button>
                </div>

                {isExpanded && (
                  <>
                    {officials.length > 0 && (
                      <>
                        <table className="table-bordered">
                          <thead>
                            <tr>
                              <th style={{ width: "30%" }}>{T("Question")}</th>
                              <th style={{ width: "45%" }}>{T("Answer")}</th>
                              <th style={{ width: "25%" }}>{T("Attachment")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {officials.map((item) => (
                              <tr key={item.id}>
                                <td style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>{T(item.question || "")}</td>
                                <td>{item.value_text}</td>
                                <td>
                                  <em>{decryptUniqueFilename(item.attachment_name || "")}</em>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    )}

                    {questions &&
                      Object.entries(questions).map(([typeQuestion, items]) => {
                        const firstItem = items[0];
                        const isFirstYes = Number(firstItem?.value_yes_no) === 1;

                        return (
                          <div key={typeQuestion} style={{ marginBottom: "20px" }}>
                            <h3>{T(referenceTitles[typeQuestion]) || T("Other Questions")}</h3>
                            <table className="table-bordered">
                              <thead>
                                <tr>
                                  <th style={{ width: "30%" }}>{T("Question")}</th>
                                  <th style={{ width: "10%" }}>{T("Answer")}</th>
                                  <th style={{ width: "40%" }}>{T("Justification")}</th>
                                  <th style={{ width: "20%" }}>{T("Attachment")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {items
                                  .filter((_, index) => index === 0 || isFirstYes)
                                  .map((item, index) => (
                                    <tr key={`${item.id}-${typeQuestion}-${index}`}>
                                      <td>{T(item.question || "")}</td>
                                      <td>
                                        {item.value_yes_no === 1
                                          ? T("Yes")
                                          : item.value_yes_no === 2
                                            ? T("No")
                                            : item.value_yes_no === 3
                                              ? T("Not Applicable")
                                              : T("No response")}
                                      </td>
                                      <td>{item.value_text}</td>
                                      <td>
                                        <em>{decryptUniqueFilename(item.attachment_name || "")}</em>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        );
                      })}
                  </>
                )}
              </div>
            );
          })}

          {validateConclusion && (
            <div>
              <h3>{T("Conclusion")}</h3>
              <p>{T("Conclude whether a clear understanding was obtained of management's process for identifying and responding to risks of fraud and material misstatement:")}</p>
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(conclusionBase) }} />
            </div>
          )}
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Settings")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}
      >
        <div className="card-body" key={renderKey}>
          {Object.entries(groupedData).map(([orderItem, { officials, questions }], index, array) => {
            const isExpanded = expandedEditSections[orderItem] ?? false;
            const totalGroups = array.length;

            return (
              <div key={orderItem} style={{ marginBottom: "20px", border: "1px solid #ddd", padding: "10px", borderRadius: "5px" }}>
                <div className="form-group mb-1 mt-2 d-flex justify-content-center align-items-center gap-2">
                  <div className="w-80">
                    <div>
                      <label>{T("Official")}</label>
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder={T("Enter official")}
                        value={dataBaseAllRegisters.find((item) => item.order_item === Number(orderItem))?.official || ""}
                        onChange={(e) => handleEditOfficial(Number(orderItem), e.target.value)}
                      />
                    </div>
                    <div>
                      <label>{T("Official position")}</label>
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder={T("Enter official position")}
                        value={dataBaseAllRegisters.find((item) => item.order_item === Number(orderItem))?.official_position || ""}
                        onChange={(e) => handleEditOfficialPosition(Number(orderItem), e.target.value)}
                      />
                    </div>
                  </div>
                  {totalGroups > 1 && (
                    <button
                      type="button"
                      style={{ cursor: 'pointer' }}
                      className="btn btn-danger mb-0"
                      onClick={() => handleDeleteSection(Number(orderItem))}
                    >
                      <i className="fa-regular fa-trash-can"></i>
                    </button>
                  )}
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    onClick={() => toggleEditSection(orderItem)}
                    className="btn btn-primary w-100"
                  >
                    {isExpanded ? T("Hide Information") : T("Show Information")}
                  </button>
                </div>

                {isExpanded && (
                  <>
                    {officials.length > 0 && (
                      <div style={{ marginBottom: "20px" }}>
                        <table className="table-bordered">
                          <thead>
                            <tr>
                              <th style={{ width: "30%" }}>{T("Question")}</th>
                              <th style={{ width: "45%" }}>{T("Answer")}</th>
                              <th style={{ width: "25%" }}>{T("Attachment")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {officials.map((item) => (
                              <tr key={item.id}>
                                <td style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>{T(item.question || "")}</td>
                                <td>
                                  <textarea
                                    className="form-control"
                                    style={{ height: "100px" }}
                                    defaultValue={dataBaseAllRegisters.find((itemEdit) => itemEdit.id === item.id)?.value_text ?? ""}
                                    onChange={(e) => handleEditAnswer(item.id, e.target.value)}
                                  />
                                </td>
                                <td>
                                  <DropzoneComponentMeetingsMembersView
                                    elementId={element.id}
                                    attachmentName={fileNames[item.id] || []}
                                    onUpload={(newFileNames) => handleSavingAttachment(item.id, newFileNames[0])}
                                    onRemove={(fileName) => handleRemoveAttachment(item.id, fileName)}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}

                    {Object.entries(questions).map(([typeQuestion, items]) => {
                      const firstItem = items[0];
                      const isFirstYes = visibleSections[`${orderItem}-${typeQuestion}`] ?? (Number(firstItem?.value_yes_no) === 1);

                      return (
                        <div key={typeQuestion} style={{ marginBottom: "20px" }}>
                          <h3>{T(referenceTitles[typeQuestion]) || T("Other Questions")}</h3>
                          <table className="table-bordered">
                            <thead>
                              <tr>
                                <th style={{ width: "30%" }}>{T("Question")}</th>
                                <th style={{ width: "10%" }}>{T("Answer")}</th>
                                <th style={{ width: "40%" }}>{T("Justification")}</th>
                                <th style={{ width: "20%" }}>{T("Attachment")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {items
                                .filter((item, index) => index === 0 || isFirstYes)
                                .map((item) => (
                                  <tr key={item.id}>
                                    <td style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>{T(item.question || "")}</td>
                                    <td style={{ height: "280px" }}>
                                      <label>{T("Select Answer")}</label>
                                      <select
                                        className="form-select"
                                        value={dataBaseAllRegisters.find((itemEdit) => itemEdit.id === item.id)?.value_yes_no ?? ""}
                                        onChange={(e) => handleAnswerSelected(item.id, Number(e.target.value), typeQuestion, Number(orderItem))}
                                      >
                                        <option value="" disabled>{T("Select an option")}</option>
                                        {answersYesNo.map((answer) => (
                                          <option key={answer.value} value={answer.value}>{T(answer.text)}</option>
                                        ))}
                                      </select>
                                    </td>
                                    <td>
                                      <textarea
                                        className="form-control"
                                        style={{ height: "100px" }}
                                        value={dataBaseAllRegisters.find((itemEdit) => itemEdit.id === item.id)?.value_text ?? ""}
                                        onChange={(e) => handleEditAnswerSecond(item.id, e.target.value)}
                                      />
                                    </td>
                                    <td>
                                      <DropzoneComponentMeetingsMembersView
                                        elementId={element.id}
                                        attachmentName={fileNames[item.id] || []}
                                        onUpload={(newFileNames) => handleSavingAttachment(item.id, newFileNames[0])}
                                        onRemove={(fileName) => handleRemoveAttachment(item.id, fileName)}
                                      />
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      );
                    })}



                  </>
                )}
              </div>
            );
          })}
          <div className="d-flex justify-content-end">
            <button onClick={handleAddSection} className="btn btn-primary">
              {T("Add Section")}
            </button>
          </div>
          {validateConclusion && (
            <div key={renderKey}>
              <h3>{T("Conclusion")}</h3>
              <Summernote
                value={conclusion || ""}
                onChange={handleEditConclusion}
                showModal={showConfigurationsModal}
              />
            </div>
          )}
        </div>
      </ModalWithButtons>
    </>
  );
};

export default ImportantQuestionsMeetingsMembersClientViewMode;
