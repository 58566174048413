import React, { useEffect, useState } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from '../special-document/ViewModeProps';
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import ViewModeBase from "../special-document/ViewModeBase";
import { formatCurrency } from '../../utils/utilities';
import { RegistersSummaryDepreciationFixedAssetsCost, SummaryDepreciationExpenseFixedAssetsRepository } from '../../repository/special-document/SummaryDepreciationExpenseFixedAssetsRepository';
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';


export interface AccountData {
  id: number;
  consignment_number: number;
  type_account: string;
  account: string;
  name: string;
  amount: number;
  amount_in_other_accounts: number;
  amount_per_statement: number;
  difference_amount: number;
}


/**
 * Component for displaying the summary depreciation expense fixed assets view mode.
 * @param {ViewModeProps} props - The properties for the component, including element data.
 */
const SummaryDepreciationExpenseFixedAssetsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement)
  const [isRefresh, setIsRefresh] = useState(false);
  const [registersOfTable, setRegistersOfTable] = useState<RegistersSummaryDepreciationFixedAssetsCost[]>([]);
  const [totalColumnVariationAmount, setTotalColumnVariationAmount] = useState<number>(0);
  const [totalColumnAnnualDepreciationExpense, setTotalColumnAnnualDepreciationExpense] = useState<number>(0);
  const [totalVariation, setTotalVariation] = useState<number>(0);

  useEffect(() => {
    const handleEvent = () => {
      setIsRefresh(prev => !prev);
    };
    emitter.on("refreshSubstantiveBalanceData", handleEvent);
    return () => {
      emitter.off("refreshSubstantiveBalanceData", handleEvent);
    };
  }, []);

  /**
  * Calculates the difference between the variation amount and annual depreciation expense.
  * @param variation_amount - The variation amount (defaults to 0 if undefined).
  * @param annual_depreciation_expense - The annual depreciation expense (defaults to 0 if undefined).
  * @returns The computed variation.
  */
  const calculateVariation = (variation_amount: number |  undefined, annual_depreciation_expense: number | undefined) => {
    variation_amount = variation_amount || 0
    annual_depreciation_expense = annual_depreciation_expense || 0
    const total = variation_amount - annual_depreciation_expense
    return total
  }

  /**
  * Retrieves trial balance registers and updates the state with totals and detailed data.
  */
  const getRegistersOfTrialBalanceFile = async () => {
    const summaryDepreciationFixedAssetsRepository = new SummaryDepreciationExpenseFixedAssetsRepository()
    const result = await summaryDepreciationFixedAssetsRepository.getSummaryDepreciationExpense(element.args.engagement_id, element.args.document_id, element.reference)
    
    if (result.success) {
      setTotalColumnVariationAmount(result.data.reduce((acc, item) => acc + (item.variation_amount || 0), 0));
      setTotalColumnAnnualDepreciationExpense(result.data.reduce((acc, item) => acc + (item.annual_depreciation_expense || 0), 0));
      const totalDifference = result.data.reduce((acc, item) => {
        const balance = item.variation_amount || 0;
        const balanceOfTheCutoffModule = item.annual_depreciation_expense || 0;
        return acc + (balance - balanceOfTheCutoffModule);
      }, 0);
      setTotalVariation(totalDifference);
      setRegistersOfTable(result.data);
    }
  }

  useEffect(() => {
    getRegistersOfTrialBalanceFile()
  }, [isRefresh]);


  return (
    <div>
      <ViewModeBase
        isEditable={false}
        permissions={element.permissions as Permissions}
      >
        <div className="d-flex w-full flex-column mb-3 card">
          <div className="card-body">
            <h4>{T("Depreciation expense Russell Calculation Vs Module")}</h4>
            <table className="table-bordered">
              <thead>
                <tr>
                  <th className="p-2">{T("Account")}</th>
                  <th className="p-2">{T("Account")}</th>
                  <th className="p-2">{T("Description")}</th>
                  <th className="p-2">{T("Balance of the cut-off module")}</th>
                  <th className="p-2">{T("Balance according to Russell cut")}</th>
                  <th className="p-2">{T("Variation")}</th>
                </tr>
              </thead>
              <tbody>
                {registersOfTable.map((item) => (
                  <tr key={item.code}>
                    <td className="p-1">{item.code}</td>
                    <td className="p-1">{item.sub_code}</td>
                    <td className="p-1">{item.name}</td>
                    <td className="p-1">{item.variation_amount}</td>               
                    <td className="p-1">{item.annual_depreciation_expense}</td>
                    <td className="p-1">{formatCurrency(calculateVariation(item.variation_amount, item.annual_depreciation_expense))}</td>
                  </tr>
                ))}
                <tr key={"total-banks"}>
                  <td className="p-1">{ }</td>
                  <td className="p-1">{ }</td>
                  <td className="p-1">{T("Total")}</td>
                  <td className="p-1">{formatCurrency(totalColumnVariationAmount.toFixed(2))}</td>
                  <td className="p-1">{formatCurrency(totalColumnAnnualDepreciationExpense.toFixed(2))}</td>
                  <td className="p-1">{formatCurrency(totalVariation.toFixed(2))}</td>
                </tr>
              </tbody>
            </table>
        </div>
        </div>
      </ViewModeBase>
    </div>
  );
};

export default SummaryDepreciationExpenseFixedAssetsViewMode;
