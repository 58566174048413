import { useState, useEffect, useRef } from 'react'
import { T } from '../../utils/i18n-config'
import { Permissions } from '../../models/special-document/ElementBase'
import { JournalEntriesElement } from '../../models/substantive-tests/JournalEntriesElement'
import { JournalEntriesRepository, type JEQuestion } from '../../repository/substantive-tests/JournalEntriesRepository'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeProps from '../special-document/EditModeProps'
import EditModeBase from '../special-document/EditModeBase'

/**
 * A React component to render and manage the edit mode for journal entry answers.
 */
const JournalEntriesAnswersEditMode = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<JournalEntriesElement>(iElement as JournalEntriesElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [items, setItems] = useState<JEQuestion[]>([])

  /**
   * Opens the configuration modal by updating the state.
   */
  function handleConfiguration() {
    setShowConfigurationsModal(true)
  }

  /**
   * Closes the configuration modal by updating the state.
   */
  function handleCloseModal() {
    setShowConfigurationsModal(false)
  }

  useEffect(() => {
    /**
     * Retrieves questions from the repository and updates the state with the received data.
     */
    async function fetchData() {
      const repository = new JournalEntriesRepository()
      const { success, data } = await repository.getQuestions(element.engagement_id, element.documentId, element.id)
      if (!success) return window.htmlHelpers?.swalError()
      setItems(data)
    }

    fetchData()
  }, [])

  return (
    <>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}
      >
        <table className="table table-responsive table-striped table-hover" style={{ borderCollapse: "collapse" }}>
          <thead className="table-dark border">
            <tr>
              <th scope="col" className="px-0 text-center border-end border-bottom border-white" style={{ width: "50px" }}>#</th>
              <th scope="col">{T("Question")}</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <th scope="row" className="text-center border-start border-end border-bottom">{item.reference}</th>
                <td className="ps-4 text-wrap border-bottom border-end">{item.question}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleCloseModal}
        onCancel={handleCloseModal}
      >
        <p>{T("There are no configurations for this element")}</p>
      </ModalWithButtons>
    </>
  );
}

export default JournalEntriesAnswersEditMode;