import { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { T } from '@/utils/i18n-config';
import { Constants } from '@/utils/Constants';
import { decryptUniqueFilename } from '@/utils/utilities';
import { ElementAttachmentRepository } from '@/repository/special-document/ElementAttachmentRepository';
import { getPreviewImage, getAcceptedFiles } from './file-utils';

export type DropFile = { file: File; preview: string };

export interface UseDropzoneProps {
  elementId: number;
  renderingMode: 'edition' | 'view';
  attachmentName?: string | string[];
  acceptedFiles?: string[];
  maxFiles?: number;
  onUpload: (attachmentName: string | string[], originalFilename: string | string[]) => void;
}

/**
 * Custom hook to encapsulate dropzone logic.
 * @param props - The dropzone properties.
 * @returns Dropzone state and functions.
 */
export function useDropzoneUpload({
  elementId,
  renderingMode,
  attachmentName,
  acceptedFiles,
  maxFiles,
  onUpload,
}: UseDropzoneProps) {
  const [files, setFiles] = useState<DropFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  // If attachmentName is provided (as string or non-empty array) then file is loaded.
  const [isFileLoaded, setIsFileLoaded] = useState(
    typeof attachmentName === 'string'
      ? Boolean(attachmentName)
      : Array.isArray(attachmentName) && attachmentName.length > 0
  );

  /**
   * Handles files dropped or selected via the dropzone.
   */
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const previewFiles = acceptedFiles.map(file => ({
      file,
      preview: getPreviewImage(file.type),
    }));
    setFiles(previewFiles);
  }, []);

  // Dropzone configuration.
  const isMultiple = () => {
    if (maxFiles) return maxFiles > 1;
    return Constants.MAX_FILES > 1;
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: maxFiles ?? Constants.MAX_FILES,
    multiple: isMultiple(),
    accept: acceptedFiles && acceptedFiles.length > 0
      ? getAcceptedFiles(acceptedFiles)
      : getAcceptedFiles([]),
  });

  // When the attachmentName prop changes, load the file preview(s).
  useEffect(() => {
    if (attachmentName) {
      // Support both string and array
      const fileNames = typeof attachmentName === 'string'
        ? [attachmentName]
        : attachmentName;
      const previewFiles = fileNames.map(name => {
        const fileName = decryptUniqueFilename(name);
        const fileExtension = name.split('.').pop()!;
        return {
          file: new File([], fileName),
          preview: getPreviewImage(fileExtension),
        };
      });
      setFiles(previewFiles);
    } else {
      setFiles([]);
    }
  }, [attachmentName]);

  /**
   * Removes a file from the list of files.
   *
   * @param fileName - The name of the file to remove.
   */
  const removeFile = (fileName: string) => {
    setFiles(prevFiles => prevFiles.filter(({ file }) => file.name !== fileName));
    if (isFileLoaded) setIsFileLoaded(false);
  };

  /**
   * Uploads the selected file(s) to the server.
   */
  async function uploadFiles() {
    setUploading(true);
    const elementsAttachmentRepository = new ElementAttachmentRepository();

    if (files.length === 0) {
      setUploading(false);
      setError(T("Please select a file. Maximum files allowed is " + Constants.MAX_FILES));
      return;
    }

    // For each file, create FormData and upload.
    const uploadResults = await Promise.all(
      files.map(async (dropFile) => {
        const formData = new FormData();
        formData.append('file', dropFile.file);
        return await elementsAttachmentRepository.uploadAttachment(renderingMode, elementId, formData);
      })
    );

    // Check if any upload failed.
    if (uploadResults.some(result => !result.success)) {
      setUploading(false);
      setError(T("Error uploading file. Please try again"));
      return;
    }

    // Gather filenames and original filenames.
    const attachmentNames = uploadResults.map(result => result.filename);
    const originalFilenames = files.map(({ file }) => file.name);

    // If only one file then send single string; otherwise, send arrays.
    onUpload(
      attachmentNames.length === 1 ? attachmentNames[0] : attachmentNames,
      originalFilenames.length === 1 ? originalFilenames[0] : originalFilenames
    );

    setIsFileLoaded(true);
    setUploading(false);
    setError(null);
  }

  return {
    files,
    uploading,
    error,
    isDragActive,
    isFileLoaded,
    getRootProps,
    getInputProps,
    removeFile,
    uploadFiles,
  };
}
