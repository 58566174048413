import React, { useState, useRef } from 'react'
import { T } from '../../utils/i18n-config'
import { Permissions } from '../../models/special-document/ElementBase'
import { DisallowanceIncomesElement } from '../../models/special-document/DisallowanceIncomesElement'
import { ElementRepository } from '../../repository/special-document/ElementRepository'
import ViewModeProps from '../special-document/ViewModeProps'
import ViewModeBase from '../special-document/ViewModeBase'
import LocalDeliveries from './DisallowanceIncome/local-deliveries'
import ExportDeliveries from './DisallowanceIncome/export-deliveries'
import ReturnDeliveries from './DisallowanceIncome/return-deliveries'
import ConclusionComponent from './DisallowanceIncome/conclusion'

/**
 * Component that renders the view mode of the disallowance incomes element.
 * @param iElement The disallowance incomes element.
 */
const DisallowanceIncomesViewMode = ({ iElement }: ViewModeProps) => {
  const element = useRef<DisallowanceIncomesElement>(iElement as DisallowanceIncomesElement).current
  const [deliveryType, setDeliveryType] = useState(element.deliveryType)

  /**
   * Handles the change event for the delivery type select input and persists the update.
   * @param {React.ChangeEvent<HTMLSelectElement>} event - The change event from the select element.
   */
  async function handleSetDeliveryType(event: React.ChangeEvent<HTMLSelectElement>) {
    const value = parseInt(event.target.value)
    setDeliveryType(value)
    element.deliveryType = value
    const elementRepository = new ElementRepository()
    await elementRepository.saveElement("view", element.args)
  }

  return (
    <>
      <ViewModeBase
	      isEditable={false}
	      handleEdit={() => { }}
	      permissions={iElement.permissions as Permissions}
	    >
        <div className="d-flex flex-column gap-5">
          <div className="d-flex flex-column gap-2" style={{ width: "250px" }}>
            <h6>{T("Determination of delivery types to customers")}</h6>
            <select
              className="form-select"
              value={deliveryType}
              onChange={handleSetDeliveryType}
            >
              <option value="0" disabled>{T("Select an option")}</option>
              <option value="1">{T("Local Deliveries")}</option>
              <option value="2">{T("Exportations")}</option>
              <option value="3">{T("Local Deliveries and Exportations")}</option>
            </select>
          </div>
          {deliveryType === 1 && (<LocalDeliveries element={element} />)}
          {deliveryType === 2 && (<ExportDeliveries element={element} />)}
          {deliveryType === 3 && (
            <>
              <LocalDeliveries element={element} />
              <ExportDeliveries element={element} />
            </>
          )}
          <ReturnDeliveries element={element} />
          <ConclusionComponent element={element} />
        </div>
      </ViewModeBase>
    </>
  );
}

export default DisallowanceIncomesViewMode;