import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { T } from "../../utils/i18n-config"
import { decryptUniqueFilename } from "../../utils/utilities"
import { Constants } from '../../utils/Constants';
import { ElementAttachmentRepository } from '../../repository/special-document/ElementAttachmentRepository';

type DropFile = { file: File; preview: string;uploadedId?:number; uploadedName?: string };
type AcceptedFiles = keyof typeof Constants.FILE_IMAGES;
type AcceptedExtensions = keyof typeof Constants.FILE_EXTENSIONS;

interface DropzoneComponentProps {
  elementId: number;
  // attachmentName?: string[];
  attachmentName?: { id: number, name: string }[];
  onUpload: (attachmentName: string[], originalFilename: DropFile[]) => void;
  onRemove: (attachmentName: { id: number, name: string }) => void;
  maxFiles?: number;
  fixedHeight?: number;
  percentageHeight?: number;
}

function getPreviewImage(fileType: string) {
  if (fileType in Constants.FILE_EXTENSIONS) {
    return `${Constants.FILE_IMAGE_PATH}/${Constants.FILE_EXTENSIONS[fileType as AcceptedExtensions]}`;
  }
  return `${Constants.FILE_IMAGE_PATH}/${Constants.FILE_IMAGES[fileType as AcceptedFiles]}`;
}

const MultipleDropzoneComponent = ({ elementId, attachmentName, onUpload, onRemove, maxFiles = 1, fixedHeight, percentageHeight}: DropzoneComponentProps) => {
  const [files, setFiles] = useState<DropFile[]>([]);
  const [uploadFile, setUploadFile] = useState<DropFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [refreshKey, setRefreshKey] = useState(Date.now());

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const previewFiles = acceptedFiles.map(file => ({
      file,
      preview: getPreviewImage(file.type),
    }));
    
    setFiles(previewFiles);
    setUploadFile(previewFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: maxFiles,
    multiple: true,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    }
  });

  useEffect(() => {
    if (attachmentName && attachmentName.length > 0) {
      const newFiles = attachmentName.map((attachment) => {
        const fileName = decryptUniqueFilename(attachment.name)
        const fileExtension = attachment.name.split('.').pop()!;
  
        return {
          file: new File([], fileName),
          preview: getPreviewImage(fileExtension),
          uploadedId:  attachment.id,
          uploadedName: attachment.name,
        };
      });
  
      setFiles(newFiles);
    }
  }, [attachmentName]);

  /**
   * Removes a file from the list of files.
   * @param event - The mouse event triggered by clicking the remove button.
   */
  function removeFile(event: React.MouseEvent<HTMLSpanElement, MouseEvent>, file: DropFile) {
    event.stopPropagation();
    const fileIndex = files.findIndex(f => f.file.name === file.file.name);
    if (fileIndex > -1) {
        setFiles((prevFiles) => prevFiles.filter((_, index) => index !== fileIndex));
        if (file.uploadedName && file.uploadedId) {
          onRemove({id: file.uploadedId ,name:file.uploadedName});
        }
    }
  }

  useEffect(() => {
  }, [files]);

  /**
   * Uploads the selected file to the server.
   */
  async function uploadFiles() {
    if (uploadFile.length === 0) {
      return;
    }
    setUploading(true);
    const formData = new FormData();
    const elementsAttachmentRepository = new ElementAttachmentRepository();

    if (files.length === 0 || files.length > maxFiles) {
      setUploading(false);
      setError(T('Please select a file. Maximum files allowed is {{maxFile}}', { maxFile: maxFiles}));
      return;
    }

    
    files.forEach((fileObj) => {
      formData.append('file', fileObj.file);
    });
    const { filename, success } = await elementsAttachmentRepository.uploadMultipleAttachments("view", elementId, formData);
    
    if (!success) {
      setUploading(false);
      setError(T("Error uploading file. Please try again"));
      return;
    }

    const updatedFiles = files.map((file, index) => ({
      ...file,
      uploadedName: filename[index]
    }));
    
    setFiles(prevFiles => prevFiles.map((file, index) => ({
      ...file,
      uploadedName: filename[index]
    })));
  
    onUpload(filename, files);
    setUploading(false);
    setError(null);
    setUploadFile([]);
  }

  return (
    <div className="position-relative" style={fixedHeight ? { height: `${fixedHeight}px` } : {}}>
      {
        error && <div className="alert alert-danger d-flex align-items-center justify-content-between py-2 text-white" role="alert">
          <span>{error}</span>
          <button
            type="button"
            className="px-1 bg-transparent border-0 text-white fs-3"
            style={{ lineHeight: 0, cursor: 'pointer' }}
            aria-label={T("Close")}
            onClick={() => setError(null)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      }
      <div
        {...getRootProps()}
        ref={(node) => node && node.style.setProperty('border-style', 'dashed', 'important')}
        style={{ cursor: 'pointer', height: percentageHeight != null ? `${percentageHeight}%` : 'auto',}}
        className="dropzone border border-2 border-secondary rounded p-4 d-flex align-items-center justify-content-center gap-4"
      >
        {
          files.length > 0 ? (
            <>
              {files.map(({ file, preview, uploadedId, uploadedName }) => (
                <div key={file.name} className="position-relative m-2 d-flex flex-column align-items-center" style={{ width: '120px' }}>
                  <img
                    src={preview}
                    alt={file.name}
                    className="w-100 rounded"
                    style={{ aspectRatio: '1 / 1', objectFit: 'cover' }}
                  />
                  <p
                    className="position-absolute top-50 m-0 px-2 w-100 bg-light rounded-2 fw-bold text-dark text-sm text-wrap text-center"
                    style={{ transform: 'translateY(-50%)', opacity: 0.8 }}
                  >
                    {file.name}
                  </p>
                  <span
                    role="button"
                    tabIndex={0}
                    style={{ cursor: 'pointer' }}
                    data-file={file.name}
                    className="text-muted text-sm text-center fw-bold text-decoration-underline"
                    onClick={(event) => removeFile(event, { file, preview, uploadedId, uploadedName })}
                  >
                    {T("Remove file")}
                  </span>
                </div>
              ))}
            </>
          ) : (
            <>
              {
                isDragActive ?
                  <p className="m-0">{T("Drop the file here")} ...</p> :
                  <p className="m-0">{T("Drop file or click here to upload")}</p>
              }
            </>
          )
        }
        {files.length == 0 && (
        <input {...getInputProps()} />
          )}
      </div>
      <button
        type="button"
        className="btn btn-primary position-absolute bottom-0 end-0 m-4"
        onClick={uploadFiles}
      >
        {
          uploading ? (
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : T("Upload file")
        }
      </button>
    </div>
  );
}

export default MultipleDropzoneComponent;