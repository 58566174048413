import { T } from '@/utils/i18n-config';
import FilePreviewList from './file-preview';
import { useDropzoneUpload } from './use-dropzone-upload';

interface DropzoneComponentProps {
  elementId: number;
  renderingMode?: "edition" | "view";
  attachmentName?: string | string[];
  acceptedFiles?: string[];
  maxFiles?: number;
  onUpload: (attachmentName: string | string[], originalFilename: string | string[]) => void;
}

/**
 * Main dropzone component.
 * @param elementId - The element identifier.
 * @param renderingMode - The rendering mode.
 * @param attachmentName - The current attachment(s) name(s).
 * @param acceptedFiles - List of accepted file extensions.
 * @param onUpload - Callback fired after successful upload.
 */
function DropzoneComponent({
  elementId, renderingMode = "view", attachmentName, acceptedFiles, maxFiles, onUpload,
}: DropzoneComponentProps) {
  const {
    files, uploading, error, isDragActive, isFileLoaded, getRootProps, getInputProps, removeFile, uploadFiles,
  } = useDropzoneUpload({
    elementId,
    renderingMode,
    attachmentName,
    acceptedFiles,
    maxFiles,
    onUpload,
  });

  return (
    <div className="position-relative">
      {error && (
        <div className="alert alert-danger d-flex align-items-center justify-content-between py-2 text-white" role="alert">
          <span>{error}</span>
          <button
            type="button"
            className="px-1 bg-transparent border-0 text-white fs-3"
            style={{ lineHeight: 0, cursor: 'pointer' }}
            aria-label={T("Close")}
            onClick={() => { } }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      {uploading && (
        <div
          className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center bg-light rounded"
          style={{ opacity: 0.9, zIndex: 10 }}
        >
          <strong className="fs-4 text-dark">
            <em>{T("Files are being uploaded. Please do not close this view!")}</em>
          </strong>
        </div>
      )}
      <div
        {...getRootProps()}
        ref={(node) => node && node.style.setProperty('border-style', 'dashed', 'important')}
        style={{ cursor: 'pointer' }}
        className="dropzone border border-2 border-secondary rounded p-4 d-flex flex-wrap align-items-center justify-content-center"
      >
        {files.length > 0 ? (
          <FilePreviewList files={files} removeFile={removeFile} />
        ) : (
          <>
            {isDragActive ? (
              <p className="m-0">{T("Drop the file here")} ...</p>
            ) : (
              <p className="m-0">{T("Drop file or click here to upload")}</p>
            )}
          </>
        )}
        <input {...getInputProps()} />
      </div>
      {!isFileLoaded ? (
        <button
          type="button"
          className="btn btn-primary position-absolute bottom-0 end-0 m-3"
          onClick={uploadFiles}
        >
          {uploading ? (
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="visually-hidden">{T("Loading...")}</span>
            </div>
          ) : (
            T("Upload file")
          )}
        </button>
      ) : (
        <div title={T("Uploaded")} className="position-absolute bottom-0 end-0 p-2 m-3 bg-light border-secondary rounded-2 lh-1">
          <i className="fa-solid fa-check" aria-hidden="true"></i>
        </div>
      )}
    </div>
  );
}

export default DropzoneComponent;
