import React, { useState, useEffect, useRef, useMemo } from 'react'
import type { AnswerType, ItemAnswer, ItemOption } from '../../models/special-document/ElementArgs'
import { T } from "../../utils/i18n-config"
import { Permissions } from '../../models/special-document/ElementBase'
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { QuestionTableRepository } from "../../repository/special-document/QuestionTableRepository"
import { QuestionsTableElement } from '../../models/special-document/QuestionsTableElement'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import ViewModeProps from '../special-document/ViewModeProps'
import ViewModeBase from '../special-document/ViewModeBase'
import Summernote from '../utils/Summernote'
import Dropzone from '@/components/commons/dropzone/dropzone-component'

const QuestionWithAttachmentViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<QuestionsTableElement>(iElement as QuestionsTableElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [items, setItems] = useState(element.items)
  const [currentPage, setCurrentPage] = useState(0)
  const groupedQuestions = useMemo(() => {
    return Object.entries(items.slice(1).reduce((acc, item, index) => {
      if (index === 0 && item.type === 'question') acc['Ungrouped'] = [item]
      else if (item.type === 'subtitle') acc[item.text] = []
      else if (item.type === 'question') {
        const subtitles = Object.keys(acc);
        const currentSubtitle = subtitles.at(-1)!;
        acc[currentSubtitle].push(item);
      }
      return acc;
    }, {} as Record<string, ItemOption[]>))
  }, [items])

  async function retrieveExternalInformation() {
    const questionTableRepository = new QuestionTableRepository()
    const elementId = element.id
    const itemsExternal = items.map(async item => {
      if (item.answers) {
        const updatedAnswers = item.answers.map(async answer => {
          if (answer.type === "external" && !answer.externalContent) {
            const { success, content } = await questionTableRepository.getExternalContent(elementId, element.engagement_id, answer.content)
            if (success) return { ...answer, externalContent: content }
            return answer
          }
          return answer
        })
        return { ...item, answers: await Promise.all(updatedAnswers) }
      }
      return item
    })
    setItems((await Promise.all(itemsExternal)))
  }

  function hasExternalInformation() {
    const itemExternals = items.filter(item => item.answers?.some(answer => answer.type === "external"))
    return itemExternals.every(item => item.answers?.some(answer => !!answer?.externalContent))
  }

  function handleEdit() {
    setShowConfigurationsModal(true)
  }

  async function handleEditModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastItems = element.items
    element.items = items

    let success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      element.items = lastItems
      setItems(lastItems)
      window.htmlHelpers?.swalError()
    }
  }

  function handleEditModalCancel() {
    setItems(element.items)
    setShowConfigurationsModal(false)
  }

  function setItemAnswerContent(index: number, answerType: string, content: string) {
    const item = items[index]
    const answer = item?.answers?.find(a => a.type === answerType)
    const answers = answer != null
      ? item.answers?.with(item.answers.indexOf(answer), { ...answer, content })
      : item.answers
    const newItems = [...items].with(index, { ...item, answers: answers })
    setItems(newItems)
  }

  const handleBack = () => currentPage > 0 && setCurrentPage((prev) => prev - 1);
  const handleNext = () => currentPage < groupedQuestions.length - 1 && setCurrentPage((prev) => prev + 1);

  useEffect(() => {
    if (!hasExternalInformation() && !showConfigurationsModal) {
      retrieveExternalInformation()
      handleEditModalAccept()
    }
  }, [showConfigurationsModal])

  return (
    <>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        <div className="card card-body p-3 bg-light">
          <details>
            <summary>
              <h5 className="d-inline ms-2">{items[0].text}</h5>
            </summary>
            <div className="mb-2" />
            {items.slice(1).map((item, index) => (
              <div key={index} className="mb-3">
                {item.type === "subtitle" && (<b className="mt-2">{item.text}</b>)}
                {item.type === "question" && (<p className="mb-1">{item.text}</p>)}
                {item.answers && item.answers.length > 0 && (
                  <div className="d-flex align-items-center gap-2">
                    {item.answers.map((answer, index) => (
                      <span key={index} className="badge rounded-pill bg-primary">
                        {answer.type === "textbox" && (T("Justification"))}
                        {answer.type === "attachment" && (T("Attachment"))}
                        {answer.type === "yes/no" && (T("Yes") + "/" + T("No"))}
                        {answer.type === "unique" && (T("Single Choice"))}
                        {answer.type === "external" && (T("External Information"))}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </details>
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={element.items[0].text}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}
      >
        <div>
          <div className="d-flex justify-content-between">
            <button
              title={T("Previous Section")}
              className="btn btn-secondary"
              onClick={handleBack}
              disabled={currentPage === 0}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </button>
            <button
              title={T("Next Section")}
              className="btn btn-secondary"
              onClick={handleNext}
              disabled={currentPage === groupedQuestions.length - 1}
            >
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
          <div className="d-flex flex-column gap-3 mb-4 mx-auto" style={{ maxWidth: "75%" }}>
            {groupedQuestions.slice(currentPage, currentPage + 1).map(([subtitle, questions]) => (
              <>
                {subtitle !== 'Ungrouped' && (<b className="text-center fs-5">{subtitle}</b>)}
                <QuestionsSection
                  questions={questions}
                  elementId={element.id}
                  setAnswerContent={setItemAnswerContent}
                />
              </>
            ))}
          </div>
        </div>
      </ModalWithButtons>
    </>
  );
}


interface QuestionsSectionProps {
  questions: ItemOption[]
  elementId: number
  setAnswerContent: (index: number, answerType: AnswerType, content: string) => void
}
function QuestionsSection({ questions, elementId, setAnswerContent }: QuestionsSectionProps) {
  const [page, setPage] = useState(0)

  const handleBack = () => page > 0 && setPage((prev) => prev - 1);
  const handleNext = () => page < questions.length - 1 && setPage((prev) => prev + 1);

  return questions.slice(page, page + 1).map((question, index) => (
    <>
      <p key={index} className="mb-2 text-center">{question.text}</p>
      {question.answers?.map((answer, index) => (
        <QuestionAnswers
          key={index}
          answer={answer}
          itemIndex={index}
          elementId={elementId}
          uniqueId={question.type + question.text + index}
          onUpdate={setAnswerContent}
        />
      ))}
      <div className="d-flex justify-content-center gap-4 pt-4">
        <button
          className="btn btn-secondary"
          onClick={handleBack}
          disabled={page === 0}
        >{T("Previous Question")}</button>
        <button
          className="btn btn-primary"
          onClick={handleNext}
          disabled={page === questions.length - 1}
        >{T("Next Question")}</button>
      </div>
    </>
  ))
}


interface QuestionAnswersProps {
  answer: ItemAnswer
  itemIndex: number
  elementId: number
  uniqueId: string
  onUpdate: (index: number, answerType: AnswerType, content: string) => void
}
function QuestionAnswers({ answer, itemIndex, elementId, uniqueId, onUpdate }: QuestionAnswersProps) {
  const [content, setContent] = useState(answer.content)

  function setAnswerContent(value: string) {
    setContent(value)
    onUpdate(itemIndex, answer.type, value)
  }

  return (
    <>
      {answer.type === "textbox" && (
        <div className="mt-1">
          <label htmlFor="justification" className="text-sm">{T("Justification")}</label>
          <Summernote
            value={content}
            onChange={(value) => setAnswerContent(value)}
            maxLength={2000}
          />
        </div>
      )}
      {answer.type === "attachment" && (
        <div className="mt-1">
          <label htmlFor="attachment" className="text-sm">{T("Attachment")}</label>
          <Dropzone
            elementId={elementId}
            attachmentName={content}
            onUpload={(attachmentName) => setAnswerContent(attachmentName)}
          />
        </div>
      )}
      {answer.type === "yes/no" && (
        <div className="d-flex flex-column mt-1" style={{ paddingLeft: "45%" }}>
          <div className="form-check">
            <input
              value="no"
              type="radio"
              name="yes-no-option"
              className="form-check-input"
              id={`no-option-${itemIndex}`}
              onChange={(e) => setAnswerContent(e.target.value)}
            />
            <label className="form-check-label" htmlFor={`no-option-${itemIndex}`}>
              {T("No")}
            </label>
          </div>
          <div className="form-check">
            <input
              value="yes"
              type="radio"
              name="yes-no-option"
              className="form-check-input"
              id={`yes-option-${itemIndex}`}
              onChange={(e) => setAnswerContent(e.target.value)}
            />
            <label className="form-check-label" htmlFor={`yes-option-${itemIndex}`}>
              {T("Yes")}
            </label>
          </div>
        </div>
      )}
      {answer.type === "unique" && (
        <div className="d-flex flex-column" style={{ paddingLeft: "45%" }}>
          {answer.options?.map((option, index) => (
            <div className="form-check" key={index}>
              <input
                type="radio"
                name={uniqueId}
                id={answer.type + index}
                className="form-check-input"
                checked={content === "yes"}
                onChange={() => setAnswerContent(option)}
              />
              <label className="form-check-label" htmlFor={answer.type + index}>
                {option}
              </label>
            </div>
          ))}
        </div>
      )}
      {answer.type === "external" && (
        <div className="d-flex flex-column align-items-center my-1">
          <label htmlFor="external" className="text-sm">{T("External Information")}</label>
          <div className="py-3 px-3 mt-1 bg-light text-dark rounded-2" style={{ width: "fit-content" }}>
            {answer.content === "ofac" && (<b>{T("OFAC Results")}</b>)}
            {answer.content === "budgeted_hours" && (<b>{T("Budgeted Hours")}</b>)}
            {answer.content === "independence_format" && (<b>{T("Independence Format")}</b>)}
            <pre className="m-0 text-muted fs-6" style={{ fontFamily: "sans-serif" }}>{answer.externalContent}</pre>
          </div>
        </div>
      )}
    </>
  )
}

export default QuestionWithAttachmentViewMode;