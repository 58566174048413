import { T } from "@/utils/i18n-config"
import { decryptUniqueFilename, downloadFile } from '@/utils/utilities'
import DropzoneComponent from '@/components/utils/Dropzone'
import { ElementAttachmentRepository } from '@/repository/special-document/ElementAttachmentRepository'

interface AttachmentZoneProps {
  elementId: number
  templateName: string
  attachmentName: string
  onUpdateAttachment: (attachmentName: string) => void
  onAction: () => void
}
/**
 * Renders the AttachmentZone component that handles file upload/download and related actions.
 * @param elementId The element id.
 * @param templateName The template name.
 * @param attachmentName The attachment name.
 * @param onUpdateAttachment The callback function to update the attachment name.
 * @param onAction The callback function to handle the action.
 */
export function AttachmentZone({ elementId, templateName, attachmentName, onUpdateAttachment, onAction }: AttachmentZoneProps) {
  /**
   * Handles downloading the attachment by retrieving it from the repository and triggering a file download if the response is valid.
   */
  async function handleDownloadAttachment() {
    const elementAttachmentRepository = new ElementAttachmentRepository();
    const response = await elementAttachmentRepository.getAttachment("view", elementId, templateName);
    if (!(response instanceof Blob)) return window.htmlHelpers?.swalError();
    downloadFile(response, templateName);
  }

  return (
    <div className="d-flex flex-column gap-2 w-100">
      <h6 className="m-0">{T("Upload file")}</h6>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <p className="m-0">{T("Template")}: <em>{decryptUniqueFilename(templateName)}</em></p>
        <button
          type="button"
          style={{ cursor: 'pointer' }}
          className="d-flex align-items-center gap-2 p-1 bg-transparent border-0 text-secondary"
          onClick={handleDownloadAttachment}
        >
          <i className="fa-solid fa-download"></i>
          <span>{T("Download file")}</span>
        </button>
      </div>
      <DropzoneComponent
        elementId={elementId}
        renderingMode="view"
        attachmentName={attachmentName}
        onUpload={(attachmentName) => onUpdateAttachment(attachmentName)}
      />
      <button type="button" className="btn btn-primary align-self-center mt-3 mb-1" onClick={onAction}>
        {T("Extract Information")}
      </button>
    </div>
  )
}