import React, { useState, useRef, useEffect } from 'react';
import { T } from "../../utils/i18n-config"
import { FindingsElement } from '../../models/special-document/FindingsElement';
import EditModeBase from "../special-document/EditModeBase";
import EditModeProps from '../special-document/EditModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import ModalBase from '../modals/ModalBase';
import Select from "../commons/Select"
import { Option, RequiredAccountConfigurationFixedAssetsRepository } from '../../repository/substantive-tests/RequiredAccountConfigurationFixedAssetsRepository';


const RequiredAccountConfigurationFixedAssetsEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<FindingsElement>(iElement as FindingsElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [accountOptions, setAccountOptions] = useState<Option[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<number | undefined>();
  const [selectedRegisterBase, setSelectedRegisterBase] = useState<Option | undefined>();
  const [refresh, setRefresh] = useState(false);
  const [key, setKey] = useState(0);

  /**
  * Retrieves account configuration and ledger accounts, updating state accordingly.
  */
  const getRegisters = async () => {
    const requiredAccountConfigurationRepository = new RequiredAccountConfigurationFixedAssetsRepository();
    const response_accounts_configuration = await requiredAccountConfigurationRepository.getAccountsConfiguration(element.args.engagement_id, element.args.document_id, element.reference, "edition");
    if (response_accounts_configuration.success) {
      setSelectedRegisterBase(response_accounts_configuration.data);
      setKey(prevKey => prevKey + 1);
    }
    const response_ledger_accounts = await requiredAccountConfigurationRepository.getLedgerAccountsFixedAssets();
    setAccountOptions(response_ledger_accounts);
  }

  useEffect(() => {
    getRegisters();
  }, [refresh]);

  /**
  * Handles the acceptance action from the configurations modal, updates account configuration and closes the modal.
  */
  const handleConfigurationsModalAccept = () => {
    addAccountConfiguration();
    setShowConfigurationsModal(false);
  }

  /**
  * Cancels the configuration modal, resets selection and forces a re-render.
  */
  const handleCancel = () => {
    setSelectedAccount(undefined)
    setKey(prevKey => prevKey + 1);
    setShowConfigurationsModal(false)
  };

     /**
  * Opens the configurations modal.
  */
  const handleConfiguration = () => {
    setShowConfigurationsModal(true);
  }

  /**
   * Updates the account configuration using the selected account and forces a refresh of the state.
   */
  const addAccountConfiguration = async () => {
    const requiredAccountConfigurationRepository = new RequiredAccountConfigurationFixedAssetsRepository();
    const response_accounts_configuration = await requiredAccountConfigurationRepository.updateAccountConfiguration(element.args.engagement_id, element.args.document_id, { "reference" : element.reference, "ledger_account_id": selectedAccount }, "edition");
    setRefresh(!refresh);
  }

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={element.permissions as Permissions}>
        <div className="card h-100">
          <div className="card-body p-3 text-center">
          <h5>{T("Configuration")}</h5>
            <h6>{T("Required Accounts Settings")}</h6>
            <p>{T("Below, you can see the default configuration of the accounting accounts that will be analyzed in this substantive test.")}</p>
            <div>
                <p>{selectedRegisterBase?.label}</p>
              </div>
          </div>
        </div>
      </EditModeBase>
      <ModalBase
        showModal={showConfigurationsModal}
        title={T("Required Accounts Settings")}
        size="xl">
         <div className="modal-body text-center">
          <div>
          <div>
          <h5>{T("Configuration")}</h5>
            </div>
            <h6>{T("Required Accounts Settings")}</h6>
            <div className="d-flex justify-content-center mt-2">
              <div key={key}>
                <Select
                  hasSearch={false}
                  options={accountOptions}
                  defaultValue={selectedRegisterBase ? [selectedRegisterBase] : []}
                  placeholder={T("Select an option")}
                  onChange={(values) => { if (values.length) setSelectedAccount(Number(values[0].id)); }}
                  hasDelete={false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn bg-gradient-secondary" onClick={handleCancel}>{T("Cancel")}</button>
          <button type="button" className="btn bg-gradient-primary" onClick={handleConfigurationsModalAccept}>{T("Accept")}</button>
        </div>
      </ModalBase>
    </div>
  )
}
export default RequiredAccountConfigurationFixedAssetsEditMode;
