import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'


export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface Balances {
  value: number;
  percentage: number;
  period?: Date;
}

export interface RegistersCustomerInformationFixedAssets {
  id?: number
  fixed_assets?: string
  niif_state?: string
  account?: string
  description?: string
  acquisition_date?: Date
  total_cost_niif?: number
  residual_value?: number
  accumulated_depreciation?: number
  expense_in_the_year?: number
  net_cost_cut?: number
  useful_life_months?: number
  depreciated_accounting_times?: number
  invoice_number?: string
  additional_date?: Date | string
  additional_description?: string
  additional_cost?: number
  additional_vat?: number
  additional_total_cost?: number
  additional_difference?: number
} 

export class CustomerInformationFixedAssetsRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  /**
  * Retrieves customer fixed asset information from the API.
  * @param engagement_id Engagement identifier.
  * @param documentId Document identifier.
  * @param reference Reference string.
  * @returns Promise resolving to an object containing a success flag and the asset data.
  */
  async getCustomerInformationFixedAsset(engagement_id: number|null | undefined, documentId: number, reference: string | undefined): Promise<{success: boolean, data: RegistersCustomerInformationFixedAssets[]}> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/get_customer_information_fixed_asset/' + engagement_id + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {success: boolean, data: RegistersCustomerInformationFixedAssets[]} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
  * Updates the fixed asset registration with additional customer information.
  * @param engagementId Engagement identifier.
  * @param documentId Document identifier.
  * @param reference Reference string.
  * @param dataSubsequent Record of changes to update.
  * @returns Promise resolving to an object with a success flag and optionally an id.
  */
  async updateRegistersAdditionsCustomerInformation(engagementId: number| undefined| null,documentId: number, reference: string | undefined, dataSubsequent: Record<number, Partial<RegistersCustomerInformationFixedAssets>>): Promise<{ success: boolean, id?: number }> {
    const apiUrl = getUrl('substantive_test_fixed_assets_api/update_addition_customer_fixed_asset/' + engagementId + '/' + documentId + '/' + reference)

    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({"data_changes": dataSubsequent})
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return { success: false };
      }

      const data: {success: boolean, id: number} = await response.json()
      return { success: true, id: data.id };
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false }
    }
  }
}
