import React from "react"
import DropzoneDocumentSamplesComponent from "../utils/DropzoneDocumentSamples"


export interface DropFile {
  file: File;
  preview: string;
}

interface DropzoneComponentProps {
  elementId: number;
  attachmentName?: { id: number, name: string }[];
  onUpload: (attachmentName: string[], originalFilename: DropFile[]) => void;
  onRemove: (attachmentName: { id: number, name: string }) => void;
}


/**
 * Renders the dropzone component for meetings and members view.
 * @param {DropzoneComponentProps} props - Component properties.
 */
const DropzoneComponentScopeAndObjectivesView: React.FC<DropzoneComponentProps> = ({ elementId, attachmentName, onUpload, onRemove }) => {

  return (
    <div>
      <DropzoneDocumentSamplesComponent
        elementId={elementId}
        attachmentName={attachmentName }
        onUpload={onUpload}
        onRemove={onRemove}
      />
    </div>
  );
}

export default DropzoneComponentScopeAndObjectivesView;
