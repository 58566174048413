import { useState, useCallback } from 'react';

/**
 * Hook to manage a state with history.
 * @param initialState The initial state.
 * @param initialPosition (Optional) The initial position in the history.
 * @returns The state, a function to update the state, a function to undo, a function to redo, a boolean indicating if it can undo, and a boolean indicating if it can redo.
 */
function useHistoryState<T>(initialState: T | T[], initialPosition = 0) {
  const [history, setHistory] = useState(() => {
    if (Array.isArray(initialState)) return [...initialState];
    return [initialState];
  });
  const [position, setPosition] = useState(initialPosition);

  const current = history[position];

  // Function to update state and add it to history
  const setState = useCallback(
    (newState: T | ((prevState: T) => T)) => {
      const resolvedState =
        typeof newState === 'function' ? (newState as (prevState: T) => T)(current) : newState;

      // When state is updated, discard any "future" states
      const newHistory = history.slice(0, position + 1);
      setHistory([...newHistory, resolvedState]);
      setPosition(newHistory.length);
    },
    [history, position, current]
  );

  // Function to undo to the previous state
  const undo = useCallback(() => {
    if (position > 0) {
      setPosition(position - 1);
      setHistory(history.slice(0, position));
    }
  }, [position]);

  // Function to redo to the next state
  const redo = useCallback(() => {
    if (position < history.length - 1) {
      setPosition(position + 1);
    }
  }, [position, history.length]);

  return {
    state: current,
    setState,
    undo,
    redo,
    canUndo: position > 0,
    canRedo: position < history.length - 1,
  };
}

export default useHistoryState;
