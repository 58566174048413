import { ElementArgs, InternalControlComponentsQuestions } from "./ElementArgs";
import { IElementBase, ElementBase } from "./ElementBase";


export class InternalControlComponentsQuestionsElement extends ElementBase implements IElementBase{
  _type_section_id: number | undefined
  _title_section: string
  _paragraph_section: string
  _question_conclusion: number | undefined
  _questions_internal_control: InternalControlComponentsQuestions[]
  _initial_conclusion: string | null
  _basic_conclusion: string | null
  _intermediate_conclusion: string | null
  _optimal_conclusion: string | null

  constructor(args:ElementArgs) {
    super(args);
    this._type_section_id = args.type_section_id ?? undefined
    this._title_section = args.title_section ?? ""
    this._paragraph_section = args.paragraph_section ?? ""
    this._question_conclusion = args.question_conclusion ?? undefined
    this._questions_internal_control = args.questions_internal_control as InternalControlComponentsQuestions[] ?? [];
    this._initial_conclusion = args.initial_conclusion ?? null
    this._basic_conclusion = args.basic_conclusion ?? null
    this._intermediate_conclusion = args.intermediate_conclusion ?? null
    this._optimal_conclusion = args.optimal_conclusion ?? null
  }

  get type_section_id(): number | undefined {
    return this._type_section_id;
  }

  get title_section(): string {
    return this._title_section;
  }

  get paragraph_section(): string {
    return this._paragraph_section;
  }

  get question_conclusion(): number | undefined {
    return this._question_conclusion;
  }

  get questions_internal_control(): InternalControlComponentsQuestions[] {
    return this._questions_internal_control;
  }

  get initial_conclusion(): string | null {
    return this._initial_conclusion;
  }

  get basic_conclusion(): string | null {
    return this._basic_conclusion;
  }

  get intermediate_conclusion(): string | null {
    return this._intermediate_conclusion;
  }

  get optimal_conclusion(): string | null {
    return this._optimal_conclusion
  }

  set type_section_id(type_section_id: number | undefined) {
    this._type_section_id = type_section_id;
    this.args.type_section_id = type_section_id;
  }

  set title_section(title_section: string) {
    this._title_section = title_section;
    this.args.title_section = title_section;
  }

  set paragraph_section(paragraph_section: string) {
    this._paragraph_section = paragraph_section;
    this.args.paragraph_section = paragraph_section;
  }

  set question_conclusion(question_conclusion: number | undefined) {
    this._question_conclusion = question_conclusion;
    this.args.question_conclusion = question_conclusion;
  }

  set questions_internal_control(questions_internal_control: InternalControlComponentsQuestions[]) {
    this._questions_internal_control = questions_internal_control;
    this.args.questions_internal_control = questions_internal_control;
  }

  set initial_conclusion(initial_conclusion: string | null) {
    this._initial_conclusion = initial_conclusion;
    this.args.initial_conclusion = initial_conclusion;
  }

  set basic_conclusion(basic_conclusion: string | null) {
    this._basic_conclusion = basic_conclusion;
    this.args.basic_conclusion = basic_conclusion;
  }

  set intermediate_conclusion(intermediate_conclusion: string | null) {
    this._intermediate_conclusion = intermediate_conclusion;
    this.args.intermediate_conclusion = intermediate_conclusion;
  }

  set optimal_conclusion(optimal_conclusion: string | null) {
    this._optimal_conclusion = optimal_conclusion;
    this.args.optimal_conclusion = optimal_conclusion;
  }

  render(): void {
    console.log("render internal control components questions element")
  }
}