import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { T } from '../../utils/i18n-config'
import { BasicResponse } from './ThreeStatesTableRepository'

export interface IReturnId {
  id: number
}

export interface ResponseUpDown {
  success: boolean,
  id: number,
  swapId: number | null
}

export interface TextContentOpinion {
  title: string
  type_opinion?: string
  reference: string
  text_content: string
  answer_yes?: string
  answer_no?: string
  answer_selected?: number
  justification_yes?: string
  justification_no?: string
}

export interface Options {
  id: number,
  answer: string | undefined,
  justification: string | undefined,
}


/**
* ParagraphsTypesOpinionsRepository handles API interactions related to text content opinions and KAM paragraph opinions.
* It provides methods to update and retrieve opinions with properly typed parameters and responses.
*/
export class ParagraphsTypesOpinionsRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }

  /**
   * Creates a new instance of ParagraphsTypesOpinionsRepository.
   */
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) { }

  /**
 * Updates the type opinion for a given engagement document.
 * This function sends a request to update the type of opinion associated with a specific
 * engagement and document in a given rendering mode.
 * @param {number | undefined | null} engagementId - The ID of the engagement. Can be undefined or null if not applicable.
 * @param {number} documentId - The ID of the document whose type opinion is being updated.
 * @param {string} renderingMode - The rendering mode in which the update should be applied.
 * @param {{content: string, reference: string | undefined}} opinion_data - An object containing the opinion content and an optional reference.
 * @returns {Promise<boolean>} - A promise that resolves to `true` if the update was successful, or `false` otherwise.
 */
  async UpdateTypeOpinion(engagementId: number | undefined | null, documentId: number, renderingMode: string, opinion_data: { content: string, reference: string | undefined }): Promise<boolean> {
    const apiUrl = `${getUrl("elements_api/update_type_opinion")}/${engagementId}/${documentId}/${renderingMode}`

    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(opinion_data)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return false
      }

      const data: BasicResponse = await response.json()
      if (!data.success) {
        throw new Error(T("Error saving element"))
      }
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }
  }

  /**
 * Updates the KAM (Key Audit Matters) paragraph opinion for a given engagement document.
 * This function sends a request to update the KAM paragraph opinion, including its reference, 
 * related question, and additional data options.
 * @param {number | undefined | null} engagementId - The ID of the engagement. Can be `undefined` or `null` if not applicable.
 * @param {number} documentId - The ID of the document whose KAM paragraph opinion is being updated.
 * @param {string} renderingMode - The rendering mode in which the update should be applied.
 * @param {{reference: string | undefined, question: string | undefined, data: Options[]}} opinion_data - 
 *        An object containing the KAM opinion details:
 *        - `reference` (optional): A reference identifier for the opinion.
 *        - `question` (optional): The related audit question.
 *        - `data`: An array of `Options` representing additional KAM opinion details.
 * @returns {Promise<boolean>} - A promise that resolves to `true` if the update was successful, or `false` otherwise.
 */
  async UpdateKamParagraphOpinion(engagementId: number | undefined | null, documentId: number, renderingMode: string, opinion_data: { reference: string | undefined, question: string | undefined, data: Options[] }): Promise<boolean> {
    const apiUrl = `${getUrl("elements_api/update_kam_paragraph_opinion")}/${engagementId}/${documentId}/${renderingMode}`

    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(opinion_data)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return false
      }

      const data: BasicResponse = await response.json()
      if (!data.success) {
        throw new Error(T("Error saving element"))
      }
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }
  }

  /**
 * Retrieves an opinion based on reference and type of opinion for a given engagement document.
 * This function sends a request to fetch the opinion's text content based on the engagement ID, 
 * document ID, element ID, reference, type of opinion, and rendering mode.
 * @param {number | undefined | null} engagementId - The ID of the engagement. Can be `undefined` or `null` if not applicable.
 * @param {number} documentId - The ID of the document associated with the opinion.
 * @param {number} elementId - The specific element within the document for which the opinion is being retrieved.
 * @param {string | undefined} reference - The reference identifier for the opinion (optional).
 * @param {string | undefined} type_opinion - The type of opinion being requested (optional).
 * @param {string} renderingMode - The rendering mode in which the opinion should be retrieved.
 * @returns {Promise<{success: boolean; data: TextContentOpinion; not_necessary_kam?: boolean}>} 
 *          - A promise that resolves with an object containing:
 *            - `success`: A boolean indicating whether the request was successful.
 *            - `data`: The retrieved `TextContentOpinion` object.
 *            - `not_necessary_kam` (optional): A boolean indicating if KAM is not necessary.
 */
  async getOpinionByReferenceAndTypeOpinion(engagementId: number | undefined | null, documentId: number, elementId: number, reference: string | undefined, type_opinion: string | undefined, renderingMode: string): Promise<{ success: boolean; data: TextContentOpinion, not_necessary_kam?: boolean }> {
    const apiUrl = `${getUrl("elements_api/get_text_content_opinion")}/${engagementId}/${documentId}/${elementId}/${reference}/${type_opinion}/${renderingMode}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        console.error('Network response was not ok');
        return { success: false, data: {} as TextContentOpinion };
      }

      if (response.status !== 200) {
        console.error(`Status is not OK: ${response.status}`);
        return { success: false, data: {} as TextContentOpinion };
      }

      const data: { success: boolean; data: TextContentOpinion, not_necessary_kam?: boolean } = await response.json();
      return data;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return { success: false, data: {} as TextContentOpinion };
    }
  }

  /**
 * Updates the selected option for a KAM (Key Audit Matters) paragraph opinion.
 * This function sends a request to update the selected option for a KAM paragraph opinion
 * associated with a specific engagement document in the given rendering mode.
 * @param {number | undefined | null} engagementId - The ID of the engagement. Can be `undefined` or `null` if not applicable.
 * @param {number} documentId - The ID of the document whose KAM paragraph opinion is being updated.
 * @param {string} renderingMode - The rendering mode in which the update should be applied.
 * @param {{selectedId: number | undefined}} option_data - An object containing the selected option ID:
 *        - `selectedId` (optional): The ID of the selected option for the KAM paragraph opinion.
 * @returns {Promise<boolean>} - A promise that resolves to `true` if the update was successful, or `false` otherwise.
 */
  async UpdateOptionKamParagraphOpinion(engagementId: number | undefined | null, documentId: number, renderingMode: string, option_data: { selectedId: number | undefined }): Promise<boolean> {
    const apiUrl = `${getUrl("elements_api/update_option_kam_paragraph_opinion")}/${engagementId}/${documentId}/${renderingMode}`

    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(option_data)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        return false
      }

      const data: BasicResponse = await response.json()
      if (!data.success) {
        throw new Error(T("Error saving element"))
      }
      return data.success
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return false
    }
  }

  /**
 * Retrieves the selected option for a KAM (Key Audit Matters) paragraph opinion.
 * This function sends a request to fetch the selected option associated with a 
 * specific engagement document.
 * @param {number | undefined | null} engagementId - The ID of the engagement. Can be `undefined` or `null` if not applicable.
 * @param {number} documentId - The ID of the document whose selected KAM paragraph option is being retrieved.
 * @returns {Promise<{ success: boolean; selected_id: number }>} - A promise that resolves to an object containing:
 *          - `success`: A boolean indicating whether the request was successful.
 *          - `selected_id`: The ID of the currently selected option for the KAM paragraph opinion.
 */
  async getOptionKamParagraphOpinion(engagementId: number | undefined | null, documentId: number): Promise<{ success: boolean; selected_id: number }> {
    const apiUrl = `${getUrl("elements_api/get_option_kam_paragraph_opinion")}/${engagementId}/${documentId}`
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: { success: boolean; selected_id: number } = await response.json()
      if ("success" in data && !data.success) {
        throw new Error(T("Error getting elements"))
      }
      return data as { success: boolean; selected_id: number }
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}

