import React, { useState, useEffect, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { Permissions } from '../../models/special-document/ElementBase'
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { QuestionTextFileElement } from '../../models/special-document/QuestionTextFileElement'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeProps from '../special-document/EditModeProps'
import EditModeBase from '../special-document/EditModeBase'
import Summernote from '../utils/Summernote'

const QuestionTextFileEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<QuestionTextFileElement>(iElement as QuestionTextFileElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [question, setQuestion] = useState<string>(element.question)

  function handleConfiguration() {
    setShowConfigurationsModal(true)
  }

  async function handleConfigurationsModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastQuestion = element.question
    element.question = question

    let success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      element.question = lastQuestion
      setQuestion(lastQuestion)
      window.htmlHelpers?.swalError()
    }
  }

  function handleConfigurationsModalCancel() {
    setQuestion(element.question)
    setShowConfigurationsModal(false)
  }

  return (
    <>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}
      >
        <div className="card card-body p-3 bg-light" style={{ marginLeft: "2rem" }}>
          <pre
            className="m-0 text-dark fs-6 fw-bold text-wrap"
            style={{ fontFamily: "sans-serif" }}
            dangerouslySetInnerHTML={{ __html: question ? question : T("Question not defined") }}
          />
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Conditional settings")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}
      >
        <div className="form-group">
          <label htmlFor="question">{T("Question")}</label>
          <Summernote value={question} onChange={setQuestion} />
        </div>
      </ModalWithButtons>
    </>
  );
}

export default QuestionTextFileEditMode;