import { useState, useEffect } from "react";
import { GoingConcernRepository } from '../../repository/GoingConcernRepository';
import GoingConcernMemo from "./GoingConcernMemo";
import GoingConcernMemoDocument from "./GoingConcernMemoDocument";

interface GoingConcernContainerProps {
  engagementId: number;
}

export default function GoingConcernContainer({ engagementId }: Readonly<GoingConcernContainerProps>) {
  const [isFinished, setIsFinished] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const existAnswers = async () => {
      const repository = new GoingConcernRepository();
      const isFinished = await repository.getAllQuestionsFinished(engagementId);
      if (isFinished) setIsFinished(true);
      else setIsEditing(true);
    };

    existAnswers();
  }, []);

  function onEditing() {
    setIsEditing(true);
    setIsFinished(false);
  }

  function onFinished() {
    setIsFinished(true);
    setIsEditing(false);
  }

  if (isFinished) return <GoingConcernMemoDocument engagementId={engagementId} onEdit={onEditing} />;
  return <GoingConcernMemo engagementId={engagementId} onFinish={onFinished} isEditing={isEditing} />;
}