import React, { useState } from 'react'

interface ToggleInputParagraphProps {
  initialText: string
  onChange: (text: string) => void
}

/**
 * Component that allows to toggle between a paragraph and an input to edit it
 * @param initialText Initial text to display
 * @param onChange Callback to be called when the text is changed 
 */
const ToggleInputParagraph = ({ initialText, onChange }: ToggleInputParagraphProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const [text, setText] = useState(initialText)

  const handleToggle = () => {
    setIsEditing(!isEditing)
  }

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value
    setText(value)
    onChange(value)
  }

  return (
    <div className="d-flex justify-content-between align-items-center gap-2">
      {isEditing ? (
        <input
          type="text"
          className="form-control"
          value={text}
          onChange={handleChange}
        />
      ) : (
        <p className="flex-shrink-0 m-0">{text}</p>
      )}
      <button className="p-2 bg-secondary border-0 rounded text-white lh-1" onClick={handleToggle}>
        <i className={`fa-regular ${isEditing ? 'fa-check' : 'fa-edit'}`}></i>
      </button>
    </div>
  )
}

export default ToggleInputParagraph