import React, { useRef } from 'react';
import { T } from "../../utils/i18n-config"
import EditModeProps from './EditModeProps';
import { ConditionalParagraphElementWithSelector } from '../../models/special-document/ConditionalParagraphElementWithSelector';


/**
 * Component to display the KamParagraphOpinion in edit mode
 * @param iElement - interface with the element to display
 * @param handleDeleteElement - function to handle the delete element
 * @param handleUpElement - function to handle the up element
 * @param handleDownElement - function to handle the down element
 */
const KamParagraphOpinionEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<ConditionalParagraphElementWithSelector>(iElement as ConditionalParagraphElementWithSelector).current
 
  return (
    <div className="card h-100 bg-light col-11 mb-3">
      <div className="card-body p-3">
        <h6>{T("Element for displaying the kam paragraph")}</h6>
      </div>
    </div>
  );

};

export default KamParagraphOpinionEditMode;
