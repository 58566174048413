import type { JournalEntriesArgs, JournalItem } from '../special-document/ElementArgs'
import { ElementBase, IElementBase } from '../special-document/ElementBase'

export class JournalEntriesElement extends ElementBase implements IElementBase {
  private _items: JournalItem[]
  private _dateRange: string
  private _attachmentName: string
  private _attachmentTitle: string
  private _taskProcessingId?: string
  private _taskStatus?: string
  private _conclusion: string

  /**
   * Constructs a new JournalEntries instance and initializes properties from the provided arguments.
   * @param args - Object containing initialization values for the journal entry.
   */
  constructor(args: JournalEntriesArgs) {
    super(args)
    this._items = args.items
    this._dateRange = args.dateRange
    this._attachmentName = args.attachment_name
    this._attachmentTitle = args.attachment_title
    this._taskProcessingId = args.task_processing_id
    this._taskStatus = args.task_status
    this._conclusion = args.conclusion || ""
  }

  get items(): JournalItem[] {
    return this._items
  }

  get dateRange(): string {
    return this._dateRange
  }

  get attachmentName(): string {
    return this._attachmentName
  }

  get attachmentTitle(): string {
    return this._attachmentTitle
  }

  get taskProcessingId(): string | undefined {
    return this._taskProcessingId
  }

  get taskStatus(): string | undefined {
    return this._taskStatus
  }

  get conclusion(): string {
    return this._conclusion
  }

  get args(): JournalEntriesArgs {
    return {
      ...super.args,
      items: this._items,
      dateRange: this._dateRange,
      attachment_name: this._attachmentName,
      attachment_title: this._attachmentTitle,
      task_processing_id: this._taskProcessingId,
      task_status: this._taskStatus,
      conclusion: this._conclusion
    }
  }

  set items(items: JournalItem[]) {
    this._items = items
  }

  set dateRange(dateRange: string) {
    this._dateRange = dateRange
  }

  set attachmentName(attachmentName: string) {
    this._attachmentName = attachmentName
  }

  set attachmentTitle(attachmentTitle: string) {
    this._attachmentTitle = attachmentTitle
  }

  set taskProcessingId(taskProcessingId: string|undefined) {
    this._taskProcessingId = taskProcessingId
  }

  set taskStatus(taskStatus: string|undefined) {
    this._taskStatus = taskStatus
  }

  set conclusion(conclusion: string) {
    this._conclusion = conclusion
  }

  /**
   * Renders the journal entries. Implement this method with the necessary display logic.
   */
  render(): void {}
}