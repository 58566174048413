import React, { useState, useRef } from 'react'
import { T } from '../../utils/i18n-config'
import { decryptUniqueFilename } from '../../utils/utilities'
import { Permissions } from '../../models/special-document/ElementBase'
import { ElementRepository } from '../../repository/special-document/ElementRepository'
import { InformationExtractorElement } from '../../models/special-document/InformationExtractorElement'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import ViewModeProps from '../special-document/ViewModeProps'
import ViewModeBase from '../special-document/ViewModeBase'
import Summernote from '../utils/Summernote'
import Dropzone from "@/components/commons/dropzone/dropzone-component"

const InformationExtractorViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<InformationExtractorElement>(iElement as InformationExtractorElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [attachmentName, setAttachmentName] = useState<string>(element.attachmentName)
  const [extractedInformation, setExtractedInformation] = useState(element.extractedInformation)
  const [loading, setLoading] = useState(false)

  async function extractInformation() {
    if (!attachmentName) return window.htmlHelpers?.customSwalError(T("Please upload a file first"))

    const elementRepository = new ElementRepository()
    const response = await elementRepository.getExtractedValues(
      element.engagement_id, element.documentId, element.id, attachmentName, element.informationList
    )
    if(!response.success) return window.htmlHelpers?.swalError()
    setExtractedInformation(response.information)
    setLoading(false)
  }

  async function handleEditModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastAttachmentName = element.attachmentName
    const lastExtractedInformation = element.extractedInformation
    element.attachmentName = attachmentName
    element.extractedInformation = extractedInformation
    
    let success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      element.attachmentName = lastAttachmentName
      element.extractedInformation = lastExtractedInformation
      window.htmlHelpers?.swalError()
    }
  }

  function handleEditModalCancel() {
    setShowConfigurationsModal(false)
    setExtractedInformation(element.extractedInformation)
  }

  function handleEditorChange(content: string) {
    setExtractedInformation(content)
  }

  function handleEdit() {
    setShowConfigurationsModal(true)
  }

  async function handleExtractButton() {
    setLoading(true)
    await extractInformation()
  }

  return (
    <div className="mb-4">
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        {extractedInformation ? (
          <>
            <p><b>{T("Attachment")}:</b> {decryptUniqueFilename(attachmentName)}</p>
            <pre
              className="fs-6"
              style={{ fontFamily: "sans-serif" }}
              dangerouslySetInnerHTML={{ __html: extractedInformation }}
            ></pre>
          </>
        ) : (
            <p>No extracted information yet</p>
          )
        }
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Extracted Information")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
          {extractedInformation ? (
            <Summernote
              value={extractedInformation}
              onChange={handleEditorChange}
              showModal={showConfigurationsModal}
            />
          ) : (
            <div className="d-flex flex-column gap-4">
              <Dropzone
                elementId={element.id}
                attachmentName={attachmentName}
                onUpload={value => setAttachmentName(value)}
              />
              <button
                className="btn btn-primary align-self-end"
                onClick={handleExtractButton}
              >
                {
                  loading
                    ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)
                    : T("Extract Information")
                }
              </button>
            </div>
          )}
      </ModalWithButtons>
    </div>
  );
}

export default InformationExtractorViewMode;