import React, { useEffect, useState, useRef } from 'react';
import { CSSProperties } from "react";
import { T } from "../../utils/i18n-config"
import ViewModeProps from '../special-document/ViewModeProps';
import { ConditionalParagraphOption } from '../../models/special-document/ElementArgs';
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import ViewModeBase from "../special-document/ViewModeBase";
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { formatCurrency } from '../../utils/utilities';
import { TrialBalanceBankConciliationsRepository } from '../../repository/special-document/TrialBalanceBankConciliationsRepository';
import { GeneralDataBankConciliationsRepository, RegistersOfTrialBalanceFile } from '../../repository/special-document/GeneralDataBankConciliationsRepository';
import { Balances, RegistersFixedAssetsFile, TrialBalanceFixedAssetsRepository } from '../../repository/substantive-tests/TrialBalanceFixedAssetsRepository';
import { CustomerInformationFixedAssetsRepository, RegistersCustomerInformationFixedAssets } from '../../repository/substantive-tests/CustomerInformationAssetsRepository';
import { RecalculationCustomerFixedRepository, RegistersRecalculationCustomerFixedAssets } from '../../repository/substantive-tests/RecalculationCustomerFixedAssetsRepository';
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';


export interface AccountData {
  id: number;
  consignment_number: number;
  type_account: string;
  account: string;
  name: string;
  amount: number;
  amount_in_other_accounts: number;
  amount_per_statement: number;
  difference_amount: number;
}


const RecalculationCustomerFixedAssetsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement)
  const [isRefresh, setIsRefresh] = useState(false);
  const [totalColumnMonthlyDepreciation, setTotalColumnMonthlyDepreciation] = useState<number | undefined>(0);
  const [totalColumnAccumulatedDepreciation, setTotalColumnAccumulatedDepreciation] = useState<number | undefined>(0);
  const [totalColumnAccumulatedDifference, setTotalColumnAccumulatedDifference] = useState<number | undefined>(0);
  const [totalColumnAnnualDepreciationExpense, setTotalColumnAnnualDepreciationExpense] = useState<number | undefined>(0);
  const [totalColumnDifferenceDepreciation, setTotalColumnDifferenceDepreciation] = useState<number | undefined>(0);
  const [groupedData, setGroupedData] = useState<Record<string, RegistersRecalculationCustomerFixedAssets[]>>({});

  useEffect(() => {
    const handleEvent = () => {
      setIsRefresh(prev => !prev);
    };
    emitter.on("refreshSubstantiveBalanceData", handleEvent);
    return () => {
      emitter.off("refreshSubstantiveBalanceData", handleEvent);
    };
  }, []);

  /**
 * Fetches the registers from the trial balance file, groups the data,
 * and updates the relevant state variables.
 */
  const getRegistersOfTrialBalanceFile = async () => {
    const recalculationCustomerFixedRepository = new RecalculationCustomerFixedRepository()
    const result = await recalculationCustomerFixedRepository.getRecalculationCustomerFixedAsset(element.args.engagement_id, element.args.document_id, element.reference)

    const groupedData = result.data.reduce((acc: Record<string, any>, item) => {
      if (item.account) {
        acc[item.account] = acc[item.account] || [];
        acc[item.account].push(item);
      }
      return acc;
    }, {});
    setGroupedData(groupedData);
    setTotalColumnMonthlyDepreciation(
      result.data.reduce(
        (acc: number, item) =>
          acc + (item.monthly_depreciation ? item.monthly_depreciation : 0),
        0
      )
    )
    setTotalColumnAccumulatedDepreciation(
      result.data.reduce(
        (acc: number, item) =>
          acc + (item.accumulated_depreciation ? item.accumulated_depreciation : 0),
        0
      )
    )
    setTotalColumnAccumulatedDifference(
      result.data.reduce(
        (acc: number, item) =>
          acc + (item.accumulated_difference ? item.accumulated_difference : 0),
        0
      )
    )
    setTotalColumnAnnualDepreciationExpense(
      result.data.reduce(
        (acc: number, item) =>
          acc + (item.annual_depreciation_expense ? item.annual_depreciation_expense : 0),
        0
      )
    )
    setTotalColumnDifferenceDepreciation(
      result.data.reduce(
        (acc: number, item) =>
          acc + (item.difference_depreciation ? item.difference_depreciation : 0),
        0
      )
    )
  }

  useEffect(() => {
    getRegistersOfTrialBalanceFile()
  }, [isRefresh]);

  const [expandedAccounts, setExpandedAccounts] = useState<Record<string, boolean>>({});

  const toggleAccount = (account: string) => {
    setExpandedAccounts((prev) => ({
      ...prev,
      [account]: !prev[account],
    }));
  };

  const styles = {
    accordionItem: {
      border: "1px solid #ccc",
      marginBottom: "8px",
    },
    accordionHeader: {
      padding: "8px",
      backgroundColor: "#f7f7f7",
      cursor: "pointer",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse" as "collapse",
    },
    tableCell: {
      border: "1px solid #ddd",
      padding: "8px",
    }
  }

  const styleHeader: CSSProperties = {
    width: "11.11%",
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
    whiteSpace: "normal",
    verticalAlign: "middle"
  }

  return (
    <div>
      <ViewModeBase
        isEditable={false}
        permissions={element.permissions as Permissions}
      >
        <div className="d-flex w-full flex-column mb-3 card">
          <h6>{T("Recalculation Customer")}</h6>
          <div className="card-body">
            <table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }} className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th style={styleHeader} className="p-2">{T("Fixed Assets")}</th>
                  <th style={styleHeader} className="p-2">{T("Months Elapsed")}</th>
                  <th style={styleHeader} className="p-2">{T("Depreciation End")}</th>
                  <th style={styleHeader} className="p-2">{T("Monthly Depreciation")}</th>
                  <th style={styleHeader} className="p-2">{T("Accumulated Depreciation")}</th>
                  <th style={styleHeader} className="p-2">{T("Accumulated Difference")}</th>
                  <th style={styleHeader} className="p-2">{T("Annual Depreciation Expense")}</th>
                  <th style={styleHeader} className="p-2">{T("Difference Depreciation")}</th>
                  <th style={styleHeader} className="p-2">{T("Months to Depreciate Year")}</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(groupedData).map(([account, items]) => (
                  <React.Fragment key={account}>
                    <tr
                      style={{
                        backgroundColor: "#f7f7f7",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                      onClick={() => toggleAccount(account)}
                    >
                      <td
                        colSpan={12}
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                      {`${T("Account")}: ${account} (${items.length} ${T("registers")})`}
                      </td>
                    </tr>
                    {expandedAccounts[account] &&
                      items.map((item) => (
                        <tr key={item.id}>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.fixed_assets}>{item.fixed_assets}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.months_elapsed?.toString()}>{item.months_elapsed?.toString()}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.depreciation_end ? new Date(item.depreciation_end).toLocaleDateString() : ""}>{item.depreciation_end ? new Date(item.depreciation_end).toLocaleDateString() : ""}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.monthly_depreciation?.toString()}>{item.monthly_depreciation}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.accumulated_depreciation?.toString()}>{item.accumulated_depreciation}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.accumulated_difference?.toString()}>{item.accumulated_difference}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.annual_depreciation_expense?.toString()}>{item.annual_depreciation_expense}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.difference_depreciation?.toString()}>{item.difference_depreciation}</td>
                          <td className="p-1" style={{
                            width: "20%",
                            border: "1px solid #ddd",
                            padding: "8px",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "42.6px",
                          }}
                            title={item.months_to_depreciate_year?.toString()}>{item.months_to_depreciate_year}</td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
                <tr>
                <td className="p-1" style={{
                  width: "20%",
                  border: "1px solid #ddd",
                  padding: "8px",
                  whiteSpace: "normal",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxHeight: "42.6px",
                }}>{}</td>
                <td className="p-1" style={{
                  width: "20%",
                  border: "1px solid #ddd",
                  padding: "8px",
                  whiteSpace: "normal",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxHeight: "42.6px",
                }}>{}</td>
                <td className="p-1" style={{
                  width: "20%",
                  border: "1px solid #ddd",
                  padding: "8px",
                  whiteSpace: "normal",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxHeight: "42.6px",
                }}>{formatCurrency(totalColumnMonthlyDepreciation?.toFixed(2))}</td>
                <td className="p-1" style={{
                  width: "20%",
                  border: "1px solid #ddd",
                  padding: "8px",
                  whiteSpace: "normal",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxHeight: "42.6px",
                }}>{formatCurrency(totalColumnAccumulatedDepreciation?.toFixed(2))}</td>
                <td className="p-1" style={{
                  width: "20%",
                  border: "1px solid #ddd",
                  padding: "8px",
                  whiteSpace: "normal",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxHeight: "42.6px",
                }}>{formatCurrency(totalColumnAccumulatedDifference?.toFixed(2))}</td>
                <td className="p-1" style={{
                  width: "20%",
                  border: "1px solid #ddd",
                  padding: "8px",
                  whiteSpace: "normal",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxHeight: "42.6px",
                }}>{formatCurrency(totalColumnAnnualDepreciationExpense?.toFixed(2))}</td>
                                <td className="p-1" style={{
                  width: "20%",
                  border: "1px solid #ddd",
                  padding: "8px",
                  whiteSpace: "normal",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxHeight: "42.6px",
                }}>{formatCurrency(totalColumnDifferenceDepreciation?.toFixed(2))}</td>
                <td className="p-1" style={{
                  width: "20%",
                  border: "1px solid #ddd",
                  padding: "8px",
                  whiteSpace: "normal",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxHeight: "42.6px",
                }}>{}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ViewModeBase>
    </div>
  );
};

export default RecalculationCustomerFixedAssetsViewMode;
